<template>
  <div>
    <div class="title is-5">
      User: {{ user.id }} - {{ user.name }} {{ user.surname }}
    </div>

    <div class="title is-5">Addresses:</div>

    <span v-if="user.addresses && user.addresses.length">
      <span v-for="address in user.addresses" :key="address.id">
        <userAddress :address="address" />
        <br />
      </span>
    </span>

    <div class="title is-5">Orders:</div>
    <userOrders
      v-if="user.orders && user.orders.length"
      :userOrders="user.orders"
    />
  </div>
</template>

<script>
import userAddress from "./AdminUserAddress.vue";
import userOrders from "./AdminUserOrders.vue";
export default {
  components: {
    userAddress,
    userOrders,
  },
  props: [],
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.getters["adminUsers/user"];
    },
  },
  methods: {},
  mounted() {
    this.$store.dispatch(
      "adminUsers/getUser",
      this.$router.currentRoute.params.user
    );
  },
};
</script>
