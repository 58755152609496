<style scoped>
.is-active {
  background-color: white !important;
}
</style>

<template>
  <div>
    <aside class="menu">
      <ul class="menu-list">
        <li>
          <router-link
            exact
            @click="closeMobile()"
            tag="a"
            :to="{ name: 'admin' }"
            >Website</router-link
          >
        </li>
        <li>
          <router-link
            @click="closeMobile()"
            tag="a"
            :to="{ name: 'admin-orders' }"
            >Orders</router-link
          >
        </li>
        <li>
          <router-link
            @click="closeMobile()"
            tag="a"
            :to="{ name: 'admin-categories' }"
            >Categories</router-link
          >
        </li>
        <li>
          <router-link
            @click="closeMobile()"
            tag="a"
            :to="{ name: 'admin-products' }"
            >Products</router-link
          >
        </li>
        <li>
          <router-link
            @click="closeMobile()"
            tag="a"
            :to="{ name: 'admin-invoices' }"
            >Invoices</router-link
          >
        </li>
        <li>
          <router-link
            @click="closeMobile()"
            tag="a"
            :to="{ name: 'admin-shipping-labels' }"
            >Shipping</router-link
          >
        </li>
        <li>
          <router-link
            @click="closeMobile()"
            tag="a"
            :to="{ name: 'admin-revenue' }"
            >Revenue</router-link
          >
        </li>
        <li>
          <router-link
            @click="closeMobile()"
            tag="a"
            :to="{ name: 'admin-prices' }"
            >Countries</router-link
          >
        </li>
        <li>
          <router-link
            @click="closeMobile()"
            tag="a"
            :to="{ name: 'admin-users' }"
            >Users</router-link
          >
        </li>

        <li>
          <router-link
            @click="closeMobile()"
            tag="a"
            :to="{ name: 'admin-settings' }"
            >Settings</router-link
          >
        </li>
      </ul>
    </aside>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>
