var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('nav',{staticClass:"navbar",attrs:{"role":"navigation","aria-label":"main navigation"}},[_c('div',{staticClass:"navbar-brand"},[_c('router-link',{staticClass:"navbar-item",attrs:{"tag":"a","to":{ name: 'home' }}},[_c('img',{attrs:{"src":require("@/assets/pics/bannerKnusKeramiek.png"),"width":"112","height":"28"}})]),_c('a',{staticClass:"navbar-burger",attrs:{"role":"button","aria-label":"menu","aria-expanded":"true","data-target":"navbarBasicExample"},on:{"click":function($event){return _vm.toggleMobile()}}},[_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"aria-hidden":"true"}})])],1),_c('div',{class:['navbar-menu', { 'is-active': _vm.showMobile }]},[_c('div',{staticClass:"navbar-start"},[_c('singleLink',{attrs:{"link":{
            naam: `Home`,
            routerLink: 'home',
            exact: true,
          }},on:{"closedMobileMenu":function($event){return _vm.closeMobile()}}}),_c('singleLink',{attrs:{"link":{
            naam: `Shop`,
            routerLink: 'webshop-categories',
          }},on:{"closedMobileMenu":function($event){return _vm.closeMobile()}}}),_c('singleLink',{attrs:{"link":{
            naam: `About`,
            routerLink: 'about',
          }},on:{"closedMobileMenu":function($event){return _vm.closeMobile()}}}),_c('singleLink',{attrs:{"link":{
            naam: `FAQ`,
            routerLink: 'faq',
          }},on:{"closedMobileMenu":function($event){return _vm.closeMobile()}}}),_c('singleLink',{attrs:{"link":{
            naam: `Contact`,
            routerLink: 'contact',
          }},on:{"closedMobileMenu":function($event){return _vm.closeMobile()}}}),(_vm.user.role === 'admin')?_c('singleLink',{attrs:{"link":{
            naam: `Admin`,
            routerLink: 'admin',
          }},on:{"closedMobileMenu":function($event){return _vm.closeMobile()}}}):_vm._e()],1),_c('div',{staticClass:"navbar-end"},[(_vm.user.id)?_c('dropDownLink',{attrs:{"type":"button","showMobileParent":_vm.showMobile,"link":{
            naam: _vm.user.name,
            routerLink: 'instellingen',
            exact: true,
            children: [
              {
                naam: 'Account',
                routerLink: 'account',
                exact: true,
              },
              {
                naam: 'Change password',
                routerLink: 'account-change-password',
              },
              {
                naam: 'Orders',
                routerLink: 'account-orders',
              },
              {
                divider: true,
              },
              {
                naam: 'Logout',
                emit: 'logout',
              },
            ],
          }},on:{"closedMobileMenu":function($event){return _vm.closeMobile()},"logout":function($event){return _vm.logout()}}}):_vm._e(),(!_vm.user.id && _vm.userToolsVisibleCheck())?_c('div',{staticClass:"navbar-item"},[_c('div',{staticClass:"buttons"},[_c('b-button',{attrs:{"type":"is-rounded","icon-left":"user","size":"is-small"},on:{"click":function($event){return _vm.openRegisterModal()}}},[_vm._v("Register")])],1)]):_vm._e(),(!_vm.user.id && _vm.userToolsVisibleCheck())?_c('div',{staticClass:"navbar-item"},[_c('div',{staticClass:"buttons"},[_c('b-button',{attrs:{"type":"is-rounded","icon-left":"user","size":"is-small"},on:{"click":function($event){return _vm.openLoginModal()}}},[_vm._v("Log in")])],1)]):_vm._e(),(_vm.$route.name !== 'webshop-checkout' && _vm.cartVisibleCheck())?_c('div',{staticClass:"navbar-item"},[_c('div',{staticClass:"buttons"},[_c('b-button',{attrs:{"type":"is-rounded","icon-left":"shopping-cart","size":"is-small"},on:{"click":function($event){return _vm.openCart()}}},[_vm._v(_vm._s(_vm.countCartItems()))])],1)]):_vm._e()],1)])]),_c('loginModal'),_c('registerModal'),_c('passwordResetModal'),_c('shoppingcartModal')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }