<style>
#footer {
  background-color: #ededed !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.padding {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>

<template>
  <div>
    <br />
    <div
      id="footer"
      v-if="settings && settings.variables && settings.variables.length"
    >
      <div class="container has-text-centered is-fluid">
        Knuskeramiek &nbsp;/ &nbsp;

        <a href="https://www.instagram.com/knuskeramiek"
          ><img
            src="@/assets/pics/instagram.png"
            width="16"
            height="16"
          />&nbsp;Instagram</a
        >
        <br />
        <div class="padding">
          For questions or other inqueries, feel free to contact me at:
          <a
            :href="
              'mailto:' +
              settings.variables.find((a) => a.key === 'company_contact_email')
                .value
            "
            >{{
              settings.variables.find((a) => a.key === "company_contact_email")
                .value
            }}</a
          >.
        </div>
        <a @click="showTermsAndConditions()"
          >Terms and Conditions / Shipping & Return / Privacy Policy</a
        >
        <br />
        &copy; {{ new Date().getFullYear() }}
        {{ settings.variables.find((a) => a.key === "company_website").value }}.
        All rights reserved.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {
    settings() {
      return this.$store.getters["shop/settings"];
    },
  },
  methods: {
    showTermsAndConditions() {
      this.$store.dispatch("shop/showTermsAndConditions");
    },
  },
  mounted() {},
};
</script>
