<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}

.table-icons {
  float: right;
}

.box {
  border-color: #333 !important;
  border: solid;
  border-width: thin;
  /* Set the hr color */
}

img {
  max-height: 150px;
}

hr {
  border: none;
  height: 1px;
  /* Set the hr color */
  color: #333; /* old IE */
  background-color: #333; /* Modern Browsers */
  margin-top: 0px;
}
</style>

<template>
  <div>
    <div>
      <div class="box" v-for="order of orders" :key="order.id">
        <div class="columns">
          <div class="column">
            <strong>Buyer: </strong>{{ order.name }} {{ order.surname }} <br />
            <strong>Email: </strong>
            <a :href="'mailto:' + order.email">{{ order.email }}</a>
          </div>
          <div class="column">
            <strong>Account: </strong>
            <span v-if="order.user_id"
              >{{ order.user.name }} {{ order.user.surname }} <br />
              <strong>Total Orders:</strong>
              {{ order.user.orders.length }} </span
            ><span v-else>No</span>
          </div>
        </div>
        <hr />
        <div class="columns">
          <div class="column">
            <strong>{{ formatDatabaseDate(order.created_at) }}</strong> <br />
            Payment Method: {{ order.payment_method }} <br />
            Payment Status:
            {{ hoofdletter(order.payment_status) }} <br />
            <br />
            <strong>
              {{ order.product_count }}
              <span v-if="order.product_count === 1">Item</span
              ><span v-else>Items</span>
            </strong>
          </div>
          <div class="column">
            <strong>Order: {{ formatOrderNumber(order) }}</strong> <br />
            Shipping Method: {{ order.shipping_method }} <br />
            Shipping Status:
            {{ hoofdletter(order.shipping_status) }} <br />
            <br />
            <strong>Total: {{ euroFromCents(order.total_price) }}</strong>
          </div>
        </div>
        <hr />
        <div class="columns">
          <div class="column">
            <div class="columns is-mobile">
              <div class="column is-3" v-for="product of 4" :key="product">
                <b-image
                  ratio="1by1"
                  v-if="order.order_products[product - 1]"
                  :src="
                    env.VUE_APP_API_URL +
                    '/web/order-picture/' +
                    order.order_products[product - 1].picture_uuid
                  "
                  alt="Product Image"
                />
              </div>
            </div>
          </div>
          <div class="column">
            <button
              class="button is-fullwidth is-primary"
              @click="orderClicked(order)"
            >
              Show Order
            </button>

            <br />

            <span
              v-if="
                order.shipping_method !== 'Pickup in Zutphen' &&
                order.shipping_labels.length
              "
            >
              <button
                v-if="checkLabelStatus(order) === 'concept'"
                class="button is-fullwidth is-primary"
                :class="{ 'is-loading': ajaxloading.register_shipping_label }"
                @click="registerLabel(order)"
              >
                Register shipping label
              </button>
              <button
                v-else
                class="button is-fullwidth is-primary"
                @click="labelClicked(order)"
                @click.middle="labelClicked(order)"
              >
                Download shipping label
              </button>
            </span>

            <span v-for="invoice in order.all_invoices" :key="invoice.id">
              <br />
              <button
                @click="openPdf(invoice)"
                @click.middle="openPdf(invoice)"
                class="button is-fullwidth is-primary"
              >
                Show invoice {{ invoice.invoice_year }}-{{
                  invoice.invoice_number
                }}
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    propOrders: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      env: process.env,
      selected: {},
      isLoading: true,
    };
  },
  computed: {
    settings() {
      return this.$store.getters["shop/settings"];
    },
    orders() {
      const orders = this.propOrders;

      orders.sort((a, b) => {
        if (a.id > b.id) {
          return -1;
        } else {
          return 1;
        }
      });
      return orders;
    },
  },
  methods: {
    openPdf(invoice) {
      window.open(
        this.env.VUE_APP_API_URL + "/web/show-user-invoice/" + invoice.id
      );
    },
    labelClicked(order) {
      const label = order.shipping_labels[order.shipping_labels.length - 1];

      const extension = this.settings.variables.find(
        (a) => a.key === "shipping_label_extension"
      ).value;

      if (extension === ".pdf") {
        window.open(
          this.env.VUE_APP_API_URL +
            "/web/admin-show-pdf-shipping-label/" +
            label.id
        );
      }

      if (extension === ".zpl") {
        window.open(
          this.env.VUE_APP_API_URL +
            "/web/admin-show-zpl-shipping-label/" +
            label.id
        );
      }
    },
    formatOrderNumber(order) {
      return order.order_year + "-" + order.order_number;
    },
    orderClicked(order) {
      this.$router.push({ name: "admin-order", params: { order: order.id } });
    },
    checkLabelStatus(order) {
      const label = order.shipping_labels[order.shipping_labels.length - 1];
      if (label) {
        return label.shipping_status;
      }
      return "concept";
    },
    registerLabel(order) {
      const label = order.shipping_labels[order.shipping_labels.length - 1];
      this.$store.dispatch("adminOrders/registerShippingLabel", label);
    },
  },
  watch: {},
  mounted() {},
};
</script>
