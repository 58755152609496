<template>
  <div>
    <div class="title">Orders</div>
    <b-field label="">
      <b-input
        v-model="zoekwaarde"
        @input="zoekOrders()"
        placeholder="Zoeken..."
      ></b-input>
    </b-field>
    <br />
    <span v-if="zoekwaarde.length < 3">
      <b-tabs expanded v-model="activeTab">
        <b-tab-item
          value="needs_packing"
          v-if="needsPacking.length || zoekwaarde.length < 3"
        >
          <template #header>
            <span>
              Needs packing
              <b-tag class="is-primary" v-if="needsPacking.length" rounded>{{
                needsPacking.length
              }}</b-tag>
            </span>
          </template>
          <order :propOrders="needsPacking" />
        </b-tab-item>

        <b-tab-item
          value="ready_for_pickup"
          v-if="readyForPickupOrders.length || zoekwaarde.length < 3"
        >
          <template #header>
            <span>
              Ready for pickup
              <b-tag
                class="is-primary"
                rounded
                v-if="readyForPickupOrders.length"
                >{{ readyForPickupOrders.length }}</b-tag
              >
            </span>
          </template>
          <order :propOrders="readyForPickupOrders" />
        </b-tab-item>

        <b-tab-item
          value="ready_for_shipping"
          v-if="readyForShippingOrders.length || zoekwaarde.length < 3"
        >
          <template #header>
            <span>
              Ready for shipping
              <b-tag
                class="is-primary"
                v-if="readyForShippingOrders.length"
                rounded
                >{{ readyForShippingOrders.length }}</b-tag
              >
            </span>
          </template>
          <order :propOrders="readyForShippingOrders" />
        </b-tab-item>

        <b-tab-item
          value="shipped"
          v-if="shippedOrders.length || zoekwaarde.length < 3"
        >
          <template #header>
            <span>
              In transit
              <b-tag class="is-primary" v-if="shippedOrders.length" rounded>{{
                shippedOrders.length
              }}</b-tag>
            </span>
          </template>
          <order :propOrders="shippedOrders" />
        </b-tab-item>

        <b-tab-item value="waiting_for_payment" v-if="waitingForPayment.length">
          <template #header>
            <span>
              Waiting for Payment
              <b-tag
                class="is-primary"
                v-if="waitingForPayment.length"
                rounded
                >{{ waitingForPayment.length }}</b-tag
              >
            </span>
          </template>
          <adminOrderTable :propOrders="waitingForPayment" />
        </b-tab-item>

        <b-tab-item
          value="archive"
          v-if="deliveredOrders.length || zoekwaarde.length < 3"
        >
          <template #header>
            <span>
              Archive
              <b-tag class="is-primary" v-if="deliveredOrders.length" rounded>{{
                deliveredOrders.length
              }}</b-tag>
            </span>
          </template>
          <adminOrderTable :propOrders="deliveredOrders" />
        </b-tab-item>
      </b-tabs>
    </span>

    <span v-else-if="zoekwaarde.length > 2 && orders.length > 0">
      <adminOrderTable :sortOrders="false" :propOrders="orders" />
    </span>

    <span v-if="zoekwaarde.length > 2 && orders.length === 0">
      No orders found
    </span>
  </div>
</template>

<script>
import order from "./Order.vue";
import adminOrderTable from "./AdminOrdersTable.vue";

export default {
  components: {
    order,
    adminOrderTable,
  },
  props: [],
  data() {
    return {
      activeTab: "needs_packing",
      zoekwaarde: "",
      searchedOrders: [],
    };
  },
  computed: {
    storeOrders() {
      return this.$store.getters["adminOrders/orders"];
    },
    orders() {
      if (this.zoekwaarde.length < 3) {
        return this.storeOrders;
      } else {
        return this.searchedOrders;
      }
    },
    needsPacking() {
      return this.orders.filter(
        (a) =>
          a.shipping_status === "ready for packing" &&
          a.payment_status === "paid"
      );
    },
    readyForShippingOrders() {
      return this.orders.filter(
        (a) =>
          a.shipping_status === "ready for shipping" &&
          a.payment_status === "paid"
      );
    },
    readyForPickupOrders() {
      return this.orders.filter(
        (a) =>
          a.shipping_status === "ready for pickup" &&
          a.payment_status === "paid"
      );
    },
    shippedOrders() {
      return this.orders.filter(
        (a) => a.shipping_status === "shipped" && a.payment_status === "paid"
      );
    },
    waitingForPayment() {
      return this.orders.filter(
        (a) =>
          a.payment_status === "waiting for payment" &&
          a.shipping_status === "waiting for payment"
      );
    },
    deliveredOrders() {
      return this.orders.filter(
        (a) =>
          a.shipping_status === "delivered" ||
          a.shipping_status === "picked up" ||
          a.shipping_status === "canceled"
      );
    },
  },
  methods: {
    zoekOrders() {
      const data = {
        zoekwaarde: this.zoekwaarde,
      };

      if (data.zoekwaarde.length > 2) {
        this.$store
          .dispatch("adminOrders/searchOrders", data)
          .then((response) => {
            this.searchedOrders = response.data;
          });
      }
    },
    openPdf(invoice) {
      window.open(
        this.env.VUE_APP_API_URL + "/web/show-user-invoice/" + invoice.id
      );
    },
    syncActiveTabToQuery() {
      if (this.$router.currentRoute.query.activeTab != this.activeTab) {
        this.$router
          .replace({ query: { activeTab: this.activeTab } })
          .catch(() => {});
      }
    },
    getActiveTabFromQuery() {
      if (this.$router.currentRoute.query.activeTab) {
        this.activeTab = this.$router.currentRoute.query.activeTab;
      }
    },
    evalActiveTab() {
      if (this.needsPacking.length) {
        return this.setActiveTab("needs_packing");
      }

      if (this.readyForShippingOrders.length) {
        return this.setActiveTab("ready_for_pickup");
      }

      if (this.readyForPickupOrders.length) {
        return this.setActiveTab("ready_for_shipping");
      }

      if (this.shippedOrders.length) {
        return this.setActiveTab("shipped");
      }

      if (this.waitingForPayment.length) {
        return this.setActiveTab("waiting_for_payment");
      }

      if (this.deliveredOrders.length) {
        return this.setActiveTab("archive");
      }
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
  },
  watch: {
    searchedOrders: {
      deep: false,
      handler() {
        this.evalActiveTab();
      },
    },
    activeTab: {
      deep: false,
      handler() {
        this.syncActiveTabToQuery();
      },
    },
  },
  mounted() {
    this.getActiveTabFromQuery();
  },
};
</script>
