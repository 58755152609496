<template>
  <div>
    <div class="columns">
      <div class="column">
        <div class="title is-4">Edit Order: {{ formatOrderNumber(order) }}</div>
        <div class="subtitle" v-if="order.id">
          Placed on {{ formatDatabaseDate(order.created_at) }}
        </div>

        <div class="columns">
          <div class="column">
            <button class="button is-fullwidth" @click="cancel()">
              Back to order
            </button>
          </div>

          <div class="column">
            <button
              class="button is-fullwidth is-danger"
              @click="deleteOrderConfirm()"
            >
              Delete order
            </button>
          </div>
        </div>

        <div class="title is-5">Order</div>

        <div class="columns">
          <div class="column">
            <formInput label="on-border" :model="form" modelKey="email" />
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <formSelect
              v-if="settings"
              label="on-border"
              :model="form"
              modelKey="shipping_method"
              :selectOptions="
                settings.admin.shipping_methods.map((a) => a.name)
              "
            />
            <br />
            <formSelect
              v-if="settings"
              label="on-border"
              :model="form"
              modelKey="payment_method"
              :selectOptions="settings.admin.payment_methods.map((a) => a.name)"
            />
          </div>
          <div class="column">
            <formSelect
              v-if="settings"
              label="on-border"
              :model="form"
              modelKey="shipping_status"
              :selectOptions="settings.admin.shipping_statuses"
            />
            <br />
            <formSelect
              v-if="settings"
              label="on-border"
              :model="form"
              modelKey="payment_status"
              :selectOptions="settings.admin.payment_statuses"
            />
          </div>
        </div>
        <div
          class="columns"
          v-if="order.shipping_method !== 'Pickup in Zutphen'"
        >
          <div class="column">
            <formSelect
              v-if="settings"
              label="on-border"
              :model="form"
              modelKey="package_type"
              :selectOptions="settings.admin.package_types"
            />
          </div>
          <div class="column">
            <formInput
              label="on-border"
              :model="form"
              title="Total Weight in grams"
              modelKey="total_weight"
            />
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <div class="title is-5">Billing address</div>
            <!-- name -->
            <div class="columns">
              <div class="column">
                <formInput label="on-border" :model="form" modelKey="name" />
              </div>
              <div class="column">
                <formInput label="on-border" :model="form" modelKey="surname" />
              </div>
            </div>

            <formInput
              label="on-border"
              :model="form"
              modelKey="business_name"
            />

            <br />

            <!-- street -->
            <formInput label="on-border" :model="form" modelKey="street" />
            <br />
            <!-- postalcode -->
            <div class="columns">
              <div class="column">
                <formInput
                  label="on-border"
                  :model="form"
                  modelKey="postal_code"
                />
              </div>
              <div class="column">
                <formInput
                  label="on-border"
                  :model="form"
                  modelKey="house_number"
                />
              </div>
            </div>
            <!-- city -->
            <div class="columns">
              <div class="column">
                <formInput label="on-border" :model="form" modelKey="city" />
              </div>
              <div class="column">
                <formInput label="on-border" :model="form" modelKey="state" />
              </div>
            </div>
            <formInput label="on-border" :model="form" modelKey="country" />
          </div>
          <div class="column">
            <div class="title is-5">Shipping address</div>
            <div class="columns">
              <div class="column">
                <formInput
                  label="on-border"
                  :model="form"
                  modelKey="send_name"
                />
              </div>
              <div class="column">
                <formInput
                  label="on-border"
                  :model="form"
                  modelKey="send_surname"
                />
              </div>
            </div>
            <formInput
              label="on-border"
              :model="form"
              modelKey="send_business_name"
            />

            <br />
            <!-- street -->
            <formInput label="on-border" :model="form" modelKey="send_street" />
            <br />
            <!-- postalcode -->
            <div class="columns">
              <div class="column">
                <formInput
                  label="on-border"
                  :model="form"
                  modelKey="send_postal_code"
                />
              </div>
              <div class="column">
                <formInput
                  label="on-border"
                  :model="form"
                  modelKey="send_house_number"
                />
              </div>
            </div>
            <!-- city -->
            <div class="columns">
              <div class="column">
                <formInput
                  label="on-border"
                  :model="form"
                  modelKey="send_city"
                />
              </div>
              <div class="column">
                <formInput
                  label="on-border"
                  :model="form"
                  modelKey="send_state"
                />
              </div>
            </div>
            <formInput
              label="on-border"
              :model="form"
              modelKey="send_country"
            />
          </div>
        </div>

        <div class="title is-5">Mails</div>

        <div class="columns">
          <div class="column">
            <formCheckbox
              :labelHidden="true"
              :model="form"
              modelKey="order_confirmed_mail_send"
            />
          </div>
          <div class="column">
            <formCheckbox
              :labelHidden="true"
              :model="form"
              modelKey="order_canceled_mail_send"
            />
          </div>
          <div class="column">
            <formCheckbox
              :labelHidden="true"
              :model="form"
              modelKey="order_shipped_mail_send"
            />
          </div>
        </div>

        <br />

        <div class="title is-5">Products</div>

        <product :order="order" />

        <br />

        <div class="title is-5">BTW</div>

        <div class="columns">
          <div class="column">
            <b-field label="BTW percentage high">
              <b-radio-button
                v-model="edit_tax_percentage_high"
                native-value="geen"
                type="is-success"
              >
                <span>geen</span>
              </b-radio-button>

              <b-radio-button
                v-model="edit_tax_percentage_high"
                :native-value="0"
                type="is-success"
              >
                <span>0%</span>
              </b-radio-button>
              <b-radio-button
                v-model="edit_tax_percentage_high"
                native-value="custom"
                type="is-success"
              >
                <span>custom</span>
              </b-radio-button>
            </b-field>
          </div>
          <div class="column" v-if="edit_tax_percentage_high === 'custom'">
            <formInput :model="form" modelKey="tax_high_percentage" />
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <b-field label="BTW percentage low">
              <b-radio-button
                v-model="edit_tax_percentage_low"
                native-value="geen"
                type="is-success"
              >
                <span>geen</span>
              </b-radio-button>

              <b-radio-button
                v-model="edit_tax_percentage_low"
                :native-value="0"
                type="is-success"
              >
                <span>0%</span>
              </b-radio-button>
              <b-radio-button
                v-model="edit_tax_percentage_low"
                native-value="custom"
                type="is-success"
              >
                <span>custom</span>
              </b-radio-button>
            </b-field>
          </div>
          <div class="column" v-if="edit_tax_percentage_low === 'custom'">
            <formInput :model="form" modelKey="tax_low_percentage" />
          </div>
        </div>

        <br />

        <div class="title is-5">Totals</div>

        <!-- subtotal -->
        <div class="columns">
          <div class="column is-6"></div>
          <div class="column">
            <strong>Subtotal</strong>
          </div>
          <div class="column">
            {{ euroFromCents(subtotal) }}
          </div>
        </div>
        <!-- shipping costs -->
        <div class="columns">
          <div class="column is-6">
            <formInput
              label="on-border"
              :value="euroFromCentsWithoutSymbol(form.shipping_costs)"
              v-on:input="setPrice('edit_shipping_costs', 'shipping_costs')"
              class="customInput"
              modelKey="edit_shipping_costs"
              :model="form"
              validationKey="shipping_costs"
              placeholder="Prijs in euro"
              title="Edit shipping costs"
            />
          </div>
          <div class="column">
            <strong>Shipping costs</strong>
          </div>

          <div class="column">
            {{ euroFromCents(form.shipping_costs) }}
          </div>
        </div>
        <!-- payment costs -->
        <div class="columns">
          <div class="column is-6">
            <formInput
              label="on-border"
              :value="euroFromCentsWithoutSymbol(form.payment_costs)"
              v-on:input="setPrice('edit_payment_costs', 'payment_costs')"
              validationKey="payment_costs"
              class="customInput"
              modelKey="edit_payment_costs"
              :model="form"
              placeholder="Prijs in euro"
              title="Edit payment costs"
            />
          </div>
          <div class="column">
            <strong>Payment costs</strong>
          </div>
          <div class="column">
            {{ euroFromCents(form.payment_costs) }}
          </div>
        </div>

        <!-- discount -->
        <!-- <div class="columns">
            <div class="column is-6">
              <formInput
                validationKey="order_discount"
                label="on-border"
                v-on:input="setPrice('edit_discount', 'order_discount')"
                class="customInput"
                modelKey="edit_discount"
                :model="form"
                :value="euroFromCentsWithoutSymbol(form.order_discount)"
                placeholder="Prijs in euro"
                title="Edit discount"
              />
            </div>
            <div class="column">
              <strong>Discount</strong>
            </div>
            <div class="column">
              {{ euroFromCents(-form.order_discount) }}
            </div>
          </div> -->
        <!-- total Price -->
        <div class="columns">
          <div class="column is-6"></div>
          <div class="column">
            <strong>Total</strong>
          </div>
          <div class="column">
            {{ euroFromCents(total) }}
          </div>
        </div>

        <br />
        <!-- VAT -->
        <div class="columns">
          <div class="column is-6"></div>
          <div class="column">Taxable amount</div>
          <div class="column">
            {{
              euroFromCents(
                form.taxable_amount_low +
                  form.taxable_amount_high +
                  form.taxable_amount_none
              )
            }}
          </div>
        </div>
        <div class="columns">
          <div class="column is-6"></div>
          <div class="column">VAT</div>
          <div class="column">
            {{ euroFromCents(form.tax_high + form.tax_low) }}
          </div>
        </div>
        <button
          class="button is-rounded is-primary"
          :class="{ 'is-loading': ajaxloading.saveOrder }"
          @click="saveOrder()"
        >
          Save Changes
        </button>
        &nbsp;
        <button class="button is-rounded" @click="cancel()">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
import product from "./AdminOrderEditProduct.vue";
import Vue from "vue";

export default {
  components: {
    product,
  },
  props: [],
  data() {
    return {
      edit_tax_percentage_high: "",
      edit_tax_percentage_low: "",
      env: process.env,
      sendOrderPickupMail: false,
      sendCustomerEmail: false,
      form: {},
    };
  },
  computed: {
    subtotal() {
      return this.form.subtotal;
    },
    total() {
      return (
        Number(this.subtotal) +
        Number(this.form.shipping_costs) +
        Number(this.form.payment_costs) -
        Number(this.form.order_discount)
      );
    },
    orders() {
      return this.$store.getters["adminOrders/orders"];
    },
    settings() {
      return this.$store.getters["shop/settings"];
    },
    order() {
      if (this.orders && this.orders.length) {
        var order = this.orders.find(
          (a) => a.id == this.$router.currentRoute.params.order
        );
      }

      if (order) {
        return order;
      } else {
        return {};
      }
    },
  },
  methods: {
    formatOrderNumber(order) {
      if (!this.order.id) {
        return;
      }

      return order.order_year + "-" + order.order_number;
    },
    cancel() {
      this.$store.dispatch("adminOrders/getOrders").then(() => {
        this.refundOptions();
        this.$router.go(-1);
      });
    },
    saveOrder() {
      if (this.edit_tax_percentage_high !== "custom") {
        this.form.tax_high_percentage = this.edit_tax_percentage_high;
      }

      if (this.edit_tax_percentage_low !== "custom") {
        this.form.tax_low_percentage = this.edit_tax_percentage_low;
      }

      this.$store
        .dispatch("adminOrders/saveOrder", {
          order: this.order,
        })
        .then(() => {
          this.refundOptions();
          this.$router.go(-1);
        });
    },
    refundOptions() {
      if (this.order.balance < 0) {
        this.$buefy.dialog.confirm({
          title: "Refund options",
          message:
            "Order balance is currently negative. <br> Show refund options?",
          type: "is-danger",
          confirmText: "Show refund options",
          hasIcon: true,
          onConfirm: () =>
            this.$router.push({
              name: "admin-order-refund-options",
              params: { order: this.order.id },
            }),
        });
      }
    },
    deleteOrderConfirm() {
      this.$buefy.dialog.confirm({
        title: "Deleting order",
        message:
          "Are you sure you want to <b>delete</b> this order? This action cannot be undone.",
        confirmText: "Delete Order",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteOrder(),
      });
    },
    deleteOrder() {
      this.$store
        .dispatch("adminOrders/deleteOrder", { order: this.order })
        .then(() => {
          this.$router.replace({ name: "admin-orders" });
          this.$buefy.toast.open("Order deleted!");
        })
        .catch((error) => {
          this.$buefy.toast.open({
            message: error.response.data.message,
            type: "is-danger",
            duration: 7000,
          });
        });
    },
    setPrice(editKey, resultKey) {
      const edit = this.form[editKey].replace(",", ".") * 100;
      const editArray = String(edit).split(".");
      Vue.set(this.form, resultKey, Number(editArray[0]));
    },
    setTaxPercentages() {
      if (
        this.order.tax_high_percentage !== "geen" &&
        this.order.tax_high_percentage !== 0
      ) {
        this.edit_tax_percentage_high = "custom";
      } else {
        this.edit_tax_percentage_high = this.order.tax_high_percentage;
      }
      if (
        this.order.tax_low_percentage !== "geen" &&
        this.order.tax_low_percentage !== 0
      ) {
        this.edit_tax_percentage_low = "custom";
      } else {
        this.edit_tax_percentage_low = this.order.tax_low_percentage;
      }
    },
  },
  watch: {
    order: {
      deep: true,
      handler() {
        this.form = this.order;
        this.setTaxPercentages();
      },
    },
  },
  mounted() {
    this.form = this.order;
    this.setTaxPercentages();
  },
};
</script>
