export const toggleLoginModal = function ({ commit }, payload) {
  commit("toggleLoginModal", payload);
};

export const toggleRegisterModal = function ({ commit }, payload) {
  commit("toggleRegisterModal", payload);
};

export const toggleResetPasswordModal = function ({ commit }, payload) {
  commit("toggleResetPasswordModal", payload);
};

export const clearUser = function ({ commit }, payload) {
  commit("setUser", {});
};

export const register = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "register";
  data.method = "POST";
  data.loading = "register";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setUser", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const sendResetPasswordLink = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "resetpassword";
  data.method = "POST";
  data.loading = "resetpassword";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setUser", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const changePassword = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "change-password-with-token";
  data.method = "POST";
  data.loading = "changepassword";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setUser", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const login = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "login";
  data.method = "POST";
  data.loading = "login";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      dispatch("getLoggedInUser");
      commit("setUser", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const logout = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "logout";
  data.method = "POST";
  data.loading = "logout";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      //to get new csfr token
      dispatch("getLoggedInUser");
      commit("setUser", {});
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const deleteAccount = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "delete-account";
  data.method = "POST";
  data.loading = "deleteaccount";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      dispatch("getLoggedInUser");
      commit("setUser", {});
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getLoggedInUser = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "get-logged-in-user";
  data.method = "get";
  data.loading = "get_logged_in_user";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setUser", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getLoggedInUserWithAuth = function (
  { dispatch, commit },
  payload
) {
  const data = {};
  data.url = "get-logged-in-user-with-auth";
  data.method = "get";
  data.loading = "get_logged_in_user";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setUser", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const createNewUserAddress = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "create-user-address";
  data.method = "POST";
  data.loading = "creatingAddress";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setUser", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const updateUserAddress = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "update-user-address";
  data.method = "POST";
  data.loading = "creatingAddress";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setUser", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const deleteUserAddress = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "delete-user-address";
  data.method = "POST";
  data.loading = "creatingAddress";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setUser", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const updateUserData = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "update-user-data";
  data.method = "POST";
  data.loading = "updateuserdata";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setUser", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const changeUserPassword = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "change-user-password";
  data.method = "POST";
  data.loading = "changeuserpassword";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setUser", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const logInFromRememberMeCookie = function (
  { dispatch, commit },
  payload
) {
  const data = {};
  data.url = "log-in-from-remember-token";
  data.method = "get";
  data.loading = "changeuserpassword";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setUser", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getOrders = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "get-user-orders";
  data.method = "GET";
  data.loading = "gettingusererrors";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setOrders", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
