import axios from "axios";

export const axiosApi = function ({ rootGetters, commit }, payload) {
  const token = rootGetters["auth/token"];
  const user = rootGetters["auth/user"];

  commit("loadingOn", payload.loading);
  return axios({
    url: process.env.VUE_APP_API_URL + "/api/" + payload.url,
    method: payload.method,
    data: payload.data,
    headers: {
      Authorization: "Bearer " + user.id + " " + token,
    },
  })
    .then(function (response) {
      commit("loadingOff", payload.loading);
      return Promise.resolve(response);
    })
    .catch(function (error) {
      commit("loadingOff", payload.loading);
      return Promise.reject(error);
    });
};

export const axiosWeb = function ({ commit }, payload) {
  commit("loadingOn", payload.loading);
  return axios({
    url: process.env.VUE_APP_API_URL + "/web/" + payload.url,
    method: payload.method,
    data: payload.data,
    withCredentials: true,
  })
    .then(function (response) {
      commit("loadingOff", payload.loading);
      return Promise.resolve(response);
    })
    .catch(function (error) {
      commit("loadingOff", payload.loading);
      return Promise.reject(error);
    });
};

export const loadingOn = function ({ commit }, payload) {
  commit("loadingOn", payload);
};

export const loadingOff = function ({ commit }, payload) {
  commit("loadingOff", payload);
};

export const setValidationErrors = function ({ commit }, payload) {
  commit("setValidationErrors", payload);
};

export const clearValidationErrors = function ({ commit }) {
  commit("setValidationErrors", {});
};

export const clearValidationError = function ({ commit }, payload) {
  commit("clearValidationError", payload);
};

export const createFlashBanner = function ({ commit, dispatch }, payload) {
  var message = String;
  var timeout = Number;

  if (typeof payload === "string") {
    message = payload;
    timeout = 2500;
  } else if (payload.timeout) {
    message = payload.message;
    timeout = payload.timeout * 1000;
  } else {
    message = payload.message;
    timeout = 2500;
  }

  window.scrollTo(0, 0);

  const data = {
    message: message,
    type: payload.type,
    html: payload.html,
  };

  commit("setFlashBanner", data);
  setTimeout(function () {
    dispatch("destroyFlashBanner");
  }, timeout);
};

export const destroyFlashBanner = function ({ commit }, payload) {
  commit("setFlashBanner", null);
};

export const getAddress = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "get-address-from-postcode";
  data.method = "POST";
  data.loading = "gebruikertoevoegen";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const submitContactForm = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "submit-contact-form";
  data.method = "POST";
  data.loading = "submit_contact_form";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

//   for (let order of state.orders) {
//     order.order_combo_number = order.order_year + "-" + order.order_number;
//     order.fullname = order.name + " " + order.surname;
//     order.sendfullname = order.send_name + " " + order.send_surname;
//     for (let value of Object.values(order)) {
//       if (
//         typeof value === "string" &&
//         value.toLowerCase().includes(payload.zoekwaarde.toLowerCase())
//       ) {
//         matched.push(order);
//         break;
//       }
//       if (
//         typeof value === "number" &&
//         String(value)
//           .toLowerCase()
//           .includes(String(payload.zoekwaarde.toLowerCase()))
//       ) {
//         matched.push(order);
//         break;
//       }
//     }
//   }
