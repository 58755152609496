<template>
  <div>
    <div class="title is-4">{{ user.name }}</div>
    <hr />
    <span class="title is-5">User details:</span>
    <accountName :form="form" />

    <br />
    <br />

    <span class="title is-5">Addressess:</span>
    <b-tooltip class="is-pulled-right" label="Add address" position="is-left">
      <button
        class="button is-rounded is-small is-primary"
        @click="showModal = true"
      >
        Add address
      </button>
    </b-tooltip>

    <br />
    <br />

    <span v-for="address in addresses" :key="address.id">
      <account-address
        @click.native="addressClicked(address)"
        :address="address"
      />
    </span>

    <accountAddresCrud
      :updateForm="clickedAddress"
      v-on:cancel="closeModal()"
      v-if="showModal"
    />
  </div>
</template>

<script>
import accountName from "./AccountName.vue";
import accountAddress from "./AccountAddress.vue";
import accountAddresCrud from "./AccountAddresCrud.vue";

export default {
  components: {
    accountName: accountName,
    accountAddress: accountAddress,
    accountAddresCrud,
  },
  props: [],
  data() {
    return {
      form: {
        shippingdiffers: false,
        shipping: {},
      },
      showModal: false,
      clickedAddress: {},
    };
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
    addresses() {
      if (this.user.addresses) {
        const addresses = this.user.addresses;
        addresses.sort((a, b) => {
          return b.default - a.default;
        });

        return addresses;
      } else {
        return [];
      }
    },
  },
  methods: {
    addressClicked(address) {
      this.clickedAddress = address;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.clickedAddress = {};
    },
  },
  mounted() {
    if (this.user.id) {
      this.form = this.user;
      this.form.email_confirmation = this.form.email;
    } else {
      this.$store.dispatch("auth/getLoggedInUserWithAuth").then((response) => {
        this.form = response.data;
        this.form.email_confirmation = this.form.email;
      });
    }
  },
};
</script>
