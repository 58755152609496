<style scoped></style>

<template>
  <div v-if="showResetPasswordModal">
    <div class="modal is-active">
      <div
        class="modal-background"
        @click.prevent="closeResetPasswordModal()"
      ></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Reset password</p>
          <button
            class="delete"
            aria-label="close"
            @click.prevent="closeResetPasswordModal()"
          ></button>
        </header>
        <section class="modal-card-body">
          <form @submit.prevent="sendResetPasswordLink()">
            <div class="modal-card" style="width: auto">
              <formInput
                placeholder="Please fill in your email address"
                :model="form"
                modelKey="email"
                type="email"
              />
            </div>
          </form>
        </section>
        <footer class="modal-card-foot">
          <button
            :class="{ 'is-loading': ajaxloading.resetpassword }"
            class="button is-success is-rounded is-small"
            @click.prevent="sendResetPasswordLink()"
          >
            Send reset link
          </button>
          <button
            class="button is-rounded is-small"
            @click.prevent="closeResetPasswordModal()"
          >
            Cancel
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      form: {
        rememberme: false,
      },
    };
  },
  computed: {
    showResetPasswordModal() {
      return this.$store.getters["auth/resetPasswordModal"];
    },
  },
  methods: {
    sendResetPasswordLink() {
      this.$store.dispatch("auth/sendResetPasswordLink", this.form).then(() => {
        this.$store.dispatch("createFlashBanner", {
          message:
            "A password reset link has been send to your email. Open your email to continue.",
          timeout: 5,
        });
        this.closeResetPasswordModal();
      });
    },
    closeResetPasswordModal() {
      return this.$store.dispatch("auth/toggleResetPasswordModal", false);
    },
  },
  mounted() {},
};
</script>
