var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"columns is-mobile"},[_vm._m(0),_c('div',{staticClass:"column"},[_c('button',{staticClass:"button is-pulled-right is-rounded is-small",on:{"click":function($event){_vm.showCrModal = true}}},[_vm._v(" Toevoegen ")])])]),_vm._l((Math.ceil(_vm.categories.length / _vm.maxItemsPerRow)),function(outerLoop){return _c('div',{key:outerLoop,staticClass:"columns"},_vm._l((_vm.calculatedMax(outerLoop)),function(item){return _c('div',{key:item,staticClass:"column is-3"},[_c('div',{staticClass:"card pointer",on:{"click":function($event){return _vm.navigateToCategory(
            _vm.categories[item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1]
              .slug
          )}}},[_c('div',{staticClass:"card-image"},[_c('figure',{staticClass:"image is-1by1"},[_c('div',{on:{"mouseover":function($event){return _vm.mouseoverImage(
                  _vm.categories[
                    item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1
                  ]
                )},"mouseleave":_vm.mouseleaveImage}},[(
                  _vm.hoveredPoduct ===
                  _vm.categories[
                    item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1
                  ]
                )?_c('img',{attrs:{"src":_vm.env.VUE_APP_API_URL +
                  '/web/picture/' +
                  _vm.categories[
                    item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1
                  ].alt_picture,"alt":[item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1]
                    .naam}}):_c('img',{attrs:{"src":_vm.env.VUE_APP_API_URL +
                  '/web/picture/' +
                  _vm.categories[
                    item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1
                  ].main_picture,"alt":[item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1]
                    .naam}})])])]),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"media"},[_c('div',{staticClass:"media"}),_c('div',{staticClass:"media-content"},[_c('p',{staticClass:"title is-4 has-text-centered"},[_c('span',{class:{
                    notVisible:
                      !_vm.categories[
                        item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1
                      ].visible,
                  }},[_vm._v(" "+_vm._s(_vm.hoofdletter( _vm.categories[ item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1 ].naam ))+" ")]),_c('br'),_c('button',{staticClass:"button",on:{"click":function($event){$event.stopPropagation();return _vm.changePosition(
                      _vm.categories[
                        item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1
                      ],
                      'up'
                    )}}},[_c('b-icon',{attrs:{"icon":"arrow-up","size":"is-small"}})],1),_c('button',{staticClass:"button",on:{"click":function($event){$event.stopPropagation();return _vm.changePosition(
                      _vm.categories[
                        item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1
                      ],
                      'down'
                    )}}},[_c('b-icon',{attrs:{"icon":"arrow-down","size":"is-small"}})],1)])])])])])])}),0)}),(_vm.showCrModal)?_c('crmodal',{on:{"closeModal":function($event){return _vm.closeCRmodal()}}}):_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column"},[_c('h1',{staticClass:"title is-5"},[_vm._v("Categories")])])
}]

export { render, staticRenderFns }