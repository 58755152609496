<template>
  <span>
    <customIcon
      v-if="
        settings &&
        icon &&
        settings.admin.payment_methods.find((a) => a.name === icon)
          .icon_location === 'local'
      "
      :icon="
        settings.admin.payment_methods.find((a) => a.name === icon).icon_name
      "
    />

    <b-icon
      v-if="
        settings &&
        icon &&
        settings.admin.payment_methods.find((a) => a.name === icon)
          .icon_location === 'font awesome'
      "
      :pack="
        settings.admin.payment_methods.find((a) => a.name === icon).icon_pack
      "
      :icon="
        settings.admin.payment_methods.find((a) => a.name === icon).icon_name
      "
    ></b-icon>
  </span>
</template>

<script>
export default {
  components: {},
  props: {
    icon: {
      required: true,
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {
    settings() {
      return this.$store.getters["shop/settings"];
    },
  },
  methods: {},
  mounted() {},
};
</script>
