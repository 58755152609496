<style scoped>
.columns {
  margin-bottom: 0px;
}

.box {
  border-style: solid;

  margin-bottom: 4px;
}

.default-address {
  border-color: #4a9e83;
  color: #4a9e83;
}

.default-address strong {
  border-color: #4a9e83;
  color: #4a9e83;
}
</style>

<template>
  <div>
    <div class="box pointer" :class="{ 'default-address': address.default }">
      <b-tooltip
        class="is-pulled-right"
        label="Delete address"
        position="is-left"
      >
        <b-icon
          class="pointer"
          @click.native.stop="deleteAddress()"
          icon="trash"
          type="is-success"
        >
        </b-icon>
      </b-tooltip>
      <b-tooltip
        class="is-pulled-right"
        label="Edit address"
        position="is-left"
      >
        <b-icon class="pointer" icon="edit" type="is-success"> </b-icon>
      </b-tooltip>
      <strong v-if="!address.different_ship_address"
        >Shipping and Billing address
      </strong>

      <strong v-else>Billing address</strong>
      <br />
      {{ address.business_name }} <br v-if="address.business_name" />
      {{ address.name }} {{ address.surname }}<br />
      {{ address.street }} {{ address.house_number }} <br />
      {{ address.postal_code }} {{ address.city }} <br />
      {{ address.state }},
      {{ address.country }}

      <span v-if="address.different_ship_address">
        <br />
        <br />
        <strong>Shipping address</strong> <br />
        {{ address.business_name }}
        <br v-if="address.business_name" />
        {{ address.send_name }} {{ address.send_surname }}<br />
        {{ address.send_street }} {{ address.send_house_number }}
        <br />
        {{ address.send_postal_code }} {{ address.send_city }} <br />
        {{ address.send_state }},
        {{ address.send_country }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    address: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      business: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
  },
  methods: {
    deleteAddress() {
      this.$buefy.dialog.confirm({
        title: "Deleting address",
        message:
          "Are you sure you want to <b>delete</b> this address? This action cannot be undone.",
        confirmText: "Delete address",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          this.$store.dispatch("auth/deleteUserAddress", this.address),
      });
    },
  },
  mounted() {},
};
</script>
