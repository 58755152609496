<style scoped>
.imageContainer {
  background-color: red;
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  position: relative; /* If you want text inside of it */
}

.text {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0px;
  padding: 0px;
}
</style>

<template>
  <div class="container box">
    <div class="columns">
      <div class="column is-4">
        <div class="">
          <pagePictureShower
            v-if="!previewImage"
            :variable_key="modelKey"
            :settings="settings"
          />
        </div>
      </div>
      <div
        class="column has-text-centered"
        v-if="settings && settings.variables"
      >
        <span
          v-if="
            settings.variables.find((a) => a.key === modelKey) &&
            settings.variables.find((a) => a.key === modelKey).value != ''
          "
        >
          <span
            v-for="key in settings.variables.find((a) => a.key === modelKey)
              .value.length"
            :key="key"
          >
            <div
              :class="{
                'is-loading': ajaxloading['destroy_picture' + key + modelKey],
              }"
              class="button is-fullwidth is-primary is-outlined"
              @click="deletePictureApi(key)"
            >
              Delete Picture {{ key }}
            </div>
            <br />
          </span>
        </span>
      </div>
    </div>

    <b-field :label="computedTitle">
      <div v-if="previewImage">
        <div class="box has-text-centered">
          <button
            class="delete is-small is-pulled-right"
            type="button"
            @click="deletePicture()"
          ></button>

          <!-- <img
             :style="'height:' + height + 'px'"
            v-if="previewImage.url && !cropper"
            :src="previewImage.url"
          /> -->

          <cropper
            :style="'height:' + height + 'px'"
            v-if="previewImage && cropper"
            class="cropper"
            :src="previewImage.url"
            :resizeImage="{ wheel: true }"
            :stencil-props="{
              scalable: false,
              aspectRatio: aspectRatio,
            }"
            @change="change"
          ></cropper>
        </div>
      </div>

      <b-field>
        <b-upload v-model="file" expanded @input="fileChanged()">
          <a :class="['button is-outlined  is-primary is-fullwidth ' + size]">
            <b-icon icon="upload"></b-icon>
            <span>{{ computedPictureName || "Click to upload" }}</span>
          </a>
        </b-upload>
      </b-field>
      <p class="help is-danger" v-if="validation['foto']">
        {{ validation["foto"][0] }}
      </p>
    </b-field>
    <div
      :class="{ 'is-loading': ajaxloading.save_variables }"
      class="button is-primary is-fullwidth"
      @click="savePicture()"
      v-if="upload.get('foto')"
    >
      Upload
    </div>
  </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  components: { Cropper },
  props: {
    cropper: {
      default: true,
      type: Boolean,
    },
    preview: {
      default: false,
      type: Boolean,
    },
    title: {
      type: String,
    },
    model: {
      required: true,
      type: Object,
    },
    modelKey: {
      required: true,
      type: String,
    },
    placeholder: {
      type: String,
    },
    size: {
      default: "is-normal",
      type: String,
    },
    height: {
      default: "400",
      type: String,
    },
    aspectRatio: {
      default: 1 / 1,
    },
    category: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      file: {},
      upload: new FormData(),
      previewImage: null,
    };
  },
  computed: {
    settings() {
      return this.$store.getters["shop/settings"];
    },
    computedTitle() {
      if (this.title) {
        return this.title;
      } else {
        return this.modelKey;
      }
    },
    computedPictureName() {
      if (this.file) {
        return this.file.name;
      } else {
        return "";
      }
    },
  },
  methods: {
    savePicture() {
      if (this.category) {
        this.upload.append("category", this.category);
      }

      this.upload.append("key", this.modelKey);

      this.$store
        .dispatch("shop/savePictureVariable", this.upload)
        .then(() => {
          this.file = {};
          this.upload = new FormData();
          this.previewImage = null;
          this.$buefy.toast.open({
            message: "Picture opgeslagen",
            type: "is-success",
          });
        })
        .catch((error) => {
          this.upload.category = null;
          if (error.response.status === 422) {
            this.$store.dispatch("createFlashBanner", {
              type: "is-danger",
              message: error.response.data,
              timeout: 10,
            });
          }
        });
    },
    change({ coordinates, canvas }) {
      this.upload.append("foto" + "_width", coordinates.width);
      this.upload.append("foto" + "_height", coordinates.height);
      this.upload.append("foto" + "_x", coordinates.left);
      this.upload.append("foto" + "_y", coordinates.top);
    },
    fileChanged() {
      this.clearValidationError();
      this.upload.append("foto", this.file, this.file.name);
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          let filepreview = {
            name: this.file.name,
            url: reader.result,
          };
          this.previewImage = filepreview;
        },
        false
      );
      reader.readAsDataURL(this.file);
    },
    deletePicture() {
      this.previewImage = null;
      this.upload["foto"] = null;
      this.file = null;
    },
    clearValidationError() {
      this.$store.dispatch("clearValidationError", "foto");
    },
    deletePictureApi(key) {
      const picture = this.settings.variables.find(
        (a) => a.key === this.modelKey
      );
      this.$store.dispatch("shop/destroyPagePicture", {
        picture: picture.id,
        uuid: picture.value[key - 1],
        loadingKey: key + this.modelKey,
      });
    },
  },
  mounted() {
    this.file = this.upload["foto"];
  },
};
</script>
