<template>
  <div>
    <b-table
      :data="invoice.details"
      :bordered="true"
      :striped="true"
      :narrowed="true"
      :selected.sync="selected"
      class="pointer"
      @dblclick="showDetail(selected)"
    >
      <b-table-column label="Name" sortable v-slot="props">
        {{ props.row.name }}
      </b-table-column>

      <b-table-column label="Category" sortable v-slot="props">
        {{ props.row.category }}
      </b-table-column>

      <b-table-column label="Amount" sortable v-slot="props">
        {{ props.row.amount }}
      </b-table-column>

      <b-table-column label="Btwttype" sortable v-slot="props">
        {{ props.row.btwtype }}
      </b-table-column>

      <b-table-column label="Total" sortable v-slot="props">
        {{ euroFromCents(props.row.price) }}
      </b-table-column>
    </b-table>

    <editDetailModal
      v-on:close="closeDetailModal()"
      :invoice="invoice"
      :detail="detail"
      v-if="detail"
    />
  </div>
</template>

<script>
import editDetailModal from "./AdminEditDetailModal.vue";

export default {
  components: {
    editDetailModal,
  },
  props: {
    invoice: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      selected: null,
      detail: null,
    };
  },
  computed: {
    invoices() {
      const invoices = this.$store.getters["adminInvoices/invoices"];
      invoices.sort((a, b) => {
        return b.id - a.id;
      });
      return invoices;
    },
  },
  methods: {
    closeDetailModal() {
      this.detail = null;
      this.selected = null;
    },
    showDetail(detail) {
      this.detail = detail;
    },
  },
  mounted() {},
};
</script>
