<style scoped>
.overflow {
  width: 300px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.selected {
  border-color: #4a9e83 !important;
  border-style: solid !important;
  border: 1px;
}

.not-selected {
  color: lightgrey;
}

.not-selected strong {
  color: lightgrey;
}

.customInput {
  margin-bottom: 10px;
}

.help {
  margin-top: 0px;
}

.checkoutTermsCheckbox {
  margin-bottom: 0px;
}
</style>

<template>
  <div>
    <br />
    <div class="container is-fluid">
      <div class="columns">
        <div class="column is-4">
          <form @submit.prevent="">
            <span v-if="!user.id">
              <!-- <b-field>
                <b-radio v-model="guest" size="is-small" :native-value="true">
                  Checkout as guest
                </b-radio>
              </b-field>
              <b-field>
                <b-radio v-model="guest" size="is-small" :native-value="false">
                  Returning customer
                </b-radio>
              </b-field> -->

              <formInput
                @blur="checkIfAccount()"
                class="customInput"
                :model="form"
                modelKey="email"
                label="on-border"
                placeholder="Email address"
              />

              <span v-if="guest === false">
                <formInput
                  ref="password"
                  class="customInput"
                  :model="form"
                  modelKey="password"
                  label="on-border"
                  type="password"
                  placeholder="Password"
                />

                <button
                  @click.prevent="login()"
                  class="button is-rounded is-fullwidth is-primary is-small"
                >
                  Log in
                </button>
                <a
                  ><p
                    class="help is-fullwidth has-text-centered"
                    @click="openPasswordResetModal()"
                  >
                    I forgot my password
                  </p></a
                >
              </span>
              <br />
            </span>
            <span v-else
              ><strong> Email: {{ user.email }}</strong> <br />
              <p class="help is-primary pointer" @click="logout()">
                Click here to log out
              </p>
              <br
            /></span>
            <span v-if="addresses.length"
              ><strong>Select Address:</strong></span
            >
            <span v-else
              ><strong v-if="!form.different_ship_address">Address</strong
              ><strong v-else>Billing address</strong></span
            >
            <b-tooltip
              v-if="user.id"
              class="is-pulled-right"
              label="Add address"
              position="is-left"
            >
              <b-icon
                class="pointer"
                @click.native="AddAddress()"
                icon="plus"
                type="is-success"
              >
              </b-icon>
            </b-tooltip>

            <br />
            <br />

            <span v-if="addresses.length">
              <span v-if="user.id">
                <div
                  class="box pointer"
                  :class="{
                    selected: selectedAddress.id === address.id,
                    'not-selected':
                      selectedAddress !== address && selectedAddress.id,
                  }"
                  v-for="address of addresses"
                  :key="address.id"
                  @click="selectedAddress = address"
                >
                  <b-tooltip
                    class="is-pulled-right"
                    label="Edit address"
                    position="is-left"
                  >
                    <b-icon
                      @click.native="updateAddress(address)"
                      class="pointer"
                      icon="edit"
                      type="is-success"
                    >
                    </b-icon>
                  </b-tooltip>

                  <strong v-if="!address.different_ship_address"
                    >Shipping and Billing address</strong
                  >

                  <strong v-else>Billing address:</strong>
                  <br />
                  {{ address.business_name }}
                  <br v-if="address.business_name" />
                  {{ address.name }} {{ address.surname }}<br />
                  {{ address.street }} {{ address.house_number }} <br />
                  {{ address.postal_code }} {{ address.city }} <br />
                  {{ address.state }},
                  {{ address.country }}

                  <span v-if="address.different_ship_address">
                    <br />
                    <br />
                    <strong>Shipping address:</strong> <br />
                    {{ address.send_business_name }}
                    <br v-if="address.send_business_name" />
                    {{ address.send_name }} {{ address.send_surname }}<br />
                    {{ address.send_street }} {{ address.send_house_number }}
                    <br />
                    {{ address.send_postal_code }} {{ address.send_city }}
                    <br />
                    {{ address.state }},
                    {{ address.send_country }}
                  </span>
                </div>
              </span>
            </span>
            <span v-else-if="user && user.id">
              No addresses found,
              <a @click="AddAddress()">click here to add an address</a>
            </span>
            <guestAddress v-else :form="form" />
          </form>
        </div>
        <div class="column is-4">
          <strong>Shipping methods</strong> <br />

          <span v-if="cart.length">
            <span v-if="form.country || selectedAddress.id">
              <span v-if="countryShippingMethods.length">
                <b-field
                  v-for="shippingmethod in countryShippingMethods"
                  :key="shippingmethod.id"
                >
                  <b-radio
                    v-model="form.shippingmethod"
                    size="is-small"
                    :native-value="shippingmethod.id"
                  >
                    <checkoutShippingIcon :icon="shippingmethod.icon" />
                    &nbsp;{{ shippingmethod.display_name }}
                    <span v-if="!shippingmethod.price">(free)</span>
                    <span v-if="shippingmethod.price"
                      >({{ euroFromCents(shippingmethod.price) }})
                    </span>
                  </b-radio>
                </b-field>
              </span>
              <span
                v-else-if="
                  selectedAddress.id &&
                  selectedAddress.different_ship_address &&
                  !selectedAddress.send_country
                "
              >
                <small> Please select the shipping country </small>
                <br />
              </span>
              <span
                v-else-if="form.different_ship_address && !form.send_country"
              >
                <small> Please select the shipping country </small>
                <br />
              </span>
              <span v-else>
                <small
                  >No shipping methods available for
                  <span v-if="selectedAddress.country"
                    >{{ selectedAddress.country }}
                  </span>
                  <span v-else>
                    {{ form.country }}
                  </span>
                </small>
                <br />
              </span>
            </span>
            <span v-else>
              <small>
                Please fill in your address to view shipping options</small
              >
              <br />
            </span>
          </span>
          <span v-else> No products added to cart <br /></span>
          <p class="help is-danger" v-if="validation.shippingmethod">
            {{ validation.shippingmethod[0] }}
          </p>
          <br />

          <strong>Payment methods</strong> <br />

          <span v-if="cart.length">
            <span v-if="form.country || selectedAddress.id">
              <span v-if="countryPaymentMethods.length">
                <b-field
                  v-for="paymentmethod in countryPaymentMethods"
                  :key="paymentmethod.id"
                >
                  <b-radio
                    v-model="form.paymentmethod"
                    size="is-small"
                    :native-value="paymentmethod.id"
                  >
                    <checkoutPaymentIcon :icon="paymentmethod.icon" />
                    &nbsp;{{ paymentmethod.display_name }}
                    <span v-if="!paymentmethod.price && !paymentmethod.opslag"
                      >(free)</span
                    >
                    <span v-if="paymentmethod.price && paymentmethod.opslag"
                      >({{ euroFromCents(paymentmethod.price) }} +
                      {{ paymentmethod.opslag / 100 }}%)</span
                    >
                    <span v-if="!paymentmethod.price && paymentmethod.opslag"
                      >({{ paymentmethod.opslag / 100 }}%)</span
                    >
                    <span v-if="paymentmethod.price && !paymentmethod.opslag"
                      >({{ euroFromCents(paymentmethod.price) }})</span
                    >
                  </b-radio>
                </b-field>
              </span>
              <span v-else>
                <small
                  >No payment methods available for
                  <span v-if="selectedAddress.country"
                    >{{ selectedAddress.country }}
                  </span>
                  <span v-else>
                    {{ form.country }}
                  </span>
                </small>
                <br />
              </span>
            </span>
            <span v-else>
              <small>
                Please fill in your address to view payment options</small
              >
              <br />
            </span>
          </span>
          <span v-else> No products added to cart <br /> </span>

          <p class="help is-danger" v-if="validation.paymentmethod">
            {{ validation.paymentmethod[0] }}
          </p>

          <br />

          <span v-if="guest && !user.id">
            <strong>Account</strong>

            <b-field>
              <b-checkbox
                size="is-small"
                @input="resetPassword()"
                v-model="form.account"
                >Create an account</b-checkbox
              >
            </b-field>
            <formInput
              v-if="form.account"
              ref="password"
              class="customInput"
              :model="form"
              modelKey="password"
              label="on-border"
              type="password"
              placeholder="Password"
            />
          </span>

          <strong>Terms</strong>

          <b-field class="checkoutTermsCheckbox">
            <b-checkbox size="is-small" v-model="form.terms"
              >I have read and agree to the
              <a @click="showTermsAndConditions()"
                >terms and conditions</a
              ></b-checkbox
            >
          </b-field>
          <p class="help is-danger" v-if="validation.terms">
            {{ validation.terms[0] }}
          </p>
        </div>

        <div class="column is-4">
          <strong>Products</strong>
          <br />
          <br />
          <span v-if="cart.length">
            <div
              class="columns is-mobile"
              v-for="index in cart.length"
              :key="index"
            >
              <div class="column is-2">
                <b-image
                  ratio="1by1"
                  v-if="
                    cart[index - 1].product.type === 'options' &&
                    cart[index - 1].option.picture <
                      cart[index - 1].product.pictures.length &&
                    cart[index - 1].option.picture > 0
                  "
                  :src="
                    env.VUE_APP_API_URL +
                    '/web/picture/' +
                    cart[index - 1].product.pictures[
                      cart[index - 1].option.picture
                    ].uuid
                  "
                  alt="Product Image"
                />

                <b-image
                  ratio="1by1"
                  v-else
                  :src="
                    env.VUE_APP_API_URL +
                    '/web/picture/' +
                    cart[index - 1].product.pictures[0].uuid
                  "
                  alt="Product Image"
                />
              </div>
              <div class="column is-7 overflow">
                <span v-if="cart[index - 1].product.type === 'options'">
                  {{ cart[index - 1].product.name }} -
                  <small>
                    {{ cart[index - 1].option.name }}
                  </small>
                </span>
                <span v-else>
                  {{ cart[index - 1].product.name }}
                </span>
              </div>
              <div class="column is-3 has-text-right">
                <span v-if="cart[index - 1].product.type === 'options'">
                  {{
                    euroFromCents(
                      (cart[index - 1].product.price +
                        cart[index - 1].option.price) *
                        cart[index - 1].amount
                    )
                  }}
                </span>
                <span v-else>
                  {{
                    euroFromCents(
                      cart[index - 1].product.price * cart[index - 1].amount
                    )
                  }}
                </span>
                <b-tooltip label="Delete product from cart" position="is-left">
                  <b-icon
                    @click.native="removeFromCart(cart[index - 1])"
                    icon="trash"
                    class="pointer"
                    size="is-small"
                    type="is-success"
                  >
                  </b-icon>
                </b-tooltip>
              </div>
            </div>
          </span>
          <span v-else> No products added to cart.</span>
          <hr />
          <span>Subtotal</span
          ><span class="is-pulled-right">
            {{ euroFromCents(cartTotal()) }}</span
          >
          <br />
          <span v-if="shippingCosts > 0">
            <span>Shipping costs</span
            ><span class="is-pulled-right">{{
              euroFromCents(shippingCosts)
            }}</span>
            <br />
          </span>
          <span v-if="paymentCosts > 0">
            <span>Payment costs</span
            ><span class="is-pulled-right">{{
              euroFromCents(paymentCosts)
            }}</span>
            <br />
          </span>
          <span><strong>Total</strong></span
          ><span class="is-pulled-right"
            ><strong>{{ euroFromCents(totalCosts) }}</strong></span
          >
          <br />
          <br />
          <span v-if="vat > 0">
            <span>Vat</span
            ><span class="is-pulled-right">{{ euroFromCents(vat) }}</span>
          </span>

          <p class="help is-danger has-text-centered" v-if="validation.cart">
            {{ validation.cart[0] }}
          </p>

          <button
            :disabled="!form.country && !selectedAddress.street"
            @click="checkout()"
            :class="{ 'is-loading': ajaxloading.checkout }"
            class="button is-rounded is-fullwidth is-primary is-small"
          >
            Checkout
          </button>
        </div>
      </div>
    </div>
    <accountAddresCrud
      :updateForm="selectedAddress"
      v-on:newAddress="setNewAddress"
      v-on:cancel="closeModal()"
      v-if="showAddresModal"
    />
  </div>
</template>

<script>
// import customerAdress from "./CustomerAdress.vue";
import guestAddress from "./guestAddress.vue";
import accountAddresCrud from "@/views/account/AccountAddresCrud.vue";
import checkoutPaymentIcon from "./checkoutPaymentIcon.vue";
import checkoutShippingIcon from "./checkoutShippingIcon.vue";

export default {
  components: {
    accountAddresCrud,
    guestAddress,
    checkoutShippingIcon,
    checkoutPaymentIcon,
  },
  props: [],
  data() {
    return {
      vat: 0,
      selectedAddress: {},
      showAddresModal: false,
      env: process.env,
      guest: true,
      gettingOrderTotal: false,
      form: {
        terms: false,
        account: false,
        different_ship_address: false,
        send_business: false,
        business: false,
        name: "",
        send_name: "",
        shippingmethod: "",
        paymentmethod: "",
        country: "",
        selectedAddress: {},
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
    send_country() {
      var country;
      if (this.selectedAddress.id) {
        country = this.selectedAddress.send_country;
      } else {
        if (this.form.send_country) {
          country = this.form.send_country;
        } else {
          country = this.form.country;
        }
      }
      return this.countries.find((a) => a.display_name === country);
    },
    countryPaymentMethods() {
      var country = "";
      if (this.selectedAddress.id) {
        country = this.selectedAddress.country;
      } else {
        country = this.form.country;
      }

      const paymentCountry = this.countries.find(
        (a) => a.display_name === country
      );
      if (paymentCountry) {
        return paymentCountry.payment_methods;
      } else {
        return [];
      }
    },
    countryShippingMethods() {
      var country = "";
      if (this.selectedAddress.id) {
        if (this.selectedAddress.different_ship_address) {
          country = this.selectedAddress.send_country;
        } else {
          country = this.selectedAddress.country;
        }
      }

      if (!this.selectedAddress.id) {
        if (this.form.different_ship_address) {
          country = this.form.send_country;
        } else {
          country = this.form.country;
        }
      }

      const shippingCountry = this.countries.find(
        (a) => a.display_name === country
      );
      if (shippingCountry) {
        const methodsOnWeight = shippingCountry.shipping_methods.filter(
          (a) =>
            a.min_weight <= this.totalGrams && a.max_weight >= this.totalGrams
        );

        var letterbox = true;

        for (let product of this.cart) {
          if (product.product.small_product === false) {
            letterbox = false;
          }
        }

        const letterboxes = methodsOnWeight.filter(
          (a) => a.package_type === "letter parcel"
        );

        if (letterbox && letterboxes.length) {
          return letterboxes;
        } else {
          return methodsOnWeight.filter(
            (a) => a.package_type !== "letter parcel"
          );
        }
      } else {
        return [];
      }
    },
    selectedShippingMethod() {
      if (this.countryShippingMethods.length && this.form.shippingmethod) {
        return this.countryShippingMethods.find(
          (a) => a.id === this.form.shippingmethod
        );
      } else {
        return {};
      }
    },
    selectedPaymentMethod() {
      if (this.countryPaymentMethods.length && this.form.paymentmethod) {
        return this.countryPaymentMethods.find(
          (a) => a.id === this.form.paymentmethod
        );
      } else {
        return {};
      }
    },
    shippingCosts() {
      if (!this.cart.length) {
        return 0;
      }
      if (
        this.form.shippingmethod &&
        this.selectedShippingMethod &&
        this.selectedShippingMethod.id
      ) {
        return this.selectedShippingMethod.price;
      } else {
        return 0;
      }
    },
    paymentCosts() {
      if (!this.cart.length) {
        return 0;
      }

      if (this.form.paymentmethod) {
        const paymentmethod = this.selectedPaymentMethod;
        const subtotal =
          this.cartTotal() + this.shippingCosts + paymentmethod.price;
        var opslag;
        if (subtotal > 0 && paymentmethod.opslag) {
          opslag = ((subtotal / 100) * paymentmethod.opslag) / 100;
        } else {
          opslag = 0;
        }

        return opslag + paymentmethod.price;
      } else {
        return 0;
      }
    },
    totalCosts() {
      return this.paymentCosts + this.shippingCosts + this.cartTotal();
    },
    addresses() {
      if (this.user.addresses) {
        const addresses = this.user.addresses;

        addresses.sort((a, b) => {
          return b.default - a.default;
        });

        return addresses;
      } else {
        return [];
      }
    },
    settings() {
      return this.$store.getters["shop/settings"];
    },
    countries() {
      if (this.settings) {
        return this.settings.countries;
      } else {
        return [];
      }
    },
    cart() {
      return this.$store.getters["cart/cart"];
    },
    totalGrams() {
      var items = 0;

      this.cart.forEach((item) => {
        items = items + item.amount * item.product.weight;
      });

      if (this.settings && this.settings.variables) {
        var base_weight = this.settings.variables.find(
          (a) => a.key === "package_base_weight_in_grams"
        );
      }

      if (base_weight) {
        return Number(items) + Number(base_weight.value);
      } else {
        return items;
      }
    },
  },
  methods: {
    resetPassword() {
      this.form.password = null;
    },
    checkIfAccount() {
      this.$store
        .dispatch("shop/CheckIfAccount", { email: this.form.email })
        .then((response) => {
          if (response.data === "user found") {
            this.guest = false;
            setTimeout(
              function () {
                this.$refs.password.$refs.password.$refs.input.focus();
              }.bind(this),
              44
            );
            this.$buefy.toast.open({
              message: "An account has been found for " + this.form.email + ".",
              type: "is-success",
            });
          }
          if (response.data === "user not found") {
            this.guest = true;
          }
        });
    },
    logout() {
      this.$store.dispatch("auth/logout").then((response) => {
        this.guest = true;
        this.form.password = "";
        this.selectedAddress = {};
        this.form.selectedAddress = {};
        this.form.shippingmethod = "";
        this.form.paymentmethod = "";
        this.$store.dispatch("createFlashBanner", {
          message: "Logged out succesfully.",
          timeout: 3,
        });
      });
    },
    findDefaultAddress() {
      if (this.addresses.length) {
        const address = this.addresses.find((a) => a.default === true);
        if (address) {
          return (this.selectedAddress = address);
        }
        return (this.selectedAddress = this.addresses[0]);
      }
    },
    closeModal() {
      this.showAddresModal = false;
      if (!this.selectedAddress.id) {
        this.findDefaultAddress();
      }
    },
    AddAddress() {
      this.selectedAddress = {};
      this.showAddresModal = true;
    },
    setNewAddress(address) {
      const constaddress = this.addresses.find((a) => a.id === address.id);
      this.selectedAddress = constaddress;
    },
    updateAddress(address) {
      this.selectedAddress = address;
      this.showAddresModal = true;
    },
    login() {
      return this.$store
        .dispatch("auth/login", {
          email: this.form.email,
          password: this.form.password,
          rememberme: false,
        })
        .then(() => {
          this.$buefy.toast.open({
            message: "Logged in succesfully!",
            type: "is-success",
          });
        });
    },
    openPasswordResetModal() {
      return this.$store.dispatch("auth/toggleResetPasswordModal", true);
    },
    removeFromCart(item) {
      this.$buefy.toast.open({
        message: "Product removed from cart",
        type: "is-danger",
      });
      this.$store.dispatch("cart/RemoveProductFromCart", item);
    },
    showTermsAndConditions() {
      this.$store.dispatch("shop/showTermsAndConditions");
    },

    checkout() {
      this.form.cart = this.cart;
      if (this.selectedAddress.id) {
        this.form.selectedAddress = this.selectedAddress;
      }

      this.$store.dispatch("shop/checkout", this.form).then((response) => {
        this.$store.dispatch("cart/clearLocalStorage");
        location.assign(response.data);
      });
    },
    cartTotal() {
      var items = 0;

      this.cart.forEach((item) => {
        if (item.product.type === "options") {
          items =
            items + item.amount * (item.option.price + item.product.price);
        } else {
          items = items + item.amount * item.product.price;
        }
      });

      return items;
    },
    calcVat() {
      const vat = this.calcVatMixin(
        this.cart,
        this.shippingCosts,
        this.paymentCosts,
        this.send_country,
        this.settings
      );

      if (vat) {
        this.vat = vat.tax_low + vat.tax_high;
      }
    },
  },
  watch: {
    send_country: {
      deep: false,
      handler() {
        this.calcVat();
      },
    },
    totalCosts: {
      deep: false,
      handler() {
        this.calcVat();
      },
    },

    countryShippingMethods: {
      deep: true,
      handler() {
        if (this.countryShippingMethods.length === 1) {
          this.form.shippingmethod = this.countryShippingMethods[0].id;
        }
      },
    },
    countryPaymentMethods: {
      deep: true,
      handler() {
        if (this.countryPaymentMethods.length === 1) {
          this.form.paymentmethod = this.countryPaymentMethods[0].id;
        }
      },
    },
    addresses: {
      deep: true,
      handler() {
        this.findDefaultAddress();
      },
    },
  },
  mounted() {
    this.findDefaultAddress();
  },
};
</script>
