<style scoped>
.notVisible {
  color: lightgrey;
}
</style>

<template>
  <div>
    <div class="columns is-mobile">
      <div class="column">
        <h1 class="title is-5">Categories</h1>
      </div>
      <div class="column">
        <button
          class="button is-pulled-right is-rounded is-small"
          @click="showCrModal = true"
        >
          Toevoegen
        </button>
      </div>
    </div>

    <div
      class="columns"
      v-for="outerLoop in Math.ceil(categories.length / maxItemsPerRow)"
      :key="outerLoop"
    >
      <div
        class="column is-3"
        v-for="item in calculatedMax(outerLoop)"
        :key="item"
      >
        <div
          class="card pointer"
          @click="
            navigateToCategory(
              categories[item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1]
                .slug
            )
          "
        >
          <div class="card-image">
            <figure class="image is-1by1">
              <div
                @mouseover="
                  mouseoverImage(
                    categories[
                      item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                    ]
                  )
                "
                @mouseleave="mouseleaveImage"
              >
                <img
                  v-if="
                    hoveredPoduct ===
                    categories[
                      item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                    ]
                  "
                  :src="
                    env.VUE_APP_API_URL +
                    '/web/picture/' +
                    categories[
                      item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                    ].alt_picture
                  "
                  :alt="
                    [item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1]
                      .naam
                  "
                />

                <img
                  v-else
                  :src="
                    env.VUE_APP_API_URL +
                    '/web/picture/' +
                    categories[
                      item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                    ].main_picture
                  "
                  :alt="
                    [item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1]
                      .naam
                  "
                />
              </div>
            </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media"></div>
              <div class="media-content">
                <p class="title is-4 has-text-centered">
                  <span
                    :class="{
                      notVisible:
                        !categories[
                          item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                        ].visible,
                    }"
                  >
                    {{
                      hoofdletter(
                        categories[
                          item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                        ].naam
                      )
                    }}
                  </span>
                  <br />
                  <button
                    class="button"
                    @click.stop="
                      changePosition(
                        categories[
                          item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                        ],
                        'up'
                      )
                    "
                  >
                    <b-icon icon="arrow-up" size="is-small"> </b-icon>
                  </button>
                  <button
                    class="button"
                    @click.stop="
                      changePosition(
                        categories[
                          item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                        ],
                        'down'
                      )
                    "
                  >
                    <b-icon icon="arrow-down" size="is-small"> </b-icon>
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <crmodal v-on:closeModal="closeCRmodal()" v-if="showCrModal" />
  </div>
</template>

<script>
import crmodal from "./AdminCategoriesCreateModal.vue";

export default {
  components: {
    crmodal,
  },
  props: [],
  data() {
    return {
      showCrModal: false,
      env: process.env,
      maxItemsPerRow: 4,
      hoveredPoduct: null,
    };
  },
  computed: {
    categories() {
      const cats = this.$store.getters["adminCategories/categories"];

      cats.sort(function (a, b) {
        return a.position - b.position;
      });

      return cats;
    },
  },
  methods: {
    closeCRmodal() {
      this.showCrModal = false;
    },
    calculatedMax(loop) {
      const max = this.categories.length;
      const rows = this.maxItemsPerRow * loop;
      if (max < rows) {
        return max - this.maxItemsPerRow * (loop - 1);
      } else {
        return this.maxItemsPerRow;
      }
    },
    mouseoverImage(category) {
      this.hoveredPoduct = category;
    },
    mouseleaveImage() {
      this.hoveredPoduct = {};
    },
    navigateToCategory(category) {
      this.$router.push({
        name: "admin-category",
        params: { category: category },
      });
    },
    maxItems(max) {
      return this.categories.slice(0, 2);
    },
    changePosition(item, direction) {
      const payload = {
        array: this.categories,
        id: item.id,
        direction: direction,
      };
      this.$store.dispatch("adminCategories/changeCategoryPosition", payload);
    },
  },
  mounted() {},
};
</script>
