import axios from "axios";

export const getSettings = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "get-settings";
  data.method = "GET";
  data.loading = "get-settings";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setSettings", response.data);
      const open = response.data.variables.find(
        (a) => a.key === "website_open"
      );
      if (!open || open.value == 0) {
        dispatch("setClosedModal", true);
      }
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getCountries = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "get-countries";
  data.method = "GET";
  data.loading = "get-countries";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setCountries", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getProducts = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "get-products";
  data.method = "GET";
  data.loading = "get-products";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setProducts", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getCategories = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "get-categories";
  data.method = "GET";
  data.loading = "get-categories";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setCategories", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const saveVariables = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-save-variables";
  data.method = "POST";
  data.loading = "save_variables";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      dispatch("getSettings");
      commit("setVariables", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const savePictureVariable = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-save-picture-variable";
  data.method = "POST";
  data.loading = "save_variables";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      dispatch("getSettings");
      commit("setVariables", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const destroyPagePicture = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-destroy-picture-variable";
  data.method = "POST";
  data.loading = "destroy_picture" + payload.loadingKey;
  console.log(data.loading);
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      dispatch("getSettings");
      commit("setVariables", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const CheckIfAccount = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "check-for-account";
  data.method = "POST";
  data.loading = "save_variables";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const checkout = function ({ commit, dispatch }, payload) {
  const data = {};
  data.url = "checkout";
  data.method = "POST";
  data.data = payload;
  data.loading = "checkout";

  const id = Math.random();

  dispatch("websocket/setExcludeId", id, { root: true });

  dispatch("loadingOn", data.loading, { root: true });

  return axios({
    url: process.env.VUE_APP_API_URL + "/web/" + data.url,
    method: data.method,
    data: data.data,
    headers: {
      "X-Socket-ID": window.echo.socketId(),
      "Broadcast-ID-Excluded": id,
    },
  })
    .then(function (response) {
      // dispatch("loadingOff", data.loading, { root: true });
      return Promise.resolve(response);
    })
    .catch(function (error) {
      dispatch("loadingOff", data.loading, { root: true });
      return Promise.reject(error);
    });
};

export const setClosedModal = function ({ dispatch, commit }, payload) {
  commit("setClosedModal", payload);
};

export const setCategories = function ({ dispatch, commit }, payload) {
  commit("setCategories", payload);
};

export const getOrder = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "get-order/" + payload;
  data.method = "GET";
  data.loading = "save_variables";
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setOrder", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const calcOrdertotal = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "calc-order-totals/";
  data.method = "POSt";
  data.loading = "calc_order_totals";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const showTermsAndConditions = function ({ dispatch, commit }, payload) {
  window.open(process.env.VUE_APP_API_URL + "/web/show-terms-and-conditions");
};
