import Vue from "vue";
import currency from "currency.js";

const euro = Vue.mixin({
  methods: {
    euro(payload) {
      return currency(payload, {
        separator: ".",
        decimal: ",",
        fromCents: false,
        symbol: "€",
      });
    },
    euroFromCents(payload) {
      const money = currency(payload, {
        separator: ".",
        decimal: ",",
        fromCents: true,
        symbol: "€",
      }).format();
      return this.displayDecimalDash(money);
    },
    euroFromCentsFormatZeroToDash(payload) {
      if (payload === 0) {
        return "-";
      }
      const money = currency(payload, {
        separator: ".",
        decimal: ",",
        fromCents: true,
        symbol: "€",
      }).format();
      return this.displayDecimalDash(money);
    },
    euroFromCentsWithoutSymbol(payload) {
      const money = currency(payload, {
        separator: ".",
        decimal: ",",
        fromCents: true,
        symbol: "€",
      }).format();
      return money.replace("€", "");
    },
    euroFromCentsWithoutDash(payload) {
      const money = currency(payload, {
        separator: ".",
        decimal: ",",
        fromCents: true,
        symbol: "€",
      }).format();

      return money;
    },
    setPrice(modal, sourceKey, targetKey) {
      const edit = modal[sourceKey].replace(",", ".") * 100;
      const editArray = String(edit).split(".");
      Vue.set(modal, targetKey, Number(editArray[0]));
    },
    displayDecimalDash(payload) {
      if (payload.substring(payload.length - 2) === "00") {
        return payload.substring(0, payload.length - 2) + "-";
      }

      return payload;
    },
  },
});

export default euro;
