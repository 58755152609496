var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('navBar'),_c('div',{staticClass:"container box"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-6 has-text-centered"},[_c('pagePictureShower',{attrs:{"variable_key":"about_page_picture_1","settings":_vm.settings}})],1),_c('div',{staticClass:"column is-6 content"},[(
            _vm.settings &&
            _vm.settings.variables.find((a) => a.key === 'about_page_text_1')
          )?_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.settings.variables.find((a) => a.key === 'about_page_text_1')
              .value
          )}}):_vm._e()])]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-6 content"},[(
            _vm.settings &&
            _vm.settings.variables.find((a) => a.key === 'about_page_text_2')
          )?_c('span',{domProps:{"innerHTML":_vm._s(
            _vm.settings.variables.find((a) => a.key === 'about_page_text_2')
              .value
          )}}):_vm._e()]),_c('div',{staticClass:"column is-6 has-text-centered"},[_c('pagePictureShower',{attrs:{"variable_key":"about_page_picture_2","settings":_vm.settings}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }