<style scoped>
.customInput {
  margin-bottom: 15px;
}
.columns {
  margin: 0px;
  padding: 0px;
}

.column {
  margin: 0px;
  padding: 0px;
}
</style>

<template>
  <div class="modal is-active">
    <div class="modal-background" @click="closeModal()"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Edit customer</p>
        <button
          class="delete"
          aria-label="close"
          @click="closeModal()"
        ></button>
      </header>
      <section class="modal-card-body">
        <div class="columns">
          <div class="column">
            <formInput
              class="customInput"
              :model="invoice"
              title="name"
              modelKey="customer_name"
              label="on-border"
            />
          </div>
          <div class="column">
            <formInput
              class="customInput"
              :model="invoice"
              title="surname"
              modelKey="customer_surname"
              label="on-border"
            />
          </div>
        </div>
        <!-- email -->
        <formInput
          class="customInput"
          :model="invoice"
          title="business name"
          modelKey="customer_business_name"
          label="on-border"
        />
        <!-- email -->
        <formInput
          class="customInput"
          :model="invoice"
          title="email"
          modelKey="customer_email"
          label="on-border"
        />
        <!-- street -->
        <div class="columns">
          <div class="column is-7">
            <formInput
              class="customInput"
              title="street"
              :model="invoice"
              modelKey="customer_street"
              label="on-border"
            />
          </div>
          <div class="column">
            <formInput
              title="house number"
              class="customInput"
              :model="invoice"
              modelKey="customer_house_number"
              label="on-border"
            />
          </div>
        </div>
        <!-- postal code -->
        <div class="columns">
          <div class="column">
            <formInput
              title="postal code"
              class="customInput"
              :model="invoice"
              modelKey="customer_postal_code"
              label="on-border"
            />
          </div>
          <div class="column">
            <formInput
              title="city"
              class="customInput"
              :model="invoice"
              modelKey="customer_city"
              label="on-border"
            />
          </div>
        </div>
        <!-- state  -->
        <div class="columns">
          <div class="column">
            <formInput
              title="state"
              class="customInput"
              :model="invoice"
              modelKey="customer_state"
              label="on-border"
            />
          </div>
          <div class="column">
            <formInput
              title="country"
              class="customInput"
              :model="invoice"
              modelKey="customer_country"
              label="on-border"
            />
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          class="button is-rounded is-primary"
          :class="{ 'is-loading': ajaxloading.update_invoice }"
          @click="saveProduct()"
        >
          Save
        </button>
        <button
          class="button is-rounded"
          :class="{ 'is-loading': ajaxloading.update_invoice }"
          @click="cancelUpdate()"
        >
          Cancel
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    invoice: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    saveProduct() {
      this.$store
        .dispatch("adminInvoices/saveInvoiceCustomerDetails", this.invoice)
        .then(() => {
          this.$buefy.toast.open("Invoice updated");
          this.closeModal();
        });
    },
    cancelUpdate() {
      this.$store
        .dispatch("adminInvoices/cancelUpdate", this.invoice)
        .then(() => {
          this.closeModal();
        });
    },
  },
  mounted() {},
};
</script>
