import state from "./state";

export const getOrders = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-get-orders";
  data.method = "get";
  data.loading = "getorders";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setOrders", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const changeOrderShippingStatus = function (
  { dispatch, commit },
  payload
) {
  const data = {};
  data.url = "admin-change-order-shipping-status/" + payload.order.id;
  data.method = "post";
  data.loading = "";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setUpdateOrder", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const resendOrderConfirmedEmail = function (
  { dispatch, commit },
  payload
) {
  const data = {};
  data.url = "admin-resend-order-confirmed-email/" + payload.id;
  data.method = "post";
  data.loading = "resend_order_confirmed_mail";
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const sendPickupReadyEmail = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-send-pickup-ready-email/" + payload.order.id;
  data.method = "post";
  data.loading = "sending_email";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const sendEmailToCustomer = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-send-email-to-customer/" + payload.order.id;
  data.method = "post";
  data.loading = "sending_email";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const recheckPaymentStatus = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-recheck-order-payment-status/" + payload.order.id;
  data.method = "post";
  data.loading = "checkingMollieStatus";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setUpdateOrder", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const searchOrders = function ({ dispatch }, payload) {
  const data = {};
  data.url = "admin-search-order";
  data.method = "post";
  data.loading = "searching_orders";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const deleteOrder = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-delete-order/" + payload.order.id;
  data.method = "post";
  data.loading = "deleteOrder";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("deleteOrder", payload.order);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const saveOrder = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-save-order/" + payload.order.id;
  data.method = "post";
  data.loading = "saveOrder";
  data.data = payload.order;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setUpdateOrder", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const saveProduct = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-edit-order-edit-product/" + payload.product.id;
  data.method = "post";
  data.loading = "editOrderProduct";
  data.data = payload.product;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setUpdateOrder", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const deleteProduct = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-edit-order-delete-product/" + payload.product.id;
  data.method = "post";
  data.loading = "editOrderProduct";
  data.data = payload.product;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setUpdateOrder", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const refundOrder = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-refund-order/" + payload.order.id;
  data.method = "post";
  data.loading = "refunding";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setUpdateOrder", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      dispatch(
        "createFlashBanner",
        {
          message: error.response.data.message,
          type: "is-danger",
          timeout: 10,
        },
        { root: true }
      );
      return Promise.reject(error);
    });
};

export const getPaymentRefunds = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-get-payment-refunds/" + payload.id;
  data.method = "get";
  data.loading = "refunding";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setUpdateOrder", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const cancelRefund = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-cancel-refund/" + payload.id;
  data.method = "post";
  data.loading = "cancel_refund";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setUpdateOrder", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const cancelManualRefund = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-cancel-manual-refund/" + payload.id;
  data.method = "post";
  data.loading = "cancel_refund";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setUpdateOrder", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const createNewShippingLabel = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-create-new-order-shipping-label/" + payload.id;
  data.method = "post";
  data.loading = "create_shipping_label";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setUpdateOrder", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const generateAndSendInvoices = function (
  { dispatch, commit },
  payload
) {
  const data = {};
  data.url = "admin-generate-and-send-invoices/" + payload.id;
  data.method = "post";
  data.loading = "generating_and_sending_invoices";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setUpdateOrder", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const recheckShippingStatus = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-check-order-shipping-status/" + payload.id;
  data.method = "post";
  data.loading = "checking_shipping_status";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setUpdateOrder", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const registerShippingLabel = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-register-order-shipping-label/" + payload.id;
  data.method = "post";
  data.loading = "register_shipping_label";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setUpdatedLabel", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const createExtraInvoice = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-create-extra-order-invoice/" + payload.id;
  data.method = "post";
  data.loading = "create_extra_invoice";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      if (response.data !== "") {
        commit("setUpdateOrder", response.data.order);
      }
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const sendInvoiceToCustomer = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-send-invoice-to-customer-from-order/" + payload.invoice.id;
  data.method = "post";
  data.loading = "send_invoice";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      if (response.data !== "") {
        commit("setUpdateOrder", response.data);
      }
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const setInvoiceNumber = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-set-invoice-number/" + payload.id;
  data.method = "post";
  data.loading = "setting_invoice_number";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      if (response.data !== "") {
        commit("setUpdateOrder", response.data);
      }
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
