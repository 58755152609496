export const createCountry = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-prices-create-country";
  data.method = "post";
  data.loading = "create_country";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setNewCountry", response.data);
      commit("setCountry", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getCountries = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-prices-get-countries";
  data.method = "get";
  data.loading = "create_country";
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setCountries", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getShippingMethods = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-prices-get-shipping-methods";
  data.method = "get";
  data.loading = "create_country";
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setShippingMethods", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getPaymentMethods = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-prices-get-payment-methods";
  data.method = "get";
  data.loading = "create_country";
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setPaymentMethods", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getCountry = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-prices-get-country/" + payload;
  data.method = "get";
  data.loading = "create_country";
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setCountry", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const ToggleCountry = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-prices-toggle-country/" + payload.id;
  data.method = "post";
  data.loading = "create_country";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setNewCountry", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const deleteCountry = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-prices-delete-country/" + payload.id;
  data.method = "post";
  data.loading = "deletecountry";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("removeCountry", payload);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const cancelCountryUpdate = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-prices-get-country/" + payload.id;
  data.method = "get";
  data.loading = "deletecountry";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("cancelCountryUpdate", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const updateCountry = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-prices-update-country/" + payload.id;
  data.method = "post";
  data.loading = "create_country";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const cancelShippingMethodUpdate = function (
  { dispatch, commit },
  payload
) {
  dispatch("getShippingMethods");
};

export const cancelPaymentMethodUpdate = function (
  { dispatch, commit },
  payload
) {
  dispatch("getPaymentMethods");
};

export const createShippingMethod = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-prices-create-shipping-method/";
  data.method = "post";
  data.loading = "create_shipping_method";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setNewShippingMethod", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const updateShippingMethod = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-prices-update-shipping-method/" + payload.id;
  data.method = "post";
  data.loading = "create_shipping_method";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      dispatch("getCountries");
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const savePaymentMethodToCountry = function (
  { dispatch, commit },
  payload
) {
  const data = {};
  data.url =
    "admin-prices-save-payment-method-to-country/" + payload.country.id;
  data.method = "post";
  data.loading = "save_payment_method";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("cancelCountryUpdate", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const removePaymentMethodFromCountry = function (
  { dispatch, commit },
  payload
) {
  const data = {};
  data.url =
    "admin-prices-remove-payment-method-from-country/" + payload.country.id;
  data.method = "post";
  data.loading = "remove_payment_method";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("cancelCountryUpdate", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const saveShippingMethodToCountry = function (
  { dispatch, commit },
  payload
) {
  const data = {};
  data.url =
    "admin-prices-save-shipping-method-to-country/" + payload.country.id;
  data.method = "post";
  data.loading = "save_shipping_method";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("cancelCountryUpdate", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const removeShippingMethodFromCountry = function (
  { dispatch, commit },
  payload
) {
  const data = {};
  data.url =
    "admin-prices-remove-shipping-method-from-country/" + payload.country.id;
  data.method = "post";
  data.loading = "remove_shipping_method";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("cancelCountryUpdate", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const deleteShippingMethod = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-prices-delete-shipping-method/" + payload.id;
  data.method = "post";
  data.loading = "create_shipping_method";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("deleteShippingMethod", payload.id);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const setShippingMethod = function ({ dispatch, commit }, payload) {
  commit("setShippingMethod", payload);
};

export const setPaymentMethod = function ({ dispatch, commit }, payload) {
  commit("setPaymentMethod", payload);
};

export const createPaymentMethod = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-prices-create-payment-method/";
  data.method = "post";
  data.loading = "create_payment_method";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setNewPaymentMethod", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const deletePaymentMethod = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-prices-delete-payment-method/" + payload.id;
  data.method = "post";
  data.loading = "create_payment_method";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("deletePaymentMethod", payload.id);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const updatePaymentMethod = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-prices-update-payment-method/" + payload.id;
  data.method = "post";
  data.loading = "create_payment_method";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      dispatch("getCountries");
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
