<template>
  <div>
    <div class="title is-5">Order {{ order.number }} Invoice options</div>
    <div class="columns"></div>
    <button class="button is-fullwidth" @click="$router.go(-1)">
      Back to order
    </button>
    <br />
    <button
      class="button is-fullwidth is-primary"
      :class="{ 'is-loading': ajaxloading.create_extra_invoice }"
      @click="preCreateExtraInvoice()"
    >
      Create invoice after order changes
    </button>
    <br />
    <invoiceTable
      v-if="order && order.all_invoices"
      :invoices="order.all_invoices"
    />
    <customMailEditor
      v-if="invoice && settings"
      :invoice="invoice"
      vuexStoreAction="adminOrders/sendInvoiceToCustomer"
      v-on:close="invoice = null"
      settingsDefaultEmailKey="extra_invoice_email"
      settingsDefaultEmailSubjectKey="extra_invoice_subject"
      :attachment="
        settings.variables.find((a) => a.key === 'company_name').value +
        ' ' +
        invoice.number
      "
      loadingKey="send_invoice"
    />
  </div>
</template>

<script>
import invoiceTable from "./AdminInvoiceTable.vue";
import customMailEditor from "./CustomMailEditorModal.vue";

export default {
  components: {
    invoiceTable,
    customMailEditor,
  },
  props: [],
  data() {
    return {
      invoice: null,
    };
  },
  computed: {
    settings() {
      return this.$store.getters["shop/settings"];
    },
    orders() {
      return this.$store.getters["adminOrders/orders"];
    },
    order() {
      if (this.orders && this.orders.length) {
        var order = this.orders.find(
          (a) => a.id == this.$router.currentRoute.params.order
        );
      }

      if (order) {
        return order;
      } else {
        return {};
      }
    },
  },
  methods: {
    preCreateExtraInvoice() {
      this.$buefy.dialog.confirm({
        title: "Create invoice?",
        message: "Do you wish to create all missing invoices?",
        type: "is-info",
        onConfirm: () =>
          this.$store
            .dispatch("adminOrders/createExtraInvoice", this.order)
            .then((response) => {
              if (response.data === "") {
                this.$store.dispatch("createFlashBanner", {
                  type: "is-info",
                  timeout: 5,
                  message:
                    "No invoices created, all invoices have already been created.",
                });
              } else {
                this.$buefy.dialog.confirm({
                  title: "Send invoice to customer",
                  message:
                    "Invoice has been created. <br> Do you wish to compose an email with this invoice to the customer?",
                  confirmText: "Compose email",
                  type: "is-info",
                  hasIcon: true,
                  onConfirm: () => (this.invoice = response.data.invoice),
                });
              }
            }),
      });
    },
  },
  mounted() {},
};
</script>
