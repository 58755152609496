<template>
  <div class="FlashBannerOuterDiv">
    <transition name="slide">
      <nav :class="['navbar flashBanner', customClass]" v-if="flashBanner">
        <div class="flashBannerText">
          <span v-html="flashBanner.message"></span>&nbsp;
          <a class="flashBannerIcon" @click="destroyFlashBanner()"
            ><icon icon="times"></icon
          ></a>
        </div>
      </nav>
    </transition>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {
    flashBanner() {
      return this.$store.getters["flashBanner"];
    },
    customClass() {
      if (this.flashBanner.type) {
        return this.flashBanner.type;
      } else {
        return "is-success";
      }
    },
  },
  methods: {
    destroyFlashBanner() {
      this.$store.dispatch("destroyFlashBanner");
    },
  },
  mounted() {},
};
</script>
