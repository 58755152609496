<style scoped>
.column {
  padding-top: 0px;
  padding-bottom: 0px;
}

.pointer {
  margin-left: 5px;
}
</style>

<template>
  <div>
    <button class="button is-small is-rounded" @click="$router.go(-1)">
      Naar alle producten
    </button>
    <br />
    <br />
    <div class="">
      <span class="title is-5">Product: {{ product.slug }}</span> &nbsp;
    </div>

    <br />

    <div class="columns is-mobile">
      <div class="column is-3">Name:</div>
      <div class="column">
        {{ product.name }}
      </div>
      <div class="column">
        <b-icon
          class="pointer is-pulled-right"
          icon="trash"
          size="is-small"
          @click.native="deleteProduct()"
        />
        <b-icon
          class="pointer is-pulled-right"
          icon="edit"
          size="is-small"
          @click.native="
            $router.push({
              name: 'admin-product-edit',
              params: { product: product.slug },
            })
          "
        />
      </div>
    </div>

    <div class="columns is-mobile">
      <div class="column is-3">ID:</div>
      <div class="column">
        {{ product.id }}
      </div>
    </div>

    <div class="columns is-mobile">
      <div class="column is-3">Category:</div>
      <div class="column" v-if="product.category">
        {{ product.category.naam }}
      </div>
    </div>

    <div class="columns is-mobile">
      <div class="column is-3">Status:</div>
      <div class="column">
        {{ product.status }}
      </div>
    </div>

    <hr />

    <div class="columns is-mobile" v-for="tag of product.tags" :key="tag.id">
      <div class="column is-3">Tag:</div>
      <div class="column" v-if="tag">
        {{ tag.naam }}
      </div>
    </div>

    <hr />

    <div class="columns is-mobile">
      <div class="column is-3">Price:</div>
      <div class="column">
        {{ euroFromCents(product.price) }}
      </div>
    </div>

    <div class="columns is-mobile">
      <div class="column is-3">Weight (grams):</div>
      <div class="column">
        {{ product.weight }}
      </div>
    </div>

    <div class="columns is-mobile">
      <div class="column is-3">Small product</div>
      <div class="column">
        {{ product.small_product }}
      </div>
    </div>

    <div class="columns is-mobile">
      <div class="column is-3">Type:</div>
      <div class="column">
        {{ hoofdletter(product.type) }}
      </div>
    </div>
    <hr />

    <span v-if="product.type === 'options'">
      <span v-for="option of product.options" :key="option.id">
        <div class="columns is-mobile">
          <div class="column is-3">Option:</div>
          <div class="column">
            {{ option.name }}
          </div>
        </div>
        <div class="columns is-mobile">
          <div class="column is-3">Amount:</div>
          <div class="column">
            {{ option.amount }}
          </div>
        </div>
        <div class="columns is-mobile">
          <div class="column is-3">Price:</div>
          <div class="column">
            {{ euroFromCents(option.price) }}
          </div>
        </div>
        <div class="columns is-mobile">
          <div class="column is-3">Picture:</div>
          <b-image
            ratio="1by1"
            width="100px"
            v-if="product.pictures"
            :src="
              env.VUE_APP_API_URL +
              '/web/picture/' +
              product.pictures[option.picture].uuid
            "
            alt="Product Image"
          />
        </div>

        <br />
      </span>

      <hr />
    </span>

    <br />

    <div class="columns">
      <div class="column is-6">
        <b-carousel
          indicator-custom
          :indicator-inside="false"
          :autoplay="false"
        >
          <b-carousel-item v-for="(item, i) in product.pictures" :key="i">
            <b-image
              ratio="1by1"
              class="image"
              :src="
                env.VUE_APP_API_URL + '/web/picture/' + product.pictures[i].uuid
              "
            ></b-image>
          </b-carousel-item>
          <template #indicators="props">
            <b-image
              v-if="product.pictures"
              class="al image"
              :src="
                env.VUE_APP_API_URL +
                '/web/picture/' +
                product.pictures[props.i].uuid
              "
              :title="props.i"
            ></b-image>
          </template>
        </b-carousel>
      </div>

      <div class="column is-6">
        <div class="content" v-html="product.description"></div>
      </div>
    </div>

    <editPictureModal
      v-on:closeModal="showEditPictureModal = false"
      :updateForm="product"
      v-if="showEditPictureModal"
    />
  </div>
</template>

<script>
import editPictureModal from "./AdminProductEditPictures.vue";

export default {
  components: {
    editPictureModal,
  },
  props: [],
  data() {
    return {
      env: process.env,
      showEditModal: false,
      showEditPictureModal: false,
    };
  },
  computed: {
    product() {
      return this.$store.getters["adminProducts/product"];
    },
  },
  methods: {
    deleteProduct() {
      this.$buefy.dialog.confirm({
        title: "Delete product",
        message:
          "Are you sure you want to <b>delete</b> this product? This action cannot be undone.",
        confirmText: "Delete Product",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          this.$store
            .dispatch("adminProducts/deleteProduct", this.product)
            .then(() => {
              this.$router.go(-1);
            }),
      });
    },
  },
  mounted() {
    this.$store.dispatch(
      "adminProducts/getProduct",
      this.$route.params.product
    );
  },
};
</script>
