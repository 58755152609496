<style scoped>
.columns {
  margin-bottom: 0px;
}

.customInput {
  margin-bottom: 12px;
}
</style>

<template>
  <div>
    <div class="title" v-if="$router.currentRoute.name === updateRoute">
      Update Shipping Method: {{ shipping_method.name }}
    </div>
    <div v-else class="title">Add Shipping Method</div>

    <div class="columns">
      <div class="column">
        <formInput
          class="customInput"
          :model="form"
          title="Internal name"
          modelKey="internal_name"
          label="on-border"
          placeholder="Name for internal use"
        />
      </div>
      <div class="column">
        <formInput
          class="customInput"
          :model="form"
          title="Display name"
          modelKey="display_name"
          label="on-border"
          placeholder="Name that the customer sees at checkout"
        />
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <formSelect
          v-if="settings"
          v-on:change="setMethodName()"
          class="customInput"
          label="on-border"
          :model="form"
          modelKey="icon"
          title="Icon"
          placeholder="Select icon"
          :selectOptions="
            settings.admin.shipping_methods.map(function (o) {
              return o.name;
            })
          "
        />
      </div>
      <div class="column">
        <customIcon
          v-if="
            settings &&
            form.icon &&
            settings.admin.shipping_methods.find(
              (a) => a.name === this.form.icon
            ).icon_location === 'local'
          "
          :icon="
            settings.admin.shipping_methods.find(
              (a) => a.name === this.form.icon
            ).icon_name
          "
        />
        <b-icon
          v-if="
            settings &&
            form.icon &&
            settings.admin.shipping_methods.find(
              (a) => a.name === this.form.icon
            ).icon_location === 'font awesome'
          "
          :pack="
            settings.admin.shipping_methods.find(
              (a) => a.name === this.form.icon
            ).icon_pack
          "
          :icon="
            settings.admin.shipping_methods.find(
              (a) => a.name === this.form.icon
            ).icon_name
          "
        ></b-icon>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <formInput
          @input="setPrice()"
          class="customInput"
          :model="form"
          modelKey="editprice"
          title="price"
          label="on-border"
          placeholder="Price of shipping method"
        />
        <p class="help is-danger" v-if="validation.price">
          {{ validation["price"][0] }}
        </p>
      </div>
      <div class="column">{{ euroFromCents(form.price) }}</div>
    </div>

    <div class="columns">
      <div class="column">
        <formInput
          class="customInput"
          :model="form"
          modelKey="min_weight"
          label="on-border"
          placeholder="Minimum weight for shipping method in grams"
        />
      </div>
      <div class="column">
        <formInput
          class="customInput"
          :model="form"
          modelKey="max_weight"
          label="on-border"
          placeholder="Maximum weight for shipping method in grams"
        />
      </div>
    </div>

    <formSelect
      :model="form"
      modelKey="package_type"
      label="on-border"
      :selectOptions="['letter parcel', 'regular', 'large package']"
    />

    <br />
    <button
      v-if="$router.currentRoute.name === updateRoute"
      class="button is-primary is-rounded"
      :class="{ 'is-loading': ajaxloading[loadingKey] }"
      @click="update()"
    >
      Update
    </button>
    <button
      v-else
      class="button is-primary is-rounded"
      :class="{ 'is-loading': ajaxloading[loadingKey] }"
      @click="submit()"
    >
      Create
    </button>
    &nbsp;
    <button class="button is-rounded" @click.prevent="cancelUpdate()">
      Cancel
    </button>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  components: {},
  props: {},
  data() {
    return {
      updateRoute: "admin-prices-edit-shipping_method",
      loadingKey: "create_shipping_method",
      form: {
        package_type: "regular",
      },
    };
  },
  computed: {
    country() {
      return this.$store.getters["adminPrices/country"];
    },
    settings() {
      return this.$store.getters["shop/settings"];
    },
    shipping_method() {
      return this.$store.getters["adminPrices/shippingMethod"];
    },
  },
  methods: {
    setPrice() {
      this.form.editprice;
      Vue.set(this.form, "price", this.form.editprice.replace(",", ".") * 100);
    },
    submit() {
      this.form.country = this.country;

      this.$store
        .dispatch("adminPrices/createShippingMethod", this.form)
        .then((response) => {
          this.$router.go(-1);
        });
    },
    update() {
      this.$store
        .dispatch("adminPrices/updateShippingMethod", this.form)
        .then((response) => {
          this.$router.go(-1);
        });
    },
    setMethodName() {
      this.form.name = this.form.icon;
    },
    cancelUpdate() {
      this.$store.dispatch(
        "adminPrices/cancelShippingMethodUpdate",
        this.country
      );
      this.$router.go(-1);
    },
  },
  mounted() {
    if (this.$router.currentRoute.name === this.updateRoute) {
      if (!this.shipping_method.id) {
        this.$router.go(-1);
      } else {
        this.form = this.shipping_method;
      }
    }
  },
};
</script>
