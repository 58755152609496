<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Edit product</p>
        <button
          class="delete"
          aria-label="close"
          @click="closeModal()"
        ></button>
      </header>
      <section class="modal-card-body">
        <div class="content" style="color: hsl(0deg, 0%, 21%)">
          <formInput
            label="on-border"
            class="customInput"
            :model="product"
            modelKey="name"
            title="Name"
          />

          <br />

          <span v-if="product.type !== 'unique'">
            <numberInput
              label="on-border"
              size="is-small"
              type="is-primary"
              :model="product"
              modelKey="amount"
            />
            <br />
          </span>

          <formSelect
            label="on-border"
            class="customInput"
            :model="product"
            modelKey="btwtype"
            :selectOptions="['low', 'high']"
            title="Btw
          type"
          />

          <br />



          <formInput
            :value="euroFromCentsWithoutSymbol(product.exbtw)"
            v-on:input="formatPrices()"
            label="on-border"
            class="customInput"
            :model="product"
            modelKey="edit_price"
            title="Prijs per stuk"
            validationKey="price"
          />

          <hr />

          <div class="columns">
            <div class="column"><strong></strong></div>
            <div class="column"><strong>Per stuk</strong></div>
            <div class="column"><strong>Som</strong></div>
          </div>


          <!--  price -->
          <div class="columns">
            <div class="column"><strong>Price</strong></div>
            <div class="column">{{ euroFromCents(product.price) }}</div>
            <div class="column">
              {{ euroFromCents(product.price * product.amount) }}
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          :class="{ 'is-loading': ajaxloading.editOrderProduct }"
          class="button is-success"
          @click="saveProduct()"
        >
          Save Changes
        </button>
        <button
          :class="{ 'is-loading': ajaxloading.deleteOrderProduct }"
          class="button is-danger"
          @click="preDelete()"
        >
          Delete Product
        </button>
        <button @click="closeModal()" class="button">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        email: "",
        subject: "",
      },
    };
  },
  computed: {
    settings() {
      return this.$store.getters["shop/settings"];
    },
  },
  methods: {
    closeModal() {
      this.$emit("close", true);
    },
    saveProduct() {
      this.$store
        .dispatch("adminOrders/saveProduct", {
          product: this.product,
        })
        .then(() => {
          this.$emit("close", false);
        });
    },
    formatPrices() {
      this.setPrice(this.product, "edit_price", "price");
    },
    deleteProduct() {
      this.$store
        .dispatch("adminOrders/deleteProduct", {
          product: this.product,
          order: this.order,
        })
        .then(() => {
          this.$emit("close", false);
        });
    },
    preDelete() {
      this.$buefy.dialog.confirm({
        title: "Deleting product",
        message:
          "Are you sure you want to <b>delete</b> this product? This action cannot be undone.",
        confirmText: "Delete product",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteProduct(),
      });
    },
  },
  mounted() {},
};
</script>
