<style scoped>
.column {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.columns {
  margin-bottom: 0px;
  margin-top: 0px;
}

.customInput {
  margin-bottom: 20px;
}
</style>

<template>
  <div>
    <formSelect
      v-if="settings && settings.countries.length"
      type="password"
      class="customInput"
      modelKey="country"
      :selectOptions="
        settings.countries.map(function (count) {
          return count.display_name;
        })
      "
      label="on-border"
      size="is-small"
      :model="form"
    />

    <span v-if="form.country">
      <b-field>
        <b-checkbox
          class="customCheckbox"
          v-model="form.business"
          size="is-small"
          >I am ordering for a business</b-checkbox
        >
      </b-field>

      <formInput
        v-if="form.business"
        class="customInput"
        :model="form"
        modelKey="business_name"
        label="on-border"
        title="Business name"
        size="is-small"
      />

      <div class="columns">
        <div class="column">
          <formInput
            class="customInput"
            :model="form"
            modelKey="name"
            label="on-border"
            size="is-small"
          />
        </div>
        <div class="column">
          <formInput
            class="customInput"
            :model="form"
            modelKey="surname"
            label="on-border"
            size="is-small"
          />
        </div>
      </div>

      <span v-if="!findObject.foundBillingAddress">
        <formAutocomplete
          :model="form"
          label="on-border"
          optionKey="description"
          modelKey="autocomplete"
          size="is-small"
          notFoundText="No address found"
          :options="addressOptions"
          validationKey="street"
          :openOnFocus="false"
          title="Address"
          v-on:input="findAddressId()"
          v-on:selected="findAddressDetails"
        />

        <br />

        <formCheckbox
          size="is-small"
          :model="findObject"
          :labelHidden="true"
          modelKey="foundBillingAddress"
          title="Enter address manually"
        />
      </span>

      <span v-else>
        <formInput
          class="customInput"
          :model="form"
          modelKey="street"
          label="on-border"
          size="is-small"
        />
        <div class="columns">
          <div class="column is-7">
            <formInput
              class="customInput"
              :model="form"
              modelKey="postal_code"
              label="on-border"
              title="Postal code"
              size="is-small"
            />
          </div>
          <div class="column is-5">
            <formInput
              class="customInput"
              :model="form"
              modelKey="house_number"
              label="on-border"
              title="House number"
              size="is-small"
            />
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <formInput
              class="customInput"
              :model="form"
              modelKey="city"
              label="on-border"
              size="is-small"
            />
          </div>
          <div class="column">
            <formInput
              class="customInput"
              :model="form"
              modelKey="state"
              label="on-border"
              size="is-small"
            />
          </div>
        </div>

        <b-field>
          <b-checkbox v-model="form.different_ship_address" size="is-small"
            >Ship to different address</b-checkbox
          >
        </b-field>
      </span>

      <span v-show="form.different_ship_address">
        <strong>Shipping address</strong>
        <br />
        <br />
        <formSelect
          v-if="settings && settings.countries.length"
          type="password"
          class="customInput"
          title="country"
          modelKey="send_country"
          :selectOptions="
            settings.countries.map(function (count) {
              return count.display_name;
            })
          "
          label="on-border"
          size="is-small"
          :model="form"
        />

        <span v-if="form.send_country">
          <b-field>
            <b-checkbox v-model="form.send_business" size="is-small"
              >I am ordering for a business</b-checkbox
            >
          </b-field>
          <formInput
            v-if="form.send_business"
            class="customInput"
            :model="form"
            modelKey="send_business_name"
            label="on-border"
            title="Business name"
            size="is-small"
          />

          <div class="columns">
            <div class="column">
              <formInput
                class="customInput"
                :model="form"
                modelKey="send_name"
                title="Name"
                label="on-border"
                size="is-small"
              />
            </div>
            <div class="column">
              <formInput
                class="customInput"
                :model="form"
                modelKey="send_surname"
                title="Surname"
                label="on-border"
                size="is-small"
              />
            </div>
          </div>

          <span v-if="!findObject.foundSendAddress">
            <formAutocomplete
              :model="form"
              label="on-border"
              optionKey="description"
              modelKey="send_autocomplete"
              size="is-small"
              notFoundText="No address found"
              validationKey="send_street"
              :options="send_addressOptions"
              :openOnFocus="false"
              title="Address"
              v-on:input="findSendAddressId()"
              v-on:selected="findSendAddressDetails"
            />

            <br />

            <formCheckbox
              size="is-small"
              :model="findObject"
              :labelHidden="true"
              modelKey="foundSendAddress"
              title="Enter address manually"
            />
          </span>

          <span v-else>
            <formInput
              class="customInput"
              :model="form"
              modelKey="send_street"
              title="Street"
              label="on-border"
              size="is-small"
            />
            <div class="columns">
              <div class="column is-7">
                <formInput
                  class="customInput"
                  :model="form"
                  modelKey="send_postal_code"
                  label="on-border"
                  title="Postal code"
                  size="is-small"
                />
              </div>
              <div class="column is-5">
                <formInput
                  class="customInput"
                  :model="form"
                  modelKey="send_house_number"
                  label="on-border"
                  title="House number"
                  size="is-small"
                />
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <formInput
                  class="customInput"
                  :model="form"
                  title="City"
                  modelKey="send_city"
                  label="on-border"
                  size="is-small"
                />
              </div>
              <div class="column">
                <formInput
                  class="customInput"
                  :model="form"
                  title="State"
                  modelKey="send_state"
                  label="on-border"
                  size="is-small"
                />
              </div>
            </div>
          </span>
        </span>
      </span>
    </span>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  components: {},
  props: {
    form: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      addressOptions: [],
      send_addressOptions: [],
      findObject: {
        foundBillingAddress: false,
        foundSendAddress: false,
      },
    };
  },
  computed: {
    settings() {
      return this.$store.getters["shop/settings"];
    },
    user() {
      return this.$store.getters["auth/user"];
    },
  },
  methods: {
    async findAddressId() {
      var country = {};

      if (this.form.country) {
        country = this.settings.countries.find(
          (a) => a.name === this.form.country
        );
      }

      const places = await this.findGooglePlaceIdMixin(
        this.form.autocomplete,
        country
      );

      this.addressOptions = places.predictions;
    },
    async findSendAddressId() {
      var country = {};

      if (this.form.send_country) {
        country = this.settings.countries.find(
          (a) => a.name === this.form.send_country
        );
      }

      const places = await this.findGooglePlaceIdMixin(
        this.form.send_autocomplete,
        country
      );
      this.send_addressOptions = places.predictions;
    },
    // async findAddress() {
    //   if (this.form.postal_code && this.form.house_number) {
    //     const address = await this.findAddressMixin(
    //       this.form.postal_code,
    //       this.form.house_number
    //     );

    //     if (address) {
    //       Vue.set(this.form, "street", address.data.street);
    //       Vue.set(this.form, "city", address.data.city);
    //     }
    //   }
    // },
    async findAddressDetails(address) {
      if (address) {
        const addressDetails = await this.findAddressDetailsMixin(address);

        if (addressDetails.street) {
          this.form.street = addressDetails.street;
        }

        if (addressDetails.house_number) {
          this.form.house_number = addressDetails.house_number;
        }

        if (addressDetails.postal_code) {
          this.form.postal_code = addressDetails.postal_code;
        }

        if (addressDetails.city) {
          this.form.city = addressDetails.city;
        }

        if (addressDetails.state) {
          this.form.state = addressDetails.state;
        }

        this.findObject.foundBillingAddress = true;
      }
    },
    async findSendAddressDetails(address) {
      if (address) {
        const addressDetails = await this.findAddressDetailsMixin(address);

        if (addressDetails.street) {
          this.form.send_street = addressDetails.street;
        }

        if (addressDetails.house_number) {
          this.form.send_house_number = addressDetails.house_number;
        }

        if (addressDetails.postal_code) {
          this.form.send_postal_code = addressDetails.postal_code;
        }

        if (addressDetails.city) {
          this.form.send_city = addressDetails.city;
        }

        if (addressDetails.state) {
          this.form.send_state = addressDetails.state;
        }

        this.findObject.foundSendAddress = true;
      }
    },
    // async findSendAddress() {
    //   if (this.form.send_postal_code && this.form.send_house_number) {
    //     const address = await this.findAddressMixin(
    //       this.form.send_postal_code,
    //       this.form.send_house_number
    //     );

    //     if (address) {
    //       Vue.set(this.form, "send_street", address.data.street);
    //       Vue.set(this.form, "send_city", address.data.city);
    //     }
    //   }
    // },
  },
  mounted() {},
};
</script>
