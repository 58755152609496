<style scoped>
.help {
  margin: 0px;
  padding: 0px;
}
</style>

<template>
  <div>
    <section class="ResetPasswordBackgroundColor hero is-login is-fullheight">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-4">
              <div class="container box">
                <!-- email form -->
                <form @submit.prevent="passwordChangeButtonClicked()">
                  <span
                    class="has-text-centered title is-3"
                    style="color: black"
                    >Choose a new Password</span
                  >

                  <br />
                  <br />
                  <span class="has-text-centered is-5"
                    >Email: {{ form.email }}</span
                  >

                  <br />
                  <br />

                  <formInput
                    :model="form"
                    modelKey="password"
                    type="password"
                  />
                  <formInput
                    :model="form"
                    title="confirm password"
                    modelKey="password_confirmation"
                    type="password"
                  />

                  <br />

                  <formCheckbox
                    :model="form"
                    modelKey="rememberme"
                    :labelHidden="true"
                    title="Stay
              logged in on this computer"
                  />

                  <br />

                  <span v-if="form.password.length">
                    <p
                      class="help is-primary"
                      :class="{ 'is-danger': form.password.length < 10 }"
                    >
                      Password must be atleast 10 characters long.
                    </p>
                    <p
                      class="help is-primary"
                      :class="{
                        'is-danger':
                          this.form.password !==
                          this.form.password_confirmation,
                      }"
                    >
                      Passwords must match.
                    </p>
                  </span>

                  <b-button
                    native-type="submit"
                    :loading="ajaxloading.changepassword"
                    type="is-primary is-rounded"
                    >Change password</b-button
                  >
                  <p class="help">
                    <router-link tag="a" :to="{ name: 'webshop-categories' }"
                      >Back to shop</router-link
                    >
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      form: {
        email: "",
        token: null,
        password: "",
        password_confirmation: null,
      },
    };
  },
  computed: {},
  methods: {
    passwordChangeButtonClicked() {
      this.$store
        .dispatch("auth/changePassword", this.form)
        .then((response) => {
          if (response.data === "wachtwoord aangepast") {
            this.$store.dispatch("createFlashBanner", {
              message: "Password changed succesfully.",
              timeout: 3,
            });
            this.$store.dispatch("auth/getLoggedInUser");
            this.$router.push({ name: "webshop-categories" });
          }

          if (response.data === "token is ongeldig") {
            this.$store.dispatch("createFlashBanner", {
              message:
                "Password reset link is invalid or expired. A new link will be send to your email.",
              timeout: 5,
            });
            this.$router.push({ name: "wachtwoord-resetten" });
          }
        });
    },
  },
  mounted() {
    if (this.$route.params.token) {
      this.form.token = this.$route.params.token;
      this.form.email = this.$route.params.email;
    }
  },
};
</script>

<style scoped></style>
