<style scoped>
.customInput {
  margin-bottom: 20px;
}
</style>

<template>
  <div>
    <div class="title">Website Instellingen</div>
    <span v-if="settings">
      <span v-for="variable in settings.admin.variables.length" :key="variable">
        <span
          v-if="
            settings.admin.variables[variable - 1].category === 'website_open'
          "
        >
          <br v-if="settings.admin.variables[variable - 1].type === 'switch'" />
          <b-switch
            @input="saveVariables('website_open')"
            class="customInput"
            v-if="settings.admin.variables[variable - 1].type === 'switch'"
            v-model="form[settings.admin.variables[variable - 1].key]"
          >
            {{ settings.admin.variables[variable - 1].name }}
          </b-switch>
        </span>
      </span>

      <br />

      <div class="title">Producten</div>

      <div class="field is-grouped">
        <p class="control">
          <button
            @click="makePreviewProductsAvailable()"
            class="button is-primary"
            :class="{ 'is-loading': ajaxloading.makepreviewavailable }"
          >
            Make preview products available
          </button>
        </p>
        <p class="control">
          <button
            :class="{ 'is-loading': ajaxloading.removesoldproducts }"
            class="button is-danger"
            @click="removeSoldProducts()"
          >
            Remove sold products from website
          </button>
        </p>
        <p class="control">
          <button
            :class="{ 'is-loading': ajaxloading.deletingconceptproducts }"
            class="button is-warning"
            @click="deleteConceptProducts()"
          >
            Remove all concept products
          </button>
        </p>
      </div>

      <div class="title">Mails</div>

      <div class="field is-grouped">
        <p class="control">
          <button
            @click="showEmail('orderConfirmedShipping')"
            class="button is-primary"
          >
            Show OrderConfirmedShippingMail
          </button>
        </p>
        <p class="control">
          <button
            class="button is-primary"
            @click="showEmail('orderConfirmedPickup')"
          >
            Show OrderConfirmedPickupMail
          </button>
        </p>
        <p class="control">
          <button
            class="button is-primary"
            @click="showEmail('defaultInvoiceEmail')"
          >
            Show DefaultInvoiceEmail
          </button>
        </p>
        <p class="control">
          <button class="button is-primary" @click="showEmail('orderCanceled')">
            Show OrderCanceledMail
          </button>
        </p>
      </div>
    </span>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  components: {},
  props: [],
  data() {
    return {
      testInput: "",
      result: "",
      form: {
        website_open: false,
        new_order_notifications: false,
      },
    };
  },
  computed: {
    settings() {
      return this.$store.getters["shop/settings"];
    },
  },
  methods: {
    makePreviewProductsAvailable() {
      this.$buefy.dialog.confirm({
        title: "Change products",
        message:
          "Are you sure you want to make preview products available in the store?",
        confirmText: "Confirm",
        type: "is-warning",
        onConfirm: () =>
          this.$store
            .dispatch("adminCommands/makePreviewProductsAvailable")
            .then((response) => {
              this.$buefy.toast.open({
                message: response.data + " Products updated",
                type: "is-success",
              });
            }),
      });
    },
    deleteConceptProducts() {
      this.$buefy.dialog.confirm({
        title: "Deleting sold products",
        message:
          "Are you sure you want to <b>delete</b> all concept products from the website? This action cannot be undone.",
        confirmText: "Delete Concept Products",
        type: "is-warning",
        hasIcon: true,
        onConfirm: () =>
          this.$store
            .dispatch("adminCommands/deleteConceptProducts")
            .then((response) => {
              this.$buefy.toast.open({
                message: "Removed concept products",
                type: "is-success",
              });
            }),
      });
    },
    removeSoldProducts() {
      this.$buefy.dialog.confirm({
        title: "Deleting sold products",
        message:
          "Are you sure you want to <b>delete</b> sold products from the website? This action cannot be undone.",
        confirmText: "Delete Products",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          this.$store
            .dispatch("adminCommands/removeSoldProducts")
            .then((response) => {
              this.$buefy.toast.open({
                message: "Removed sold products",
                type: "is-success",
              });
            }),
      });
    },
    showEmail(email) {
      location.assign(
        process.env.VUE_APP_API_URL + "/web/show-user-email-example/" + email
      );
    },
    saveVariables(category) {
      const closedMessage = this.form.website_closed_message;
      if (closedMessage && closedMessage.length && closedMessage.length < 8) {
        this.form.website_closed_message = "";
      }

      if (category) {
        this.form.category = category;
      }

      this.$store
        .dispatch("shop/saveVariables", this.form)
        .then(() => {
          this.form.category = null;
          this.$buefy.toast.open({
            message: "Variabelen opgeslagen",
            type: "is-success",
          });
        })
        .catch((error) => {
          this.form.category = null;
          this.form.website_open = false;
          if (error.response.status === 422) {
            this.$store.dispatch("shop/getSettings").then(() => {
              this.setVariables();
            });
            this.$store.dispatch("createFlashBanner", {
              type: "is-danger",
              message: error.response.data,
              timeout: 10,
            });
          }
        });
    },
    setVariables() {
      const array = this.settings.variables;

      array.forEach((a) => {
        if (a.type === "boolean") {
          if (a.value == 1) {
            Vue.set(this.form, a.key, true);
          }

          if (a.value == 0) {
            Vue.set(this.form, a.key, false);
          }
        } else {
          Vue.set(this.form, a.key, a.value);
        }
      });
    },
  },
  mounted() {
    if (this.settings) {
      this.setVariables();
    } else {
      this.$store.dispatch("shop/getSettings").then(() => {
        this.setVariables();
      });
    }
  },
};
</script>
