<style></style>

<template>
  <div>
    <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <router-link tag="a" class="navbar-item" :to="{ name: 'home' }">
          <img
            src="@/assets/pics/bannerKnusKeramiek.png"
            width="112"
            height="28"
          />
        </router-link>

        <a
          @click="toggleMobile()"
          role="button"
          class="navbar-burger"
          aria-label="menu"
          aria-expanded="true"
          data-target="navbarBasicExample"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div :class="['navbar-menu', { 'is-active': showMobile }]">
        <div class="navbar-start">
          <singleLink
            :link="{
              naam: `Home`,
              routerLink: 'home',
              exact: true,
            }"
            v-on:closedMobileMenu="closeMobile()"
          ></singleLink>

          <singleLink
            :link="{
              naam: `Shop`,
              routerLink: 'webshop-categories',
            }"
            v-on:closedMobileMenu="closeMobile()"
          ></singleLink>

          <singleLink
            :link="{
              naam: `About`,
              routerLink: 'about',
            }"
            v-on:closedMobileMenu="closeMobile()"
          ></singleLink>

          <singleLink
            :link="{
              naam: `FAQ`,
              routerLink: 'faq',
            }"
            v-on:closedMobileMenu="closeMobile()"
          ></singleLink>

          <singleLink
            :link="{
              naam: `Contact`,
              routerLink: 'contact',
            }"
            v-on:closedMobileMenu="closeMobile()"
          ></singleLink>

          <singleLink
            v-if="user.role === 'admin'"
            :link="{
              naam: `Admin`,
              routerLink: 'admin',
            }"
            v-on:closedMobileMenu="closeMobile()"
          ></singleLink>
        </div>

        <div class="navbar-end">
          <dropDownLink
            v-if="user.id"
            type="button"
            :showMobileParent="showMobile"
            v-on:closedMobileMenu="closeMobile()"
            v-on:logout="logout()"
            :link="{
              naam: user.name,
              routerLink: 'instellingen',
              exact: true,
              children: [
                {
                  naam: 'Account',
                  routerLink: 'account',
                  exact: true,
                },
                {
                  naam: 'Change password',
                  routerLink: 'account-change-password',
                },
                {
                  naam: 'Orders',
                  routerLink: 'account-orders',
                },
                {
                  divider: true,
                },
                {
                  naam: 'Logout',
                  emit: 'logout',
                },
              ],
            }"
          ></dropDownLink>

          <div class="navbar-item" v-if="!user.id && userToolsVisibleCheck()">
            <div class="buttons">
              <b-button
                @click="openRegisterModal()"
                type="is-rounded"
                icon-left="user"
                size="is-small"
                >Register</b-button
              >
            </div>
          </div>
          <div class="navbar-item" v-if="!user.id && userToolsVisibleCheck()">
            <div class="buttons">
              <b-button
                @click="openLoginModal()"
                type="is-rounded"
                icon-left="user"
                size="is-small"
                >Log in</b-button
              >
            </div>
          </div>
          <div
            class="navbar-item"
            v-if="$route.name !== 'webshop-checkout' && cartVisibleCheck()"
          >
            <div class="buttons">
              <b-button
                @click="openCart()"
                type="is-rounded"
                icon-left="shopping-cart"
                size="is-small"
                >{{ countCartItems() }}</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </nav>

    <loginModal />
    <registerModal />
    <passwordResetModal />
    <shoppingcartModal />
  </div>
</template>

<script>
import shoppingcartModal from "@/views/shoppingcart/shoppingcartModal.vue";
import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";
import passwordResetModal from "@/views/auth/ResetPassword.vue";
import singleLink from "@/components/router/SingleLink.vue";
import dropDownLink from "@/components/router/DropDownLink.vue";
export default {
  components: {
    shoppingcartModal: shoppingcartModal,
    loginModal: Login,
    registerModal: Register,
    passwordResetModal: passwordResetModal,
    singleLink,
    dropDownLink,
  },
  props: [],
  data() {
    return {
      showMobile: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
    cart() {
      return this.$store.getters["cart/cart"];
    },
  },
  methods: {
    openCart() {
      if (this.$router.currentRoute.name !== "shoppingcart") {
        this.$router.push({ name: "shoppingcart" });
      }
    },
    openCartModal() {
      this.showMobile = false;
      this.$store.dispatch("cart/toggleCartModal", true);
    },
    closeMobile() {
      this.showMobile = false;
    },
    toggleMobile() {
      if (this.showMobile) {
        this.showMobile = false;
      } else {
        this.showMobile = true;
      }
    },
    openLoginModal() {
      this.showMobile = false;
      this.$store.dispatch("auth/toggleLoginModal", true);
    },
    openRegisterModal() {
      this.showMobile = false;
      this.$store.dispatch("auth/toggleRegisterModal", true);
    },
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$store.dispatch("auth/getLoggedInUser");
        if (this.$router.currentRoute.name !== "webshop-categories") {
          this.$router.push({ name: "webshop-categories" });
        }
        this.$store.dispatch("createFlashBanner", {
          message: "Logged out succesfully.",
          timeout: 3,
        });
      });
    },
    cartVisibleCheck() {
      var visible = false;
      for (let a of this.$route.matched) {
        if (a.path === "/shop") {
          visible = true;
          break;
        }
      }

      return visible;
    },
    userToolsVisibleCheck() {
      var visible = false;
      for (let a of this.$route.matched) {
        if (
          a.path === "/shop" ||
          a.path === "/admin" ||
          a.path === "/account"
        ) {
          visible = true;
          break;
        }
      }

      return visible;
    },
    countCartItems() {
      var items = 0;

      this.cart.forEach((item) => {
        items = items + item.amount;
      });

      return items;
    },
  },
  mounted() {},
};
</script>
