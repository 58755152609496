export const setNewCountry = function (state, payload) {
  state.countries.push(payload);
};

export const setCountries = function (state, payload) {
  state.countries = payload;
};

export const setShippingMethods = function (state, payload) {
  state.shippingMethods = payload;
};

export const setNewShippingMethod = function (state, payload) {
  state.shippingMethods.push(payload);
};

export const setUpdatedShippingMethod = function (state, payload) {
  const array = state.shippingMethods;
  const id = payload.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1, payload);
};

export const setPaymentMethods = function (state, payload) {
  state.paymentMethods = payload;
};

export const setNewPaymentMethod = function (state, payload) {
  state.paymentMethods.push(payload);
};

export const setUpdatedPaymentMethod = function (state, payload) {
  const array = state.paymentMethods;
  const id = payload.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1, payload);
};

export const setCountry = function (state, payload) {
  state.country = payload;
};

export const removeCountry = function (state, payload) {
  state.country = {};
  const array = state.countries;
  const id = payload.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1);
};

export const cancelCountryUpdate = function (state, payload) {
  state.country = payload;
  const array = state.countries;
  const id = payload.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1, payload);
};

export const setShippingMethod = function (state, payload) {
  state.shippingMethod = payload;
};

export const setPaymentMethod = function (state, payload) {
  state.paymentMethod = payload;
};

export const deleteShippingMethod = function (state, payload) {
  const array = state.shippingMethods;
  const id = payload;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1);
};

export const deletePaymentMethod = function (state, payload) {
  const array = state.paymentMethods;
  const id = payload;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1);
};
