<template>
  <div>
    <body>
      <flashBanner />
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </body>
  </div>
</template>

<script>
import flashBanner from "@/components/headers/flashBanner.vue";

export default {
  components: {
    flashBanner,
  },
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {
    this.$store.dispatch("auth/logInFromRememberMeCookie").then((response) => {
      this.$store.dispatch("shop/getSettings");
    });
  },
};
</script>
