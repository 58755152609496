import axios from "axios";

export const toggleCartModal = function ({ commit }, payload) {
  commit("toggleCartModal", payload);
};

export const AddProductToCart = function ({ dispatch, commit }, payload) {
  commit("AddProductToCart", payload);
};

export const RemoveProductFromCart = function ({ dispatch, commit }, payload) {
  commit("RemoveProductFromCart", payload);
};

export const getCartFromLocalStorage = function (
  { dispatch, commit, rootGetters },
  payload
) {
  const categories = rootGetters["shop/categories"];
  commit("getCartFromLocalStorage", categories);
};

export const syncCartToProducts = function ({ commit }, payload) {
  commit("syncCartToProducts", payload);
};

export const syncCartToLocalstore = function ({ dispatch, commit }, payload) {
  commit("syncCartToLocalstoreMutation");
};

export const getUnpaidOrder = function ({ commit, dispatch }, payload) {
  const data = {};
  data.url = "fill-order-from-cookie";
  data.method = "get";
  data.data = payload;

  dispatch("loadingOn", data.loading, { root: true });
  return axios({
    url: process.env.VUE_APP_API_URL + "/web/" + data.url,
    method: data.method,
    data: data.data,
    withCredentials: true,
    headers: {
      "Cache-Control": "no-cache, no-store, must-revalidate",
      Pragma: "no-cache",
      Expires: "0",
    },
  })
    .then(function (response) {
      commit("setOldOrder", response.data);
      if (response.data.id) {
        commit("clearCart");
      }
      dispatch("loadingOff", data.loading, { root: true });
      return Promise.resolve(response);
    })
    .catch(function (error) {
      dispatch("loadingOff", data.loading, { root: true });
      return Promise.reject(error);
    });
};

export const closeOldOrderModal = function ({ dispatch, commit }, payload) {
  commit("setOldOrder", null);
};

export const cancelOrder = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "cancel-order";
  data.method = "POST";
  data.loading = "finishing_old_order";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      dispatch("closeOldOrderModal");
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const clearCart = function ({ dispatch, commit }, payload) {
  commit("clearCart");
};

export const clearLocalStorage = function ({ dispatch, commit }, payload) {
  commit("clearLocalStorage");
};

export const checkoutFromCookie = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "checkout-from-cookie/" + payload.id;
  data.method = "POST";
  data.loading = "finishing_old_order";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      dispatch("closeOldOrderModal");
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const updateCartAfterWebsocketUpdate = function (
  { state, commit, rootGetters, dispatch },
  payload
) {
  const categories = rootGetters["shop/categories"];
  const flashbanner = syncCartAfterWebsocketUpdate(categories, state.cart);

  if (flashbanner) {
    const categories = rootGetters["shop/categories"];

    commit("syncCartToProducts", categories);

    const message = {
      message:
        "Some products in your cart are no longer available and will be removed from your cart.",
      type: "is-danger",
      html: true,
      timeout: 5,
    };
    dispatch("createFlashBanner", message, { root: true });
  }
};

function syncCartAfterWebsocketUpdate(categories, cart) {
  var cartAltered = false;

  cart.forEach((cart_product) => {
    const categoryId = cart_product.product.category_id;
    const category = categories.find((cat) => cat.id === categoryId);

    if (cart_product.product.type === "unique") {
      let product = category.products.find(
        (a) => a.id === cart_product.product.id
      );

      if (!product || product.status === "sold") {
        cartAltered = true;
      }
    }

    if (cart_product.product.type === "multiple") {
      let product = category.products.find(
        (a) => a.id === cart_product.product.id
      );

      if (
        !product ||
        product.status === "sold" ||
        product.amount < cart_product.amount
      ) {
        cartAltered = true;
      }
    }

    if (cart_product.product.type === "options") {
      let product = category.products.find(
        (a) => a.id === cart_product.product.id
      );
      let option = product.options.find(
        (option) => option.id === cart_product.option.id
      );

      if (
        !product ||
        product.status === "sold" ||
        option.amount < cart_product.amount
      ) {
        cartAltered = true;
      }
    }
  });

  return cartAltered;
}
