<style scoped>
.column {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.columns {
  margin-bottom: 0px;
  margin-top: 0px;
}

.customInput {
  margin-bottom: 20px;
}

.help {
  margin: 0px;
  padding: 0px;
}
</style>

<template>
  <div>
    <div class="title is-4">Change password for {{ user.name }}</div>

    <formInput
      class="customInput"
      :model="form"
      modelKey="password"
      title="New password"
      label="on-border"
      size="is-small"
      type="password"
    />
    <formInput
      title="Confirm password"
      class="customInput"
      :model="form"
      modelKey="password_confirmation"
      label="on-border"
      size="is-small"
      type="password"
    />

    <span v-if="form.password.length">
      <p
        class="help is-primary"
        :class="{ 'is-danger': form.password.length < 10 }"
      >
        Password must be atleast 10 characters long.
      </p>
      <p
        class="help is-primary"
        :class="{
          'is-danger': this.form.password !== this.form.password_confirmation,
        }"
      >
        Passwords must match.
      </p>
    </span>
    <br />

    <button
      @click="changePassword()"
      class="button is-primary is-rounded is-small"
      :class="{ 'is-loading': ajaxloading.changeuserpassword }"
    >
      Change Password</button
    >&nbsp;
    <button class="button is-rounded is-small" @click="$router.go(-1)">
      Cancel
    </button>
  </div>
</template>

<script>
export default {
  props: [],
  data() {
    return {
      form: {
        password: "",
        password_confirm: null,
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
  },
  methods: {
    changePassword() {
      return this.$store
        .dispatch("auth/changeUserPassword", this.form)
        .then(() => {
          this.form = {};
          this.$buefy.toast.open({
            message: "Password changed succesfully",
            type: "is-success",
          });
        });
    },
  },
  mounted() {},
};
</script>
