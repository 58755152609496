import Vue from "vue";
import axios from "axios";

const findAddress = Vue.mixin({
  methods: {
    async findAddressMixin(code, number) {
      const postcode = code.replace(/\s/g, "");
      if (postcode.length > 5 && number) {
        const data = {
          postcode: postcode,
          number: number,
        };
        return this.$store.dispatch("getAddress", data);
      }
    },
    findGooglePlaceIdMixin(address, country) {
      return this.$store
        .dispatch("axiosWeb", {
          url: "get-address-id",
          method: "post",
          data: {
            address: address,
            country: country,
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch(() => {
          return Promise.reject();
        });
    },
    findAddressDetailsMixin(address) {
      if (address) {
        return this.$store
          .dispatch("axiosWeb", {
            url: "find-address-details",
            method: "post",
            data: address,
          })
          .then((response) => {
            const returnAddress = {};
            response.data.result.address_components.forEach((component) => {
              this.findHousenumber(
                component,
                returnAddress,
                address.structured_formatting.main_text
              );
              this.findStreet(component, returnAddress);
              this.findCity(component, returnAddress);
              this.findState(component, returnAddress);
              this.findCountry(component, returnAddress);
              this.findPostalCode(component, returnAddress);
              this.findMunicipality(component, returnAddress);
            });

            return returnAddress;
          })
          .catch(() => {
            return Promise.reject();
          });
      }
    },
    findPostalCode(component, address) {
      if (component.types.includes("postal_code")) {
        address.postal_code = component.long_name;
      }
    },
    findState(component, address) {
      if (component.types.includes("administrative_area_level_1")) {
        address.state = component.long_name;
      }
    },
    findCountry(component, address) {
      if (component.types.includes("country")) {
        address.country = component.long_name;
        address.country_short = component.short_name;
      }
    },
    findMunicipality(component, address) {
      if (component.types.includes("administrative_area_level_2")) {
        address.municipality = component.long_name;
      }
    },
    findCity(component, address) {
      if (component.types.includes("locality")) {
        address.city = component.long_name;
      }
    },
    findStreet(component, address) {
      if (component.types.includes("route")) {
        address.street = component.long_name;
      }
    },
    findHousenumber(component, address, street_and_housenumber) {
      if (component.types.includes("street_number")) {
        if (street_and_housenumber) {
          var house_number_index = street_and_housenumber.indexOf(
            component.long_name
          );
        }

        if (house_number_index) {
          var house_number =
            street_and_housenumber.substring(house_number_index);
        }

        if (house_number) {
          address.house_number = house_number;
        } else {
          address.house_number = component.long_name;
        }
      }
    },

    async findGoogleAddresFromPlaceId() {
      //       https://maps.googleapis.com/maps/api/place/details/json?
      // ?fields=address_components&place_id=EiJFaWtlbnNpbmdlbCwgSGF1bGVyd2lqaywgTmVkZXJsYW5kIi4qLAoUChIJU3MJqu83yEcRpyfK7SSEfaMSFAoSCX-vNa7pN8hHEbrr760NvRdc&key=AIzaSyBdys1BB9pfe6sJXv4LztOEQ1UDMzxzV8A
    },
  },
});

export default findAddress;
