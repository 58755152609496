<style scoped>
img {
  max-width: 75%;
  height: auto;
}
</style>

<template>
  <div>
    <navBar />
    <div class="container is-fluid" v-if="settings && settings.variables">
      <span
        class="content"
        v-html="settings.variables.find((a) => a.key === 'faq').value"
      ></span>
    </div>
  </div>
</template>

<script>
import NavBar from "@/views/Navbar.vue";
export default {
  components: {
    navBar: NavBar,
  },
  data() {
    return {};
  },
  computed: {
    settings() {
      return this.$store.getters["shop/settings"];
    },
  },
  methods: {},
  mounted() {},
};
</script>
