<style scoped>
.columns {
  margin-bottom: 0px;
}

.box {
  border-style: solid;

  margin-bottom: 4px;
}
</style>

<template>
  <div>
    <div class="box pointer" :class="{ 'default-address': address.default }">
      <strong v-if="!address.different_ship_address"
        >Shipping and Billing address
      </strong>

      <strong v-else>Billing address</strong>
      <br />
      {{ address.business_name }} <br v-if="address.business_name" />
      {{ address.name }} {{ address.surname }}<br />
      {{ address.street }} {{ address.house_number }} <br />
      {{ address.postal_code }} {{ address.city }} <br />
      {{ address.country }}

      <span v-if="address.different_ship_address">
        <br />
        <br />
        <strong>Shipping address</strong> <br />
        {{ address.business_name }}
        <br v-if="address.business_name" />
        {{ address.send_name }} {{ address.send_surname }}<br />
        {{ address.send_street }} {{ address.send_house_number }}
        <br />
        {{ address.send_postal_code }} {{ address.send_city }} <br />
        {{ address.send_country }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    address: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      business: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
  },
  methods: {},
  mounted() {},
};
</script>
