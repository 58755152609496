export const setCategories = function (state, payload) {
  state.categories = payload;
};

export const setCategory = function (state, payload) {
  state.categories.push(payload);
};

export const cancelUpdateCategory = function (state, payload) {
  const array = state.categories;
  const id = payload.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1, payload);
};

export const deleteCategory = function (state, payload) {
  const array = state.categories;
  const id = payload.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1);
};

export const setNewTag = function (state, payload) {
  payload.category.tags.push(payload.response.data);
};

export const cancelTagUpdate = function (state, payload) {
  const array = payload.category.tags;
  const id = payload.tag.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1, payload.tag);
};

export const deleteTag = function (state, payload) {
  const array = payload.category.tags;
  const id = payload.tag.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1);
};
