<style scoped>
.subtotal {
  text-align: right !important;
}

.modal {
  width: 100%;
  padding: 0 !important;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-card {
  margin: 0px !important;
  width: 95%;
}
</style>

<template>
  <div v-if="showModal">
    <div class="modal is-active is-fullscreen">
      <div class="modal-background" @click="closeModal()"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Shopping Cart</p>
          <button
            @click="closeModal()"
            class="delete"
            aria-label="close"
          ></button>
        </header>
        <section class="modal-card-body">
          <table class="table is-hoverable is-fullwidth">
            <tbody>
              <tr v-for="index of cart.length" :key="index">
                <td width="60">
                  <b-image
                    ratio="1by1"
                    src="@/assets/pics/potterdog.jpg"
                    alt=""
                  />
                </td>
                <td>
                  {{ euroFromCents(cart[index - 1].product.name) }}
                </td>
                <td width="60">
                  {{ euroFromCents(cart[index - 1].product.price) }}
                </td>
                <td width="30">
                  <b-icon
                    @click.native="removeFromCart(cart[index - 1])"
                    icon="trash"
                    class="pointer"
                    size="is-small"
                    type="is-success"
                  >
                  </b-icon>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table is-fullwidth">
            <tbody>
              <tr>
                <td class="has-text-left" v-if="countCartItems() === 1">
                  {{ countCartItems() }} item
                </td>
                <td class="has-text-left" v-else>
                  {{ countCartItems() }} items
                </td>
                <td class="has-text-right">
                  Subtotal:&nbsp;{{ euroFromCents(cartTotal()) }}
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <footer class="modal-card-foot">
          <a
            href="#"
            class="card-footer-item button is-rounded"
            size="is-small"
            @click.prevent="closeModal()"
            >Continue shopping</a
          >
          <a
            href="#"
            class="card-footer-item button is-rounded is-primary"
            @click.prevent="WebshopCheckout()"
            >Checkout</a
          >
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {
    showModal() {
      return this.$store.getters["cart/cartModal"];
    },
    cart() {
      return this.$store.getters["cart/cart"];
    },
  },
  methods: {
    WebshopCheckout() {
      this.closeModal();
      this.$router.push({ name: "webshop-checkout" });
    },
    closeModal() {
      this.$store.dispatch("cart/toggleCartModal", false);
    },
    removeFromCart(item) {
      this.$buefy.toast.open({
        message: "Product removed from cart",
        type: "is-danger",
      });
      this.$store.dispatch("cart/RemoveProductFromCart", item);
    },
    countCartItems() {
      var items = 0;

      this.cart.forEach((item) => {
        items = items + item.amount;
      });

      return items;
    },
    cartTotal() {
      var items = 0;

      this.cart.forEach((item) => {
        if (item.product.type === "options") {
          items =
            items + item.amount * (item.option.price + item.product.price);
        } else {
          items = items + item.amount * item.product.price;
        }
      });

      return items;
    },
  },
  mounted() {},
};
</script>
