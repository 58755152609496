<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}

.table-icons {
  float: right;
}

.box {
  border-color: #333 !important;
  border: solid;
  border-width: thin;
  /* Set the hr color */
}

img {
  max-height: 150px;
}

hr {
  border: none;
  height: 1px;
  /* Set the hr color */
  color: #333; /* old IE */
  background-color: #333; /* Modern Browsers */
}
</style>

<template>
  <div>
    <div class="users-title">Orders</div>
    <br />
    <br />
    <br />
    <div>
      <b-loading v-model="ajaxloading.gettingusererrors" />

      <div class="columns title is-5 is-mobile">
        <div class="column">
          {{ totalItemsBought() }}
          <span v-if="totalItemsBought() === 1">item bought</span
          ><span v-else> total items bought</span>
        </div>
        <div class="column">
          <span class="is-pulled-right">
            {{ euroFromCents(totalAmountSpend()) }}
          </span>
        </div>
      </div>
      <!-- orders -->
      <div class="box" v-for="order of orders" :key="order.id">
        <div class="columns">
          <div class="column">
            <strong>{{ formatDatabaseDate(order.created_at) }}</strong> <br />
            Payment Method: {{ order.payment_method }} <br />
            Payment Status:
            {{ hoofdletter(order.payment_status) }} <br />
            <br />
            <strong>
              {{ order.product_count }}
              <span v-if="order.product_count === 1">Item</span
              ><span v-else>Items</span>
            </strong>
          </div>
          <div class="column">
            <strong>Order: {{ formatOrderNumber(order) }}</strong> <br />
            Shipping Method: {{ order.shipping_method }} <br />
            Shipping Status:
            {{ hoofdletter(order.shipping_status) }} <br />
            <br />
            <strong>Total: {{ euroFromCents(order.total_price) }}</strong>
          </div>
        </div>
        <hr />
        <div class="columns">
          <div class="column">
            <div class="columns is-mobile">
              <div class="column is-3" v-for="product of 4" :key="product">
                <b-image
                  ratio="1by1"
                  v-if="order.order_products[product - 1]"
                  :src="
                    env.VUE_APP_API_URL +
                    '/web/order-picture/' +
                    order.order_products[product - 1].picture_uuid
                  "
                  alt="Product Image"
                />
              </div>
            </div>
          </div>
          <div class="column">
            <button
              class="button is-fullwidth is-primary"
              @click="orderClicked(order)"
            >
              Show Order
            </button>

            <br />

            <span v-if="order.shipping_labels && order.shipping_labels.length">
              <button
                class="button is-primary is-fullwidth"
                @click="
                  showTrackingInformation(
                    order.shipping_labels[order.shipping_labels.length - 1]
                  )
                "
              >
                Show tracking information
              </button>
            </span>

            <span v-for="invoice in order.invoices" :key="invoice.id">
              <br />

              <button
                @click="openPdf(invoice)"
                @click.middle="openPdf(invoice)"
                class="button is-fullwidth is-primary"
              >
                Show invoice {{ invoice.invoice_year }}-{{
                  invoice.invoice_number
                }}
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      env: process.env,
      selected: {},
      isLoading: true,
    };
  },
  computed: {
    orders() {
      const orders = this.$store.getters["auth/orders"];

      orders.sort((a, b) => {
        if (a.id > b.id) {
          return -1;
        } else {
          return 1;
        }
      });
      return orders;
    },
  },
  methods: {
    openPdf(invoice) {
      window.open(
        this.env.VUE_APP_API_URL + "/web/show-user-invoice/" + invoice.id
      );
    },
    formatOrderNumber(order) {
      return order.order_year + "-" + order.order_number;
    },
    totalItemsBought() {
      var items = 0;
      this.orders.forEach((a) => {
        a.order_products.forEach((a) => {
          items = items + a.amount;
        });
      });

      return items;
    },
    orderClicked(order) {
      this.$router.push({ name: "account-order", params: { order: order.id } });
    },
    showTrackingInformation(label) {
      window.open(label.track_and_trace_url);
    },
    totalAmountSpend() {
      var amount = 0;
      this.orders.forEach((a) => {
        amount = amount + a.total_price;
      });

      return amount;
    },
  },
  watch: {},
  mounted() {},
};
</script>
