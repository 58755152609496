export const setSettings = function (state, payload) {
  state.settings = payload;
};

export const setProducts = function (state, payload) {
  state.products = payload;
};

export const setCategories = function (state, payload) {
  state.categories = payload;
};

export const setCountries = function (state, payload) {
  state.countries = payload;
};

export const setVariables = function (state, payload) {
  state.settings.variables = payload;
};

export const setClosedModal = function (state, payload) {
  state.closedModalActive = payload;
};

export const setOrder = function (state, payload) {
  state.order = payload;
};
