<template>
  <div>
    <navBar />
    <div class="box has-text-centered">
      Thank you for your message. <br />
      We will be contacting you about your inquiry shortly.
      <br />
      <br />
      This page will be redirected to the homepage in {{ timeTillReturn }}
      {{ calculateSeconden }}.
      <br />
      <br />
      <div class="button is-rounded is-primary" @click="returnToHomePagina()">
        Return Home
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/views/Navbar.vue";
export default {
  components: { NavBar },
  props: [],
  data() {
    return {
      interval: "",
      timeTillReturn: 10,
    };
  },
  computed: {
    calculateSeconden() {
      if (this.timeTillReturn === 1) {
        return "second";
      } else {
        return "seconds";
      }
    },
  },
  methods: {
    returnToHomePagina() {
      clearInterval(this.interval);
      this.$router.replace({ name: "home" });
    },
    returnToHome() {
      clearInterval(this.interval);
      if (this.$router.currentRoute.name === "contact-form-send") {
        this.$router.replace({ name: "home" });
      }
    },
  },
  mounted() {
    setTimeout(
      function () {
        this.returnToHome();
      }.bind(this),
      10000
    );
    this.interval = setInterval(
      function () {
        this.timeTillReturn--;
      }.bind(this),
      1000
    );
  },
};
</script>
