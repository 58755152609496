<template>
  <div>
    <span class="title is-3">Landen</span>

    <b-tooltip label="Add country" class="is-pulled-right" position="is-top">
      <button
        @click.prevent="$router.push({ name: 'admin-prices-add-country' })"
        class="button is-rounded"
      >
        Add country
      </button>
    </b-tooltip>

    <br />

    <div class="content" v-if="countries.length">
      <ul>
        <li v-for="country in countries" :key="country.id">
          <a
            @click="
              $router.push({
                name: 'admin-prices-country',
                params: { country: country.id },
              })
            "
          >
            {{ country.name }}</a
          >
        </li>
      </ul>
    </div>
    <div v-else class=""><br />No countries added.</div>

    <hr />

    <!-- Shipping Methods -->
    <span class="title is-3">Shipping Methods</span>

    <b-tooltip
      v-if="selectedShippingMethod.id"
      class="is-pulled-right"
      label="delete shipping method"
      position="is-left"
    >
      <b-icon
        @click.native="deleteShippingMethod()"
        class="pointer"
        icon="trash"
      >
      </b-icon>
    </b-tooltip>

    <b-tooltip
      v-if="selectedShippingMethod.id"
      class="is-pulled-right"
      label="update shipping method"
      position="is-left"
    >
      <b-icon
        @click.native="updateShippingMethod()"
        class="pointer"
        icon="edit"
      >
      </b-icon>
    </b-tooltip>

    <b-tooltip
      class="is-pulled-right"
      label="Add shipping method"
      position="is-left"
    >
      <b-icon
        @click.native="
          $router.push({
            name: 'admin-prices-add-shipping_method',
          })
        "
        class="pointer"
        icon="plus"
      >
      </b-icon>
    </b-tooltip>

    <br />
    <br />

    <b-table
      :data="shippingMethods"
      :bordered="true"
      :striped="true"
      :hoverable="true"
      :loading="false"
      :focusable="false"
      :mobile-cards="false"
      :selected.sync="selectedShippingMethod"
      @dblclick="updateShippingMethod()"
    >
      <b-table-column
        sortable
        :label="hoofdletter('Interne naam')"
        v-slot="props"
      >
        {{ hoofdletter(props.row.internal_name) }}
      </b-table-column>

      <b-table-column
        sortable
        :label="hoofdletter('Display naam')"
        v-slot="props"
      >
        {{ hoofdletter(props.row.display_name) }}
      </b-table-column>

      <b-table-column
        field="icon"
        sortable
        :label="hoofdletter('icon')"
        v-slot="props"
      >
        <customIcon
          v-if="
            settings &&
            props.row.icon &&
            settings.admin.shipping_methods.find(
              (a) => a.name === props.row.icon
            ).icon_location === 'local'
          "
          :icon="
            settings.admin.shipping_methods.find(
              (a) => a.name === props.row.icon
            ).icon_name
          "
        />

        <b-icon
          v-if="
            settings &&
            props.row.icon &&
            settings.admin.shipping_methods.find(
              (a) => a.name === props.row.icon
            ).icon_location === 'font awesome'
          "
          :pack="
            settings.admin.shipping_methods.find(
              (a) => a.name === props.row.icon
            ).icon_pack
          "
          :icon="
            settings.admin.shipping_methods.find(
              (a) => a.name === props.row.icon
            ).icon_name
          "
        ></b-icon>
      </b-table-column>

      <b-table-column
        sortable
        :label="hoofdletter('min_weight')"
        v-slot="props"
      >
        {{ props.row.min_weight }} gr.
      </b-table-column>

      <b-table-column
        sortable
        :label="hoofdletter('max_weight')"
        v-slot="props"
      >
        {{ props.row.max_weight }} gr.
      </b-table-column>

      <b-table-column
        field="prijs"
        sortable
        :label="hoofdletter('price')"
        v-slot="props"
      >
        {{ euroFromCents(props.row.price) }}
      </b-table-column>

      <b-table-column
        field="datum_prijsverhoging"
        sortable
        :label="hoofdletter('datum_prijsverhoging')"
        v-slot="props"
      >
        {{ props.row.datum_prijsverhoging }}
      </b-table-column>

      <b-table-column
        field="nieuwe_prijs"
        sortable
        :label="hoofdletter('nieuwe_prijs')"
        v-slot="props"
      >
        {{ props.row.nieuwe_prijs }}
      </b-table-column>
    </b-table>

    <hr />

    <span class="title is-3">Payment Methods</span>

    <b-tooltip
      v-if="selectedPaymentMethod.id"
      class="is-pulled-right"
      label="Delete payment method"
      position="is-left"
    >
      <b-icon
        @click.native="deletePaymentMethod()"
        class="pointer"
        icon="trash"
      >
      </b-icon>
    </b-tooltip>

    <b-tooltip
      v-if="selectedPaymentMethod.id"
      class="is-pulled-right"
      label="Update payment method"
      position="is-left"
    >
      <b-icon @click.native="updatePaymentMethod()" class="pointer" icon="edit">
      </b-icon>
    </b-tooltip>

    <b-tooltip
      class="is-pulled-right"
      label="Add payment method"
      position="is-left"
    >
      <b-icon
        @click.native="
          $router.push({
            name: 'admin-prices-add-payment_method',
          })
        "
        class="pointer"
        icon="plus"
      >
      </b-icon>
    </b-tooltip>

    <br />
    <br />

    <b-table
      :data="paymentMethods"
      :bordered="true"
      :striped="true"
      :hoverable="true"
      :loading="false"
      :focusable="false"
      :mobile-cards="false"
      :selected.sync="selectedPaymentMethod"
      @dblclick="updatePaymentMethod()"
    >
      <b-table-column
        field="naam"
        sortable
        :label="hoofdletter('Interne naam')"
        v-slot="props"
      >
        {{ hoofdletter(props.row.internal_name) }}
      </b-table-column>

      <b-table-column
        field="naam"
        sortable
        :label="hoofdletter('Display naam')"
        v-slot="props"
      >
        {{ hoofdletter(props.row.display_name) }}
      </b-table-column>

      <b-table-column
        field="icon"
        sortable
        :label="hoofdletter('icon')"
        v-slot="props"
      >
        <customIcon
          v-if="
            settings &&
            props.row.icon &&
            settings.admin.payment_methods.find(
              (a) => a.name === props.row.icon
            ).icon_location === 'local'
          "
          :icon="
            settings.admin.payment_methods.find(
              (a) => a.name === props.row.icon
            ).icon_name
          "
        />

        <b-icon
          v-if="
            settings &&
            props.row.icon &&
            settings.admin.payment_methods.find(
              (a) => a.name === props.row.icon
            ).icon_location === 'font awesome'
          "
          :pack="
            settings.admin.payment_methods.find(
              (a) => a.name === props.row.icon
            ).icon_pack
          "
          :icon="
            settings.admin.payment_methods.find(
              (a) => a.name === props.row.icon
            ).icon_name
          "
        ></b-icon>
      </b-table-column>

      <b-table-column
        field="prijs"
        sortable
        :label="hoofdletter('price')"
        v-slot="props"
      >
        {{ euroFromCents(props.row.price) }}
      </b-table-column>

      <b-table-column sortable :label="hoofdletter('Opslag %')" v-slot="props">
        {{ props.row.opslag / 100 }}%
      </b-table-column>

      <b-table-column
        field="datum_prijsverhoging"
        sortable
        :label="hoofdletter('datum_prijsverhoging')"
        v-slot="props"
      >
        {{ props.row.datum_prijsverhoging }}
      </b-table-column>

      <b-table-column
        field="nieuwe_prijs"
        sortable
        :label="hoofdletter('nieuwe_prijs')"
        v-slot="props"
      >
        {{ props.row.new_price }}
      </b-table-column>

      <b-table-column
        field="nieuwe_opslag"
        sortable
        :label="hoofdletter('nieuwe_opslag')"
        v-slot="props"
      >
        {{ props.row.new_opslag }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
export default {
  props: [],
  data() {
    return {
      selectedShippingMethod: {},
      selectedPaymentMethod: {},
    };
  },
  computed: {
    settings() {
      return this.$store.getters["shop/settings"];
    },
    countries() {
      return this.$store.getters["adminPrices/countries"];
    },
    shippingMethods() {
      return this.$store.getters["adminPrices/shippingMethods"];
    },
    paymentMethods() {
      return this.$store.getters["adminPrices/paymentMethods"];
    },
  },
  methods: {
    updatePaymentMethod() {
      this.$store
        .dispatch("adminPrices/setPaymentMethod", this.selectedPaymentMethod)
        .then(() => {
          this.$router.push({
            name: "admin-prices-edit-payment_method",
          });
        });
    },
    updateShippingMethod() {
      this.$store
        .dispatch("adminPrices/setShippingMethod", this.selectedShippingMethod)
        .then(() => {
          this.$router.push({
            name: "admin-prices-edit-shipping_method",
          });
        });
    },
    deleteShippingMethod() {
      this.$buefy.dialog.confirm({
        title: "Deleting shipping method",
        message:
          "Are you sure you want to <b>delete</b> this shipping method? This action cannot be undone.",
        confirmText: "Delete shipping method",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          this.$store
            .dispatch(
              "adminPrices/deleteShippingMethod",
              this.selectedShippingMethod
            )
            .then(() => {
              this.selectedShippingMethod = {};
            }),
      });
    },
  },
  mounted() {
    this.$store.dispatch("adminPrices/getCountries");
    this.$store.dispatch("adminPrices/getShippingMethods");
    this.$store.dispatch("adminPrices/getPaymentMethods");
  },
};
</script>
