import Vue from "vue";
import { mapGetters } from "vuex";

const loading = Vue.mixin({
  computed: {
    ...mapGetters({
      ajaxloading: "loading",
    }),
  },
});

export default loading;
