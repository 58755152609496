<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Add payment method</p>
        <button
          class="delete"
          aria-label="close"
          @click="closeModal()"
        ></button>
      </header>
      <section class="modal-card-body">
        <formSelect
          :model="form"
          modelKey="payment_method"
          :selectOptions="paymentMethods"
          selectKey="internal_name"
        />
      </section>
      <footer class="modal-card-foot">
        <button
          :class="{ 'is-loading': ajaxloading.save_payment_method }"
          class="button is-success"
          @click="savePaymentMethod()"
        >
          Save Changes
        </button>

        <button @click="closeModal()" class="button">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    country: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {},
    };
  },
  computed: {
    settings() {
      return this.$store.getters["shop/settings"];
    },
    paymentMethods() {
      return this.$store.getters["adminPrices/paymentMethods"];
    },
  },
  methods: {
    closeModal() {
      this.$emit("close", true);
    },
    savePaymentMethod() {
      this.$store
        .dispatch("adminPrices/savePaymentMethodToCountry", {
          country: this.country,
          payment_method: this.form.payment_method.id,
        })
        .then(() => {
          this.$emit("close", false);
        });
    },
  },
  mounted() {
    this.$store.dispatch("adminPrices/getPaymentMethods");
  },
};
</script>
