<template>
  <div>
    <navBar></navBar>
    <div class="columns container is-fluid">
      <div class="column is-2">
        <adminMenu />
      </div>
      <div class="column is-10">
        <transition name="fade" mode="out-in">
          <router-view />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/views/Navbar.vue";
import adminMenu from "@/views/admin/AdminMenu.vue";

export default {
  components: {
    navBar: NavBar,
    adminMenu: adminMenu,
  },
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>
