<template>
  <div>
    <div class="title is-3">Shipping</div>
    <button
      class="button is-primary"
      @click="$router.push({ name: 'admin-create-shipping-label' })"
    >
      Create shipping label
    </button>
    <hr />
    <div class="title is-5">Shipping labels</div>
    <b-table
      :data="shipping_labels"
      :bordered="true"
      :striped="true"
      :narrowed="true"
      :hoverable="true"
      class="pointer"
    >
      <b-table-column label="Created by" v-slot="props">
        <span v-if="props.row.order_id">
          <a
            @click="
              $router.push({
                name: 'admin-order',
                params: { order: props.row.order_id },
              })
            "
            >Order: {{ props.row.order_id }}</a
          >
        </span>
        <span v-if="props.row.user_id">
          {{ props.row.user.name }} {{ props.row.user.surname }}
        </span>
      </b-table-column>

      <b-table-column label="Barcode" v-slot="props">
        {{ props.row.barcode }}
      </b-table-column>

      <b-table-column label="Receiver" v-slot="props">
        {{ props.row.name }} {{ props.row.surname }}
      </b-table-column>

      <b-table-column label="Internal ref" v-slot="props">
        {{ props.row.internal_reference }}
      </b-table-column>

      <b-table-column label="Address" v-slot="props">
        {{ props.row.street }} {{ props.row.house_number }} <br />
        {{ props.row.postal_code }} {{ props.row.city }}
      </b-table-column>

      <b-table-column label="Country" v-slot="props">
        {{ props.row.country }}
      </b-table-column>

      <b-table-column label="Status" v-slot="props">
        {{ props.row.shipping_status }}
      </b-table-column>

      <b-table-column label="Type" v-slot="props">
        {{ props.row.package_type }}&nbsp;({{ props.row.package_code }})
      </b-table-column>

      <b-table-column label="Created" v-slot="props">
        {{ formatDatabaseDate(props.row.created_at) }}
      </b-table-column>

      <b-table-column label="Buttons" v-slot="props">
        <span v-if="props.row.barcode">
          <button
            class="button is-small is-primary"
            @click="showTrackAndTrace(props.row)"
          >
            Track and Trace
          </button>
          &nbsp;
          <button
            class="button is-small is-primary"
            @click="downloadLabel(props.row)"
          >
            Download label
          </button>
          &nbsp;
        </span>
        <span v-else>
          <button
            class="button is-small is-primary"
            :class="{ 'is-loading': ajaxloading.register_shipping_label }"
            @click="registerLabel(props.row)"
          >
            Register label
          </button>
          &nbsp;
        </span>
        <button
          class="button is-small is-danger"
          @click="preDeleteLabel(props.row)"
        >
          Delete label
        </button>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      env: process.env,
    };
  },
  computed: {
    settings() {
      return this.$store.getters["shop/settings"];
    },
    shipping_labels() {
      return this.$store.getters["adminShipping/shipping_labels"];
    },
  },
  methods: {
    showTrackAndTrace(label) {
      window.open(label.track_and_trace_url);
    },
    downloadLabel(label) {
      const extension = this.settings.variables.find(
        (a) => a.key === "shipping_label_extension"
      ).value;

      if (extension === ".pdf") {
        window.open(
          this.env.VUE_APP_API_URL +
            "/web/admin-show-pdf-shipping-label/" +
            label.id
        );
      }

      if (extension === ".zpl") {
        window.open(
          this.env.VUE_APP_API_URL +
            "/web/admin-show-zpl-shipping-label/" +
            label.id
        );
      }
    },
    preDeleteLabel(label) {
      this.$buefy.dialog.confirm({
        title: "Delete label",
        message:
          "Are you sure you want to delete this label? <br> This action cannot be undone",
        confirmText: "Delete label",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteLabel(label),
      });
    },
    deleteLabel(label) {
      this.$store.dispatch("adminShipping/deleteShippingLabel", label);
    },
    registerLabel(label) {
      this.$store
        .dispatch("adminShipping/registerShippingLabel", label)
        .then((response) => {
          label = response.data;
        });
    },
  },
  mounted() {
    this.$store.dispatch("adminShipping/getShippingLabels");
  },
};
</script>
