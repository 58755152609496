export const toggleCartModal = function (state, payload) {
  state.cartModal = payload;
};

export const syncCartToLocalstoreMutation = function (state, payload) {
  syncCartToLocalstore(state.cart);
};

export const setOldOrder = function (state, payload) {
  state.oldOrder = payload;
};

export const clearCart = function (state, payload) {
  state.cart = [];
  syncCartToLocalstore(state.cart);
};

export const clearLocalStorage = function (state, payload) {
  syncCartToLocalstore([]);
};

export const AddProductToCart = function (state, payload) {
  var cart = null;

  if (payload.option.id) {
    cart = state.cart.find(
      (a) =>
        a.product.id === payload.product.id && a.option.id === payload.option.id
    );
  } else {
    cart = state.cart.find((a) => a.product.id === payload.product.id);
  }

  if (cart) {
    cart.amount = cart.amount + payload.amount;
  } else {
    state.cart.push(payload);
  }

  syncCartToLocalstore(state.cart);
};

export const RemoveProductFromCart = function (state, payload) {
  if (payload.product.type === "options") {
    const array = state.cart;
    const id = payload.product.id;
    const index = array.findIndex(
      (a) => a.product.id === id && a.option.id === payload.option.id
    );
    array.splice(index, 1);
  } else {
    const array = state.cart;
    const id = payload.product.id;
    const index = array.findIndex((a) => a.id === id);
    array.splice(index, 1);
  }

  syncCartToLocalstore(state.cart);
};

export const getCartFromLocalStorage = function (state, payload) {
  if (payload.length) {
    if (localStorage.getItem("cart") !== null && !state.cart.length) {
      const oldCart = JSON.parse(localStorage.getItem("cart"));
      updateCartWithProducts(state, payload, oldCart);
      syncCartToLocalstore(state.cart);
    }
  }
};

export const syncCartToProducts = function (state, payload) {
  if (payload.length) {
    if (localStorage.getItem("cart") !== null) {
      state.cart = [];
      const oldCart = JSON.parse(localStorage.getItem("cart"));
      updateCartWithProducts(state, payload, oldCart);
      syncCartToLocalstore(state.cart);
    }
  }
};

const updateCartWithProducts = function (state, payload, oldCart) {
  oldCart.forEach((a) => {
    const categoryId = a.product.category_id;
    const category = payload.find((cat) => cat.id === categoryId);
    if (category && category.products && category.products.length) {
      const product = category.products.find(
        (prod) => prod.id === a.product.id && prod.type === a.product.type
      );

      if (product && product.type === "options") {
        const option = product.options.find((opt) => opt.id === a.option.id);

        if (option && option.amount > 0) {
          var optionamount = a.amount;

          if (option.amount < a.amount) {
            optionamount = option.amount;
          }

          const cartItem = {
            amount: Number(optionamount),
            option: option,
            product: product,
          };
          if (cartItem.amount && cartItem.option && cartItem.product) {
            state.cart.push(cartItem);
          }
        }
      }

      if (
        product &&
        product.type === "unique" &&
        product.status === "available"
      ) {
        const cartItem = {
          amount: Number(a.amount),
          product: product,
        };
        if (cartItem.amount && cartItem.product) {
          state.cart.push(cartItem);
        }
      }

      if (product && product.type === "multiple" && product.amount > 0) {
        var multiamount = a.amount;

        if (product.amount < a.amount) {
          multiamount = product.amount;
        }

        const cartItem = {
          amount: Number(multiamount),
          product: product,
        };
        if (cartItem.amount && cartItem.product) {
          state.cart.push(cartItem);
        }
      }
    }
  });
};

const syncCartToLocalstore = function (cart) {
  localStorage.setItem("cart", JSON.stringify(cart));
};
