export const makePreviewProductsAvailable = function (
  { dispatch, commit },
  payload
) {
  const data = {};
  data.url = "make-preview-products-available";
  data.method = "get";
  data.loading = "makepreviewavailable";

  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      dispatch("shop/getCategories", "", { root: true });
      dispatch("adminCategories/getCategories", "", { root: true });
      dispatch("adminProducts/getProducts", "", { root: true });
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const removeSoldProducts = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "remove-sold-products";
  data.method = "get";
  data.loading = "removesoldproducts";

  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      dispatch("shop/getCategories", "", { root: true });
      dispatch("adminCategories/getCategories", "", { root: true });
      dispatch("adminProducts/getProducts", "", { root: true });
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const deleteConceptProducts = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "remove-concept-products";
  data.method = "get";
  data.loading = "deletingconceptproducts";

  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      dispatch("shop/getCategories", "", { root: true });
      dispatch("adminCategories/getCategories", "", { root: true });
      dispatch("adminProducts/getProducts", "", { root: true });
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
