<template>
  <div>
    <div class="title">Delete my account</div>
    We are sad to see you go! <br />
    After deleting your account you will not longer have access to your orders
    and your account will be deleted from our system.
    <br />
    <br />
    Your order details will be saved for up to 10 years in according to Dutch
    tax law.

    <br />
    If you wish to continue, press the button below.

    <br />
    <br />
    <button
      :class="{ 'is-loading': ajaxloading.deleteaccount }"
      class="button is-danger"
      @click="deleteAccount()"
    >
      Delete my account
    </button>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {
    deleteAccount() {
      this.$buefy.dialog.confirm({
        title: "Deleting account",
        message:
          "Are you sure you want to <b>delete</b> your account? This action cannot be undone.",
        confirmText: "Delete Account",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          this.$store.dispatch("auth/deleteAccount").then(() => {
            this.$router.replace({ name: "webshop-categories" });
            this.$buefy.toast.open({
              message: "Account deleted",
              type: "is-success",
            });
          }),
      });
    },
  },
  mounted() {},
};
</script>
