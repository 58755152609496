<template>
  <div>
    <webshopNavbar />
    <div class="container is-fluid">
      <div class="columns">
        <div class="column is-2 is-hidden-touch">
          <accountMenu />
        </div>
        <div class="column">
          <transition name="fade" mode="out-in">
            <router-view />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebshopNavbar from "@/views/Navbar.vue";
import accountMenu from "./AccountMenu.vue";

export default {
  components: {
    webshopNavbar: WebshopNavbar,
    accountMenu: accountMenu,
  },
  props: [],
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
  },
  methods: {},
  mounted() {
    if (!this.user.id) {
      this.$store.dispatch("auth/getLoggedInUserWithAuth");
    }
    this.$store.dispatch("shop/getCategories");
  },
};
</script>
