<style scoped>
.custommodal {
  position: fixed;
  height: 100%;
  width: 100%;
}

.customInput {
  margin-bottom: 10px;
}

.card {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.alt-image {
  filter: grayscale(100%);
  max-height: 150px;
}
</style>

<template>
  <div>
    <div>
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">Product aanpassen</p>
        </header>
        <div class="card-content">
          <form @submit.prevent="submit()">
            <section class="modal-card-body">
              <formInput
                class="customInput"
                modelKey="name"
                :model="form"
                placeholder="Naam van product"
              />

              <formSelect
                class="customInput"
                modelKey="status"
                :model="form"
                placeholder="Select status"
                :selectOptions="['concept', 'preview', 'available', 'sold']"
              />

              <formSelect
                v-on:change="setTags()"
                class="customInput"
                modelKey="category"
                :model="form"
                placeholder="Select category"
                :selectOptions="categories"
                selectKey="naam"
              />

              <formTagsAutocomplete
                v-if="form.category"
                class="customInput"
                :model="form"
                modelKey="tags"
                tagKey="naam"
                size="is-small"
                :tags="tags"
              />

              <div class="columns">
                <div class="column">
                  <b-field label="BTW">
                    <b-radio-button
                      v-model="form.btwtype"
                      native-value="low"
                      type="is-success"
                    >
                      <span>laag</span>
                    </b-radio-button>

                    <b-radio-button
                      v-model="form.btwtype"
                      native-value="high"
                      type="is-success"
                    >
                      <span>hoog</span>
                    </b-radio-button>
                  </b-field>
                </div>
              </div>

              <span v-if="btwbedragsoort || form.btwtype === 'none'">
                <div class="columns is-mobile">
                  <div class="column">
                    <formInput
                      v-on:input="setPrice()"
                      v-on:blur="formatPriceFields()"
                      class="customInput"
                      modelKey="editprijs"
                      :model="form"
                      placeholder="Prijs in euro"
                    />
                  </div>
                  <div class="column">
                    <b-field label="Totaal prijs">
                      {{ euroFromCents(form.price) }}
                    </b-field>
                  </div>
                </div>
              </span>

              <p class="help is-danger" v-if="validation.price">
                {{ validation.price[0] }}
              </p>

              <formInput
                class="customInput"
                modelKey="weight"
                title="Weight of product in grams"
                :model="form"
                placeholder="Give weight in grams"
              />

              <formCheckbox
                :model="form"
                title="Product fits in mailbox"
                modelKey="small_product"
                checkBoxText="Small product"
              />

              <br />

              <!-- option part -->
              <formSelect
                v-on:change="changedType()"
                class="customInput"
                modelKey="type"
                :model="form"
                title="Product type"
                placeholder="Select product type"
                :selectOptions="['unique', 'multiple', 'options']"
              />

              <formInput
                v-if="form.type === 'multiple'"
                class="customInput"
                modelKey="amount"
                title="Amount available"
                :model="form"
                placeholder="Amount of products available"
              />
              <span v-if="form.type === 'options'">
                <div
                  class="columns"
                  v-for="(option, key) of form.options"
                  :key="key"
                >
                  <div class="column is-3">
                    <formInput
                      class="customInput"
                      modelKey="name"
                      title="Option name"
                      :model="option"
                      :validationKey="'options.' + key + '.name'"
                      placeholder="Naam van product"
                    />
                  </div>
                  <div class="column is-1">
                    <b-carousel
                      v-model="option.picture"
                      :indicator-inside="false"
                      :autoplay="false"
                    >
                      <b-carousel-item
                        v-for="(item, i) in product.pictures"
                        :key="i"
                      >
                        <b-image
                          ratio="1by1"
                          class="image"
                          :src="
                            env.VUE_APP_API_URL +
                            '/web/picture/' +
                            product.pictures[i].uuid
                          "
                        ></b-image>
                      </b-carousel-item>
                    </b-carousel>
                  </div>

                  <div class="column">
                    <formInput
                      class="customInput"
                      modelKey="amount"
                      :model="option"
                      placeholder="Amount available"
                      :validationKey="'options.' + key + '.amount'"
                    />
                  </div>
                  <div class="column">
                    <formInput
                      v-on:input="setOptionPrice(option)"
                      class="customInput"
                      modelKey="opslagEditPrice"
                      title="opslag"
                      :model="option"
                      placeholder="Bedrag bovenop basis prijs"
                      :validationKey="'options.' + key + '.price'"
                    />
                  </div>

                  <div class="column">
                    <div class="columns">
                      <div class="column">
                        <b-field label="Opslag">
                          {{ euroFromCents(option.price) }}
                        </b-field>
                      </div>
                      <div class="column">
                        <b-field label="Totaal">
                          <!-- {{ euro(option.price).add(form.price).format() }} -->
                          {{ euroFromCents(option.price + form.price) }}
                        </b-field>
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  class="button is-small is-rounded is-warning"
                  @click.prevent="addOption()"
                >
                  Optie toevoegen
                </button>
                <button
                  class="button is-small is-rounded is-danger"
                  @click.prevent="removeOption()"
                >
                  Optie Verwijderen
                </button>
                <p v-if="validation['options']" class="help is-danger">
                  {{ validation["options"][0] }}
                </p>
                <br />
                <br />
              </span>

              <!-- picture part -->

              <productImages
                v-on:savedpictures="setSavedPictures"
                :product="product"
              />

              <br />

              <formEditor
                :value="oldDescription"
                title="Omschrijving"
                :model="form"
                modelKey="description"
              />
            </section>

            <footer class="modal-card-foot">
              <form-button
                type="is-primary"
                title="Update"
                size="is-small"
                loadingKey="create_product"
              />

              <button
                class="button is-rounded is-small"
                @click.prevent="cancelUpdate()"
              >
                Annuleren
              </button>
            </footer>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import currency from "currency.js";
import Vue from "vue";
import productImages from "./AdminProductEditPictures.vue";
import FormCheckbox from "@/components/form/formCheckbox.vue";

export default {
  components: {
    productImages,
    FormCheckbox,
  },
  props: {
    updateForm: {
      type: Object,
    },
  },
  data() {
    return {
      model: "product",
      btwbedragsoort: "inclusief",
      form: {
        visible: true,
        status: "available",
        btwtype: undefined,
        small_product: false,
        btw: "",
        price: "",
        exbtw: "",
        tags: [],
        options: null,
      },
      savedpictures: false,
      currency: currency,
      droppedFiles: [],
      previewImages: [],
      oldDescription: "",
      tags: [],
      env: process.env,
    };
  },
  computed: {
    settings() {
      return this.$store.getters["shop/settings"];
    },
    product() {
      return this.$store.getters["adminProducts/product"];
    },
    categories() {
      return this.$store.getters["adminCategories/categories"];
    },
    btwPercentages() {
      return this.$store.getters["adminProducts/btw"];
    },

    computedTags() {
      if (this.form.category) {
        return this.form.category.tags;
      } else {
        return [];
      }
    },
    btwType() {
      return this.form.btwtype;
    },
  },
  methods: {
    setProduct() {
      this.form = this.product;

      if (this.form.price == null) {
        Vue.set(this.form, "price", 0);
      }
      this.form.category = this.product.category;
      if (this.product.description) {
        this.oldDescription = this.product.description;
      }
      this.setTags();
    },
    formatPriceFields(key) {
      this.form[key] = this.euro(this.form[key]).format();
    },
    setPrice() {
      const prijs = this.form.editprijs.replace(",", ".") * 100;
      const prijsArray = String(prijs).split(".");
      Vue.set(this.form, "price", Number(prijsArray[0]));
    },
    setOptionPrice(option) {
      const prijs = option.opslagEditPrice.replace(",", ".") * 100;
      const prijsArray = String(prijs).split(".");
      Vue.set(option, "price", Number(prijsArray[0]));
    },
    // setPrice() {
    //   if (this.form.btwtype === "none") {
    //     this.form.price =
    //       Number(this.replaceCommaWithDot(this.form.editprijs)) * 100;
    //     this.form.exbtw =
    //       Number(this.replaceCommaWithDot(this.form.editprijs)) * 100;
    //     this.form.btw = 0;
    //     return;
    //   }

    //   if (this.btwbedragsoort === "inclusief") {
    //     this.setExBtw();
    //     return;
    //   }

    //   if (this.btwbedragsoort === "exclusief") {
    //     this.setIncBtw();
    //     return;
    //   }
    // },
    // setExBtw() {
    //   const btwpercentage = this.getBTWPercentage();
    //   const btw =
    //     (Number(this.replaceCommaWithDot(this.form.editprijs) * 100) /
    //       (100 + btwpercentage)) *
    //     btwpercentage;
    //   this.form.price =
    //     Number(this.replaceCommaWithDot(this.form.editprijs)) * 100;
    //   this.form.btw = btw;
    //   this.form.exbtw =
    //     Number(this.replaceCommaWithDot(this.form.editprijs)) * 100 -
    //     Number(btw);
    // },
    // setIncBtw() {
    //   const btwpercentage = this.getBTWPercentage();
    //   const btw =
    //     (Number(this.replaceCommaWithDot(this.form.editprijs) * 100) / 100) *
    //     btwpercentage;
    //   this.form.exbtw =
    //     Number(this.replaceCommaWithDot(this.form.editprijs)) * 100;
    //   this.form.btw = btw;
    //   this.form.price =
    //     Number(this.replaceCommaWithDot(this.form.editprijs)) * 100 +
    //     Number(btw);
    // },
    // getBTWPercentage() {
    //   const high = this.btwPercentages.nederland.percentages.high;
    //   const low = this.btwPercentages.nederland.percentages.low;
    //   var btwpercentage;
    //   if (this.form.btwtype === "high") {
    //     btwpercentage = high;
    //   }

    //   if (this.form.btwtype === "low") {
    //     btwpercentage = low;
    //   }

    //   if (this.form.btwtype === "none") {
    //     btwpercentage = 0;
    //   }

    //   return btwpercentage;
    // },
    cancelUpdate() {
      if (this.updateForm) {
        this.$store.dispatch("adminProducts/cancelUpdateProduct", this.form);
      }
      this.$router.go(-1);
    },
    filesChanged() {
      this.previewImages = [];
      this.droppedFiles.forEach(
        function (file, index) {
          const reader = new FileReader();
          reader.addEventListener(
            "load",
            () => {
              let filepreview = {
                name: file.name,
                url: reader.result,
              };
              this.previewImages.push(filepreview);
            },
            false
          );
          reader.readAsDataURL(file);
        }.bind(this)
      );
    },
    uploadPictures() {
      this.droppedFiles.forEach(
        function (file, index) {
          let formdata = new FormData();
          formdata.append("image", file, file.name);
          this.$store.dispatch("uploadPicture", formdata).then(() => {
            this.droppedFiles.splice(index, 1);
            this.previewImages.splice(index, 1);
          });
        }.bind(this)
      );
    },
    deleteDroppedFile(index) {
      const array = this.droppedFiles;
      array.splice(index, 1);

      this.previewImages.splice(index, 1);
    },
    submit() {
      // this.form.btwpercentage = this.getBTWPercentage();
      this.$store
        .dispatch("adminProducts/updateProduct", this.form)
        .then((response) => {
          if (this.savedpictures) {
            this.$buefy.dialog.confirm({
              title: "Unsaved pictures",
              message:
                "You have unsaved pictures, do you wish to continue without saving?",
              confirmText: "Continue without saving",
              type: "is-warning",
              hasIcon: true,
              onConfirm: () =>
                this.$router.replace({
                  name: "admin-product",
                  params: { product: response.data.slug },
                }),
            });
          } else {
            // if (response.data.slug === this.$route.params.product) {
            //   this.$router.replace({name: })
            // } else {
            this.$router.replace({
              name: "admin-product",
              params: { product: response.data.slug },
            });
          }
          // }
        });
    },
    setTags() {
      if (this.form.category) {
        Vue.set(this, "tags", this.form.category.tags);
      }
    },
    addOption() {
      if (!this.form.options) {
        this.form.options = [];
      }
      this.form.options.push({
        name: "",
        price: 0,
        picture: 0,
      });
    },
    replaceCommaWithDot(payload) {
      return payload.replace(",", ".");
    },
    removeOption() {
      this.form.options.pop();
      if (this.form.options.length === 0) {
        this.form.options = null;
      }
    },
    changedType() {
      this.form.options = null;
    },
    setSavedPictures(payload) {
      this.savedpictures = payload;
    },
    setBtwType() {
      if (this.form.btwtype === undefined || this.form.btwtype === null) {
        const btw = this.settings.variables.find(
          (a) => a.key === "standard_btw_type"
        );

        if (btw) {
          this.form.btwtype = btw.value;
        }
      }
    },
    // setOptionPrice(option) {
    //   if (option.opslagEditPrice) {
    //     if (this.form.btwtype === "none") {
    //       option.btw = 0;
    //       option.exbtw = this.replaceCommaWithDot(option.opslagEditPrice) * 100;
    //       option.price = this.replaceCommaWithDot(option.opslagEditPrice) * 100;
    //       return;
    //     }

    //     if (this.btwbedragsoort === "inclusief") {
    //       const btwpercentage = this.getBTWPercentage();
    //       const btw =
    //         (Number(this.replaceCommaWithDot(option.opslagEditPrice) * 100) /
    //           (100 + btwpercentage)) *
    //         btwpercentage;
    //       option.btw = btw;
    //       option.exbtw =
    //         Number(this.replaceCommaWithDot(option.opslagEditPrice)) * 100 -
    //         Number(btw);
    //       option.price =
    //         Number(this.replaceCommaWithDot(option.opslagEditPrice)) * 100;
    //       return;
    //     }

    //     if (this.btwbedragsoort === "exclusief") {
    //       const btwpercentage = this.getBTWPercentage();
    //       const btw =
    //         (Number(this.replaceCommaWithDot(option.opslagEditPrice) * 100) /
    //           100) *
    //         btwpercentage;
    //       option.btw = btw;
    //       option.exbtw =
    //         Number(this.replaceCommaWithDot(option.opslagEditPrice)) * 100;
    //       option.price =
    //         Number(this.replaceCommaWithDot(option.opslagEditPrice)) * 100 +
    //         btw;
    //       return;
    //     }
    //   }
    // },
  },
  watch: {
    // btwbedragsoort: {
    //   deep: true,
    //   handler() {
    //     if (this.form.editprijs) {
    //       this.setPrice();
    //     }
    //     if (this.form.options) {
    //       for (let option of this.form.options) {
    //         this.setOptionPrice(option);
    //       }
    //     }
    //   },
    // },
    // btwType: {
    //   deep: true,
    //   handler() {
    //     if (this.form.editprijs) {
    //       this.setPrice();
    //     }
    //     if (this.form.options) {
    //       for (let option of this.form.options) {
    //         this.setOptionPrice(option);
    //       }
    //     }
    //   },
    // },
  },
  mounted() {
    const productId = this.$router.currentRoute.params.product;

    if (!this.product.id || this.product.id != productId) {
      this.$store
        .dispatch(
          "adminProducts/getProduct",
          this.$router.currentRoute.params.product
        )
        .then(() => {
          this.setProduct();
        });
    } else {
      this.setProduct();
    }

    if (this.settings && this.settings.variables) {
      this.setBtwType();
    } else {
      this.$store.dispatch("shop/getSettings").then(() => {
        this.setBtwType();
      });
    }
    // if (this.updateForm) {
    // Vue.set(this.form, "id", this.updateForm.id);
    // Vue.set(this.form, "name", this.updateForm.name);
    // Vue.set(this.form, "type", this.updateForm.type);
    // Vue.set(this.form, "amount", this.updateForm.amount);
    // Vue.set(this.form, "category", this.updateForm.category);
    // Vue.set(this.form, "tags", this.updateForm.tags);
    // for (let tag of this.form.tags) {
    //   delete tag.pivot;
    // }
    // Vue.set(this.form, "btwtype", this.updateForm.btwtype);
    // Vue.set(this.form, "exbtw", this.updateForm.exbtw);
    // Vue.set(this.form, "btw", this.updateForm.btw);
    // Vue.set(this.form, "price", this.updateForm.price);
    // Vue.set(this.form, "visible", this.updateForm.visible);
    // Vue.set(this.form, "status", this.updateForm.status);
    // Vue.set(this, "oldDescription", this.updateForm.description);
    // Vue.set(this, "tags", this.form.category.tags);
    // if (this.updateForm.options[0]) {
    //   Vue.set(this.form, "options", []);
    //   for (let option of this.updateForm.options) {
    //     this.form.options.push({
    //       name: option.name,
    //       picture: option.picture,
    //       amount: option.amount,
    //       exbtw: option.exbtw,
    //       price: option.price,
    //       btw: option.btw
    //     });
    //   }
    // }
    // } else {
    // this.form.btw = this.euro(0).format();
    // this.form.exbtw = this.euro(0).format();
    // this.form.price = this.euro(0).format();
    // }
    this.$store.dispatch("clearValidationError", this.modelKey);
  },
};
</script>
