<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}

.table-icons {
  float: right;
}

.box {
  border-color: #333 !important;
  border: solid;
  border-width: thin;
  /* Set the hr color */
}

img {
  max-height: 150px;
}

hr {
  margin-top: 0px;
  border: none;
  height: 1px;
  /* Set the hr color */
  color: #333; /* old IE */
  background-color: #333; /* Modern Browsers */
}
</style>

<template>
  <div>
    <div class="columns">
      <div class="column">
        <div class="title is-4">Order: {{ formatOrderNumber(order) }}</div>
        <div class="subtitle" v-if="order.id">
          Placed on {{ formatDatabaseDate(order.created_at) }}
        </div>

        <button class="button is-fullwidth" @click="$router.go(-1)">
          Back to orders
        </button>
        <br />

        <div class="columns">
          <div class="column">
            <button
              class="button is-primary is-fullwidth"
              @click="sendCustomerEmail = true"
            >
              Compose email to customer
            </button>
          </div>

          <div class="column">
            <button
              class="button is-primary is-fullwidth"
              :class="{ 'is-loading': ajaxloading.resend_order_confirmed_mail }"
              @click="resendOrderConfirmedMail()"
            >
              Resend orderconfirmation
            </button>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <button
              class="button is-fullwidth is-primary"
              @click="
                $router.push({
                  name: 'admin-order-edit',
                  params: { order: order.id },
                })
              "
            >
              Edit / delete order
            </button>
          </div>

          <div class="column">
            <button
              class="button is-fullwidth is-primary"
              @click="
                $router.push({
                  name: 'admin-order-refund-options',
                  params: { order: order.id },
                })
              "
            >
              Show refund options
            </button>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <button
              class="button is-primary is-fullwidth"
              @click="
                $router.push({
                  name: 'admin-order-logs',
                  params: { order: order.id },
                })
              "
            >
              Show order logs
            </button>
          </div>

          <div class="column">
            <button
              class="button is-fullwidth is-primary"
              :class="{ 'is-loading': ajaxloading.checkingMollieStatus }"
              @click="recheckMollieStatus()"
            >
              Recheck Mollie status
            </button>
          </div>
        </div>

        <div
          class="columns"
          v-if="order.shipping_method !== 'Pickup in Zutphen'"
        >
          <div class="column">
            <button
              class="button is-fullwidth is-primary"
              :class="{ 'is-loading': ajaxloading.create_shipping_label }"
              @click="preCreateNewShippingLabel()"
            >
              Create new shipping label
            </button>
          </div>
          <div class="column">
            <button
              class="button is-fullwidth is-primary"
              :class="{ 'is-loading': ajaxloading.checking_shipping_status }"
              @click="recheckShippingStatus()"
            >
              Recheck shipping status
            </button>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <button
              class="button is-fullwidth is-primary"
              :class="{
                'is-loading': ajaxloading.generating_and_sending_invoices,
              }"
              @click="
                $router.push({
                  name: 'admin-order-invoice-options',
                  params: { order: order.id },
                })
              "
            >
              Show invoice options
            </button>
          </div>
        </div>

        <div class="box" v-if="order && order.id">
          <div class="has-text-centered">
            <strong>Order shipping status</strong>
          </div>

          <br />

          <span
            v-if="
              order.shipping_status === 'canceled' ||
              order.payment_status === 'canceled'
            "
          >
            <div class="has-text-centered">Order canceled</div>
          </span>

          <span v-else>
            <b-steps
              @input="setNewStatus()"
              type="is-primary"
              :has-navigation="false"
              v-model="order.shipping_status"
              v-if="order.id && order.shipping_method !== 'Pickup in Zutphen'"
            >
              <b-step-item
                clickable
                value="ready for packing"
                label="Ready for packing"
              ></b-step-item>
              <b-step-item
                clickable
                value="ready for shipping"
                label="Ready for shipping"
              ></b-step-item>
              <b-step-item
                clickable
                value="shipped"
                label="Shipped"
              ></b-step-item>
              <b-step-item
                clickable
                value="delivered"
                label="Delivered"
              ></b-step-item>
            </b-steps>

            <b-steps
              @input="setNewStatus()"
              type="is-primary"
              :has-navigation="false"
              v-model="order.shipping_status"
              v-else
            >
              <b-step-item
                clickable
                value="ready for packing"
                label="Ready for packing"
              ></b-step-item>
              <b-step-item
                clickable
                value="ready for pickup"
                label="Ready for pickup"
              ></b-step-item>
              <b-step-item
                clickable
                value="picked up"
                label="Picked up"
              ></b-step-item>
            </b-steps>
          </span>
        </div>

        <span
          v-if="
            order &&
            order.shipping_method !== 'Pickup in Zutphen' &&
            order.shipping_labels
          "
        >
          <div
            class="columns"
            v-for="label in order.shipping_labels"
            :key="label.id"
          >
            <div class="column" v-if="label.shipping_status === 'concept'">
              <button
                class="button is-fullwidth is-primary"
                :class="{ 'is-loading': ajaxloading.register_shipping_label }"
                @click="registerLabel(label)"
              >
                Register shipping label with {{ label.shipping_api_provider }}
              </button>
            </div>
            <div class="column" v-if="label.shipping_status !== 'concept'">
              <button
                class="button is-fullwidth is-primary"
                @click="downloadLabel(label)"
              >
                Download label (id: {{ label.id }})
              </button>
            </div>
            <div class="column" v-if="label.shipping_status !== 'concept'">
              <button
                class="button is-fullwidth is-primary"
                @click="showTrackAndTrace(label)"
              >
                Track and Trace
              </button>
            </div>
          </div>
        </span>

        <br />

        <div class="box">
          <div class="columns">
            <div class="column is-6">
              <!-- order balance paid-->
              <strong>Order balance status:</strong>
              <span class="is-pulled-right"
                ><span style="color: green" v-if="order.balance === 0"
                  >Good</span
                >
                <span v-else>
                  <div style="color: red">Needs attention</div>
                </span>
              </span>
              <!-- only show wen negative -->
              <span v-if="order.balance !== 0">
                <br />

                Total:
                <span class="is-pulled-right">{{
                  euroFromCentsWithoutDash(order.total_price)
                }}</span>

                <br />
                <span v-if="order.balance !== 0">
                  Paid:
                  <span class="is-pulled-right">{{
                    euroFromCentsWithoutDash(order.total_price - order.balance)
                  }}</span>
                </span>
                <br />
                Balance
                <span class="is-pulled-right">
                  <div>{{ euroFromCentsWithoutDash(order.balance) }}</div>
                </span>
              </span>
            </div>
            <div
              class="column"
              v-if="
                ['shipped', 'delivered', 'picked up'].includes(
                  order.shipping_status
                ) ||
                (order.all_invoices && order.all_invoices.length)
              "
            >
              <!-- invoice balance -->
              <strong>Invoice balance status:</strong>
              <span class="is-pulled-right"
                ><span
                  style="color: green"
                  v-if="totalInvoiced === order.total_price"
                  >Good</span
                >
                <span v-else>
                  <div style="color: red">Needs attention</div>
                </span>
              </span>
              <!-- only show wen negative -->
              <span v-if="totalInvoiced !== order.total_price">
                <br />
                Total of invoiced:
                <span class="is-pulled-right">
                  <div>{{ euroFromCents(totalInvoiced) }}</div>
                </span>
                <br />
              </span>

              <br />

              <!-- unsend invoices -->
              <strong>Invoice send status:</strong>
              <span class="is-pulled-right"
                ><span style="color: green" v-if="invoicesNeedSending === 0"
                  >Good</span
                >
                <span v-else style="color: red">Needs attention</span>
              </span>
              <span v-if="invoicesNeedSending > 0">
                <br />
                Amount of unsend invoices
                <span class="is-pulled-right">
                  <span>{{ invoicesNeedSending }}</span>
                </span>
              </span>
            </div>
          </div>
        </div>

        <div class="box">
          <div class="columns">
            <div class="column">
              <strong>Buyer: </strong>{{ order.name }} {{ order.surname }}
            </div>
            <div class="column">
              <strong>Email: </strong>
              <a :href="'mailto:' + order.email">{{ order.email }}</a>
            </div>
          </div>
          <hr />
          <div class="columns">
            <div class="column">
              <div class="">
                <strong>Billing address:</strong>
                <br />
                {{ order.business_name }}
                <br v-if="order.business_name" />
                {{ order.name }} {{ order.surname }}<br />
                {{ order.street }} {{ order.house_number }} <br />
                {{ order.postal_code }} {{ order.city }},
                {{ order.state }}
                <br />

                {{ order.country }}

                <span v-if="order.shipping_method !== 'Pickup in Zutphen'">
                  <br />
                  <br />
                  <strong>Shipping address:</strong> <br />
                  {{ order.send_business_name }}
                  <br v-if="order.business_name" />
                  {{ order.send_name }} {{ order.send_surname }}<br />
                  {{ order.send_street }} {{ order.send_house_number }}
                  <br />
                  {{ order.send_postal_code }} {{ order.send_city }}
                  <br />
                  {{ order.send_state }},
                  {{ order.send_country }}
                </span>
              </div>
            </div>
            <div class="column">
              Payment method:
              <span class="is-pulled-right">{{ order.payment_method }}</span>
              <br />
              Payment status:
              <span class="is-pulled-right">{{ order.payment_status }}</span>

              <br />
              <br />

              Shipping method:
              <span class="is-pulled-right">{{ order.shipping_method }}</span>
              <br />
              Shipping status:
              <span class="is-pulled-right">{{ order.shipping_status }}</span>
              <span v-if="order.shipping_method !== 'Pickup in Zutphen'">
                <br />
                Package type:
                <span class="is-pulled-right">{{ order.package_type }}</span>
                <br />
                Total weight:
                <span class="is-pulled-right"
                  >{{ order.total_weight / 1000 }} Kilo</span
                >
              </span>

              <br />
              <br />
              <strong>
                Subtotal:
                <span class="is-pulled-right">{{
                  euroFromCentsWithoutDash(order.subtotal)
                }}</span>
              </strong>
              <br />

              Shipping Costs:
              <span class="is-pulled-right">{{
                euroFromCentsWithoutDash(order.shipping_costs)
              }}</span>
              <br />

              Payment Costs:
              <span class="is-pulled-right">{{
                euroFromCentsWithoutDash(order.payment_costs)
              }}</span>
              <br />

              <span v-if="order.tax_high + order.tax_low > 0">
                <strong>
                  Total:
                  <span class="is-pulled-right">{{
                    euroFromCentsWithoutDash(order.total_price)
                  }}</span></strong
                >
                <br />
                <br />

                BTW:
                <span class="is-pulled-right">{{
                  euroFromCentsWithoutDash(order.tax_low + order.tax_high)
                }}</span>
                <br />

                Taxable amount:
                <span class="is-pulled-right">{{
                  euroFromCentsWithoutDash(
                    order.taxable_amount_low +
                      order.taxable_amount_high +
                      order.taxable_amount_none
                  )
                }}</span>
                <br />
              </span>

              <strong>
                Total:
                <span class="is-pulled-right">{{
                  euroFromCentsWithoutDash(order.total_price)
                }}</span></strong
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box">
      <div class="title is-5" v-if="order.product_count === 1">
        {{ order.product_count }} Product:
      </div>
      <div class="title is-5" v-else>{{ order.product_count }} Products:</div>
      <br />
      <span v-if="order.id">
        <div
          class="columns is-mobile"
          v-for="product in order.order_products"
          :key="product.id"
        >
          <div class="column is-2">
            <b-image
              ratio="1by1"
              :src="
                env.VUE_APP_API_URL +
                '/web/order-picture/' +
                product.picture_uuid
              "
              alt="Product Image"
            />
          </div>
          <div class="column is-6 overflow">
            <strong>{{ product.id }} - {{ product.name }}</strong>
            <span v-html="product.description"></span>
          </div>
          <div class="column is-2 has-text-right">
            <span> {{ product.amount }}x </span>
          </div>
          <div class="column is-2 has-text-right">
            <span>
              {{ euroFromCents(product.price * product.amount) }}
            </span>
          </div>
        </div>
      </span>
    </div>
    <customMailModal
      v-on:close="sendOrderPickupMail = false"
      v-if="sendOrderPickupMail"
      :order="order"
      vuexStoreAction="adminOrders/sendPickupReadyEmail"
      settingsDefaultEmailKey="email_ready_for_pickup"
      settingsDefaultEmailSubjectKey="email_ready_for_pickup_subject"
    />

    <customMailModal
      v-on:close="sendCustomerEmail = false"
      v-if="sendCustomerEmail"
      :order="order"
      vuexStoreAction="adminOrders/sendEmailToCustomer"
      settingsDefaultEmailKey=""
    />
  </div>
</template>

<script>
import customMailModal from "./CustomMailEditorModal.vue";

export default {
  components: {
    customMailModal,
  },
  props: [],
  data() {
    return {
      env: process.env,
      sendOrderPickupMail: false,
      sendCustomerEmail: false,
      isLoading: true,
    };
  },
  computed: {
    settings() {
      return this.$store.getters["shop/settings"];
    },
    totalPaid() {
      if (this.order && this.order.id) {
        var total = 0;
        this.order.order_payments.forEach((a) => {
          if (a.status === "paid") {
            total = total + a.paid;
          }
        });
        return total;
      } else {
        return 0;
      }
    },
    orders() {
      return this.$store.getters["adminOrders/orders"];
    },
    order() {
      if (this.orders && this.orders.length) {
        var order = this.orders.find(
          (a) => a.id == this.$router.currentRoute.params.order
        );
      }

      if (order) {
        return order;
      } else {
        return {};
      }
    },
    totalInvoiced() {
      var total = 0;

      if (this.order && this.order.all_invoices) {
        for (let invoice of this.order.all_invoices) {
          total = total + invoice.total_price;
        }
      }

      return total;
    },
    invoicesNeedSending() {
      var total = 0;

      if (this.order && this.order.all_invoices) {
        for (let invoice of this.order.all_invoices) {
          if (!invoice.invoice_send) {
            total++;
          }
        }
      }

      return total;
    },
  },
  methods: {
    preCreateNewShippingLabel() {
      this.$buefy.dialog.confirm({
        title: "Create shipping label?",
        message: "Do you wish to create another shipping label?",
        type: "is-info",
        onConfirm: () =>
          this.$store.dispatch(
            "adminOrders/createNewShippingLabel",
            this.order
          ),
      });
    },
    showTrackAndTrace(label) {
      window.open(label.track_and_trace_url);
    },
    downloadLabel(label) {
      const extension = this.settings.variables.find(
        (a) => a.key === "shipping_label_extension"
      ).value;

      if (extension === ".pdf") {
        window.open(
          this.env.VUE_APP_API_URL +
            "/web/admin-show-pdf-shipping-label/" +
            label.id
        );
      }

      if (extension === ".zpl") {
        window.open(
          this.env.VUE_APP_API_URL +
            "/web/admin-show-zpl-shipping-label/" +
            label.id
        );
      }
    },
    registerLabel(label) {
      this.$store.dispatch("adminOrders/registerShippingLabel", label);
    },
    recheckShippingStatus() {
      this.$store
        .dispatch("adminOrders/recheckShippingStatus", this.order)
        .then((response) => {
          this.$buefy.toast.open({
            message: "Shipping status = " + response.data.shipping_status,
            type: "is-success",
            duration: 4000,
          });
        });
    },
    setNewStatus() {
      const status = this.order.shipping_status;
      if (status === "ready for pickup") {
        this.sendOrderPickupMail = true;
      }

      if (status === "delivered" || status === "picked up") {
        this.$buefy.dialog.confirm({
          title: "Generate and send invoices?",
          message:
            "Do you wish to generate and send all missing invoices for this order?",
          onConfirm: () =>
            this.$store
              .dispatch("adminOrders/generateAndSendInvoices", this.order)
              .then(() => {
                this.$buefy.snackbar.open({
                  message: "Invoices generated and send by email to customer",
                  duration: 3000,
                  position: "is-top",
                });
              }),
        });
      }

      const data = {
        order: this.order,
        status: status,
      };
      this.$store.dispatch("adminOrders/changeOrderShippingStatus", data);
    },
    openPdf(invoice) {
      window.open(
        this.env.VUE_APP_API_URL + "/web/show-user-invoice/" + invoice.id
      );
    },
    resendOrderConfirmedMail() {
      this.$buefy.dialog.confirm({
        title: "Resend email?",
        message: "Do you wish to resend the order confirmation email?",
        onConfirm: () =>
          this.$store.dispatch(
            "adminOrders/resendOrderConfirmedEmail",
            this.order
          ),
      });
    },
    recheckMollieStatus() {
      this.$store
        .dispatch("adminOrders/recheckPaymentStatus", {
          order: this.order,
        })
        .then((response) => {
          this.$buefy.toast.open({
            message: "Payment status = " + response.data.payment_status,
            type: "is-success",
            duration: 4000,
          });
        });
    },
    formatOrderNumber(order) {
      if (!this.order.id) {
        return;
      }

      return order.order_year + "-" + order.order_number;
    },
  },
  mounted() {},
};
</script>
