import Vue from "vue";

const hoofdletter = Vue.mixin({
  methods: {
    hoofdletter(payload) {
      if (typeof payload === "string") {
        return payload.charAt(0).toUpperCase() + payload.slice(1);
      } else {
        return payload;
      }
    },
  },
});

export default hoofdletter;
