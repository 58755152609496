var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container is-fluid"},[_c('br'),(
      _vm.settings &&
      _vm.settings.variables.find((a) => a.key === 'shop_welcome_message')
    )?_c('span',{domProps:{"innerHTML":_vm._s(
      _vm.settings.variables.find((a) => a.key === 'shop_welcome_message').value
    )}}):(_vm.settings && _vm.settings.webshop.welcome_message)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.settings.webshop.welcome_message)}}):_vm._e(),_c('br'),_c('br'),_vm._l((Math.ceil(_vm.categories.length / _vm.maxItemsPerRow)),function(outerLoop){return _c('div',{key:outerLoop,staticClass:"columns"},_vm._l((_vm.calculatedMax(outerLoop)),function(item){return _c('div',{key:item,staticClass:"column is-3"},[_c('div',{staticClass:"card pointer",on:{"click":function($event){return _vm.navigateToCategory(
            _vm.categories[item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1]
              .slug
          )}}},[_c('div',{staticClass:"card-image"},[_c('figure',{staticClass:"image is-1by1"},[_c('div',{on:{"mouseover":function($event){return _vm.mouseoverImage(
                  _vm.categories[
                    item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1
                  ]
                )},"mouseleave":_vm.mouseleaveImage}},[(
                  _vm.hoveredPoduct ===
                  _vm.categories[
                    item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1
                  ]
                )?_c('img',{attrs:{"src":_vm.env.VUE_APP_API_URL +
                  '/web/picture/' +
                  _vm.categories[
                    item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1
                  ].alt_picture,"alt":"Category image"}}):_c('img',{attrs:{"src":_vm.env.VUE_APP_API_URL +
                  '/web/picture/' +
                  _vm.categories[
                    item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1
                  ].main_picture,"alt":"Category image"}})])])]),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"media"},[_c('div',{staticClass:"media"}),_c('div',{staticClass:"media-content"},[_c('p',{staticClass:"title is-4 has-text-centered"},[_vm._v(" "+_vm._s(_vm.hoofdletter( _vm.categories[ item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1 ].naam ))+" ")])])])])])])}),0)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }