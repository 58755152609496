<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}

.table-icons {
  float: right;
}

.columns {
  margin-bottom: 0px;
}

.column {
  margin-bottom: 0px;
}

.box {
  border-color: #333 !important;
  border: solid;
  border-width: thin;
  /* Set the hr color */
}
.shippingbox {
  margin-bottom: 0px;
}

img {
  max-height: 150px;
}

hr {
  border: none;
  height: 1px;
  /* Set the hr color */
  color: #333; /* old IE */
  background-color: #333; /* Modern Browsers */
}
</style>

<template>
  <div>
    <div class="columns">
      <div class="column">
        <div class="title is-4">Order: {{ formatOrderNumber(order) }}</div>
        <div class="subtitle" v-if="order.id">
          Placed on {{ formatDatabaseDate(order.created_at) }}
        </div>

        <button class="button is-fullwidth is-primary" @click="$router.go(-1)">
          Back to orders
        </button>
        <br />

        <div class="box shippingbox">
          <div class="has-text-centered">
            <strong>Order shipping status</strong>
          </div>

          <br />

          <b-steps
            type="is-primary"
            :has-navigation="false"
            v-model="order.shipping_status"
            v-if="order.id && order.shipping_method !== 'Pickup in Zutphen'"
          >
            <b-step-item value="paid" label="Paid"></b-step-item>
            <b-step-item
              value="ready for packing"
              label="Ready for packing"
            ></b-step-item>
            <b-step-item
              value="ready for shipping"
              label="Ready for shipping"
            ></b-step-item>
            <b-step-item value="shipped" label="Shipped"></b-step-item>
            <b-step-item value="delivered" label="Delivered"></b-step-item>
          </b-steps>

          <b-steps
            type="is-primary"
            :has-navigation="false"
            v-model="order.shipping_status"
            v-else
          >
            <b-step-item value="paid" label="Paid"></b-step-item>
            <b-step-item
              value="ready for packing"
              label="Ready for packing"
            ></b-step-item>
            <b-step-item
              value="ready for pickup"
              label="Ready for pickup"
            ></b-step-item>
            <b-step-item value="picked up" label="Picked up"></b-step-item>
          </b-steps>
        </div>
        <div class="columns">
          <div class="column">
            <br />
            <span v-if="order.shipping_labels && order.shipping_labels.length">
              <button
                class="button is-primary is-fullwidth"
                @click="
                  showTrackingInformation(
                    order.shipping_labels[order.shipping_labels.length - 1]
                  )
                "
              >
                Show tracking information
              </button>
            </span>
          </div>

          <div class="column">
            <br />
            <span v-for="invoice in order.invoices" :key="invoice.id">
              <button
                @click="openPdf(invoice)"
                @click.middle="openPdf(invoice)"
                class="button is-fullwidth is-primary"
              >
                Show invoice {{ invoice.invoice_year }}-{{
                  invoice.invoice_number
                }}
              </button>
              <br />
            </span>
          </div>
        </div>

        <div class="box">
          <div class="columns">
            <div class="column">
              <div class="">
                <strong>Billing address:</strong>
                <br />
                {{ order.business_name }}
                <br v-if="order.business_name" />
                {{ order.name }} {{ order.surname }}<br />
                {{ order.street }} {{ order.house_number }} <br />
                {{ order.postal_code }} {{ order.city }} <br />
                {{ order.state }},
                {{ order.country }}

                <span v-if="order.shipping_method !== 'Pickup in Zutphen'">
                  <br />
                  <br />
                  <strong>Shipping address:</strong> <br />
                  {{ order.business_name }}
                  <br v-if="order.business_name" />
                  {{ order.send_name }} {{ order.send_surname }}<br />
                  {{ order.send_street }} {{ order.send_house_number }}
                  <br />
                  {{ order.send_postal_code }} {{ order.send_city }}
                  <br />
                  {{ order.send_state }},
                  {{ order.send_country }}
                </span>
              </div>
            </div>
            <div class="column">
              Payment method:
              <span class="is-pulled-right">{{ order.payment_method }}</span>

              <br />

              Shipping method:
              <span class="is-pulled-right">{{ order.shipping_method }}</span>

              <br />
              <br />
              <strong>
                Subtotal:
                <span class="is-pulled-right">{{
                  euroFromCentsWithoutDash(order.subtotal)
                }}</span>
              </strong>
              <br />

              Shipping Costs:
              <span class="is-pulled-right">{{
                euroFromCentsWithoutDash(order.shipping_costs)
              }}</span>
              <br />

              Payment Costs:
              <span class="is-pulled-right">{{
                euroFromCentsWithoutDash(order.payment_costs)
              }}</span>
              <br />

              <strong>
                Total:
                <span class="is-pulled-right">{{
                  euroFromCentsWithoutDash(order.total_price)
                }}</span></strong
              >
              <br />
              <br />
              VAT:
              <span class="is-pulled-right">{{
                euroFromCentsWithoutDash(order.tax_low + order.tax_high)
              }}</span>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="title is-5" v-if="order.product_count === 1">
        {{ order.product_count }} Product:
      </div>
      <div class="title is-5" v-else>{{ order.product_count }} Products:</div>
      <br />
      <span v-if="order.id">
        <div
          class="columns is-mobile"
          v-for="product in order.order_products"
          :key="product.id"
        >
          <div class="column is-2">
            <b-image
              ratio="1by1"
              :src="
                env.VUE_APP_API_URL +
                '/web/order-picture/' +
                product.picture_uuid
              "
              alt="Product Image"
            />
          </div>
          <div class="column is-6 overflow">
            <strong>{{ product.name }}</strong>
            <span v-html="product.description"></span>
          </div>
          <div class="column is-2 has-text-right">
            <span> {{ product.amount }}x </span>
          </div>
          <div class="column is-2 has-text-right">
            <span>
              {{ euroFromCents(product.price * product.amount) }}
            </span>
          </div>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      env: process.env,
    };
  },
  computed: {
    orders() {
      return this.$store.getters["auth/orders"];
    },
    order() {
      if (this.orders && this.orders.length) {
        var order = this.orders.find(
          (a) => a.id == this.$router.currentRoute.params.order
        );
      }

      if (order) {
        return order;
      } else {
        return {};
      }
    },
  },
  methods: {
    openPdf(invoice) {
      window.open(
        this.env.VUE_APP_API_URL + "/web/show-user-invoice/" + invoice.id
      );
    },
    showTrackingInformation(label) {
      window.open(label.track_and_trace_url);
    },
    formatOrderNumber(order) {
      if (!this.order.id) {
        return;
      }
      return order.order_year + "-" + order.order_number;
    },
  },
  mounted() {},
};
</script>
