<template>
  <span>
    &nbsp;
    <img
      v-if="icon === 'postnl'"
      height="16"
      width="16"
      src="@/assets/icons/postnl.svg"
      alt="postnl"
    />

    <img
      v-if="icon === 'bancontact'"
      height="16"
      width="16"
      src="@/assets/icons/bancontact.svg"
      alt="bancontact"
    />

    <img
      style="filter: grayscale(100%)"
      v-if="icon === 'giropay'"
      height="16"
      width="16"
      src="@/assets/icons/giropay.svg"
      alt="giropay"
    />
  </span>
</template>

<script>
export default {
  components: {},
  props: {
    icon: {
      required: true,
      type: String,
    },
  },

  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>
