<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Add shipping method</p>
        <button
          class="delete"
          aria-label="close"
          @click="closeModal()"
        ></button>
      </header>
      <section class="modal-card-body">
        <formSelect
          :model="form"
          modelKey="shipping_method"
          :selectOptions="shippingMethods"
          selectKey="internal_name"
        />
      </section>
      <footer class="modal-card-foot">
        <button
          :class="{ 'is-loading': ajaxloading.save_shippingmethod }"
          class="button is-success"
          @click="saveShippingMethod()"
        >
          Save Changes
        </button>

        <button @click="closeModal()" class="button">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    country: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {},
    };
  },
  computed: {
    settings() {
      return this.$store.getters["shop/settings"];
    },
    shippingMethods() {
      return this.$store.getters["adminPrices/shippingMethods"];
    },
  },
  methods: {
    closeModal() {
      this.$emit("close", true);
    },
    saveShippingMethod() {
      this.$store
        .dispatch("adminPrices/saveShippingMethodToCountry", {
          country: this.country,
          shipping_method: this.form.shipping_method.id,
        })
        .then(() => {
          this.$emit("close", false);
        });
    },
  },
  mounted() {
    this.$store.dispatch("adminPrices/getShippingMethods");
  },
};
</script>
