<template>
  <div class="container is-fluid">
    <br />

    <span
      v-if="
        settings &&
        settings.variables.find((a) => a.key === 'shop_welcome_message')
      "
      v-html="
        settings.variables.find((a) => a.key === 'shop_welcome_message').value
      "
    >
    </span>
    <span
      v-else-if="settings && settings.webshop.welcome_message"
      v-html="settings.webshop.welcome_message"
    >
    </span>
    <br />
    <br />

    <div
      class="columns"
      v-for="outerLoop in Math.ceil(categories.length / maxItemsPerRow)"
      :key="outerLoop"
    >
      <div
        class="column is-3"
        v-for="item in calculatedMax(outerLoop)"
        :key="item"
      >
        <div
          class="card pointer"
          @click="
            navigateToCategory(
              categories[item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1]
                .slug
            )
          "
        >
          <div class="card-image">
            <figure class="image is-1by1">
              <div
                @mouseover="
                  mouseoverImage(
                    categories[
                      item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                    ]
                  )
                "
                @mouseleave="mouseleaveImage"
              >
                <img
                  v-if="
                    hoveredPoduct ===
                    categories[
                      item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                    ]
                  "
                  :src="
                    env.VUE_APP_API_URL +
                    '/web/picture/' +
                    categories[
                      item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                    ].alt_picture
                  "
                  alt="Category image"
                />
                <img
                  v-else
                  :src="
                    env.VUE_APP_API_URL +
                    '/web/picture/' +
                    categories[
                      item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                    ].main_picture
                  "
                  alt="Category image"
                />
              </div>
            </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media"></div>
              <div class="media-content">
                <p class="title is-4 has-text-centered">
                  {{
                    hoofdletter(
                      categories[
                        item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                      ].naam
                    )
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      hoveredPoduct: {},
      env: process.env,
      maxItemsPerRow: 3,
    };
  },
  computed: {
    categories() {
      return this.$store.getters["shop/categories"];
    },
    settings() {
      return this.$store.getters["shop/settings"];
    },
  },
  methods: {
    calculatedMax(loop) {
      const max = this.categories.length;
      const rows = this.maxItemsPerRow * loop;
      if (max < rows) {
        return max - this.maxItemsPerRow * (loop - 1);
      } else {
        return this.maxItemsPerRow;
      }
    },
    mouseoverImage(category) {
      this.hoveredPoduct = category;
    },
    mouseleaveImage() {
      this.hoveredPoduct = {};
    },
    navigateToCategory(category) {
      this.$router.push({
        name: "webshop-category",
        params: { category: category },
      });
    },
    maxItems(max) {
      return this.categories.slice(0, 2);
    },
  },
  mounted() {},
};
</script>
