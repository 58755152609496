<template>
  <div id="main">
    <div class="fof">
      <h1>Error 404</h1>
      <div>Page not found</div>
      <br />
      <div>
        <button
          @click="$router.replace({ name: 'home' })"
          class="button is-rounded is-primary"
        >
          Home
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
* {
  transition: all 0.6s;
}

html {
  height: 100%;
}

body {
  font-family: "Lato", sans-serif;
  color: #888;
  margin: 0;
}

#main {
  display: table;
  width: 100%;
  height: 100vh;
  text-align: center;
}

.fof {
  display: table-cell;
  vertical-align: middle;
}

.fof h1 {
  font-size: 50px;
  display: inline-block;
  padding-right: 12px;
  animation: type 0.5s alternate infinite;
}
</style>
<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>
