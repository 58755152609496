import Vue from "vue";
import VueRouter from "vue-router";
import AppBase from "@/views/AppBase.vue";
import Home from "@/views/Home.vue";
import About from "@/views/About.vue";
import FourZeroFour from "../views/FourZeroFour.vue";
import Faq from "@/views/FAQ.vue";
import Contact from "@/views/Contact.vue";
import ContactRedirect from "@/views/ContactFormSend.vue";

import WebshopBase from "@/views/webshop/WebshopIndex.vue";
import WebshopCategories from "@/views/webshop/WebshopCategories.vue";
import WebshopCategoryBase from "@/views/webshop/WebshopCategoryBase.vue";
import WebshopCategory from "@/views/webshop/WebshopCategory.vue";
import WebshopProduct from "@/views/webshop/WebshopProduct.vue";

import WebshopCheckout from "@/views/shoppingcart/checkout.vue";
import ShoppingCart from "@/views/shoppingcart/ShoppingCart.vue";
import AccountBase from "@/views/account/AccountBase.vue";
import Account from "@/views/account/Account.vue";
import AccountOrderBase from "@/views/account/AccountOrderBase.vue";
import AccountOrders from "@/views/account/AccountOrders.vue";
import AccountOrder from "@/views/account/AccountOrder.vue";
import AccountChangePassword from "@/views/account/AccountChangePassword.vue";
import AccountDeleteUser from "@/views/account/AccountDeleteUser.vue";

import AdminBase from "@/views/admin/AdminBase.vue";

import AdminCategoryBase from "@/views/admin/categories/AdminCategoryBase.vue";
import AdminCategories from "@/views/admin/categories/AdminCategories.vue";
import AdminCategory from "@/views/admin/categories/AdminCategory.vue";

import AdminProductBase from "@/views/admin/products/AdminProductBase.vue";
import AdminProduct from "@/views/admin/products/AdminProduct.vue";
import AdminProducts from "@/views/admin/products/AdminProducts.vue";
import AdminProductEdit from "@/views/admin/products/AdminProductEdit.vue";

import AdminSettings from "@/views/admin/AdminSettings.vue";

import AdminShippingBase from "@/views/admin/shipping/AdminShippingBase.vue";
import AdminShippingIndex from "@/views/admin/shipping/AdminShippingIndex.vue";
import AdminShippingCreateAndUpdate from "@/views/admin/shipping/AdminShippingCreateAndUpdate.vue";

import AdminOrders from "@/views/admin/orders/AdminOrders.vue";
import AdminOrder from "@/views/admin/orders/AdminOrder.vue";
import AdminOrderRefundOptions from "@/views/admin/orders/AdminOrderRefundOptions.vue";
import AdminOrderEdit from "@/views/admin/orders/AdminOrderEdit.vue";
import AdminOrderLogs from "@/views/admin/orders/AdminOrderLogs.vue";
import AdminOrdersBase from "@/views/admin/orders/AdminOrdersBase.vue";
import AdminOrderInvoiceOptions from "@/views/admin/orders/AdminOrderInvoiceOptions.vue";

import AdminUsers from "@/views/admin/users/AdminUsers.vue";
import AdminUser from "@/views/admin/users/AdminUser.vue";
import AdminUserBase from "@/views/admin/users/AdminUserBase.vue";

import AdminInvoiceBase from "@/views/admin/invoices/AdminInvoiceBase";
import AdminInvoices from "@/views/admin/invoices/AdminInvoices";
import AdminInvoice from "@/views/admin/invoices/AdminInvoice";

import AdminPricesBase from "@/views/admin/AdminPricesBase.vue";
import AdminPrices from "@/views/admin/prices/AdminPrices.vue";
import AdminAddCountry from "@/views/admin/prices/AdminAddCountry";
import AdminCountry from "@/views/admin/prices/AdminCountry";
import AdminCountryAddShippingMethod from "@/views/admin/prices/AdminCountryAddShippingMethod";
import AdminCountryAddPaymentMethod from "@/views/admin/prices/AdminCountryAddPaymentMethod";

import AdminRevenueBase from "@/views/admin/revenue/AdminRevenueBase.vue";
import AdminRevenue from "@/views/admin/revenue/AdminRevenue.vue";

import OrderRedirect from "@/views/OrderRedirect.vue";

import AdminWebsite from "@/views/admin/AdminWebsite.vue";

import SetNewPasswordFromToken from "@/views/auth/SetNewPasswordFromToken";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: AppBase,
    children: [
      { path: "", component: Home, name: "home" },
      {
        path: "about",
        component: About,
        name: "about",
      },
      {
        path: "faq",
        component: Faq,
        name: "faq",
      },
      {
        path: "contact",
        component: Contact,
        name: "contact",
      },
      {
        path: "contact-form-received",
        component: ContactRedirect,
        name: "contact-form-send",
      },
      {
        path: "change-password/:email/:token",
        component: SetNewPasswordFromToken,
        name: "wachtwoord-resetten",
      },
      {
        path: "admin",
        component: AdminBase,
        meta: { admin: true },
        children: [
          {
            path: "",
            component: AdminWebsite,
            name: "admin",
            meta: { admin: true },
          },
          {
            path: "instellingen",
            component: AdminSettings,
            name: "admin-settings",
            meta: { admin: true },
          },

          {
            path: "shipping-labels",
            component: AdminShippingBase,
            meta: { admin: true },
            children: [
              {
                path: "",
                component: AdminShippingIndex,
                name: "admin-shipping-labels",
                meta: { admin: true },
              },
              {
                path: "create",
                component: AdminShippingCreateAndUpdate,
                name: "admin-create-shipping-label",
                meta: { admin: true },
              },
            ],
          },

          {
            path: "orders",
            component: AdminOrdersBase,
            meta: { admin: true },
            children: [
              {
                path: "",
                component: AdminOrders,
                name: "admin-orders",
                meta: { admin: true },
              },
              {
                path: "order/:order",
                component: AdminOrder,
                name: "admin-order",
                meta: { admin: true },
              },
              {
                path: "order/:order/invoice-options",
                component: AdminOrderInvoiceOptions,
                name: "admin-order-invoice-options",
                meta: { admin: true },
              },
              {
                path: "order/:order/refund-options",
                component: AdminOrderRefundOptions,
                name: "admin-order-refund-options",
                meta: { admin: true },
              },

              {
                path: "order/:order/edit",
                component: AdminOrderEdit,
                name: "admin-order-edit",
                meta: { admin: true },
              },
              {
                path: "order/:order/logs",
                component: AdminOrderLogs,
                name: "admin-order-logs",
                meta: { admin: true },
              },
            ],
          },
          {
            path: "categorieen",
            component: AdminCategoryBase,
            meta: { admin: true },
            children: [
              {
                path: "",
                component: AdminCategories,
                name: "admin-categories",
                meta: { admin: true },
              },
              {
                path: "categorieen/:category",
                component: AdminCategory,
                name: "admin-category",
                meta: { admin: true },
              },
            ],
          },
          {
            path: "products",
            component: AdminProductBase,
            meta: { admin: true },
            children: [
              {
                path: "",
                component: AdminProducts,
                name: "admin-products",
                meta: { admin: true },
              },
              {
                path: ":product",
                component: AdminProduct,
                name: "admin-product",
                meta: { admin: true },
              },
              {
                path: ":product/aanpassen",
                component: AdminProductEdit,
                name: "admin-product-edit",
                meta: { admin: true },
              },
            ],
          },
          {
            path: "users",
            component: AdminUserBase,
            children: [
              {
                path: "",
                component: AdminUsers,
                name: "admin-users",
                meta: { admin: true },
              },
              {
                path: "user/:user",
                component: AdminUser,
                name: "admin-user",
                meta: { admin: true },
              },
            ],
          },
          {
            path: "invoices",
            component: AdminInvoiceBase,
            children: [
              {
                path: "",
                component: AdminInvoices,
                name: "admin-invoices",
                meta: { admin: true },
              },
              {
                path: "invoice/:invoice",
                component: AdminInvoice,
                name: "admin-invoice",
                meta: { admin: true },
              },
            ],
          },
          {
            path: "revenue",
            component: AdminRevenueBase,
            children: [
              {
                path: "",
                component: AdminRevenue,
                name: "admin-revenue",
                meta: { admin: true },
              },
            ],
          },
          {
            path: "prices",
            component: AdminPricesBase,
            meta: { admin: true },
            children: [
              {
                path: "",
                name: "admin-prices",
                component: AdminPrices,
                meta: { admin: true },
              },
              {
                path: "add-country",
                component: AdminAddCountry,
                name: "admin-prices-add-country",
                meta: { admin: true },
              },
              {
                path: "edit/:country",
                component: AdminAddCountry,
                name: "admin-prices-edit-country",
                meta: { admin: true },
              },

              {
                path: "add-shipping-method",
                component: AdminCountryAddShippingMethod,
                name: "admin-prices-add-shipping_method",
                meta: { admin: true },
              },
              {
                path: "edit-shipping-method",
                component: AdminCountryAddShippingMethod,
                name: "admin-prices-edit-shipping_method",
                meta: { admin: true },
              },
              {
                path: "add-payment-method",
                component: AdminCountryAddPaymentMethod,
                name: "admin-prices-add-payment_method",
                meta: { admin: true },
              },
              {
                path: "edit-payment-method",
                component: AdminCountryAddPaymentMethod,
                name: "admin-prices-edit-payment_method",
                meta: { admin: true },
              },
              {
                path: "country/:country",
                component: AdminCountry,
                name: "admin-prices-country",
                meta: { admin: true },
              },
            ],
          },
        ],
      },
      {
        path: "account",
        component: AccountBase,
        meta: { user: true },
        children: [
          {
            path: "",
            component: Account,
            name: "account",
            meta: { user: true },
          },
          {
            path: "orders",
            component: AccountOrderBase,
            meta: { user: true },
            children: [
              {
                path: "",
                component: AccountOrders,
                name: "account-orders",
                meta: { user: true },
              },
              {
                path: ":order",
                component: AccountOrder,
                name: "account-order",
                meta: { user: true },
              },
            ],
          },

          {
            path: "change-password",
            component: AccountChangePassword,
            name: "account-change-password",
            meta: { user: true },
          },
          {
            path: "delete-my-account",
            component: AccountDeleteUser,
            name: "account-delete-user",
            meta: { user: true },
          },
        ],
      },
      {
        path: "shop",
        component: WebshopBase,
        children: [
          {
            path: "",
            component: WebshopCategories,
            name: "webshop-categories",
          },
          {
            path: "order/:order",
            component: OrderRedirect,
            name: "order-redirect",
          },
          {
            path: "checkout",
            component: WebshopCheckout,
            name: "webshop-checkout",
          },
          {
            path: "cart",
            component: ShoppingCart,
            name: "shoppingcart",
          },
          {
            path: ":category",
            component: WebshopCategoryBase,
            children: [
              {
                path: "",
                component: WebshopCategory,
                name: "webshop-category",
              },
              {
                path: ":product",
                component: WebshopProduct,
                name: "webshop-product",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "*",
    component: FourZeroFour,
    name: "404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "is-active",
  linkExactActiveClass: "is-active",
  routes,
});

export default router;
