<style scoped>
.help {
  margin-top: 10px;
}

.columns {
  margin-bottom: 0px;
}

.column {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.marginBottom {
  margin-bottom: 12px;
}

.help {
  margin: 0px;
  padding: 0px;
}
</style>

<template>
  <div v-if="showRegisterModal">
    <div class="modal is-active">
      <div class="modal-background" @click.prevent="closeRegisterModal()"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Register account</p>
          <button
            class="delete"
            aria-label="close"
            @click.prevent="closeRegisterModal()"
          ></button>
        </header>
        <form action="" @submit.prevent="register()">
          <section class="modal-card-body">
            <formSelect
              v-if="settings && settings.countries.length"
              type="password"
              class="marginBottom"
              modelKey="country"
              :selectOptions="
                settings.countries.map(function (count) {
                  return count.name;
                })
              "
              label="on-border"
              size="is-small"
              :model="form"
            />

            <span v-if="form.country">
              <div class="columns">
                <div class="column is-6">
                  <formInput
                    class="marginBottom"
                    modelKey="name"
                    label="on-border"
                    size="is-small
                 "
                    :model="form"
                  />
                </div>
                <div class="column is-6">
                  <formInput
                    class="marginBottom"
                    modelKey="surname"
                    label="on-border"
                    size="is-small
                 "
                    :model="form"
                  />
                </div>
              </div>
              <formInput
                class="marginBottom"
                modelKey="email"
                label="on-border"
                size="is-small
                 "
                :model="form"
              />

              <formInput
                type="password"
                class="marginBottom"
                modelKey="password"
                label="on-border"
                size="is-small
                 "
                :model="form"
              />
              <formInput
                class="marginBottom"
                type="password"
                title="Repeat password"
                modelKey="password_confirmation"
                label="on-border"
                size="is-small
                 "
                :model="form"
              />
              <div v-if="form.password.length">
                <p
                  class="help"
                  :class="{ 'is-danger': form.password.length < 10 }"
                >
                  Password must be atleast 10 characters long.
                </p>
                <p
                  class="help"
                  :class="{
                    'is-danger':
                      this.form.password !== this.form.password_confirmation,
                  }"
                >
                  Passwords must match.
                </p>
                <br />
              </div>

              <!-- old address form -->
              <!-- <div class="columns">
                <div class="column">
                  <formInput
                    @blur="findAddress()"
                    class="marginBottom"
                    modelKey="postal_code"
                    title="Postal code"
                    label="on-border"
                    size="is-small
                 "
                    :model="form"
                  />
                </div>
                <div class="column">
                  <formInput
                    @blur="findAddress()"
                    class="marginBottom"
                    modelKey="house_number"
                    title="House number"
                    label="on-border"
                    size="is-small "
                    :model="form"
                  />
                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <formInput
                    class="marginBottom"
                    modelKey="street"
                    label="on-border"
                    size="is-small
                 "
                    :model="form"
                  />
                </div>
                <div class="column">
                  <formInput
                    class="marginBottom"
                    modelKey="city"
                    label="on-border"
                    size="is-small "
                    :model="form"
                  />
                </div>
              </div> -->

              <span v-if="!findObject.foundAddress">
                <formAutocomplete
                  :model="form"
                  label="on-border"
                  optionKey="description"
                  modelKey="autocomplete"
                  size="is-small"
                  notFoundText="No address found"
                  :options="addressOptions"
                  :openOnFocus="false"
                  title="Address"
                  v-on:input="findAddressId()"
                  v-on:selected="findAddressDetails"
                />
                <br />

                <formCheckbox
                  size="is-small"
                  :model="findObject"
                  :labelHidden="true"
                  modelKey="foundAddress"
                  title="Enter address manually"
                />
                <br />
                <br />
                <br />
                <br />
                <br />
              </span>
              <span v-else>
                <formInput
                  class="marginBottom"
                  :model="form"
                  modelKey="street"
                  label="on-border"
                  size="is-small"
                />
                <div class="columns">
                  <div class="column is-7">
                    <formInput
                      class="marginBottom"
                      :model="form"
                      modelKey="postal_code"
                      label="on-border"
                      title="Postal code"
                      size="is-small"
                    />
                  </div>
                  <div class="column is-5">
                    <formInput
                      class="marginBottom"
                      :model="form"
                      modelKey="house_number"
                      label="on-border"
                      title="House number"
                      size="is-small"
                    />
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <formInput
                      class="marginBottom"
                      :model="form"
                      modelKey="city"
                      label="on-border"
                      size="is-small"
                    />
                  </div>
                  <div class="column">
                    <formInput
                      class="marginBottom"
                      :model="form"
                      modelKey="state"
                      label="on-border"
                      size="is-small"
                    />
                  </div>
                </div>
              </span>
            </span>
          </section>
          <footer class="modal-card-foot" v-if="form.country">
            <form-button
              type="is-primary"
              title="Register"
              size="is-small"
              loadingKey="register"
            />
            <button
              class="button is-rounded is-small"
              @click.prevent="closeRegisterModal()"
            >
              Cancel
            </button>
          </footer>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  components: {},
  props: [],
  data() {
    return {
      form: {
        password: "",
        password_confirmation: null,
      },
      addressOptions: [],
      findObject: {
        foundAddress: false,
      },
    };
  },
  computed: {
    settings() {
      return this.$store.getters["shop/settings"];
    },
    showLoginModal() {
      return this.$store.getters["auth/loginModal"];
    },
    showRegisterModal() {
      return this.$store.getters["auth/registerModal"];
    },
  },
  methods: {
    async findAddressDetails(address) {
      if (address) {
        const addressDetails = await this.findAddressDetailsMixin(address);

        if (addressDetails.street) {
          this.form.street = addressDetails.street;
        }

        if (addressDetails.house_number) {
          this.form.house_number = addressDetails.house_number;
        }

        if (addressDetails.postal_code) {
          this.form.postal_code = addressDetails.postal_code;
        }

        if (addressDetails.city) {
          this.form.city = addressDetails.city;
        }

        if (addressDetails.state) {
          this.form.state = addressDetails.state;
        }

        this.findObject.foundAddress = true;
      }
    },
    closeRegisterModal() {
      this.$store.dispatch("clearValidationErrors");
      this.findObject.foundAddress = false;
      return this.$store.dispatch("auth/toggleRegisterModal", false);
    },
    async findAddress() {
      if (this.form.postal_code && this.form.house_number) {
        const address = await this.findAddressMixin(
          this.form.postal_code,
          this.form.house_number
        );

        if (address) {
          Vue.set(this.form, "street", address.data.street);
          Vue.set(this.form, "city", address.data.city);
        }
      }
    },
    async findAddressId() {
      var country = {};

      if (this.form.country) {
        country = this.settings.countries.find(
          (a) => a.name === this.form.country
        );
      }

      const places = await this.findGooglePlaceIdMixin(
        this.form.autocomplete,
        country
      );
      this.addressOptions = places.predictions;
    },
    register() {
      return this.$store.dispatch("auth/register", this.form).then(() => {
        this.form = {
          password: "",
          password_confirmation: null,
        };
        this.closeRegisterModal();
      });
    },
  },
  mounted() {
    this.$store.dispatch("clearValidationErrors");
    this.findObject.foundAddress = false;
  },
};
</script>
