<style scoped>
.column {
  margin-bottom: 0px;
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.title {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
</style>

<template>
  <div>
    <button class="button is-fullwidth" @click="$router.go(-1)">
      Back to invoices
    </button>
    <br />

    <span v-if="invoice && invoice.id">
      <div class="title is-4">Invoice: {{ invoice.number }}</div>

      <!-- invoice base -->
      <div class="box">
        <span class="title is-5">Base</span>

        <span class="pointer is-pulled-right" @click="showBaseModal = true">
          <b-tooltip label="Edit base invoice details" position="is-left">
            <b-icon icon="edit" />
          </b-tooltip>
        </span>

        <span class="pointer is-pulled-right" @click="deleteInvoice()">
          <b-tooltip label="Delete invoice" position="is-left">
            <b-icon icon="trash" />
          </b-tooltip>
        </span>

        <span
          class="pointer is-pulled-right"
          @click.middle="openPdf(invoice)"
          @click="openPdf(invoice)"
        >
          <b-tooltip label="Download invoice" position="is-left">
            <b-icon icon="file-pdf" />
          </b-tooltip>
        </span>

        <span
          class="pointer is-pulled-right"
          @click="showInvoiceSendModal = true"
        >
          <b-tooltip label="Mail invoice" position="is-left">
            <b-icon icon="envelope" />
          </b-tooltip>
        </span>

        <span
          class="pointer is-pulled-right"
          v-if="!invoice.invoice_send"
          @click="preMarkInvoiceAsSend()"
        >
          <b-tooltip label="Mark invoice as send" position="is-left">
            <b-icon icon="paper-plane" />
          </b-tooltip>
        </span>

        <br />
        <br />
        <div class="columns">
          <div class="column">Order:</div>
          <div class="column">
            <a @click="showOrder(invoice.order_id)"
              >Order: {{ invoice.order_id }}</a
            >
          </div>
        </div>
        <div class="columns">
          <div class="column">Invoice number:</div>
          <div class="column">
            {{ invoice.number }}
          </div>
        </div>
        <div class="columns">
          <div class="column">Invoice date:</div>
          <div class="column">
            {{ formatDatePickerDate(invoice.invoice_date) }}
          </div>
        </div>
        <div class="columns">
          <div class="column">Invoice send:</div>
          <div class="column">
            {{ formatDatePickerDate(invoice.invoice_send) }}
          </div>
        </div>
        <div class="columns">
          <div class="column">invoice text <br /></div>
          <div class="column">
            {{ invoice.invoice_text }}
          </div>
        </div>
      </div>

      <div class="columns">
        <!-- invoice customer -->
        <div class="column">
          <div class="box">
            <span class="title is-5">Customer</span>
            <span class="pointer is-pulled-right">
              <b-tooltip
                label="Edit customer invoice details"
                position="is-left"
              >
                <b-icon icon="edit" @click.native="showCustomerModal = true" />
              </b-tooltip>
            </span>
            <br />
            {{ invoice.customer_business_name }}
            <br v-if="invoice.customer_business_name" />
            {{ invoice.customer_name }} {{ invoice.customer_surname }} <br />
            {{ invoice.customer_email }} <br />
            {{ invoice.customer_street }} {{ invoice.customer_house_number }}
            <br />
            {{ invoice.customer_postal_code }} {{ invoice.customer_city }}
            <br />
            {{ invoice.customer_state }}, {{ invoice.customer_country }}
          </div>
        </div>
        <div class="column">
          <div class="box">
            <span class="title is-5">Seller</span>
            <span class="pointer is-pulled-right">
              <b-tooltip label="Edit seller invoice details" position="is-left">
                <b-icon icon="edit" @click.native="showSellerModal = true" />
              </b-tooltip>
            </span>
            <br />
            {{ invoice.company_name }} <br />
            {{ invoice.company_contact_email }} <br />
            {{ invoice.company_street }} {{ invoice.company_house_number }}
            <br />
            {{ invoice.company_postal_code }} {{ invoice.company_city }}
          </div>
        </div>
      </div>

      <!-- invoice products -->
      <detailsTable :invoice="invoice" />

      <!-- totals -->
      <div class="box">
        <span class="title is-5">Totals</span>
        <span class="pointer is-pulled-right">
          <b-tooltip label="Edit financial invoice details" position="is-left">
            <b-icon icon="edit" @click.native="showTotalModal = true" />
          </b-tooltip>
        </span>
        <br />
        <br />
        <div class="columns">
          <div class="column">Subtotal</div>
          <div class="column">
            {{ euroFromCents(invoice.subtotal) }}
          </div>
        </div>
        <div class="columns">
          <div class="column">Shipping</div>
          <div class="column">
            {{ euroFromCents(invoice.shipping_costs) }}
          </div>
        </div>
        <div class="columns">
          <div class="column">Payment</div>
          <div class="column">
            {{ euroFromCents(invoice.payment_costs) }}
          </div>
        </div>
        <div class="columns" v-if="invoice.tax_low_percentage !== 'geen'">
          <div class="column">Tax {{ invoice.tax_low_percentage }}%</div>
          <div class="column">
            {{ euroFromCents(invoice.tax_low) }}
          </div>
        </div>
        <div class="columns" v-if="invoice.tax_high_percentage !== 'geen'">
          <div class="column">Tax {{ invoice.tax_high_percentage }}%</div>
          <div class="column">
            {{ euroFromCents(invoice.tax_high) }}
          </div>
        </div>
        <div class="columns">
          <div class="column">Total</div>
          <div class="column">
            {{ euroFromCents(invoice.total_price) }}
          </div>
        </div>
      </div>
    </span>

    <customerModal
      v-if="showCustomerModal"
      v-on:close="showCustomerModal = false"
      :invoice="invoice"
    />

    <sellerModal
      v-if="showSellerModal"
      v-on:close="showSellerModal = false"
      :invoice="invoice"
    />

    <baseModal
      v-if="showBaseModal"
      v-on:close="showBaseModal = false"
      :invoice="invoice"
    />

    <totalModal
      v-if="showTotalModal"
      v-on:close="showTotalModal = false"
      :invoice="invoice"
    />

    <customMailEditor
      v-if="showInvoiceSendModal"
      :invoice="invoice"
      vuexStoreAction="adminInvoices/sendInvoiceToCustomer"
      v-on:close="showInvoiceSendModal = false"
      settingsDefaultEmailKey="extra_invoice_email"
      settingsDefaultEmailSubjectKey="extra_invoice_subject"
      :attachment="
        settings.variables.find((a) => a.key === 'company_name').value +
        ' ' +
        invoice.number
      "
      loadingKey="send_invoice"
    />
  </div>
</template>

<script>
import detailsTable from "./AdminInvoiceDetailTable.vue";
import customerModal from "./AdminInvoiceCustomerModal.vue";
import sellerModal from "./AdminInvoiceSellerModal.vue";
import baseModal from "./AdminInvoiceBaseModal.vue";
import totalModal from "./AdminInvoiceTotalModal.vue";
import customMailEditor from "../orders/CustomMailEditorModal.vue";

export default {
  components: {
    detailsTable,
    customerModal,
    sellerModal,
    baseModal,
    customMailEditor,
    totalModal,
  },
  props: [],
  data() {
    return {
      showCustomerModal: false,
      showSellerModal: false,
      showBaseModal: false,
      showTotalModal: false,
      showInvoiceSendModal: false,
      env: process.env,
    };
  },
  computed: {
    settings() {
      return this.$store.getters["shop/settings"];
    },
    invoices() {
      return this.$store.getters["adminInvoices/invoices"];
    },
    invoice() {
      return this.invoices.find(
        (a) => a.id == this.$router.currentRoute.params.invoice
      );
    },
  },
  methods: {
    showOrder(orderId) {
      this.$router.push({ name: "admin-order", params: { order: orderId } });
    },
    openPdf(invoice) {
      window.open(
        this.env.VUE_APP_API_URL + "/web/show-user-invoice/" + invoice.id
      );
    },
    preMarkInvoiceAsSend() {
      this.$buefy.dialog.confirm({
        title: "Mark invoice as send",
        message:
          "Do you want to mark this invoice as send? <br> The invoice will not be send to the customer.",
        type: "is-info",
        hasIcon: true,
        onConfirm: () =>
          this.$store
            .dispatch("adminInvoices/markInvoiceAsSend", this.invoice)
            .then(() => {
              this.$buefy.toast.open("Invoice marked as send!");
            }),
      });
    },
    deleteInvoice() {
      if (this.invoice.invoice_number > 0) {
        this.$buefy.dialog.confirm({
          title: "Deleting invoice",
          message:
            "Are you sure you want to <b>delete</b> this invoice? This action cannot be undone.",
          confirmText: "Delete",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () =>
            this.$store
              .dispatch("adminInvoices/deleteInvoice", this.invoice)
              .then(() => {
                this.$buefy.toast.open("invoice deleted");
                this.$router.go(-1);
              }),
        });
      } else {
        this.$store
          .dispatch("adminInvoices/deleteInvoice", this.invoice)
          .then(() => {
            this.$buefy.toast.open("invoice deleted");
            this.$router.go(-1);
          });
      }
    },
  },
  mounted() {
    if (!this.$router.currentRoute.params.invoice) {
      this.$router.go(-1);
    }
  },
};
</script>
