<template>
  <div class="box">
    <section>
      <div class="content" style="color: hsl(0deg, 0%, 21%)">
        <b-field>
          <b-radio v-model="refundType" native-value="balance">
            Refund order balance
          </b-radio>
        </b-field>

        <b-field>
          <b-radio
            v-model="refundType"
            native-value="partial"
            @input="setRefund()"
          >
            Partial refund
          </b-radio>
        </b-field>

        <b-field>
          <b-radio
            v-model="refundType"
            native-value="manual"
            @input="setRefund()"
          >
            Manual refund
          </b-radio>
        </b-field>

        <span v-if="refundType === 'partial' || refundType === 'manual'">
          <br />

          <formInput
            @input="setRefundAmount()"
            label="on-border"
            title="Enter refund amount"
            size="is-small"
            type="is-primary"
            :model="form"
            modelKey="editrefundAmount"
            validationKey="refundAmount"
          />
        </span>

        <br />

        <formTextarea
          v-if="refundType !== 'manual'"
          label="on-border"
          title="Set refund message for banktransaction"
          size="is-small"
          type="is-primary"
          :max="140"
          :model="form"
          :value="'Refund for order: ' + order.number"
          modelKey="refundMessage"
        />

        <formTextarea
          v-if="refundType === 'manual'"
          label="on-border"
          title="Refund description"
          size="is-small"
          type="is-primary"
          :max="255"
          :model="form"
          :value="'Manual refund for order: ' + order.number"
          modelKey="refundMessage"
        />

        <hr />

        <div class="columns">
          <div class="column">Order total</div>
          <div class="column has-text-right">
            {{ euroFromCents(order.total_price) }}
          </div>
        </div>

        <div class="columns">
          <div class="column">Paid</div>
          <div class="column has-text-right">
            {{ euroFromCents(paid) }}
          </div>
        </div>

        <div class="columns">
          <div class="column">Refunded</div>
          <div class="column has-text-right">{{ euroFromCents(refunded) }}</div>
        </div>
        <hr />

        <div class="columns">
          <div class="column"><strong>Balance before refund</strong></div>
          <div class="column has-text-right">
            {{ euroFromCents(order.balance) }}
          </div>
        </div>

        <div class="columns">
          <div class="column"><strong>To be refunded</strong></div>
          <div class="column has-text-right">
            {{ euroFromCents(toBeRefunded) }}
          </div>
        </div>

        <div class="columns">
          <div class="column"><strong>Balance after refund</strong></div>
          <div class="column has-text-right">
            {{ euroFromCents(balanceAfterRefund) }}
          </div>
        </div>
      </div>
      <div class="columns is-gapless">
        <div class="column" @click="preRefund()">
          <button
            :disabled="checkDisabledRefund()"
            class="button is-fullwidth is-primary"
            :class="ajaxloading.refunding"
          >
            Refund
          </button>
        </div>
        <div class="column">
          <button @click="cancel()" class="button is-fullwidth">Cancel</button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    order: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      form: {
        refundAmount: 0,
        editrefundAmount: 0,
      },
      refundType: "balance",
    };
  },
  computed: {
    toBeRefunded() {
      if (this.refundType === "balance") {
        if (this.order.balance >= 0) {
          return 0;
        } else {
          return -this.order.balance;
        }
      } else {
        return this.form.refundAmount;
      }
    },
    balanceAfterRefund() {
      if (this.refundType === "balance") {
        return this.order.balance + this.toBeRefunded;
      } else {
        return this.order.balance + this.toBeRefunded;
      }
    },
    refunded() {
      if (this.order.order_payments) {
        var refunded = 0;
        this.order.order_payments.forEach(
          (a) => (refunded = refunded + a.refunded)
        );
        return refunded;
      } else {
        return 0;
      }
    },
    paid() {
      if (this.order.order_payments) {
        var paid = 0;
        this.order.order_payments.forEach((a) => (paid = paid + a.paid));
        return paid;
      } else {
        return 0;
      }
    },
    settings() {
      return this.$store.getters["shop/settings"];
    },
  },
  methods: {
    setRefund() {
      if (this.order.balance >= 0) {
        this.form.editrefundAmount = 0;
        this.form.refundAmount = 0;
        return;
      }
      this.form.editrefundAmount = this.euroFromCentsWithoutSymbol(
        -this.order.balance
      );
      this.form.refundAmount = -this.order.balance;
    },
    checkDisabledRefund() {
      if (this.toBeRefunded <= 0) {
        return true;
      } else {
        return false;
      }
    },
    closeModal() {
      this.$emit("close", true);
    },
    setRefundAmount() {
      this.setPrice(this.form, "editrefundAmount", "refundAmount");

      if (this.paid < this.toBeRefunded + this.refunded) {
        this.form.editrefundAmount = (this.paid - this.refunded) / 100;
        this.form.refundAmount = this.paid - this.refunded;
      }
    },
    formatPrices() {
      this.setPrice(this.product, "edit_exbtw", "exbtw");
      this.setPrice(this.product, "edit_exbtw", "price");
    },
    refundPayment() {
      this.$store
        .dispatch("adminOrders/refundOrder", {
          order: this.order,
          refundType: this.refundType,
          refundAmount: this.form.refundAmount,
          refundMessage: this.form.refundMessage,
        })
        .then(() => {
          this.$buefy.toast.open(
            "Succesfully refunded " +
              this.euroFromCents(this.form.refundAmount) +
              " ."
          );
          this.$router.go(-1);
        });
    },
    cancel() {
      this.$store.dispatch("adminOrders/getOrders");
      this.$router.go(-1);
    },
    preRefund() {
      this.$buefy.dialog.confirm({
        title: "Deleting product",
        message:
          "Are you sure you want to refund this payment? <br> A total of " +
          this.euroFromCents(this.toBeRefunded) +
          " will be refunded.",
        confirmText: "Refund payment",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.refundPayment(),
      });
    },
  },
  mounted() {},
};
</script>
