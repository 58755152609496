<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}

.table-icons {
  float: right;
}

.box {
  border-color: #333 !important;
  border: solid;
  border-width: thin;
  /* Set the hr color */
}

img {
  max-height: 150px;
}

hr {
  margin-top: 0px;
  border: none;
  height: 1px;
  /* Set the hr color */
  color: #333; /* old IE */
  background-color: #333; /* Modern Browsers */
}
</style>

<template>
  <div>
    <div class="columns">
      <div class="column">
        <div class="title is-4">Order: {{ formatOrderNumber(order) }}</div>
        <div class="subtitle" v-if="order.id">
          Placed on {{ formatDatabaseDate(order.created_at) }}
        </div>

        <button class="button is-fullwidth" @click="$router.go(-1)">
          Back to order
        </button>
        <br />

        <div class="box content">
          <strong>Shipping logs:</strong>
          <br />
          <br />
          <b-table
            :data="orderShippingLogs"
            :bordered="true"
            :striped="true"
            :narrowed="true"
          >
            <b-table-column label="Date" v-slot="props">
              {{ formatDatabaseDate(props.row.created_at) }}
              {{ formatDatabaseTime(props.row.created_at) }}
            </b-table-column>

            <b-table-column label="Source" v-slot="props">
              <span
                v-if="
                  props.row.source === 'user' &&
                  props.row.user &&
                  props.row.user.id
                "
              >
                {{ props.row.user.name }} {{ props.row.user.surname }}
              </span>
              <span v-else>
                {{ props.row.source }}
              </span>
            </b-table-column>

            <b-table-column label="Old" v-slot="props">
              <span style="text-decoration: line-through">{{
                props.row.old_value
              }}</span>
            </b-table-column>

            <b-table-column label="New" v-slot="props">
              {{ props.row.new_value }}
            </b-table-column>
          </b-table>
          <br />
        </div>

        <div class="box content">
          <strong>Payments:</strong>
          <br />
          <br />
          <b-table
            :data="order.order_payments"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            detailed
            detail-key="id"
            detail-transition="fade"
          >
            <b-table-column label="id" v-slot="props">
              {{ props.row.payment_id }}
            </b-table-column>

            <b-table-column label="date" v-slot="props">
              {{ formatDatabaseDate(props.row.created_at) }}
            </b-table-column>

            <b-table-column label="Paid" v-slot="props">
              {{ euroFromCents(props.row.paid) }}
            </b-table-column>

            <b-table-column label="Refunded" v-slot="props">
              {{ euroFromCents(props.row.refunded) }}
            </b-table-column>

            <b-table-column label="Balance" v-slot="props">
              {{ euroFromCents(props.row.paid - props.row.refunded) }}
            </b-table-column>

            <b-table-column label="Method" v-slot="props">
              {{ props.row.payment_method }}
            </b-table-column>

            <b-table-column label="Status" v-slot="props">
              {{ props.row.status }}
            </b-table-column>

            <template #detail="rows">
              <span v-if="rows.row.payment_method !== 'manual_refund'">
                <div class="title is-5">Refunds</div>
                <b-table
                  :data="rows.row.refunds"
                  :bordered="true"
                  :striped="true"
                  :narrowed="true"
                >
                  <b-table-column label="Id" v-slot="props">
                    {{ props.row.refund_id }}
                  </b-table-column>

                  <b-table-column label="Refunder" v-slot="props">
                    {{ props.row.user.name }}
                  </b-table-column>

                  <b-table-column label="Date" v-slot="props">
                    {{ formatDatabaseDate(props.row.created_at) }}
                  </b-table-column>

                  <b-table-column label="Amount" v-slot="props">
                    {{ euroFromCents(props.row.amount) }}
                  </b-table-column>

                  <b-table-column label="Status" v-slot="props">
                    {{ props.row.status }}
                  </b-table-column>
                </b-table>
              </span>
              <span v-else>
                Note: {{ rows.row.payment_id }} <br />
                Refunded by {{ rows.row.user.name }} <br /><br />
              </span>
            </template>
          </b-table>
          <br />
        </div>

        <div class="box content">
          <strong>Payment logs:</strong>
          <br />
          <br />
          <b-table
            :data="orderPaymentLogs"
            :bordered="true"
            :striped="true"
            :narrowed="true"
          >
            <b-table-column label="Date" v-slot="props">
              {{ formatDatabaseDate(props.row.created_at) }}
              {{ formatDatabaseTime(props.row.created_at) }}
            </b-table-column>

            <b-table-column label="Source" v-slot="props">
              <span
                v-if="
                  props.row.source === 'user' &&
                  props.row.user &&
                  props.row.user.id
                "
              >
                {{ props.row.user.name }} {{ props.row.user.surname }}
              </span>
              <span v-else>
                {{ props.row.source }}
              </span>
            </b-table-column>

            <b-table-column label="Old" v-slot="props">
              <span style="text-decoration: line-through">{{
                props.row.old_value
              }}</span>
            </b-table-column>

            <b-table-column label="New" v-slot="props">
              {{ props.row.new_value }}
            </b-table-column>
          </b-table>
          <br />
        </div>

        <div class="box content">
          <strong>Order logs:</strong>
          <br />
          <br />
          <b-table
            :data="orderLogs"
            :bordered="true"
            :striped="true"
            :narrowed="true"
          >
            <b-table-column label="Date" v-slot="props">
              {{ formatDatabaseDate(props.row.created_at) }}
              {{ formatDatabaseTime(props.row.created_at) }}
            </b-table-column>

            <b-table-column label="Source" v-slot="props">
              <span
                v-if="
                  props.row.source === 'user' &&
                  props.row.user &&
                  props.row.user.id
                "
              >
                {{ props.row.user.name }} {{ props.row.user.surname }}
              </span>
              <span v-else>
                {{ props.row.source }}
              </span>
            </b-table-column>

            <b-table-column label="Field" v-slot="props">
              {{ props.row.field }}
            </b-table-column>

            <b-table-column label="Old" v-slot="props">
              <span style="text-decoration: line-through">{{
                props.row.old_value
              }}</span>
            </b-table-column>

            <b-table-column label="New" v-slot="props">
              {{ props.row.new_value }}
            </b-table-column>
          </b-table>
          <br />
        </div>

        <div class="box content">
          <strong>Order Product logs:</strong>
          <br />
          <br />
          <b-table
            :data="orderProductLogs"
            :bordered="true"
            :striped="true"
            :narrowed="true"
          >
            <b-table-column label="Date" v-slot="props">
              {{ formatDatabaseDate(props.row.created_at) }}
              {{ formatDatabaseTime(props.row.created_at) }}
            </b-table-column>

            <b-table-column label="Source" v-slot="props">
              <span
                v-if="
                  props.row.source === 'user' &&
                  props.row.user &&
                  props.row.user.id
                "
              >
                {{ props.row.user.name }} {{ props.row.user.surname }}
              </span>
              <span v-else>
                {{ props.row.source }}
              </span>
            </b-table-column>

            <b-table-column label="Product" v-slot="props">
              {{ props.row.order_product_id }}
            </b-table-column>

            <b-table-column label="Field" v-slot="props">
              {{ props.row.field }}
            </b-table-column>

            <b-table-column label="Old" v-slot="props">
              <span style="text-decoration: line-through">{{
                props.row.old_value
              }}</span>
            </b-table-column>

            <b-table-column label="New" v-slot="props">
              {{ props.row.new_value }}
            </b-table-column>
          </b-table>
          <br />
        </div>

        <div class="box content">
          <strong>Email logs:</strong>
          <br />
          <br />
          <b-table
            :data="orderEmailLogs"
            :bordered="true"
            :striped="true"
            :narrowed="true"
          >
            <b-table-column label="Date" v-slot="props">
              {{ formatDatabaseDate(props.row.created_at) }}
              {{ formatDatabaseTime(props.row.created_at) }}
            </b-table-column>

            <b-table-column label="Source" v-slot="props">
              <span
                v-if="
                  props.row.source === 'user' &&
                  props.row.user &&
                  props.row.user.id
                "
              >
                {{ props.row.user.name }} {{ props.row.user.surname }}
              </span>
              <span v-else>
                {{ props.row.source }}
              </span>
            </b-table-column>

            <b-table-column label="Email" v-slot="props">
              {{ props.row.field }}
            </b-table-column>

            <b-table-column label="From" v-slot="props">
              {{ props.row.old_value }}
            </b-table-column>

            <b-table-column label="To" v-slot="props">
              {{ props.row.new_value }}
            </b-table-column>
          </b-table>
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      env: process.env,
      sendOrderPickupMail: false,
    };
  },
  computed: {
    totalPaid() {
      if (this.order && this.order.id) {
        var total = 0;
        this.order.order_payments.forEach((a) => {
          if (a.status === "paid") {
            total = total + a.paid;
          }
        });
        return total;
      } else {
        return 0;
      }
    },
    orders() {
      return this.$store.getters["adminOrders/orders"];
    },
    order() {
      if (this.orders && this.orders.length) {
        var order = this.orders.find(
          (a) => a.id == this.$router.currentRoute.params.order
        );
      }

      if (order) {
        return order;
      } else {
        return {};
      }
    },
    orderLogs() {
      if (this.order && this.order.logs) {
        return this.order.logs
          .filter(
            (a) =>
              a.field !== "payment_status" &&
              a.field !== "shipping_status" &&
              a.type !== "EMAIL" &&
              !a.order_product_id
          )
          .sort((a, b) => {
            return b.id - a.id;
          });
      } else {
        return [];
      }
    },
    orderProductLogs() {
      if (this.order && this.order.logs) {
        return this.order.logs
          .filter((a) => a.order_product_id)
          .sort((a, b) => {
            return b.id - a.id;
          });
      } else {
        return [];
      }
    },
    orderPaymentLogs() {
      if (this.order && this.order.logs) {
        return this.order.logs
          .filter((a) => a.field === "payment_status")
          .sort((a, b) => {
            return b.id - a.id;
          });
      } else {
        return [];
      }
    },
    orderEmailLogs() {
      if (this.order && this.order.logs) {
        return this.order.logs
          .filter((a) => a.type === "EMAIL")
          .sort((a, b) => {
            return b.id - a.id;
          });
      } else {
        return [];
      }
    },
    orderShippingLogs() {
      if (this.order && this.order.logs) {
        return this.order.logs
          .filter((a) => a.field === "shipping_status")
          .sort((a, b) => {
            return b.id - a.id;
          });
      } else {
        return [];
      }
    },
  },
  methods: {
    setNewStatus() {
      const status = this.order.shipping_status;
      if (status === "ready for pickup") {
        this.sendOrderPickupMail = true;
      }

      if (status === "ready for pickup") {
        this.sendOrderPickupMail = true;
      }

      const data = {
        order: this.order,
        status: status,
      };
      this.$store.dispatch("adminOrders/changeOrderShippingStatus", data);
    },
    openPdf(invoice) {
      window.open(
        this.env.VUE_APP_API_URL + "/web/show-user-invoice/" + invoice.id
      );
    },
    resendOrderConfirmedMail() {
      this.$buefy.dialog.confirm({
        title: "Resend email?",
        message: "Do you wish to resend the order confirmation email?",
        onConfirm: () =>
          this.$store.dispatch(
            "adminOrders/resendOrderConfirmedEmail",
            this.order
          ),
      });
    },

    formatOrderNumber(order) {
      if (!this.order.id) {
        return;
      }

      return order.order_year + "-" + order.order_number;
    },
  },
  mounted() {},
};
</script>
