export const createShippingLabel = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-shipping-create-label";
  data.method = "post";
  data.loading = "create_shipping_label";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setNewShippingLabel", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getShippingLabels = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-get-shipping-labels";
  data.method = "get";
  data.loading = "get_shipping_labels";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setShippingLabels", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const deleteShippingLabel = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-shipping-delete-label/" + payload.id;
  data.method = "post";
  data.loading = "delete-shipping-label";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("removeShippingLabel", payload);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const registerShippingLabel = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-register-order-shipping-label/" + payload.id;
  data.method = "post";
  data.loading = "register_shipping_label";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("updateShippingLabel", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
