export default () => ({
  products: [],
  product: {},
  uploadPercentage: 0,
  btw: {
    nederland: {
      percentages: {
        high: 21,
        low: 9,
      },
    },
  },
});
