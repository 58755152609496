<style scoped>
.customInput {
  margin-bottom: 15px;
}
.columns {
  margin: 0px;
  padding: 0px;
}

.column {
  margin: 0px;
  padding: 0px;
}
</style>

<template>
  <div class="modal is-active">
    <div class="modal-background" @click="closeModal()"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Edit Invoice</p>
        <button
          class="delete"
          aria-label="close"
          @click="closeModal()"
        ></button>
      </header>
      <section class="modal-card-body">
        <div class="columns">
          <div class="column">
            <formInput
              title="Shipping costs"
              class="customInput"
              :model="invoice"
              modelKey="shipping_costs"
              label="on-border"
            />
          </div>
          <div class="column">
            <span class="is-pulled-right">{{
              euroFromCents(invoice.shipping_costs)
            }}</span>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <formInput
              title="Payment costs"
              class="customInput"
              :model="invoice"
              modelKey="payment_costs"
              label="on-border"
            />
          </div>
          <div class="column">
            <span class="is-pulled-right">{{
              euroFromCents(invoice.payment_costs)
            }}</span>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <formInput
              title="Tax low percentage"
              class="customInput"
              :model="invoice"
              modelKey="tax_low_percentage"
              label="on-border"
            />
          </div>
          <div class="column">
            <span
              v-if="invoice.tax_low_percentage !== 'geen'"
              class="is-pulled-right"
            >
              {{ invoice.tax_low_percentage }}%
            </span>
            <span v-else class="is-pulled-right">
              {{ invoice.tax_low_percentage }}
            </span>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <formInput
              title="Tax high percentage"
              class="customInput"
              :model="invoice"
              modelKey="tax_high_percentage"
              label="on-border"
            />
          </div>
          <div class="column">
            <span
              v-if="invoice.tax_high_percentage !== 'geen'"
              class="is-pulled-right"
            >
              {{ invoice.tax_high_percentage }}%
            </span>
            <span v-else class="is-pulled-right">
              {{ invoice.tax_high_percentage }}
            </span>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          class="button is-rounded is-primary"
          :class="{ 'is-loading': ajaxloading.update_invoice }"
          @click="saveBaseDetails()"
        >
          Save
        </button>
        <button
          class="button is-rounded"
          :class="{ 'is-loading': ajaxloading.update_invoice }"
          @click="cancelUpdate()"
        >
          Cancel
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    invoice: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    saveBaseDetails() {
      this.$store
        .dispatch("adminInvoices/saveInvoiceFinancialDetails", this.invoice)
        .then(() => {
          this.$buefy.toast.open("Invoice updated");
          this.closeModal();
        });
    },
    cancelUpdate() {
      this.$store
        .dispatch("adminInvoices/cancelUpdate", this.invoice)
        .then(() => {
          this.closeModal();
        });
    },
  },
  mounted() {},
};
</script>
