<template>
  <div>
    <div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            Order {{ order.order_year }}-{{ order.order_number }}
          </p>
        </header>
        <section class="modal-card-body">
          <div class="content" style="color: black">
            An unpaid order with {{ order.product_count }}
            <span v-if="order.product_count === 1">item</span
            ><span v-else>items</span>, totalling
            {{ euroFromCents(order.total_price) }} has been found. <br />Do you
            wish to finish placing this order?<br />
            <br />
            Order {{ order.order_year }}-{{ order.order_number }} expires after
            {{ formatTimeRemaining() }}
            <br />
            <br />

            <span v-if="order.id">
              <div
                class="columns is-mobile"
                v-for="index in order.order_products.length"
                :key="index"
              >
                <div class="column is-2">
                  <b-image
                    ratio="1by1"
                    :src="
                      env.VUE_APP_API_URL +
                      '/web/picture/' +
                      order.order_products[index - 1].picture_uuid
                    "
                    alt="Product Image"
                  />
                </div>
                <div class="column is-6 overflow">
                  <span>
                    {{ order.order_products[index - 1].name }}
                  </span>
                </div>
                <div class="column is-2 overflow">
                  <span> {{ order.order_products[index - 1].amount }}x </span>
                </div>
                <div class="column is-2 has-text-right">
                  <span>
                    {{
                      euroFromCents(
                        order.order_products[index - 1].price *
                          order.order_products[index - 1].amount
                      )
                    }}
                  </span>
                </div>
              </div>
            </span>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button
            @click="checkoutFromCookie()"
            :class="{ 'is-loading': ajaxloading.finishing_old_order }"
            class="button is-primary is-rounded"
          >
            Pay order
          </button>
          <button
            :class="{ 'is-loading': ajaxloading.finishing_old_order }"
            class="button is-rounded"
            @click="cancelOrder()"
          >
            Cancel order
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";

export default {
  components: {},
  props: {
    order: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      env: process.env,
      timeRemaining: 900,
    };
  },
  computed: {},
  methods: {
    checkoutFromCookie() {
      this.$store
        .dispatch("cart/checkoutFromCookie", this.order)
        .then((response) => {
          this.$store.dispatch("cart/clearCart");
          location.assign(response.data);
        });
    },
    cancelOrder() {
      this.$store.dispatch("cart/cancelOrder", this.order).then(() => {
        this.$store.dispatch("cart/clearCart").then(() => {
          this.$router.push({ name: "webshop-categories" });
        });
      });
    },
    startCountDown() {
      setInterval(
        function () {
          this.timeRemaining--;
        }.bind(this),
        1000
      );
    },
    setTimeRemaining() {
      // const expires = DateTime.now().plus({ minutes: 15 });
      const expires = DateTime.fromISO(this.order.created_at).plus({
        minutes: 15,
      });

      const timeRemaining = Math.floor(
        (expires.toMillis() - DateTime.now().toMillis()) / 1000
      );

      this.timeRemaining = timeRemaining;
    },
    formatTimeRemaining() {
      const minutes = Math.floor(this.timeRemaining / 60);
      var seconds = this.timeRemaining - minutes * 60;
      if (seconds < 10) {
        seconds = "0" + seconds;
      }

      return minutes + ":" + seconds;
    },
  },
  watch: {
    timeRemaining: {
      deep: false,
      handler() {
        if (this.timeRemaining === 0) {
          this.cancelOrder();
        }
      },
    },
  },
  mounted() {
    this.setTimeRemaining();
    this.startCountDown();
  },
};
</script>
