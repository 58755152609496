<template>
  <div>
    <div class="title is-5">Order {{ order.number }} refund options</div>
    <button class="button is-fullwidth" @click="$router.go(-1)">
      Back to order
    </button>
    <br />
    <div class="box">
      <div class="columns">
        <div class="column">
          Payment method:
          <span class="is-pulled-right">{{ order.payment_method }}</span>
          <br />
          Payment status:
          <span class="is-pulled-right">{{ order.payment_status }}</span>

          <br />
          <br />

          <strong>
            Total:
            <span class="is-pulled-right">{{
              euroFromCentsWithoutDash(order.total_price)
            }}</span></strong
          >
          <br />

          Paid:
          <span class="is-pulled-right">{{
            euroFromCentsWithoutDash(order.total_price - order.balance)
          }}</span>

          <br />
          <strong>
            Balance:
            <span class="is-pulled-right">{{
              euroFromCentsWithoutDash(order.balance)
            }}</span></strong
          >
          <br />
        </div>
      </div>
    </div>
    <div class="box content">
      <strong>Payments:</strong>
      <br />
      <br />
      <b-table
        :data="order.order_payments"
        :bordered="true"
        :striped="true"
        :narrowed="true"
        detailed
        detail-key="id"
        detail-transition="fade"
      >
        <b-table-column label="id" v-slot="props">
          {{ props.row.payment_id }}
        </b-table-column>

        <b-table-column label="date" v-slot="props">
          {{ formatDatabaseDate(props.row.created_at) }}
        </b-table-column>

        <b-table-column label="Paid" v-slot="props">
          {{ euroFromCents(props.row.paid) }}
        </b-table-column>

        <b-table-column label="Refunded" v-slot="props">
          {{ euroFromCents(props.row.refunded) }}
        </b-table-column>

        <b-table-column label="Balance" v-slot="props">
          {{ euroFromCents(props.row.paid - props.row.refunded) }}
        </b-table-column>

        <b-table-column label="Method" v-slot="props">
          {{ props.row.payment_method }}
        </b-table-column>

        <b-table-column label="Status" v-slot="props">
          {{ props.row.status }}
        </b-table-column>

        <template #detail="rows">
          <span v-if="rows.row.payment_method !== 'manual_refund'">
            <div class="title is-5">Refunds</div>
            <b-table
              :data="rows.row.refunds"
              :bordered="true"
              :striped="true"
              :narrowed="true"
            >
              <b-table-column label="Id" v-slot="props">
                {{ props.row.refund_id }}
              </b-table-column>

              <b-table-column label="Refunder" v-slot="props">
                {{ props.row.user.name }}
              </b-table-column>

              <b-table-column label="Date" v-slot="props">
                {{ formatDatabaseDate(props.row.created_at) }}
              </b-table-column>

              <b-table-column label="Amount" v-slot="props">
                {{ euroFromCents(props.row.amount) }}
              </b-table-column>

              <b-table-column label="Status" v-slot="props">
                {{ props.row.status }}
              </b-table-column>

              <b-table-column label="Action" v-slot="props">
                <a
                  v-if="props.row.status === 'pending'"
                  @click="preCancelRefund(props.row)"
                  >Cancel refund</a
                >
              </b-table-column>
            </b-table>
          </span>
          <span v-else>
            Note: {{ rows.row.payment_id }} <br />
            Refunded by {{ rows.row.user.name }} <br /><br />
            <button
              class="button is-danger"
              :class="{ 'is-loading': ajaxloading.cancel_refund }"
              @click="preCancelRefund(rows.row)"
            >
              Delete refund
            </button></span
          >
        </template>
      </b-table>
      <br />
    </div>

    <refunds v-if="order" :order="order" />
  </div>
</template>

<script>
import refunds from "./AdminOrderRefunds.vue";

export default {
  components: {
    refunds,
  },
  props: [],
  data() {
    return {
      loadedOrderRefunds: [],
    };
  },
  computed: {
    orders() {
      return this.$store.getters["adminOrders/orders"];
    },
    settings() {
      return this.$store.getters["shop/settings"];
    },
    order() {
      if (this.orders && this.orders.length) {
        var order = this.orders.find(
          (a) => a.id == this.$router.currentRoute.params.order
        );
      }

      if (order) {
        return order;
      } else {
        return {};
      }
    },
  },
  watch: {
    order: {
      immediate: true,
      deep: true,
      handler() {
        this.getPaymentRefunds();
      },
    },
  },
  methods: {
    getPaymentRefunds() {
      if (this.order && this.order.order_payments) {
        this.order.order_payments.forEach((payment) => {
          if (
            payment.payment_method !== "manual_refund" &&
            !this.loadedOrderRefunds.includes(payment.id)
          ) {
            this.$store
              .dispatch("adminOrders/getPaymentRefunds", payment)
              .then(() => {
                this.loadedOrderRefunds.push(payment.id);
              });
          }
        });
      }
    },
    cancel(updateOrders) {
      if (updateOrders) {
        this.$store.dispatch("adminOrders/getOrders");
      }
      this.selectedPayment = null;
    },
    cancelRefund(refund) {
      if (refund.payment_method === "manual_refund") {
        this.$store
          .dispatch("adminOrders/cancelManualRefund", refund)
          .then(() => {
            this.$store.dispatch("createFlashBanner", "Refund canceled.");
          });
        return;
      }
      this.$store
        .dispatch("adminOrders/cancelRefund", refund)
        .then(() => {
          this.$store.dispatch("createFlashBanner", "Refund canceled.");
        })
        .catch(() => {
          this.$store.dispatch("createFlashBanner", {
            type: "is-danger",
            message: "Refund can't be canceled. It is no longer pending",
          });
        });
    },
    preCancelRefund(refund) {
      this.$buefy.dialog.confirm({
        title: "Cancel refund",
        message: "Are you sure you want to cancel this refund?",
        confirmText: "Cancel refund",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.cancelRefund(refund),
      });
    },
  },
  mounted() {},
};
</script>
