var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$router.currentRoute.name === _vm.updateRoute)?_c('div',{staticClass:"title"},[_vm._v(" Update Payment Method: "+_vm._s(_vm.payment_method.name)+" ")]):_c('div',{staticClass:"title"},[_vm._v("Add Payment Method")]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('formInput',{staticClass:"customInput",attrs:{"model":_vm.form,"title":"Internal name","modelKey":"internal_name","label":"on-border","placeholder":"Name for internal use"}})],1),_c('div',{staticClass:"column"},[_c('formInput',{staticClass:"customInput",attrs:{"model":_vm.form,"title":"Display name","modelKey":"display_name","label":"on-border","placeholder":"Name that the customer sees at checkout"}})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[(_vm.settings)?_c('formSelect',{staticClass:"customInput",attrs:{"label":"on-border","model":_vm.form,"modelKey":"icon","title":"Icon","placeholder":"Select icon","selectOptions":_vm.settings.admin.payment_methods.map(function (o) {
            return o.name;
          })},on:{"change":function($event){return _vm.setMethodName()}}}):_vm._e()],1),_c('div',{staticClass:"column"},[(
          _vm.settings &&
          _vm.form.icon &&
          _vm.settings.admin.payment_methods.find(
            (a) => a.name === this.form.icon
          ).icon_location === 'font awesome'
        )?_c('b-icon',{attrs:{"pack":_vm.settings.admin.payment_methods.find(
            (a) => a.name === this.form.icon
          ).icon_pack,"icon":_vm.settings.admin.payment_methods.find(
            (a) => a.name === this.form.icon
          ).icon_name}}):_vm._e(),(
          _vm.settings &&
          _vm.form.icon &&
          _vm.settings.admin.payment_methods.find(
            (a) => a.name === this.form.icon
          ).icon_location === 'local'
        )?_c('customIcon',{attrs:{"icon":_vm.settings.admin.payment_methods.find(
            (a) => a.name === this.form.icon
          ).icon_name}}):_vm._e()],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('formInput',{staticClass:"customInput",attrs:{"model":_vm.form,"modelKey":"editprice","title":"price","label":"on-border","placeholder":"Price of shipping method"},on:{"input":function($event){return _vm.setPrice()}}}),(_vm.validation.price)?_c('p',{staticClass:"help is-danger"},[_vm._v(" "+_vm._s(_vm.validation["price"][0])+" ")]):_vm._e()],1),_c('div',{staticClass:"column"},[_vm._v(_vm._s(_vm.euroFromCents(_vm.form.price)))])]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('formInput',{staticClass:"customInput",attrs:{"model":_vm.form,"modelKey":"editopslag","label":"on-border","placeholder":"Opslag op prijs in procenten"},on:{"input":function($event){return _vm.setOpslag()}}}),(_vm.validation.opslag)?_c('p',{staticClass:"help is-danger"},[_vm._v(" "+_vm._s(_vm.validation["opslag"][0])+" ")]):_vm._e()],1),_c('div',{staticClass:"column"},[(_vm.form.opslag)?_c('span',[_vm._v(_vm._s(Math.round(_vm.form.opslag) / 100))]):_vm._e(),_vm._v(" % ")])]),(_vm.$router.currentRoute.name === _vm.updateRoute)?_c('button',{staticClass:"button is-primary is-rounded",class:{ 'is-loading': _vm.ajaxloading[_vm.loadingKey] },on:{"click":function($event){return _vm.update()}}},[_vm._v(" Update ")]):_c('button',{staticClass:"button is-primary is-rounded",class:{ 'is-loading': _vm.ajaxloading[_vm.loadingKey] },on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Create ")]),_vm._v("   "),_c('button',{staticClass:"button is-rounded",on:{"click":function($event){$event.preventDefault();return _vm.cancelUpdate()}}},[_vm._v(" Cancel ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }