var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('navBar'),_c('div',{staticClass:"container is-fluid"},[(_vm.settings && _vm.settings.variables)?_c('span',{staticClass:"content",domProps:{"innerHTML":_vm._s(
        _vm.settings.variables.find((a) => a.key === 'text_contact_form').value
      )}}):_vm._e(),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-8"},[_c('div',{staticClass:"box"},[_c('formInput',{staticClass:"customInput",attrs:{"modelKey":"name","model":_vm.form,"placeholder":"Name","label":"on-border"}}),_c('br'),_c('formInput',{staticClass:"customInput",attrs:{"modelKey":"email","model":_vm.form,"placeholder":"Email","label":"on-border"}}),_c('br'),_c('formInput',{staticClass:"customInput",attrs:{"modelKey":"subject","model":_vm.form,"placeholder":"Subject","label":"on-border"}}),_c('br'),_c('b-field',{attrs:{"label":"Question","label-position":"on-border","type":{ 'is-danger': _vm.validation.question },"message":{
              [_vm.validation.question]: _vm.validation.question,
            }}},[_c('b-input',{attrs:{"type":"textarea","maxlength":"10000","placeholder":"What is your question?"},on:{"input":function($event){return _vm.clearValidationError('question')}},model:{value:(_vm.form.question),callback:function ($$v) {_vm.$set(_vm.form, "question", $$v)},expression:"form.question"}})],1),_c('br'),_c('div',{staticClass:"has-text-centered"},[_c('button',{class:[
                'button is-rounded is-primary',
                { 'is-loading': _vm.ajaxloading.submit_contact_form },
              ],on:{"click":function($event){return _vm.submitContactForm()}}},[_vm._v(" Verzenden ")])])],1)]),(_vm.settings && _vm.settings.variables)?_c('div',{staticClass:"column"},[_c('div',{staticClass:"customtitle"},[_vm._v(" "+_vm._s(_vm.settings.variables.find((a) => a.key === "company_name").value)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.settings.variables.find((a) => a.key === "company_street").value)+" "+_vm._s(_vm.settings.variables.find((a) => a.key === "company_house_number") .value)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.settings.variables.find((a) => a.key === "company_postal_code") .value)+" "+_vm._s(_vm.settings.variables.find((a) => a.key === "company_city").value)),_c('br'),_vm._v(" E: "),_c('a',{attrs:{"href":'mailto:' +
              _vm.settings.variables.find(
                (a) => a.key === 'company_contact_email'
              ).value}},[_vm._v(_vm._s(_vm.settings.variables.find( (a) => a.key === "company_contact_email" ).value))]),_c('br'),_vm._v(" T: "+_vm._s(_vm.settings.variables.find((a) => a.key === "company_phonenumber") .value)+" "),_c('br'),_c('br'),_vm._v(" KvK: "+_vm._s(_vm.settings.variables.find((a) => a.key === "company_kvk_number") .value)+" ")]),_c('div',{staticClass:"customtitle"})]):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }