<style scoped>
.subtotal {
  text-align: right !important;
}
</style>

<template>
  <div class="container">
    <br />
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">Shopping Cart</p>
      </header>
      <section class="card-content">
        <div class="columns" v-for="index of cart.length" :key="index">
          <!-- Mobile name -->
          <div class="column is-hidden-tablet has-text-centered">
            <a
              @click="
                $router.push({
                  name: 'webshop-product',
                  params: {
                    category: cart[index - 1].product.category.slug,
                    product: cart[index - 1].product.slug,
                  },
                })
              "
            >
              <span>
                <span v-if="cart[index - 1].product.type === 'options'">
                  {{ cart[index - 1].product.name }} -
                  <small>
                    {{ cart[index - 1].option.name }}
                  </small>
                </span>
                <span v-else>
                  {{ cart[index - 1].product.name }}
                </span>
              </span>
            </a>
          </div>

          <div class="column is-1">
            <b-image
              ratio="1by1"
              v-if="
                cart[index - 1].product.type === 'options' &&
                cart[index - 1].option.picture <
                  cart[index - 1].product.pictures.length &&
                cart[index - 1].option.picture > 0
              "
              :src="
                env.VUE_APP_API_URL +
                '/web/picture/' +
                cart[index - 1].product.pictures[cart[index - 1].option.picture]
                  .uuid
              "
              alt="Product Image"
            ></b-image>

            <b-image
              ratio="1by1"
              v-else
              :src="
                env.VUE_APP_API_URL +
                '/web/picture/' +
                cart[index - 1].product.pictures[0].uuid
              "
              alt="Product Image"
            />
          </div>

          <!-- Desktop Name -->
          <div class="column is-hidden-mobile">
            <a
              @click="
                $router.push({
                  name: 'webshop-product',
                  params: {
                    category: cart[index - 1].product.category.slug,
                    product: cart[index - 1].product.slug,
                  },
                })
              "
            >
              <span v-if="cart[index - 1].product.type === 'options'">
                {{ cart[index - 1].product.name }} -
                <small>
                  {{ cart[index - 1].option.name }}
                </small>
              </span>
              <span v-else>
                {{ cart[index - 1].product.name }}
              </span>
            </a>
          </div>

          <!-- mobile amount and numberinput -->

          <div class="column is-hidden-tablet">
            <span
              v-if="cart[index - 1].product.type !== 'unique'"
              class="is-pulled-left"
            >
              <b-numberinput
                size="is-small"
                controls-rounded
                :min="1"
                v-model="cart[index - 1].amount"
                controls-position="compact"
                :max="
                  calculateAvailable(
                    cart[index - 1].product,
                    cart[index - 1].option
                  )
                "
              >
              </b-numberinput>
            </span>

            <span class="is-pulled-right">
              <span v-if="cart[index - 1].product.type === 'options'">
                {{
                  euroFromCents(
                    (cart[index - 1].product.price +
                      cart[index - 1].option.price) *
                      cart[index - 1].amount
                  )
                }}
              </span>
              <span v-else>
                {{
                  euroFromCents(
                    cart[index - 1].product.price * cart[index - 1].amount
                  )
                }}
              </span>
              <b-tooltip label="Delete product from cart" position="is-left">
                <b-icon
                  @click.native="removeFromCart(cart[index - 1])"
                  icon="trash"
                  class="pointer"
                  size="is-small"
                  type="is-success"
                >
                </b-icon>
              </b-tooltip>
            </span>

            <br />
          </div>

          <!-- desktop number input -->
          <div class="column is-hidden-mobile">
            <span
              v-if="cart[index - 1].product.type !== 'unique'"
              class="is-pulled-right"
            >
              <b-numberinput
                size="is-small"
                controls-rounded
                :min="1"
                v-model="cart[index - 1].amount"
                controls-position="compact"
                :max="
                  calculateAvailable(
                    cart[index - 1].product,
                    cart[index - 1].option
                  )
                "
              >
              </b-numberinput>
            </span>
          </div>

          <!-- desktop amount and price -->
          <div class="column is-hidden-mobile">
            <span class="is-pulled-right">
              <span v-if="cart[index - 1].product.type === 'options'">
                {{
                  euroFromCents(
                    (cart[index - 1].product.price +
                      cart[index - 1].option.price) *
                      cart[index - 1].amount
                  )
                }}
              </span>
              <span v-else>
                {{
                  euroFromCents(
                    cart[index - 1].product.price * cart[index - 1].amount
                  )
                }}
              </span>
              <b-tooltip label="Delete product from cart" position="is-top">
                <b-icon
                  @click.native="removeFromCart(cart[index - 1])"
                  icon="trash"
                  class="pointer"
                  size="is-small"
                  type="is-success"
                >
                </b-icon>
              </b-tooltip>
            </span>
          </div>
          <hr />
        </div>

        <table class="table is-fullwidth">
          <tbody>
            <tr>
              <td class="has-text-left" v-if="countCartItems() === 1">
                {{ countCartItems() }} item
              </td>
              <td class="has-text-left" v-else>{{ countCartItems() }} items</td>
              <td class="has-text-right">
                Subtotal:&nbsp;{{ euroFromCents(cartTotal()) }}
              </td>
            </tr>
          </tbody>
        </table>
      </section>

      <footer class="card-footer">
        <a
          href="#"
          class="card-footer-item button"
          size="is-small"
          @click.prevent="$router.go(-1)"
          >Continue shopping</a
        >
        <a
          href="#"
          class="card-footer-item button is-primary"
          @click.prevent="WebshopCheckout()"
          >Checkout</a
        >
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      env: process.env,
    };
  },
  computed: {
    cart() {
      return this.$store.getters["cart/cart"];
    },
  },
  watch: {
    cart: {
      deep: true,
      handler() {
        this.$store.dispatch("cart/syncCartToLocalstore");
      },
    },
  },
  methods: {
    WebshopCheckout() {
      this.closeModal();
      this.$router.push({ name: "webshop-checkout" });
    },
    closeModal() {
      this.$store.dispatch("cart/toggleCartModal", false);
    },
    removeFromCart(item) {
      this.$buefy.toast.open({
        message: "Product removed from cart",
        type: "is-danger",
      });
      this.$store.dispatch("cart/RemoveProductFromCart", item);
    },
    countCartItems() {
      var items = 0;

      this.cart.forEach((item) => {
        items = items + item.amount;
      });

      return items;
    },
    cartTotal() {
      var items = 0;

      this.cart.forEach((item) => {
        if (item.product.type === "options") {
          items =
            items + item.amount * (item.option.price + item.product.price);
        } else {
          items = items + item.amount * item.product.price;
        }
      });

      return items;
    },
    calculateAvailable(product, option) {
      if (product.type === "options") {
        return option.amount;
      }

      return product.amount;
    },
    findProductInCart(product, option) {
      if (product.type === "options") {
        const cartItem = this.cart.find(
          (a) => a.option.id === option.id && a.product.id === product.id
        );

        return cartItem;
      }
      return this.cart.find((a) => a.product.id === product.id);
    },
  },
  mounted() {},
};
</script>
