<style>
.is-active .al img {
  filter: grayscale(0%);
}
.al img {
  filter: grayscale(100%);
}
</style>

<template>
  <div>
    <div class="container is-fluid">
      <br />

      <div
        class="button is-outlined is-primary is-fullwidth"
        @click="$router.go(-1)"
      >
        <b-icon icon="arrow-left"></b-icon>&nbsp; Return to products
      </div>

      <!-- <nav class="breadcrumb is-centered" aria-label="breadcrumbs">
        <ul>
          <li>
            <router-link tag="a" exact :to="{ name: 'webshop-categories' }"
              >Categories</router-link
            >
          </li>
          <li>
            <router-link
              tag="a"
              exact
              :to="{
                name: 'webshop-category',
                params: { category: this.$route.params.category },
              }"
              >{{ hoofdletter(this.$route.params.category) }}</router-link
            >
          </li>
          <li>
            <a>{{ hoofdletter(this.$route.params.product) }}</a>
          </li>
        </ul>
      </nav> -->

      <br />

      <div class="columns">
        <div class="column is-5">
          <b-carousel
            indicator-custom
            v-model="shownPicture"
            :autoplay="false"
            :indicator-inside="false"
            v-if="product.pictures.length !== 1"
          >
            <b-carousel-item
              v-for="(item, i) in product.pictures"
              :key="i"
              class="has-text-centered"
            >
              <b-image
                ratio="1by1"
                class="image"
                alt="Product Image"
                :src="
                  env.VUE_APP_API_URL +
                  '/web/picture/' +
                  product.pictures[i].uuid
                "
              ></b-image>
            </b-carousel-item>
            <template #indicators="props">
              <b-image
                ratio="1by1"
                class="al image"
                :src="
                  env.VUE_APP_API_URL +
                  '/web/picture/' +
                  product.pictures[props.i].uuid
                "
                :title="props.i"
              ></b-image>
            </template>
          </b-carousel>

          <b-image
            v-else
            ratio="1by1"
            class="image"
            alt="Product Image"
            :src="
              env.VUE_APP_API_URL + '/web/picture/' + product.pictures[0].uuid
            "
          ></b-image>
        </div>
        <div class="column">
          <span class="title">{{ product.name }}</span>
          <span v-if="user.role === 'admin'">
            <b-icon
              class="pointer is-pulled-right"
              icon="edit"
              @click.native="
                $router.push({
                  name: 'admin-product-edit',
                  params: { product: product.slug },
                })
              "
            />
          </span>
          <div class="content">
            <ul>
              <li v-for="tag of product.tags" :key="tag.id">{{ tag.naam }}</li>
            </ul>
          </div>
          <hr />
          <div class="content" v-html="product.description"></div>
          <hr />

          <!-- unique part of product -->
          <span v-if="product.type === 'unique'">
            <p v-if="product.status === 'preview'">
              <i>Coming soon</i>
            </p>
            <p v-else-if="product.status !== 'sold'">
              {{ euroFromCents(product.price) }}
            </p>
            <p v-else>Sold</p>
          </span>

          <!-- multiple part of product -->
          <span v-if="product.type === 'multiple'">
            <p v-if="product.status === 'preview'">Coming soon</p>
            <p v-else-if="product.status !== 'sold'">
              {{ euroFromCents(product.price) }}
              <br />
              <br />
              <b-field :message="calculateAvailable(product) + ' available.'">
                <b-numberinput
                  controls-rounded
                  min="1"
                  v-model="amount"
                  controls-position="compact"
                  :max="calculateAvailable(product)"
                >
                </b-numberinput>
              </b-field>
              <br />
              {{ euroFromCents(product.price * amount) }}
            </p>
            <p v-else>Sold</p>
          </span>

          <!-- options part of product -->
          <span v-if="product.type === 'options'">
            <p v-if="product.status === 'preview'">Coming soon</p>
            <p v-else-if="product.status !== 'sold'">
              <b-field
                v-for="productOption in product.options"
                :key="productOption.id"
              >
                <b-radio
                  :disabled="checkOptionDisabled(product, productOption)"
                  v-model="option"
                  size="is-small"
                  :native-value="productOption"
                >
                  {{ productOption.name }} (+
                  {{ euroFromCents(productOption.price) }}) ({{
                    calculateAvailable(product, productOption)
                  }}
                  available)
                </b-radio>
              </b-field>
              <br />

              <span v-if="option.id">
                <b-field
                  :message="calculateAvailable(product, option) + ' available.'"
                >
                  <b-numberinput
                    controls-rounded
                    :min="1"
                    v-model="amount"
                    controls-position="compact"
                    :max="calculateAvailable(product, option)"
                  >
                  </b-numberinput>
                </b-field>

                <br />
              </span>
              <span v-if="option.id">
                {{ euroFromCents((product.price + option.price) * amount) }}
              </span>
              <span v-else>
                {{ euroFromCents(product.price * amount) }}
              </span>
            </p>
            <p v-else>Sold</p>
          </span>

          <p>
            <br />
            <br />

            <b-button
              v-if="product.status === 'sold' || product.status === 'preview'"
              :disabled="true"
              type="is-rounded is-primary is-fullwidth"
              icon-left="cart-plus"
            >
              <span v-if="product.status === 'sold'">Product sold</span>
              <span v-if="product.status === 'preview'">Coming soon</span>
            </b-button>

            <b-button
              v-else
              @click="addProductToCart()"
              :disabled="productsLeftToAdd(product, option)"
              type="is-rounded is-primary is-fullwidth"
              icon-left="cart-plus"
              :class="{ 'is-loading': addingToCart }"
            >
              <span> Add to Cart</span>
            </b-button>

            <br />

            <span v-if="findProductInCart(product, option)">
              <b-button
                @click="removeFromCart(product)"
                type="is-rounded is-danger is-fullwidth"
                icon-left="cart-arrow-down"
              >
                <span> Remove from Cart</span>
              </b-button>

              <br />
            </span>
            <b-button
              type="is-rounded is-fullwidth"
              icon-left="shopping-cart"
              @click="$router.push({ name: 'shoppingcart' })"
            >
              View Shopping Cart
            </b-button>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      env: process.env,
      amount: 1,
      option: {
        amount: 0,
      },
      addingToCart: false,
      shownPicture: 0,
    };
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
    products() {
      const categories = this.$store.getters["shop/categories"];

      const category = categories.find(
        (a) => a.slug === this.$route.params.category
      );

      if (category) {
        return category.products;
      } else {
        return [];
      }
    },
    cart() {
      return this.$store.getters["cart/cart"];
    },
    product() {
      if (this.products.find((a) => a.slug === this.$route.params.product)) {
        return this.products.find((a) => a.slug === this.$route.params.product);
      } else {
        return {
          tags: [],
          pictures: [],
        };
      }
    },
    category() {
      const categories = this.$store.getters["shop/categories"];
      const category = categories.find(
        (a) => a.slug === this.$route.params.category
      );
      if (category) {
        this.setTags(category);
        return category;
      } else {
        return {};
      }
    },
    categories() {
      return this.$store.getters["shop/categories"];
    },
  },
  methods: {
    openCartModal() {
      this.$store.dispatch("cart/toggleCartModal", true);
    },
    addProductToCart() {
      if (this.product.type === "options" && !this.option.id) {
        return this.$buefy.toast.open({
          message: "Choose an option before adding to cart.",
          type: "is-warning",
        });
      }

      this.setCartTimer();
      const payload = {
        product: this.product,
        amount: this.amount,
        option: this.option,
      };
      this.$store.dispatch("cart/AddProductToCart", payload);

      this.$buefy.toast.open({
        message: "Product added to cart.",
        type: "is-success",
      });

      if (this.calculateAvailable(payload.product, payload.option) === 0) {
        this.amount = 0;
      } else {
        this.amount = 1;
      }
    },
    setCartTimer() {
      this.addingToCart = true;
      setTimeout(
        function () {
          this.addingToCart = false;
        }.bind(this),
        1000
      );
    },
    calculateAvailable(product, option) {
      const cartItem = this.findProductInCart(product, option);
      // if (product.type === "options" && cartItem) {
      //   return option.amount - cartItem.amount;
      // }
      if (
        product.type === "options" &&
        cartItem &&
        cartItem.option.id === option.id
      ) {
        return option.amount - cartItem.amount;
      }

      if (
        product.type === "options" &&
        cartItem &&
        cartItem.option.id !== option.id
      ) {
        return option.amount;
      }

      if (product.type === "options" && !cartItem) {
        return option.amount;
      }

      if (cartItem) {
        return product.amount - cartItem.amount;
      } else {
        return product.amount;
      }
    },
    findProductInCart(product, option) {
      if (product.type === "options") {
        if (option.id) {
          const cartItem = this.cart.find(
            (a) => a.product.id === product.id && a.option.id === option.id
          );

          return cartItem;
        }
      }
      return this.cart.find((a) => a.product.id === product.id);
    },
    checkOptionDisabled(product, productOption) {
      if (
        this.calculateAvailable(product, productOption) === 0 &&
        !this.findProductInCart(product, productOption)
      ) {
        return true;
      } else {
        return false;
      }
    },
    removeFromCart(product) {
      const payload = {
        product: this.product,
        option: this.option,
      };

      this.$buefy.toast.open({
        message: "Product removed from cart",
        type: "is-danger",
      });
      this.$store.dispatch("cart/RemoveProductFromCart", payload);
    },
    productsLeftToAdd(product) {
      if (product && product.type === "options" && !this.option.id) {
        return true;
      }

      const inCart = this.findProductInCart(product, this.option);

      if (inCart) {
        if (product.type === "unique") {
          return true;
        }

        if (
          inCart.product.type === "multiple" &&
          this.calculateAvailable(product) === 0
        ) {
          return true;
        }

        if (
          product.type === "options" &&
          this.calculateAvailable(product, this.option) === 0
        ) {
          return true;
        }
      } else {
        return false;
      }
    },
  },
  watch: {
    option: {
      handler() {
        this.amount = 1;
        if (this.option.picture < this.product.pictures.length) {
          this.shownPicture = this.option.picture;
        }
      },
    },
  },
  mounted() {},
};
</script>
