<template>
  <div>
    <div class="title is-4">Revenue</div>
    <div class="columns">
      <div class="column">
        <formSelect
          :model="form"
          modelKey="interval"
          :selectOptions="['month', 'quarter', 'year']"
        />
      </div>
      <div class="column">
        <numberInput @input="changeYear()" :model="form" modelKey="year" />
      </div>
    </div>

    <hr />
    <b-notification :closable="false">
      <montlyTable
        v-if="form.interval === 'month'"
        :year="form.year"
        :revenue="revenue"
      />
      <quaterlyTable
        v-if="form.interval === 'quarter'"
        :year="form.year"
        :revenue="revenue"
      />
      <yearlyTable
        v-if="form.interval === 'year'"
        :year="form.year"
        :revenue="revenue"
      />
      <b-loading
        :is-full-page="false"
        v-model="ajaxloading.getting_revenue"
        :can-cancel="false"
      ></b-loading>
    </b-notification>
  </div>
</template>

<script>
import montlyTable from "./AdminRevenueMontlyTable.vue";
import quaterlyTable from "./AdminRevenueQuaterlyTable.vue";
import yearlyTable from "./AdminRevenueYearlyable.vue";

export default {
  components: {
    montlyTable,
    quaterlyTable,
    yearlyTable,
  },
  props: [],
  data() {
    return {
      form: {
        interval: "month",
        year: new Date().getFullYear(),
      },
    };
  },
  computed: {
    revenue() {
      return this.$store.getters["adminRevenue/revenue"];
    },
  },
  methods: {
    changeYear() {
      this.$store.dispatch("adminRevenue/getRevenue", {
        year: this.form.year,
      });
    },
  },
  mounted() {},
};
</script>
