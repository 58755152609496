<style scoped>
.users-title {
  float: left;
  font-weight: bold;
  font-size: 30px;
}

.table-icons {
  float: right;
}

.box {
  border-color: #333 !important;
  border: solid;
  border-width: thin;
  /* Set the hr color */
}

img {
  max-height: 150px;
}

hr {
  border: none;
  height: 1px;
  /* Set the hr color */
  color: #333; /* old IE */
  background-color: #333; /* Modern Browsers */
  margin-top: 0px;
}
</style>

<template>
  <div>
    <b-table
      :data="orders"
      :bordered="true"
      :striped="true"
      :narrowed="true"
      :hoverable="true"
      @click="orderClicked"
      class="pointer"
    >
      <b-table-column label="Order #" v-slot="props">
        {{ props.row.order_year }} - {{ props.row.order_number }}
      </b-table-column>

      <b-table-column label="Total" v-slot="props">
        {{ euroFromCents(props.row.total_price) }}
      </b-table-column>

      <b-table-column label="Name" v-slot="props">
        {{ props.row.name }} {{ props.row.surname }}
      </b-table-column>

      <b-table-column label="Address" v-slot="props">
        {{ props.row.street }} {{ props.row.house_number }}
      </b-table-column>

      <b-table-column label="Postcode" v-slot="props">
        {{ props.row.postal_code }}
      </b-table-column>

      <b-table-column label="City" v-slot="props">
        {{ props.row.postal_code }}
      </b-table-column>

      <b-table-column label="Country" v-slot="props">
        {{ props.row.country }}
      </b-table-column>

      <b-table-column label="Payment" v-slot="props">
        {{ props.row.payment_status }}
      </b-table-column>

      <b-table-column label="Shipping" v-slot="props">
        {{ props.row.shipping_status }}
      </b-table-column>

      <b-table-column label="Date" v-slot="props">
        {{ formatDatabaseDate(props.row.created_at) }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    propOrders: {
      required: true,
      type: Array,
    },
    sortOrders: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      env: process.env,
      selected: {},
      isLoading: true,
    };
  },
  computed: {
    orders() {
      const orders = this.propOrders;

      if (this.sortOrders) {
        orders.sort((a, b) => {
          if (a.id > b.id) {
            return -1;
          } else {
            return 1;
          }
        });
      }

      return orders;
    },
  },
  methods: {
    openPdf(invoice) {
      window.open(
        this.env.VUE_APP_API_URL + "/web/show-user-invoice/" + invoice.id
      );
    },
    formatOrderNumber(order) {
      var number = order.order_number;
      if (number < 100) {
        number = "0" + order.order_number;
      }

      if (number < 10) {
        number = "00" + order.order_number;
      }

      return order.order_year + "-" + number;
    },
    orderClicked(order) {
      this.$router.push({ name: "admin-order", params: { order: order.id } });
    },
  },
  watch: {},
  mounted() {},
};
</script>
