var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[(
      _vm.settings &&
      _vm.icon &&
      _vm.settings.admin.shipping_methods.find((a) => a.name === _vm.icon)
        .icon_location === 'local'
    )?_c('customIcon',{attrs:{"icon":_vm.settings.admin.shipping_methods.find((a) => a.name === _vm.icon).icon_name}}):_vm._e(),(
      _vm.settings &&
      _vm.icon &&
      _vm.settings.admin.shipping_methods.find((a) => a.name === _vm.icon)
        .icon_location === 'font awesome'
    )?_c('b-icon',{attrs:{"pack":_vm.settings.admin.shipping_methods.find((a) => a.name === _vm.icon).icon_pack,"icon":_vm.settings.admin.shipping_methods.find((a) => a.name === _vm.icon).icon_name}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }