import formatCurrency from "@/globals/functions/formatCurrency";

export const settings = (state) => state.settings;
export const closedModalActive = (state) => state.closedModalActive;
export const order = (state) => state.order;
export const countries = (state) => state.countries;
export const products = (state) => {
  for (let product of state.products) {
    product.pictures.sort((a, b) => {
      return a.position - b.position;
    });
  }

  return state.products;
};
export const categories = (state) => {
  state.categories.sort((a, b) => {
    return a.position - b.position;
  });
  for (let category of state.categories) {
    for (let product of category.products) {
      // formatCurrency(product);
      // setProductStatus(product);
      product.pictures.sort((a, b) => {
        return a.position - b.position;
      });
    }
  }
  return state.categories;
};

function setProductStatus(product) {
  if (product.status === "sold") {
    return;
  }

  if (product.type === "unique") {
    return;
  }

  if (product.type === "multiple") {
    if (product.amount == 0) {
      product.status = "sold";
      return;
    }
  }

  if (product.type === "options") {
    if (product.status === "available") {
      let i = 0;

      for (let option of product.options) {
        i = i + option.amount;
      }

      if (i === 0) {
        product.status = "sold";
      }
    }
  }
}
