<style scoped>
.customInput {
  margin-bottom: 15px;
}
</style>

<template>
  <div class="modal is-active">
    <div class="modal-background" @click="closeModal()"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Edit {{ detail.id }} - {{ detail.name }}</p>
        <button
          class="delete"
          aria-label="close"
          @click="closeModal()"
        ></button>
      </header>
      <section class="modal-card-body">
        <formInput
          class="customInput"
          :model="detail"
          modelKey="name"
          label="on-border"
        />

        <formInput
          class="customInput"
          :model="detail"
          modelKey="category"
          label="on-border"
        />

        <formSelect
          label="on-border"
          class="customInput"
          :model="detail"
          modelKey="btwtype"
          :selectOptions="['low', 'high']"
          title="Btw
          type"
        />

        <div class="columns">
          <div class="column">
            <formInput
              class="customInput"
              :model="detail"
              modelKey="price"
              label="on-border"
            />
          </div>
          <div class="column">
            {{ euroFromCents(detail.price) }}
          </div>
        </div>

        <numberInput
          class="customInput"
          label="on-border"
          type="is-primary"
          :model="detail"
          modelKey="amount"
        />

        <div class="columns">
          <div class="column">Total:</div>
          <div class="column">
            {{ euroFromCents(detail.amount * detail.price) }}
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          class="button is-rounded is-primary"
          :class="{ 'is-loading': ajaxloading.update_invoice_product }"
          @click="saveProduct()"
        >
          Save
        </button>
        <button
          class="button is-rounded is-danger"
          :class="{ 'is-loading': ajaxloading.update_invoice_product }"
          @click="deleteProduct()"
        >
          Delete
        </button>
        <button
          class="button is-rounded"
          :class="{ 'is-loading': ajaxloading.update_invoice_product }"
          @click="cancelUpdate()"
        >
          Cancel
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    invoice: {
      required: true,
      type: Object,
    },
    detail: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    saveProduct() {
      this.$store
        .dispatch("adminInvoices/saveProduct", this.detail)
        .then(() => {
          this.$buefy.toast.open("Product saved");
          this.closeModal();
        });
    },
    cancelUpdate() {
      this.$store
        .dispatch("adminInvoices/cancelUpdate", this.invoice)
        .then(() => {
          this.closeModal();
        });
    },
    deleteProduct() {
      this.$buefy.dialog.confirm({
        title: "Deleting product",
        message:
          "Are you sure you want to <b>delete</b> this product? This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          this.$store
            .dispatch("adminInvoices/deleteProduct", this.detail)
            .then(() => {
              this.$buefy.toast.open("Product deleted");
              this.closeModal();
            }),
      });
    },
  },
  mounted() {},
};
</script>
