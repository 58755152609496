<style scoped>
.customInput {
  margin-bottom: 15px;
}
.columns {
  margin: 0px;
  padding: 0px;
}

.column {
  margin: 0px;
  padding: 0px;
}

.modal-card-body {
  min-height: 400px;
}
</style>

<template>
  <div class="modal is-active">
    <div class="modal-background" @click="closeModal()"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Edit Invoice</p>
        <button
          class="delete"
          aria-label="close"
          @click="closeModal()"
        ></button>
      </header>
      <section class="modal-card-body">
        <formDatePicker
          class="customInput"
          label="on-border"
          :model="invoice"
          title="Invoice date"
          modelKey="invoice_date"
          placeholder="DD-MM-JJJJ"
        />
        <numberInput
          class="customInput"
          label="on-border"
          type="is-primary"
          :model="invoice"
          title="Invoice number"
          modelKey="invoice_number"
        />

        <formTextarea
          label="on-border"
          title="Invoice text"
          size="is-small"
          type="is-primary"
          :max="255"
          :model="invoice"
          modelKey="invoice_text"
        />
      </section>
      <footer class="modal-card-foot">
        <button
          class="button is-rounded is-primary"
          :class="{ 'is-loading': ajaxloading.update_invoice }"
          @click="saveBaseDetails()"
        >
          Save
        </button>
        <button
          class="button is-rounded"
          :class="{ 'is-loading': ajaxloading.update_invoice }"
          @click="cancelUpdate()"
        >
          Cancel
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    invoice: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    saveBaseDetails() {
      this.$store
        .dispatch("adminInvoices/saveInvoiceBaseDetails", this.invoice)
        .then(() => {
          this.$buefy.toast.open("Invoice updated");
          this.closeModal();
        });
    },
    cancelUpdate() {
      this.$store
        .dispatch("adminInvoices/cancelUpdate", this.invoice)
        .then(() => {
          this.closeModal();
        });
    },
  },
  mounted() {},
};
</script>
