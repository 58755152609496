export const getInvoices = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-get-invoices";
  data.method = "get";
  data.loading = "getinvoices";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setInvoices", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const searchInvoices = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-search-invoices";
  data.method = "POST";
  data.loading = "getinvoices";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const deleteProduct = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-delete-invoice-product/" + payload.id;
  data.method = "POST";
  data.loading = "update_invoice_product";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("updateInvoice", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const deleteInvoice = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-delete-invoice/" + payload.id;
  data.method = "POST";
  data.loading = "update_invoice_product";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("deleteInvoice", payload);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const saveProduct = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-update-invoice-product/" + payload.id;
  data.method = "POST";
  data.loading = "update_invoice_product";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("updateInvoice", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const cancelUpdate = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-cancel-invoice-update/" + payload.id;
  data.method = "POST";
  data.loading = "update_invoice_product";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("updateInvoice", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const saveInvoiceCustomerDetails = function (
  { dispatch, commit },
  payload
) {
  const data = {};
  data.url = "admin-update-invoice-customer-details/" + payload.id;
  data.method = "POST";
  data.loading = "update_invoice";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("updateInvoice", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const saveInvoiceBaseDetails = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-update-invoice-base-details/" + payload.id;
  data.method = "POST";
  data.loading = "update_invoice";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("updateInvoice", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const sendInvoiceToCustomer = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url =
    "admin-send-invoice-to-customer-from-invoice/" + payload.invoice.id;
  data.method = "POST";
  data.loading = "send_invoice";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("updateInvoice", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const markInvoiceAsSend = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-mark-invoice-as-send/" + payload.id;
  data.method = "POST";
  data.loading = "mark_invoice_as_send";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("updateInvoice", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const saveInvoiceFinancialDetails = function (
  { dispatch, commit },
  payload
) {
  const data = {};
  data.url = "admin-update-invoice-financial-details/" + payload.id;
  data.method = "POST";
  data.loading = "update_invoice";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("updateInvoice", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const saveInvoiceSellerDetails = function (
  { dispatch, commit },
  payload
) {
  const data = {};
  data.url = "admin-update-invoice-seller-details/" + payload.id;
  data.method = "POST";
  data.loading = "update_invoice";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("updateInvoice", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
