<style scoped>
.custommodal {
  position: fixed;
  height: 100%;
  width: 100%;
}

.customInput {
  margin-bottom: 10px;
}
</style>

<template>
  <div>
    <div class="modal is-active">
      <div class="modal-background" @click="closeModal()"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ hoofdletter(model) }} aanpassen</p>

          <button
            class="delete"
            aria-label="close"
            @click="closeModal()"
          ></button>
        </header>
        <form @submit.prevent="submit()">
          <section class="modal-card-body">
            <formInput
              class="customInput"
              modelKey="naam"
              :model="form"
              placeholder="Naam van categorie"
            />

            <formCheckbox
              :model="form"
              modelKey="visible"
              title="Category visible"
              :labelHidden="true"
            />
          </section>

          <footer class="modal-card-foot">
            <form-button
              type="is-primary"
              :title="updateForm ? `Updaten` : `Aanmaken`"
              size="is-small"
              loadingKey="edit_category"
            />

            <button class="button is-rounded is-small" @click="closeModal()">
              Annuleren
            </button>
          </footer>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  components: {},
  props: {
    updateForm: {
      type: Object,
    },
  },
  data() {
    return {
      model: "categorie",
      form: {},
    };
  },
  computed: {},
  methods: {
    closeModal() {
      if (this.updateForm) {
        this.$store.dispatch(
          "adminCategories/cancelUpdateCategory",
          this.updateForm
        );
      }
      this.$emit("closeModal");
    },
    submit() {
      this.update();
    },
    update() {
      this.$store
        .dispatch("adminCategories/updateCategory", this.form)
        .then((response) => {
          if (this.form.slug !== response.data.slug) {
            this.$router.replace({
              name: "admin-category",
              params: { category: response.data.slug },
            });
          }
          this.$emit("closeModal");
        });
    },
  },
  created() {
    if (this.updateForm) {
      this.form = this.updateForm;
    }
  },
  mounted() {
    this.$store.dispatch("clearValidationError", this.modelKey);
  },
};
</script>
