import Echo from "laravel-echo";
window.Pusher = require("pusher-js");

export const startWebsocket = function ({ state, commit }, settings) {
  //   const url = new URL(settings.host);
  //   state.echo = new Echo({
  //     broadcaster: "pusher",
  //     key: settings.secret,
  //     wsHost: url.hostname,
  //     wsPort: settings.port,
  //     forceTLS: false,
  //     disableStats: true,
  //   });
};

export const setExcludeId = function ({ state, commit }, payload) {
  commit("setExcludeId", payload);
};

export const joinShopChannel = function (
  { dispatch, state, commit, rootGetters },
  payload
) {
  window.echo.channel("shoppingchannel").listen(".update-store", (message) => {
    if (state.excludeId !== message.exclude) {
      dispatch("shop/setCategories", message.categories, { root: true }).then(
        () => {
          dispatch("cart/updateCartAfterWebsocketUpdate", message.categories, {
            root: true,
          });
        }
      );
    }
  });
};
