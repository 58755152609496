<template>
  <div>
    <b-table
      :data="invoices"
      :bordered="true"
      :striped="true"
      :narrowed="true"
      :hoverable="true"
      class="pointer"
      @dblclick="showInvoice"
    >
      <b-table-column label="Number" v-slot="props">
        {{ props.row.invoice_year }} - {{ props.row.invoice_number }}
      </b-table-column>

      <b-table-column label="Invoice date" v-slot="props">
        <span v-if="props.row.invoice_date == undefined">
          Send or download invoice to set invoice date</span
        >
        <span v-else>
          {{ formatDatePickerDate(props.row.invoice_date) }}
        </span>
      </b-table-column>

      <b-table-column label="Total" v-slot="props">
        {{ euroFromCents(props.row.total_price) }}
      </b-table-column>

      <b-table-column label="Invoice send?" v-slot="props">
        <span v-if="props.row.invoice_send === 'downloaded'">
          invoice downloaded
        </span>
        <span v-else-if="props.row.invoice_send">
          {{ formatDatePickerDate(props.row.invoice_send) }}
        </span>
        <span v-else> Invoice not send </span>
      </b-table-column>
      <b-table-column label="Actions" v-slot="props" centered>
        <button
          class="button is-small is-primary"
          :class="{ 'is-loading': ajaxloading.setting_invoice_number }"
          @click="showInvoicePDF(props.row)"
        >
          Download
        </button>
        &nbsp;
        <button
          class="button is-small is-primary"
          :class="{ 'is-loading': ajaxloading.create_extra_invoice }"
          @click="preSendInvoice(props.row)"
        >
          Send
        </button>
      </b-table-column>
    </b-table>

    <!-- also update on admininvoiceoptipons, has same editor but also needs updating -->
    <customMailEditor
      v-if="invoice && settings"
      :invoice="invoice"
      vuexStoreAction="adminOrders/sendInvoiceToCustomer"
      v-on:close="invoice = null"
      settingsDefaultEmailKey="extra_invoice_email"
      settingsDefaultEmailSubjectKey="extra_invoice_subject"
      :attachment="
        settings.variables.find((a) => a.key === 'company_name').value +
        ' ' +
        invoice.number
      "
      loadingKey="send_invoice"
    />
  </div>
</template>

<script>
import customMailEditor from "./CustomMailEditorModal.vue";
export default {
  components: {
    customMailEditor,
  },
  props: {
    invoices: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      invoice: null,
      env: process.env,
    };
  },
  computed: {
    settings() {
      return this.$store.getters["shop/settings"];
    },
  },
  methods: {
    showInvoicePDF(invoice) {
      this.$store.dispatch("adminOrders/setInvoiceNumber", invoice).then(() => {
        window.open(
          this.env.VUE_APP_API_URL + "/web/admin-show-invoice/" + invoice.id
        );
      });
    },
    showInvoice(invoice) {
      this.$router.push({
        name: "admin-invoice",
        params: { invoice: invoice.id },
      });
    },
    preSendInvoice(invoice) {
      this.invoice = invoice;
    },
  },
  mounted() {},
};
</script>
