<template>
  <div>
    <span class="title is-5">Invoices</span>
    <span class="block table-icons is-pulled-right">
      <span v-if="selected">
        <b-tooltip label="Invoice bekijken" position="is-top">
          <span class="pointer table-icon" @click="showInvoice(selected)">
            <b-icon icon="search"></b-icon>
          </span>
        </b-tooltip>
      </span>
    </span>

    <br />
    <br />

    <b-dropdown :mobile-modal="false" :triggers="['focus']" expanded>
      <template #trigger>
        <b-field label="">
          <b-input
            expanded
            v-model="form.zoekwaarde"
            @input="searchInvoices()"
            placeholder="Zoeken..."
          ></b-input>
        </b-field>
      </template>

      <span v-if="dropdownInvoices.length">
        <b-dropdown-item custom :focusable="false">
          <strong>Invoices</strong>
        </b-dropdown-item>

        <span v-if="dropdownInvoices.length && form.zoekwaarde.length">
          <b-dropdown-item
            :value="invoice.id"
            v-for="invoice in dropdownInvoices"
            :key="invoice.id"
          >
            <div class="box" @click="showInvoice(invoice)">
              <span
                >{{ invoice.number }} | {{ euroFromCents(invoice.total) }} |
                {{ invoice.customer_name }} {{ invoice.customer_surname }} |
                {{ invoice.customer_postal_code }}
                {{ invoice.customer_house_number }} |
                {{ invoice.customer_city }} |
                {{ formatDatePickerDate(invoice.invoice_date) }}
              </span>
            </div>
          </b-dropdown-item>
        </span>
      </span>
      <span v-else-if="form.zoekwaarde && form.zoekwaarde.length">
        <b-dropdown-item>
          <strong>No invoices found</strong>
        </b-dropdown-item>
      </span>
    </b-dropdown>

    <br />
    <br />

    <b-table
      :data="invoices"
      :bordered="true"
      :striped="true"
      :narrowed="true"
      :selected.sync="selected"
      class="pointer"
      @dblclick="showInvoice(selected)"
    >
      <b-table-column field="id" label="ID" width="40" numeric v-slot="props">
        {{ props.row.id }}
      </b-table-column>

      <b-table-column label="Number" sortable v-slot="props">
        {{ props.row.number }}
      </b-table-column>

      <b-table-column label="Order" sortable v-slot="props">
        <a @click="showOrder(props.row.order_id)"
          >Order: {{ props.row.order_id }}</a
        >
      </b-table-column>

      <b-table-column label="Total" sortable v-slot="props">
        {{ euroFromCents(props.row.total_price) }}
      </b-table-column>

      <b-table-column label="Invoice Date" sortable v-slot="props">
        {{ formatDatePickerDate(props.row.invoice_date) }}
      </b-table-column>

      <b-table-column label="Invoice send" sortable v-slot="props">
        {{ formatDatePickerDate(props.row.invoice_send) }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      selected: null,
      selectedOptions: [],
      dropdownInvoices: [],
      form: {
        zoekwaarde: null,
      },
    };
  },
  computed: {
    invoices() {
      const invoices = this.$store.getters["adminInvoices/invoices"];
      invoices.sort((a, b) => {
        return b.id - a.id;
      });
      return invoices;
    },
  },
  methods: {
    showOrder(orderId) {
      this.$router.push({ name: "admin-order", params: { order: orderId } });
    },
    showInvoice(invoice) {
      this.$router.push({
        name: "admin-invoice",
        params: { invoice: invoice.id },
      });
    },
    searchInvoices() {
      if (this.form.zoekwaarde.length === 0) {
        this.dropdownInvoices = [];
        return;
      }
      this.$store
        .dispatch("adminInvoices/searchInvoices", this.form)
        .then((response) => {
          if (this.form.zoekwaarde.length > 0) {
            this.dropdownInvoices = response.data;
          }
        });
    },
  },
  mounted() {},
};
</script>
