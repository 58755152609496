<style scoped>
.custommodal {
  position: fixed;
  height: 100%;
  width: 100%;
}

.customInput {
  margin-bottom: 10px;
}
</style>

<template>
  <div>
    <div class="modal is-active">
      <div class="modal-background" @click="closeModal()"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title" v-if="updateForm">
            {{ hoofdletter(model) }} aanpassen
          </p>
          <p class="modal-card-title" v-else>
            {{ hoofdletter(model) }} aanmaken
          </p>
          <button
            class="delete"
            aria-label="close"
            @click="closeModal()"
          ></button>
        </header>
        <form @submit.prevent="submit()">
          <section class="modal-card-body">
            <formInput
              class="customInput"
              modelKey="naam"
              :model="form"
              placeholder="Naam van tag"
            />
          </section>

          <footer class="modal-card-foot">
            <form-button
              type="is-primary"
              :title="updateForm ? `Updaten` : `Aanmaken`"
              size="is-small"
              loadingKey="create-category-tag"
            />

            <button class="button is-rounded is-small" @click="closeModal()">
              Annuleren
            </button>
          </footer>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    updateForm: {
      type: Object,
    },
    category: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      model: "tag",
      form: {
        visible: true,
      },
    };
  },
  computed: {},
  methods: {
    closeModal() {
      if (this.updateForm) {
        const data = {
          form: this.form,
          category: this.category,
        };
        this.$store.dispatch("adminCategories/cancelTagUpdate", data);
      }
      this.$emit("closeModal");
    },
    submit() {
      if (this.updateForm) {
        this.update();
      } else {
        this.create();
      }
    },
    create() {
      const data = {
        form: this.form,
        category: this.category,
      };
      this.$store.dispatch("adminCategories/createTag", data).then(() => {
        this.closeModal();
      });
    },
    update() {
      this.$store.dispatch("adminCategories/updateTag", this.form).then(() => {
        this.$emit("closeModal");
      });
    },
  },
  mounted() {
    if (this.updateForm) {
      this.form = this.updateForm;
    }
    this.$store.dispatch("clearValidationError", this.modelKey);
  },
};
</script>
