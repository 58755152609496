<template>
  <div>
    <navBar />
    <div class="container box">
      <div class="columns">
        <div class="column is-6 has-text-centered">
          <pagePictureShower
            variable_key="home_page_picture_1"
            :settings="settings"
          />
        </div>
        <div class="column is-6 content">
          <span
            v-if="
              settings &&
              settings.variables.find((a) => a.key === 'home_page_text_1')
            "
            v-html="
              settings.variables.find((a) => a.key === 'home_page_text_1').value
            "
          >
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/views/Navbar.vue";

export default {
  components: {
    navBar: NavBar,
  },
  props: [],
  data() {
    return {};
  },
  computed: {
    settings() {
      return this.$store.getters["shop/settings"];
    },
  },
  methods: {},
  mounted() {},
};
</script>
