<template>
  <div>
    <span v-if="pictures">
      <b-image
        v-if="pictures.value.length === 1"
        ratio="1by1"
        :src="env.VUE_APP_API_URL + '/web/page-picture/' + pictures.value[0]"
        :title="
          settings.variables.find((a) => a.key === variable_key).key + '_' + 1
        "
      />

      <b-carousel
        v-else
        :indicator-inside="false"
        :autoplay="false"
        :interval="4000"
        :pause-hover="true"
      >
        <b-carousel-item
          v-for="(item, i) in settings.variables.find(
            (a) => a.key === variable_key
          ).value.length"
          :key="i"
        >
          <b-image
            ratio="1by1"
            :src="
              env.VUE_APP_API_URL + '/web/page-picture/' + pictures.value[i]
            "
            :title="
              settings.variables.find((a) => a.key === variable_key).key +
              '_' +
              (i + 1)
            "
          ></b-image>
        </b-carousel-item>
      </b-carousel>
    </span>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    settings: {
      required: true,
    },
    variable_key: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      env: process.env,
    };
  },
  computed: {
    pictures() {
      if (!this.settings) {
        return;
      }
      const variable = this.settings.variables.find(
        (a) => a.key === this.variable_key
      );
      if (!variable) {
        return null;
      }

      return variable;
    },
  },
  methods: {},
  mounted() {},
};
</script>
