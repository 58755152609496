var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column"},[_c('h1',{staticClass:"title is-5"},[_vm._v("Producten")]),_c('b-field',[_c('b-switch',{model:{value:(_vm.showAll),callback:function ($$v) {_vm.showAll=$$v},expression:"showAll"}},[_vm._v("Niet sorteren op Categorie")])],1)],1),_c('div',{staticClass:"column"},[_c('button',{staticClass:"button is-pulled-right is-rounded is-small",class:{ 'is-loading': _vm.ajaxloading.create_product },on:{"click":function($event){return _vm.createProduct()}}},[_vm._v(" Toevoegen ")])])]),_c('div',{},[_c('b-tabs',{staticClass:"block",attrs:{"position":"is-centered"},on:{"input":function($event){return _vm.syncActiveTabToQuery()}},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_vm._l((_vm.categories),function(category,index){return _c('b-tab-item',{key:category.id,attrs:{"label":category.naam,"value":String(index)}},_vm._l((Math.ceil(
            _vm.filteredProducts.length / _vm.maxItemsPerRow
          )),function(outerLoop){return _c('div',{key:outerLoop,staticClass:"columns"},_vm._l((_vm.calculatedMax(outerLoop)),function(item){return _c('div',{key:item,staticClass:"column is-2"},[_c('div',{staticClass:"card pointer",on:{"click":function($event){return _vm.productClicked(
                  _vm.filteredProducts[
                    item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1
                  ].slug
                )}}},[_c('div',{staticClass:"card-image",on:{"mouseover":function($event){return _vm.mouseoverImage(
                    _vm.filteredProducts[
                      item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1
                    ]
                  )},"mouseleave":_vm.mouseleaveImage}},[_c('figure',{staticClass:"image is-1by1"},[(
                      _vm.filteredProducts[
                        item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1
                      ].pictures[0]
                    )?_c('div',[(
                        _vm.hoveredProduct ===
                        _vm.filteredProducts[
                          item +
                            outerLoop * _vm.maxItemsPerRow -
                            _vm.maxItemsPerRow -
                            1
                        ].id
                      )?_c('img',{class:{
                        greyImage:
                          _vm.filteredProducts[
                            item +
                              outerLoop * _vm.maxItemsPerRow -
                              _vm.maxItemsPerRow -
                              1
                          ].status === 'sold',
                        hiddenImage:
                          _vm.filteredProducts[
                            item +
                              outerLoop * _vm.maxItemsPerRow -
                              _vm.maxItemsPerRow -
                              1
                          ].visible === false,
                      },attrs:{"src":_vm.calcPictureLength(
                          _vm.filteredProducts[
                            item +
                              outerLoop * _vm.maxItemsPerRow -
                              _vm.maxItemsPerRow -
                              1
                          ]
                        )
                          ? _vm.env.VUE_APP_API_URL +
                            '/web/picture/' +
                            _vm.filteredProducts[
                              item +
                                outerLoop * _vm.maxItemsPerRow -
                                _vm.maxItemsPerRow -
                                1
                            ].pictures[1].uuid
                          : _vm.env.VUE_APP_API_URL +
                            '/web/picture/' +
                            _vm.filteredProducts[
                              item +
                                outerLoop * _vm.maxItemsPerRow -
                                _vm.maxItemsPerRow -
                                1
                            ].pictures[0].uuid,"alt":"Product Image"}}):_c('img',{class:{
                        greyImage:
                          _vm.filteredProducts[
                            item +
                              outerLoop * _vm.maxItemsPerRow -
                              _vm.maxItemsPerRow -
                              1
                          ].status === 'sold',
                        hiddenImage:
                          _vm.filteredProducts[
                            item +
                              outerLoop * _vm.maxItemsPerRow -
                              _vm.maxItemsPerRow -
                              1
                          ].visible === false,
                      },attrs:{"src":_vm.env.VUE_APP_API_URL +
                        '/web/picture/' +
                        _vm.filteredProducts[
                          item +
                            outerLoop * _vm.maxItemsPerRow -
                            _vm.maxItemsPerRow -
                            1
                        ].pictures[0].uuid,"alt":"Product Image"}})]):_vm._e()])]),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"media"},[_c('div',{staticClass:"media"}),_c('div',{staticClass:"media-content"},[_c('div',{staticClass:"content has-text-centered"},[_vm._v(" "+_vm._s(_vm.hoofdletter( _vm.filteredProducts[ item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1 ].name ))+" "),_c('br'),(
                          _vm.filteredProducts[
                            item +
                              outerLoop * _vm.maxItemsPerRow -
                              _vm.maxItemsPerRow -
                              1
                          ].status === 'sold'
                        )?_c('p',{staticStyle:{"color":"red"}},[_vm._v(" Sold ")]):(
                          _vm.filteredProducts[
                            item +
                              outerLoop * _vm.maxItemsPerRow -
                              _vm.maxItemsPerRow -
                              1
                          ].status === 'preview'
                        )?_c('p',[_vm._v(" Preview ")]):_c('p',[_vm._v(" "+_vm._s(_vm.euroFromCents( _vm.filteredProducts[ item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1 ].price ))+" ")])])])])])])])}),0)}),0)}),_c('b-tab-item',{attrs:{"label":"Concept","value":"concept"}},_vm._l((Math.ceil(
            _vm.conceptProducts.length / _vm.maxItemsPerRow
          )),function(outerLoop){return _c('div',{key:outerLoop,staticClass:"columns"},_vm._l((_vm.calculatedConceptMax(outerLoop)),function(item){return _c('div',{key:item,staticClass:"column is-2"},[_c('div',{staticClass:"card pointer",on:{"click":function($event){return _vm.productClicked(
                  _vm.conceptProducts[
                    item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1
                  ].slug
                )}}},[_c('div',{staticClass:"card-image",on:{"mouseover":function($event){return _vm.mouseoverImage(
                    _vm.conceptProducts[
                      item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1
                    ]
                  )},"mouseleave":_vm.mouseleaveImage}},[_c('figure',{staticClass:"image is-1by1"},[(
                      _vm.conceptProducts[
                        item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1
                      ].pictures[0]
                    )?_c('div',[(
                        _vm.hoveredProduct ===
                        _vm.conceptProducts[
                          item +
                            outerLoop * _vm.maxItemsPerRow -
                            _vm.maxItemsPerRow -
                            1
                        ].id
                      )?_c('img',{class:{
                        greyImage:
                          _vm.conceptProducts[
                            item +
                              outerLoop * _vm.maxItemsPerRow -
                              _vm.maxItemsPerRow -
                              1
                          ].status === 'sold',
                        hiddenImage:
                          _vm.conceptProducts[
                            item +
                              outerLoop * _vm.maxItemsPerRow -
                              _vm.maxItemsPerRow -
                              1
                          ].visible === false,
                      },attrs:{"src":_vm.calcPictureLength(
                          _vm.conceptProducts[
                            item +
                              outerLoop * _vm.maxItemsPerRow -
                              _vm.maxItemsPerRow -
                              1
                          ]
                        )
                          ? _vm.env.VUE_APP_API_URL +
                            '/web/picture/' +
                            _vm.conceptProducts[
                              item +
                                outerLoop * _vm.maxItemsPerRow -
                                _vm.maxItemsPerRow -
                                1
                            ].pictures[1].uuid
                          : _vm.env.VUE_APP_API_URL +
                            '/web/picture/' +
                            _vm.conceptProducts[
                              item +
                                outerLoop * _vm.maxItemsPerRow -
                                _vm.maxItemsPerRow -
                                1
                            ].pictures[0].uuid,"alt":"Product Image"}}):_c('img',{class:{
                        greyImage:
                          _vm.conceptProducts[
                            item +
                              outerLoop * _vm.maxItemsPerRow -
                              _vm.maxItemsPerRow -
                              1
                          ].status === 'sold',
                        hiddenImage:
                          _vm.conceptProducts[
                            item +
                              outerLoop * _vm.maxItemsPerRow -
                              _vm.maxItemsPerRow -
                              1
                          ].visible === false,
                      },attrs:{"src":_vm.env.VUE_APP_API_URL +
                        '/web/picture/' +
                        _vm.conceptProducts[
                          item +
                            outerLoop * _vm.maxItemsPerRow -
                            _vm.maxItemsPerRow -
                            1
                        ].pictures[0].uuid,"alt":"Product Image"}})]):_vm._e()])]),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"media"},[_c('div',{staticClass:"media"}),_c('div',{staticClass:"media-content"},[_c('div',{staticClass:"content has-text-centered"},[_vm._v(" "+_vm._s(_vm.hoofdletter( _vm.conceptProducts[ item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1 ].name ))+" "),_c('br'),(
                          _vm.conceptProducts[
                            item +
                              outerLoop * _vm.maxItemsPerRow -
                              _vm.maxItemsPerRow -
                              1
                          ].status === 'sold'
                        )?_c('p',{staticStyle:{"color":"red"}},[_vm._v(" Sold ")]):(
                          _vm.conceptProducts[
                            item +
                              outerLoop * _vm.maxItemsPerRow -
                              _vm.maxItemsPerRow -
                              1
                          ].status === 'preview'
                        )?_c('p',[_vm._v(" Preview ")]):_c('p',[_vm._v(" "+_vm._s(_vm.euroFromCents( _vm.conceptProducts[ item + outerLoop * _vm.maxItemsPerRow - _vm.maxItemsPerRow - 1 ].price ))+" ")])])])])])])])}),0)}),0)],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }