<style scoped>
.modal {
  width: 100%;
  height: 100%;
}

.modal-card {
  width: 100%;
  height: 100%;
}

.modal-card-body {
  width: 100%;
  height: 100%;
}
</style>

<template>
  <div class="modal is-active">
    <div class="modal-background" @click="closeModal()"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Foto aanpassen</p>
        <button
          class="delete"
          aria-label="close"
          @click="closeModal()"
        ></button>
      </header>
      <section class="modal-card-body">
        <div class="columns">
          <div class="column is-10">
            <b-field label="Foto aanpassen">
              <span>
                <div class="box has-text-centered">
                  <br />

                  <!--                                 <b-image
              ratio="1by1"
            v-if="previewImage.url"
            :style="'height:' + height + 'px;'"
            :src="previewImage.url"
          /> -->

                  <cropper
                    v-if="previewImage"
                    class="cropper"
                    :src="previewImage.url"
                    :resizeImage="{ wheel: false }"
                    :stencil-props="{
                      aspectRatio: 1 / 1,
                    }"
                    @change="change"
                  ></cropper>

                  <br />
                </div>

                <!-- <div
              class="box has-text-centered"
              v-if="!previewImage && existingPicture"
            >
              <button
                class="delete is-small is-pulled-right"
                type="button"
                @click="deletePicture()"
              ></button>

              <br />

                                            <b-image
              ratio="1by1"
                :style="'height:' + height + 'px;'"
                :src="linkToExistingPictureBase + '/' + existingPicture"
              />

              <br />
            </div> -->

                <!-- <b-field>
              <b-upload v-model="file" expanded @input="fileChanged()">
                <a
                  :class="['button is-rounded is-primary is-fullwidth ' + size]"
                >
                  <b-icon icon="upload"></b-icon>
                  <span>{{ computedPictureName || "Kies foto" }}</span>
                </a>
              </b-upload>
            </b-field> -->
                <!-- <p class="help is-danger" v-if="validation[modelKey]">
              {{ validation[modelKey][0] }}
            </p> -->
              </span>
            </b-field>
          </div>
          <div class="column">
            <br />
            <br />
            <br />
            <progress
              v-if="$store.getters['adminProducts/uploadPercentage'] > 0"
              class="progress is-success"
              :value="$store.getters['adminProducts/uploadPercentage']"
              max="100"
            ></progress>
            <button
              class="button is-primary is-rounded"
              :class="{ 'is-loading': ajaxloading.upload_picture }"
              @click.prevent="uploadFile()"
            >
              Upload File</button
            >&nbsp;
            <button class="button is-rounded" @click="closeModal()">
              Cancel
            </button>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  components: {
    Cropper,
  },
  props: {
    picture: {
      required: true,
      type: File,
    },
    product: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      file: new FormData(),
      previewImage: null,
    };
  },
  computed: {},
  methods: {
    fileChanged() {
      this.clearValidationError();
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          let filepreview = {
            name: this.picture.name,
            url: reader.result,
          };
          this.previewImage = filepreview;
        },
        false
      );
      reader.readAsDataURL(this.picture);
    },
    deletePicture() {
      this.previewImage = null;
      this.model.delete(this.modelKey);
      this.file = null;
    },
    clearValidationError() {
      this.$store.dispatch("clearValidationError", this.modelKey);
    },
    change({ coordinates, canvas }) {
      this.file.append("foto" + "_width", coordinates.width);
      this.file.append("foto" + "_height", coordinates.height);
      this.file.append("foto" + "_x", coordinates.left);
      this.file.append("foto" + "_y", coordinates.top);
    },
    closeModal() {
      this.$emit("closeModal");
    },
    uploadFile() {
      this.file.append("product", this.product.id);
      this.$store
        .dispatch("adminProducts/uploadResizedPicture", this.file)
        .then(() => {
          this.$emit("pictureUploaded");
        });
    },
  },
  mounted() {
    this.file.append("picture", this.picture);
    this.fileChanged();
  },
};
</script>
