export const setProducts = function (state, payload) {
  state.products = payload;
};

export const cancelUpdateProduct = function (state, payload) {
  state.product = payload;
  const array = state.products;
  const id = payload.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1);
};

export const setNewProduct = function (state, payload) {
  // formatCurrency(payload);
  state.products.push(payload);
};

export const deleteProduct = function (state, payload) {
  const array = state.products;
  const id = payload.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1);
};

export const deletePicture = function (state, payload) {
  // formatCurrency(payload);
  const array = state.product.pictures;
  const uuid = payload.uuid;
  const index = array.findIndex((a) => a.uuid === uuid);
  array.splice(index, 1);
};

export const updateProduct = function (state, payload) {
  // formatCurrency(payload);
  state.product = payload;
  const array = state.products;
  const id = payload.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1, payload);
};

export const setProduct = function (state, payload) {
  // formatCurrency(payload);
  state.product = payload;
};

export const setUploadPercentage = function (state, payload) {
  state.uploadPercentage = (payload.total / payload.loaded) * 100;
};

// export const cancelUpdateCategory = function(state, payload) {
//   const array = state.categories;
//   const id = payload.id;
//   const index = array.findIndex(a => a.id === id);
//   array.splice(index, 1, payload);
// };

// export const deleteCategory = function(state, payload) {
//   const array = state.categories;
//   const id = payload.id;
//   const index = array.findIndex(a => a.id === id);
//   array.splice(index, 1);
// };
