<style scoped>
.last-hr {
  border: none;
  height: 2px;
  margin-bottom: 4px;
  margin-top: 4px;
  /* Set the hr color */
  color: #333; /* old IE */
  background-color: #333; /* Modern Browsers */
}

.seperator-hr {
  border: none;
  height: 1px;
  margin-bottom: 4px;
  margin-top: 4px;
  /* Set the hr color */
  color: #a9a9a9; /* old IE */
  background-color: #a9a9a9; /* Modern Browsers */
}

.first-hr {
  border: none;
  height: 2px;
  margin-bottom: 4px;
  margin-top: 4px;
  /* Set the hr color */
  color: #333; /* old IE */
  background-color: #333; /* Modern Browsers */
}
</style>

<template>
  <div>
    <div class="columns" v-if="revenue.montly">
      <div class="column no-wrap">
        Category
        <hr class="first-hr" />
        <span v-for="(value, key) in revenue.montly.categories" :key="key">
          {{ key }}
        </span>
        <hr class="seperator-hr" />
        Subtotal
        <hr class="seperator-hr" />
        Shipping <br />
        Payment <br />
        <hr class="last-hr" />
        <strong>Total</strong>
      </div>

      <revenueColumn :revenue="revenue.montly" :columnKey="1" />
      <revenueColumn :revenue="revenue.montly" :columnKey="2" />
      <revenueColumn :revenue="revenue.montly" :columnKey="3" />
      <revenueColumn :revenue="revenue.montly" :columnKey="4" />
      <revenueColumn :revenue="revenue.montly" :columnKey="5" />
      <revenueColumn :revenue="revenue.montly" :columnKey="6" />
      <revenueColumn :revenue="revenue.montly" :columnKey="7" />
      <revenueColumn :revenue="revenue.montly" :columnKey="8" />
      <revenueColumn :revenue="revenue.montly" :columnKey="9" />
      <revenueColumn :revenue="revenue.montly" :columnKey="10" />
      <revenueColumn :revenue="revenue.montly" :columnKey="11" />
      <revenueColumn :revenue="revenue.montly" :columnKey="12" />
      <revenueColumn :revenue="revenue.montly" :columnKey="'total'" />
    </div>
  </div>
</template>

<script>
import revenueColumn from "./AdminRevenueColumn.vue";

export default {
  components: {
    revenueColumn,
  },
  props: {
    revenue: {
      required: true,
    },
    year: {
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>
