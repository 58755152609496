<style scoped>
.column {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.columns {
  margin-bottom: 0px;
  margin-top: 0px;
}

.customInput {
  margin-bottom: 20px;
}
</style>

<template>
  <div style="overflow-y: auto">
    <div class="modal is-active">
      <div class="modal-background" @click="$emit('cancel')"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p v-if="!updateForm.id" class="modal-card-title">Create Address</p>
          <p v-else class="modal-card-title">Update Address</p>
          <button
            class="delete"
            aria-label="close"
            @click="$emit('cancel')"
          ></button>
        </header>
        <section class="modal-card-body">
          <strong v-if="form.different_ship_address">Billing address</strong>
          <strong v-else>Billing and Shipping address</strong>
          <br />
          <br />
          <formSelect
            v-if="settings && settings.countries.length"
            type="password"
            class="customInput"
            modelKey="country"
            :selectOptions="
              settings.countries.map(function (count) {
                return count.display_name;
              })
            "
            label="on-border"
            size="is-small"
            :model="form"
          />

          <span v-if="form.country">
            <b-field>
              <b-checkbox
                class="customCheckbox"
                v-model="form.business"
                size="is-small"
                >I am ordering for a business</b-checkbox
              >
            </b-field>

            <formInput
              v-if="form.business"
              class="customInput"
              :model="form"
              modelKey="business_name"
              label="on-border"
              title="Business name"
              size="is-small"
            />

            <div class="columns">
              <div class="column is-5">
                <formInput
                  class="customInput"
                  :model="form"
                  modelKey="name"
                  label="on-border"
                  size="is-small"
                />
              </div>
              <div class="column is-7">
                <formInput
                  class="customInput"
                  :model="form"
                  modelKey="surname"
                  label="on-border"
                  size="is-small"
                />
              </div>
            </div>

            <span v-if="!findObject.foundBillingAddress">
              <formAutocomplete
                :model="form"
                label="on-border"
                optionKey="description"
                modelKey="autocomplete"
                size="is-small"
                notFoundText="No address found"
                :options="addressOptions"
                :openOnFocus="false"
                validationKey="street"
                title="Address"
                v-on:input="findAddressId()"
                v-on:selected="findAddressDetails"
              />
              <br />

              <formCheckbox
                size="is-small"
                :model="findObject"
                :labelHidden="true"
                modelKey="foundBillingAddress"
                title="Enter address manually"
              />
              <br />
              <br />
              <br />
              <br />
              <br />
            </span>

            <span v-else>
              <formInput
                class="customInput"
                :model="form"
                modelKey="street"
                label="on-border"
                size="is-small"
              />
              <div class="columns">
                <div class="column is-7">
                  <formInput
                    class="customInput"
                    :model="form"
                    modelKey="postal_code"
                    label="on-border"
                    title="Postal code"
                    size="is-small"
                  />
                </div>
                <div class="column is-5">
                  <formInput
                    class="customInput"
                    :model="form"
                    modelKey="house_number"
                    label="on-border"
                    title="House number"
                    size="is-small"
                  />
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <formInput
                    class="customInput"
                    :model="form"
                    modelKey="city"
                    label="on-border"
                    size="is-small"
                  />
                </div>
                <div class="column">
                  <formInput
                    class="customInput"
                    :model="form"
                    modelKey="state"
                    label="on-border"
                    size="is-small"
                  />
                </div>
              </div>

              <b-field>
                <b-checkbox
                  class="customCheckbox"
                  v-model="form.default"
                  size="is-small"
                  >Default address</b-checkbox
                >
              </b-field>

              <b-field>
                <b-checkbox
                  v-model="form.different_ship_address"
                  size="is-small"
                  >Ship to different address</b-checkbox
                >
              </b-field>
            </span>

            <span v-show="form.different_ship_address">
              <strong>Shipping address</strong>
              <br />
              <br />
              <formSelect
                v-if="settings && settings.countries.length"
                class="customInput"
                title="country"
                modelKey="send_country"
                :selectOptions="
                  settings.countries.map(function (count) {
                    return count.display_name;
                  })
                "
                label="on-border"
                size="is-small"
                :model="form"
              />

              <span v-if="form.send_country">
                <b-field>
                  <b-checkbox v-model="form.send_business" size="is-small"
                    >I am ordering for a business</b-checkbox
                  >
                </b-field>
                <formInput
                  v-if="form.send_business"
                  class="customInput"
                  :model="form"
                  modelKey="send_business_name"
                  label="on-border"
                  title="Business name"
                  size="is-small"
                />

                <div class="columns">
                  <div class="column is-5">
                    <formInput
                      class="customInput"
                      :model="form"
                      title="name"
                      modelKey="send_name"
                      label="on-border"
                      size="is-small"
                    />
                  </div>
                  <div class="column is-7">
                    <formInput
                      class="customInput"
                      :model="form"
                      title="surname"
                      modelKey="send_surname"
                      label="on-border"
                      size="is-small"
                    />
                  </div>
                </div>

                <span v-if="!findObject.foundSendAddress">
                  <formAutocomplete
                    :model="form"
                    label="on-border"
                    optionKey="description"
                    modelKey="send_autocomplete"
                    size="is-small"
                    notFoundText="No address found"
                    :options="send_addressOptions"
                    :openOnFocus="false"
                    validationKey="send_street"
                    title="Address"
                    v-on:input="findSendAddressId()"
                    v-on:selected="findSendAddressDetails"
                  />

                  <formCheckbox
                    size="is-small"
                    :model="findObject"
                    :labelHidden="true"
                    modelKey="foundSendAddress"
                    title="Enter address manually"
                  />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </span>

                <span v-else>
                  <formInput
                    class="customInput"
                    :model="form"
                    modelKey="send_street"
                    label="on-border"
                    size="is-small"
                  />
                  <div class="columns">
                    <div class="column is-7">
                      <formInput
                        class="customInput"
                        :model="form"
                        modelKey="send_postal_code"
                        label="on-border"
                        title="Postal code"
                        size="is-small"
                      />
                    </div>
                    <div class="column is-5">
                      <formInput
                        class="customInput"
                        :model="form"
                        modelKey="send_house_number"
                        label="on-border"
                        title="House number"
                        size="is-small"
                      />
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <formInput
                        class="customInput"
                        :model="form"
                        modelKey="send_city"
                        label="on-border"
                        size="is-small"
                      />
                    </div>
                    <div class="column">
                      <formInput
                        class="customInput"
                        :model="form"
                        modelKey="send_state"
                        label="on-border"
                        size="is-small"
                      />
                    </div>
                  </div>
                </span>
              </span>
            </span>
          </span>
        </section>
        <footer
          class="modal-card-foot"
          v-if="
            (form.country && !form.different_ship_address) ||
            (form.country && form.send_country)
          "
        >
          <button
            :class="{ 'is-loading': ajaxloading.creatingAddress }"
            class="button is-success"
            v-if="!updateForm.id"
            @click="create()"
          >
            Create Address
          </button>
          <button
            class="button is-success"
            :class="{ 'is-loading': ajaxloading.creatingAddress }"
            v-if="updateForm.id"
            @click="update()"
          >
            Update Address
          </button>
          <button class="button" @click="$emit('cancel')">Cancel</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  components: {},
  props: {
    updateForm: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      addressOptions: [],
      send_addressOptions: [],
      findObject: {
        foundBillingAddress: false,
        foundSendAddress: false,
      },
      form: {
        business: false,
        send_business: false,
        different_ship_address: false,
        default: true,
      },
    };
  },
  computed: {
    settings() {
      return this.$store.getters["shop/settings"];
    },
    user() {
      return this.$store.getters["auth/user"];
    },
  },
  methods: {
    create() {
      this.$store
        .dispatch("auth/createNewUserAddress", this.form)
        .then((response) => {
          const length = response.data.addresses.length;
          const address = response.data.addresses[length - 1];
          if (!this.form.default) {
            this.$emit("newAddress", address);
          }
          this.$emit("cancel");
        })
        .catch((error) => {});
    },
    async findAddressDetails(address) {
      if (address) {
        const addressDetails = await this.findAddressDetailsMixin(address);

        if (addressDetails.street) {
          this.form.street = addressDetails.street;
        }

        if (addressDetails.house_number) {
          this.form.house_number = addressDetails.house_number;
        }

        if (addressDetails.postal_code) {
          this.form.postal_code = addressDetails.postal_code;
        }

        if (addressDetails.city) {
          this.form.city = addressDetails.city;
        }

        if (addressDetails.state) {
          this.form.state = addressDetails.state;
        }

        this.findObject.foundBillingAddress = true;
      }
    },
    async findSendAddressDetails(address) {
      if (address) {
        const addressDetails = await this.findAddressDetailsMixin(address);

        if (addressDetails.street) {
          this.form.send_street = addressDetails.street;
        }

        if (addressDetails.house_number) {
          this.form.send_house_number = addressDetails.house_number;
        }

        if (addressDetails.postal_code) {
          this.form.send_postal_code = addressDetails.postal_code;
        }

        if (addressDetails.city) {
          this.form.send_city = addressDetails.city;
        }

        if (addressDetails.state) {
          this.form.send_state = addressDetails.state;
        }

        this.findObject.foundSendAddress = true;
      }
    },
    async findAddressId() {
      var country = {};

      if (this.form.country) {
        country = this.settings.countries.find(
          (a) => a.name === this.form.country
        );
      }

      const places = await this.findGooglePlaceIdMixin(
        this.form.autocomplete,
        country
      );
      this.addressOptions = places.predictions;
    },
    async findSendAddressId() {
      var country = {};

      if (this.form.send_country) {
        country = this.settings.countries.find(
          (a) => a.name === this.form.send_country
        );
      }

      const places = await this.findGooglePlaceIdMixin(
        this.form.send_autocomplete,
        country
      );
      this.send_addressOptions = places.predictions;
    },

    // async findAddress() {
    //   if (this.form.postal_code && this.form.house_number) {
    //     const address = await this.findAddressMixin(
    //       this.form.postal_code,
    //       this.form.house_number
    //     );

    //     if (address) {
    //       Vue.set(this.form, "street", address.data.street);
    //       Vue.set(this.form, "city", address.data.city);
    //     }
    //   }
    // },
    // async findSendAddress() {
    //   if (this.form.send_postal_code && this.form.send_house_number) {
    //     const address = await this.findAddressMixin(
    //       this.form.send_postal_code,
    //       this.form.send_house_number
    //     );

    //     if (address) {
    //       Vue.set(this.form, "send_street", address.data.street);
    //       Vue.set(this.form, "send_city", address.data.city);
    //     }
    //   }
    // },
    update() {
      this.$store
        .dispatch("auth/updateUserAddress", this.form)
        .then(() => {
          this.$emit("cancel");
          this.$emit("newAddress", this.form);
        })
        .catch((error) => {});
    },
  },
  watch: {
    form: {
      deep: true,
      handler() {
        if (!this.form.business) {
          this.form.business_name = null;
        }
        if (!this.form.send_business) {
          this.form.send_business_name = null;
        }
      },
    },
  },
  mounted() {
    if (!this.updateForm && !this.updateForm.id) {
      this.form.name = this.user.name + " " + this.user.surname;
      this.form.send_name = this.user.name + " " + this.user.surname;
    }
    if (this.updateForm && this.updateForm.id) {
      this.form = this.updateForm;
      this.findObject.foundBillingAddress = true;
      this.findObject.foundSendAddress = true;
    }
    if (this.form.business_name) {
      Vue.set(this.form, "business", true);
    }
    if (this.form.send_business_name) {
      Vue.set(this.form, "send_business", true);
    }
  },
};
</script>
