export const setInvoices = function (state, payload) {
  state.invoices = payload;
};

export const updateInvoice = function (state, payload) {
  const array = state.invoices;
  const id = payload.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1, payload);
};

export const deleteInvoice = function (state, payload) {
  const array = state.invoices;
  const id = payload.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1);
};
