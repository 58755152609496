<template>
    <b-field
      :id="modelKey"
      :label="hoofdletter(computedTitle)"
      :label-position="label"
      :type="{ 'is-danger': validation[computedValidation] }"
      :message="validation[computedValidation]"
    >
      <b-datepicker
        v-on:input="dateChanged()"
        :size="size"
        v-model="date"
        locale="nl-NL"
        :date-parser="dateParser(date)"
        :date-creator="dateCreator(date)"
        editable
        icon="calendar"
        :first-day-of-week="1"
        :mobile-native="false"
      >
        <template v-slot:trigger>
          <b-button icon-left="calendar" type="is-primary" />
        </template>
      </b-datepicker>
      <b-input
        @keyup.native.enter="editDateChanged()"
        @blur="editDateChanged()"
        expanded
        v-model="editDate"
        :placeholder="placeholder"
      />
    </b-field>
</template>

<script>
import { DateTime } from "luxon";

const dateParser = function (date) {
  if (typeof date === "string" && date.length) {
    date = date.replaceAll("/", "-");
  }
  const dateArray = date.split("-");
  if (dateArray.length > 3 || dateArray.length < 2) {
    return;
  }

  if (dateArray.length === 2) {
    dateArray.push(new Date().getFullYear());
  }
  if (dateArray[2].length === 1) {
    dateArray[2] = "0" + dateArray[2];
  }

  if (dateArray[2].length === 2) {
    const short_year = new Date().getFullYear().toString().substr(-2);
    const short_year_current_year = new Date().getFullYear();

    if (dateArray[2] >= 0 && dateArray[2] <= parseInt(short_year) + 5) {
      dateArray[2] = "20" + dateArray[2];
    } else {
      dateArray[2] = "19" + dateArray[2];
    }
  }

  const newDateString =
    dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0] + " 22:00:00.000";
  return newDateString;
};

export default {
  components: {},
  props: {
    title: {
      type: String,
    },
    model: {
      required: true,
      type: Object,
    },
    modelKey: {
      required: true,
      type: String,
    },
    placeholder: {
      type: String,
    },
    validationKey: {
      type: String,
    },
    size: {
      default: "is-normal",
      type: String,
    },
    label: {
      default: "",
      type: String,
    },
    type: {
      default: "input",
      type: String,
    },
  },
  data() {
    return {
      date: null,
      editDate: null,
    };
  },
  computed: {
    computedTitle() {
      if (this.title) {
        return this.title;
      } else {
        return this.modelKey;
      }
    },
    computedValidation() {
      if (this.validationKey) {
        return this.validationKey;
      } else {
        return this.modelKey;
      }
    },
    computedDate() {
      return this.model[this.modelKey];
    },
  },
  methods: {
    blur() {
      this.$emit("blur");
    },
    clearValidationError() {
      this.$emit("input");
      this.$store.dispatch("clearValidationError", this.modelKey);
    },
    dateParser(date) {
      return dateParser;
    },
    dateCreator(date) {
      new Date();
    },
    editDateChanged() {
      const newDate = dateParser(this.editDate);
      const newDateObject = new Date(newDate);
      const newDateBaseString = newDateObject
        .toISOString()
        .replace("T", " ")
        .replace("Z", "")
        .slice(0, -4);

      this.model[this.modelKey] = newDateBaseString;

      this.date = newDateObject;
      this.editDate = this.formatDatePickerEditDateFromDataBase(
        newDateObject.toISOString()
      );
    },
    dateChanged() {
      this.clearValidationError();
      if (this.date instanceof Date) {
        const date = this.date
          .toISOString()
          .replace("T", " ")
          .replace("Z", "")
          .slice(0, -4);

        this.model[this.modelKey] = date;
      } else {
        this.model[this.modelKey] = null;
      }

      this.editDate = this.formatDatePickerEditDateFromDataBase(
        this.model[this.modelKey]
      );
    },
  },
  watch: {
    computedDate() {
      if (this.date == null) {
        this.date = this.formatDataBaseDateToJSDate(this.model[this.modelKey]);
      }
    },
  },
  mounted() {
    this.editDate = this.formatDatePickerEditDateFromDataBase(
      this.model[this.modelKey]
    );
    this.date = this.formatDataBaseDateToJSDate(this.model[this.modelKey]);
  },
};
</script>
