<style scoped>
.columns {
  margin-bottom: 0px;
}
</style>

<template>
  <div>
    <div class="title" v-if="$router.currentRoute.name === updateRoute">
      Update Country: {{ country.name }}
    </div>
    <div v-else class="title">Add Country</div>

    <formAutocomplete
      :model="form"
      label="on-border"
      optionKey="description"
      modelKey="autocomplete"
      notFoundText="No country found"
      :options="addressOptions"
      :openOnFocus="false"
      title="Country"
      v-on:input="findAddressId()"
      v-on:selected="findAddressDetails"
    />

    <span v-if="foundCountry">
      <br />

      <div class="columns">
        <!-- <div class="column">
          <formInput
            class="customInput"
            :model="form"
            title="Internal name"
            modelKey="name"
            label="on-border"
            placeholder="Internal name of country"
          />
        </div> -->
        <div class="column">
          <formInput
            class="customInput"
            :model="form"
            modelKey="display_name"
            label="on-border"
            title="Display name"
            placeholder="Display name of country"
          />
        </div>
      </div>

      <b-field label="Moss country">
        <b-switch v-model="form.moss_country">
          {{ form.moss_country }}
        </b-switch>
      </b-field>
      <!-- <div class="column">
          <formInput
            class="customInput"
            :model="form"
            modelKey="short_name"
            label="on-border"
            placeholder="Shortname of country"
          />
        </div> -->

      <br />

      <div class="columns">
        <div class="column">
          <formInput
            class="customInput"
            :model="form"
            modelKey="vat_low"
            label="on-border"
            title="BTW laag %"
            placeholder="BTW laag %"
          />
        </div>
        <div class="column">
          <formInput
            class="customInput"
            :model="form"
            modelKey="vat_high"
            label="on-border"
            title="BTW hoog %"
            placeholder="BTW hoog %"
          />
        </div>
      </div>

      <br />

      <formInput
        class="customInput"
        :model="form"
        modelKey="increase_date"
        title="Datum verandering"
        label="on-border"
        placeholder="Datum verandering"
      />

      <br />

      <div class="columns">
        <div class="column">
          <formInput
            class="customInput"
            :model="form"
            modelKey="vat_new_low"
            label="on-border"
            title="BTW laag nieuw %"
            placeholder="BTW laag nieuw %"
          />
        </div>
        <div class="column">
          <formInput
            class="customInput"
            :model="form"
            modelKey="vat_new_hoog"
            label="on-border"
            title="BTW hoog nieuw %"
            placeholder="BTW hoog nieuw %"
          />
        </div>
      </div>
    </span>

    <br />
    <button
      v-if="$router.currentRoute.name === updateRoute"
      class="button is-primary is-rounded"
      :class="{ 'is-loading': ajaxloading[loadingKey] }"
      @click="update()"
    >
      Update
    </button>
    <button
      v-else
      class="button is-primary is-rounded"
      :class="{ 'is-loading': ajaxloading[loadingKey] }"
      @click="submit()"
    >
      Create
    </button>
    &nbsp;
    <button class="button is-rounded" @click.prevent="cancelUpdate()">
      Cancel
    </button>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  components: {},
  props: {},
  data() {
    return {
      foundCountry: false,
      addressOptions: [],
      updateRoute: "admin-prices-edit-country",
      loadingKey: "create_country",
      form: {
        moss_country: false,
      },
    };
  },
  computed: {
    country() {
      return this.$store.getters["adminPrices/country"];
    },
  },
  methods: {
    submit() {
      this.$store
        .dispatch("adminPrices/createCountry", this.form)
        .then((response) => {
          this.$router.replace({
            name: "admin-prices-country",
            params: { country: response.data.id },
          });
        });
    },
    update() {
      this.$store
        .dispatch("adminPrices/updateCountry", this.form)
        .then((response) => {
          this.$router.replace({
            name: "admin-prices-country",
            params: { country: response.data.id },
          });
        });
    },
    cancelUpdate() {
      if (this.form.id) {
        this.$store.dispatch("adminPrices/cancelCountryUpdate", this.country);
      }
      this.$router.go(-1);
    },
    async findAddressId() {
      const places = await this.findGooglePlaceIdMixin(this.form.autocomplete);

      this.addressOptions = places.predictions;
    },
    async findAddressDetails(address) {
      if (address) {
        const addressDetails = await this.findAddressDetailsMixin(address);

        if (addressDetails.country) {
          Vue.set(this.form, "name", addressDetails.country);
          Vue.set(this.form, "display_name", addressDetails.country);
          Vue.set(this.form, "short_name", addressDetails.country_short);
        }

        if (addressDetails.house_number) {
          this.form.house_number = addressDetails.house_number;
        }

        if (addressDetails.postal_code) {
          this.form.postal_code = addressDetails.postal_code;
        }

        if (addressDetails.city) {
          this.form.city = addressDetails.city;
        }

        if (addressDetails.state) {
          this.form.state = addressDetails.state;
        }

        this.foundCountry = true;
      }
    },
  },
  mounted() {
    if (this.$router.currentRoute.name === this.updateRoute) {
      if (this.country && this.country.id) {
        this.form = this.country;
        this.foundCountry = true;
      } else {
        this.$store
          .dispatch(
            "adminPrices/getCountry",
            this.$router.currentRoute.params.country
          )
          .then(() => {
            this.form = this.country;
          });
      }
    }
  },
};
</script>
