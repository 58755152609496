<style scoped>
.greyImage {
  filter: grayscale(100%);
}
</style>

<template>
  <div class="container is-fluid">
    <br />

    <div
      class="button is-outlined is-primary is-fullwidth"
      @click="$router.go(-1)"
    >
      <b-icon icon="arrow-left"></b-icon>&nbsp; Return to categories
    </div>
    <br />

    <!-- <nav class="breadcrumb is-centered" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link tag="a" exact :to="{ name: 'webshop-categories' }"
            >Categories</router-link
          >
        </li>
        <li>
          <a>{{ hoofdletter(this.$route.params.category) }}</a>
        </li>
      </ul>
    </nav> -->

    <div class="columns">
      <div class="column">
        <b-field label="Type">
          <b-checkbox
            :value="true"
            @click.native="tagClicked(tag)"
            v-for="tag of category.tags"
            v-show="checkIfTagLength(tag)"
            :key="tag.id"
            >{{ tag.naam }}</b-checkbox
          >
        </b-field>
      </div>
      <div class="column">
        <b-field label="Sort" class="is-pulled-right is-hidden-mobile">
          <b-select size="is-small" v-model="chosenSort">
            <option value="new-old">Product new - old</option>
            <option value="old-new">Product old - new</option>
            <option value="low-high">Price low - high</option>
            <option value="high-low">Price high - low</option>
          </b-select>
        </b-field>
      </div>
      <div class="column is-hidden-tablet">
        <b-field label="Sort">
          <b-select size="is-small" v-model="chosenSort">
            <option value="new-old">Product new - old</option>
            <option value="old-new">Product old - new</option>
            <option value="low-high">Price low - high</option>
            <option value="high-low">Price high - low</option>
          </b-select>
        </b-field>
      </div>
    </div>

    <div
      class="columns"
      v-for="outerLoop in Math.ceil(filteredProducts.length / maxItemsPerRow)"
      :key="outerLoop"
    >
      <div
        class="column is-2"
        v-for="item in calculatedMax(outerLoop)"
        :key="item"
      >
        <div
          v-if="
            filteredProducts[
              item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
            ]
          "
          class="card pointer"
          @click="
            productClicked(
              filteredProducts[
                item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
              ].slug
            )
          "
        >
          <div class="card-image">
            <figure class="image is-1by1">
              <div
                @mouseover="
                  mouseoverImage(
                    filteredProducts[
                      item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                    ]
                  )
                "
                @mouseleave="mouseleaveImage()"
              >
                <img
                  :class="{
                    greyImage:
                      filteredProducts[
                        item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                      ].status === 'sold',
                  }"
                  v-if="
                    hoveredProduct ===
                    filteredProducts[
                      item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                    ]
                  "
                  :src="
                    calcPictureLength(
                      filteredProducts[
                        item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                      ]
                    )
                      ? env.VUE_APP_API_URL +
                        '/web/picture/' +
                        filteredProducts[
                          item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                        ].pictures[1].uuid
                      : env.VUE_APP_API_URL +
                        '/web/picture/' +
                        filteredProducts[
                          item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                        ].pictures[0].uuid
                  "
                  alt="Product Image"
                />
                <img
                  :class="{
                    greyImage:
                      filteredProducts[
                        item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                      ].status === 'sold',
                  }"
                  v-else
                  :src="
                    env.VUE_APP_API_URL +
                    '/web/picture/' +
                    filteredProducts[
                      item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                    ].pictures[0].uuid
                  "
                  alt="Product Image"
                />
              </div>
            </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media"></div>
              <div class="media-content">
                <div class="content has-text-centered">
                  {{
                    hoofdletter(
                      filteredProducts[
                        item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                      ].name
                    )
                  }}
                  <br />
                  <p
                    v-if="
                      filteredProducts[
                        item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                      ].status === 'sold'
                    "
                  >
                    Sold
                  </p>
                  <p
                    v-else-if="
                      filteredProducts[
                        item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                      ].status === 'preview'
                    "
                  >
                    <em>Coming soon</em>
                  </p>
                  <p v-else>
                    {{
                      euroFromCents(
                        filteredProducts[
                          item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                        ].price
                      )
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import formatInteger from "@/globals/functions/formatInteger.js";
import currency from "currency.js";

export default {
  components: {},
  props: [],
  data() {
    return {
      hoveredProduct: null,
      maxItemsPerRow: 6,
      env: process.env,
      tagsChecked: {},
      filteredProducts: [],
      chosenSort: "new-old",
    };
  },
  computed: {
    products() {
      const categories = this.$store.getters["shop/categories"];

      const category = categories.find(
        (a) => a.slug === this.$route.params.category
      );

      if (category) {
        return category.products;
      } else {
        return [];
      }
    },
    category() {
      const categories = this.$store.getters["shop/categories"];
      const category = categories.find(
        (a) => a.slug === this.$route.params.category
      );
      if (category) {
        this.setTags(category);
        return category;
      } else {
        return {};
      }
    },
    categories() {
      return this.$store.getters["shop/categories"];
    },
  },
  methods: {
    calculatedMax(loop) {
      const max = this.products.length;
      const rows = this.maxItemsPerRow * loop;
      if (max < rows) {
        return max - this.maxItemsPerRow * (loop - 1);
      } else {
        return this.maxItemsPerRow;
      }
    },
    setTags(category) {
      for (let tag of category.tags) {
        if (tag.products.length) {
          this.tagsChecked[tag.id] = true;
        }
      }
      this.setFilteredProducts();
    },
    checkIfTagLength(tag) {
      if (tag.id in this.tagsChecked) {
        return true;
      }
      return false;
    },
    mouseoverImage(product) {
      this.hoveredProduct = product;
    },
    mouseleaveImage() {
      this.hoveredProduct = false;
    },
    calcPictureLength(product) {
      if (product.pictures.length < 2) {
        return false;
      } else {
        return true;
      }
    },
    productClicked(product) {
      this.$router.push({
        name: "webshop-product",
        params: { product: product, category: this.$route.params.category },
      });
    },
    setFilteredProducts() {
      const products = this.products;
      this.filterOnTags(products);
    },
    filterOnTags(products) {
      const filtered = [];
      const tags = [];
      for (let tag in this.tagsChecked) {
        if (this.tagsChecked[tag]) {
          tags.push(Number(tag));
        }
      }

      if (tags.length) {
        for (let product of products) {
          for (let productTag of product.tags) {
            if (tags.includes(productTag.id)) {
              if (!filtered.find((a) => a.id === product.id)) {
                filtered.push(product);
              }
            }
          }
        }
      }
      this.sortProducts(filtered);
    },
    tagClicked(tag) {
      if (this.tagsChecked[tag.id]) {
        this.tagsChecked[tag.id] = false;
      } else {
        this.tagsChecked[tag.id] = true;
      }
      this.setFilteredProducts();
    },
    sortProducts(products) {
      if (this.chosenSort === "new-old") {
        this.sortOnDate(products);
      }

      if (this.chosenSort === "old-new") {
        this.sortOnDate(products, true);
      }

      if (this.chosenSort === "low-high") {
        this.sortOnPrice(products);
      }

      if (this.chosenSort === "high-low") {
        this.sortOnPrice(products, true);
      }

      products.sort((a) => {
        if (a.status === "preview") {
          return -1;
        } else {
          return 1;
        }
      });

      products.sort((a) => {
        if (a.status === "sold") {
          return 1;
        } else {
          return -1;
        }
      });

      this.filteredProducts = products;
    },
    sortOnPrice(products, reverse) {
      for (let product of products) {
        product.sortprice = currency(product.price, {
          separator: ".",
          decimal: ",",
          fromCents: false,
        });
      }

      products.sort(function (a, b) {
        if (a.sortprice.intValue > b.sortprice.intValue) {
          return -1;
        } else {
          return 1;
        }
      });

      if (reverse) {
        products.reverse();
      }
    },
    sortOnDate(products, reverse) {
      products.sort(function (a, b) {
        if (a.updated_at > b.updated_at) {
          return 1;
        } else {
          return -1;
        }
      });

      if (reverse) {
        products.reverse();
      }
    },
  },
  watch: {
    products: {
      deep: true,
      handler() {
        this.setFilteredProducts();
      },
    },
    chosenSort: {
      handler() {
        if (this.$route.query.sort !== this.chosenSort) {
          this.$router.replace({
            query: { sort: this.chosenSort },
          });
          this.$route.query.sort = this.chosenSort;
        }
      },
    },
  },
  mounted() {
    this.setFilteredProducts();
    if (this.$route.query.sort) {
      this.chosenSort = this.$route.query.sort;
    }
  },
};
</script>
