import router from "@/router";

export const updateCategory = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-update-category/" + payload.id;
  data.method = "post";
  data.loading = "edit_category";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("cancelUpdateCategory", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const createCategory = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-create-category";
  data.method = "post";
  data.loading = "edit_category";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setCategory", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const cancelUpdateCategory = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-cancel-update-category/" + payload.id;
  data.method = "post";
  data.loading = "edit_category";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("cancelUpdateCategory", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const deleteCategory = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-delete-category/" + payload.id;
  data.method = "post";
  data.loading = "edit_category";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("deleteCategory", payload);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getCategories = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-get-categories";
  data.method = "get";
  data.loading = "getting-categories";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setCategories", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const changeCategoryPosition = function ({ dispatch, commit }, payload) {
  const array = payload.array;
  const id = payload.id;
  const index = array.findIndex((a) => a.id === id);
  var newIndex;

  if (payload.direction === "up") {
    newIndex = index - 1;
  }

  if (payload.direction === "down") {
    newIndex = index + 1;
  }

  const Otherbuttonid = array[newIndex].id;
  const Oldposition = array[index].position;
  const NewPosition = array[newIndex].position;

  const Otherbutton = array.find((a) => a.id === Otherbuttonid);
  const Clickedbutton = array.find((a) => a.id === id);

  Otherbutton.position = Oldposition;
  Clickedbutton.position = NewPosition;

  const requestData = {
    otherbutton: Otherbutton,
    clickedbutton: Clickedbutton,
  };

  const data = {};
  data.url = "admin-change-category-position";
  data.method = "POST";
  data.data = requestData;
  data.loading = "creatingDeviceButton";
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      // commit("setNewDeviceButton", response.data);
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error.data);
    });
};

export const updateCategoryPicture = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-update-category-picture/" + payload.get("category");
  data.method = "post";
  data.loading = "edit-category-picture";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("cancelUpdateCategory", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const createTag = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-category-create-tag/" + payload.category.id;
  data.method = "post";
  data.loading = "create-category-tag";
  data.data = payload.form;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      const data = {
        response: response,
        category: payload.category,
      };
      commit("setNewTag", data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const updateTag = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url =
    "admin-category-update-tag/" + payload.category_id + "/" + payload.id;
  data.method = "post";
  data.loading = "create-category-tag";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      const data = {
        response: response,
        category: payload.category,
      };
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const cancelTagUpdate = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-category-cancel-update-tag/" + payload.form.id;
  data.method = "post";
  data.loading = "create-category-tag";
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      const data = {
        tag: response.data,
        category: payload.category,
      };
      commit("cancelTagUpdate", data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const deleteTag = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-category-delete-tag/" + payload.tag.id;
  data.method = "post";
  data.loading = "create-category-tag";
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      const data = {
        tag: payload.tag,
        category: payload.category,
      };
      commit("deleteTag", data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
