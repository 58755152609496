<template>
  <div>
    <button
      class="button is-small is-rounded"
      @click="$router.push({ name: 'admin-prices' })"
    >
      Return to countries
    </button>
    <b-tooltip label="Delete country" class="is-pulled-right" position="is-top">
      <button
        @click.prevent="deleteCountry()"
        class="button is-rounded is-danger is-small"
        :class="{ 'is-loading': ajaxloading.deletecountry }"
      >
        Delete country
      </button>
    </b-tooltip>
    <br />
    <br />
    <span class="title">{{ country.name }}</span>
    <br />
    <span class="subtitle">Display name: {{ country.display_name }}</span>
    <br />
    <span class="subtitle">Short name: {{ country.short_name }}</span>
    <br />
    <br />
    <span class="">Vat high: {{ country.vat_high }}%</span>
    <br />
    <span class="">Vat low: {{ country.vat_low }}%</span>

    <b-tooltip class="is-pulled-right" label="Edit country" position="is-left">
      <b-icon
        @click.native="
          $router.push({
            name: 'admin-prices-edit-country',
            params: { country: country.id },
          })
        "
        class="pointer"
        icon="edit"
      >
      </b-icon>
    </b-tooltip>

    <br />
    <br />
    <b-field label="Enabled">
      <b-switch v-model="country.enabled" @input="toggleCountry()">
        {{ country.enabled }}
      </b-switch>
    </b-field>

    <br />
    <br />

    <span v-if="country.id">
      <span class="title is-5">Shipping Methods</span>

      <b-tooltip
        v-if="selectedShippingMethod.id"
        class="is-pulled-right"
        label="delete shipping method"
        position="is-left"
      >
        <b-icon
          @click.native="removeShippingMethodFromCountry()"
          class="pointer"
          icon="trash"
        >
        </b-icon>
      </b-tooltip>

      <b-tooltip
        class="is-pulled-right"
        label="Add shipping method"
        position="is-left"
      >
        <b-icon
          @click.native="showShippingModal = true"
          class="pointer"
          icon="plus"
        >
        </b-icon>
      </b-tooltip>

      <br />
      <br />

      <b-table
        :data="country.shipping_methods"
        :bordered="true"
        :striped="true"
        :hoverable="true"
        :loading="false"
        :focusable="false"
        :mobile-cards="false"
        :selected.sync="selectedShippingMethod"
      >
        <b-table-column
          field="naam"
          sortable
          :label="hoofdletter('Internal name')"
          v-slot="props"
        >
          {{ hoofdletter(props.row.internal_name) }}
        </b-table-column>

        <b-table-column
          field="naam"
          sortable
          :label="hoofdletter('Display name')"
          v-slot="props"
        >
          {{ hoofdletter(props.row.display_name) }}
        </b-table-column>

        <b-table-column sortable :label="hoofdletter('Type')" v-slot="props">
          {{ hoofdletter(props.row.package_type) }}
        </b-table-column>

        <b-table-column
          field="icon"
          sortable
          :label="hoofdletter('icon')"
          v-slot="props"
        >
          <customIcon
            v-if="
              settings &&
              props.row.icon &&
              settings.admin.shipping_methods.find(
                (a) => a.name === props.row.icon
              ).icon_location === 'local'
            "
            :icon="
              settings.admin.shipping_methods.find(
                (a) => a.name === props.row.icon
              ).icon_name
            "
          />

          <b-icon
            v-if="
              settings &&
              props.row.icon &&
              settings.admin.shipping_methods.find(
                (a) => a.name === props.row.icon
              ).icon_location === 'font awesome'
            "
            :pack="
              settings.admin.shipping_methods.find(
                (a) => a.name === props.row.icon
              ).icon_pack
            "
            :icon="
              settings.admin.shipping_methods.find(
                (a) => a.name === props.row.icon
              ).icon_name
            "
          ></b-icon>
        </b-table-column>

        <b-table-column
          sortable
          :label="hoofdletter('min_weight')"
          v-slot="props"
        >
          {{ props.row.min_weight }} gr.
        </b-table-column>

        <b-table-column
          sortable
          :label="hoofdletter('max_weight')"
          v-slot="props"
        >
          {{ props.row.max_weight }} gr.
        </b-table-column>

        <b-table-column
          field="prijs"
          sortable
          :label="hoofdletter('price')"
          v-slot="props"
        >
          {{ euroFromCents(props.row.price) }}
        </b-table-column>
      </b-table>

      <br />
      <br />

      <span class="title is-5">Payment Methods</span>

      <b-tooltip
        v-if="selectedPaymentMethod.id"
        class="is-pulled-right"
        label="Add payment method"
        position="is-left"
      >
        <b-icon
          @click.native="deletePaymentMethod()"
          class="pointer"
          icon="trash"
        >
        </b-icon>
      </b-tooltip>

      <b-tooltip
        class="is-pulled-right"
        label="Add payment method"
        position="is-left"
      >
        <b-icon
          @click.native="showPaymentModal = true"
          class="pointer"
          icon="plus"
        >
        </b-icon>
      </b-tooltip>

      <br />
      <br />

      <b-table
        :data="country.payment_methods"
        :bordered="true"
        :striped="true"
        :hoverable="true"
        :loading="false"
        :focusable="false"
        :mobile-cards="false"
        :selected.sync="selectedPaymentMethod"
      >
        <b-table-column
          field="naam"
          sortable
          :label="hoofdletter('Internal name')"
          v-slot="props"
        >
          {{ hoofdletter(props.row.internal_name) }}
        </b-table-column>

        <b-table-column
          field="naam"
          sortable
          :label="hoofdletter('Display name')"
          v-slot="props"
        >
          {{ hoofdletter(props.row.display_name) }}
        </b-table-column>

        <b-table-column
          field="icon"
          sortable
          :label="hoofdletter('icon')"
          v-slot="props"
        >
          <customIcon
            v-if="
              settings &&
              props.row.icon &&
              settings.admin.payment_methods.find(
                (a) => a.name === props.row.icon
              ).icon_location === 'local'
            "
            :icon="
              settings.admin.payment_methods.find(
                (a) => a.name === props.row.icon
              ).icon_name
            "
          />

          <b-icon
            v-if="
              settings &&
              props.row.icon &&
              settings.admin.payment_methods.find(
                (a) => a.name === props.row.icon
              ).icon_location === 'font awesome'
            "
            :pack="
              settings.admin.payment_methods.find(
                (a) => a.name === props.row.icon
              ).icon_pack
            "
            :icon="
              settings.admin.payment_methods.find(
                (a) => a.name === props.row.icon
              ).icon_name
            "
          ></b-icon>
        </b-table-column>

        <b-table-column
          field="prijs"
          sortable
          :label="hoofdletter('price')"
          v-slot="props"
        >
          {{ euroFromCents(props.row.price) }}
        </b-table-column>

        <b-table-column
          sortable
          :label="hoofdletter('Opslag %')"
          v-slot="props"
        >
          {{ props.row.opslag / 100 }}%
        </b-table-column>
      </b-table>
    </span>
    <paymentModal
      :country="country"
      v-on:close="closePaymentModal()"
      v-if="showPaymentModal"
    />

    <shippingModal
      :country="country"
      v-on:close="closeShippingModal()"
      v-if="showShippingModal"
    />
  </div>
</template>

<script>
import paymentModal from "./AdminAddPaymentMethodToCountryModal.vue";
import shippingModal from "./AdminAddShippingMethodToCountryModal.vue";

export default {
  components: {
    paymentModal,
    shippingModal,
  },
  props: [],
  data() {
    return {
      showPaymentModal: false,
      showShippingModal: false,
      selectedShippingMethod: {},
      selectedPaymentMethod: {},
    };
  },
  computed: {
    country() {
      return this.$store.getters["adminPrices/country"];
    },
    settings() {
      return this.$store.getters["shop/settings"];
    },
  },
  methods: {
    toggleCountry() {
      this.$store.dispatch("adminPrices/ToggleCountry", this.country);
    },
    updateShippingMethod() {
      this.$store
        .dispatch("adminPrices/setShippingMethod", this.selectedShippingMethod)
        .then(() => {
          this.$router.push({
            name: "admin-prices-edit-shipping_method",
            params: { country: this.country.id },
          });
        });
    },
    updatePaymentMethod() {
      this.$store
        .dispatch("adminPrices/setPaymentMethod", this.selectedPaymentMethod)
        .then(() => {
          this.$router.push({
            name: "admin-prices-edit-payment_method",
            params: { country: this.country.id },
          });
        });
    },
    deleteShippingMethod() {
      this.$buefy.dialog.confirm({
        title: "Deleting shipping method",
        message:
          "Are you sure you want to <b>delete</b> this shipping method? This action cannot be undone.",
        confirmText: "Delete shipping method",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          this.$store
            .dispatch(
              "adminPrices/deleteShippingMethod",
              this.selectedShippingMethod
            )
            .then(() => {
              this.selectedShippingMethod = {};
            }),
      });
    },
    removeShippingMethodFromCountry() {
      this.$buefy.dialog.confirm({
        title: "Deleting shipping method",
        message:
          "Are you sure you want to <b>delete</b> this shipping method from this country? <br> This action cannot be undone.",
        confirmText: "Delete shipping method",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          this.$store
            .dispatch("adminPrices/removeShippingMethodFromCountry", {
              country: this.country,
              shipping_method: this.selectedShippingMethod.id,
            })
            .then(() => {
              this.selectedShippingMethod = {};
            }),
      });
    },
    deletePaymentMethod() {
      this.$buefy.dialog.confirm({
        title: "Deleting payment method",
        message:
          "Are you sure you want to <b>delete</b> this payment method from this country? <br> This action cannot be undone.",
        confirmText: "Delete payment method",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          this.$store
            .dispatch("adminPrices/removePaymentMethodFromCountry", {
              country: this.country,
              payment_method: this.selectedPaymentMethod.id,
            })
            .then(() => {
              this.selectedPaymentMethod = {};
            }),
      });
    },
    closePaymentModal() {
      this.showPaymentModal = false;
    },
    closeShippingModal() {
      this.showShippingModal = false;
    },
    deleteCountry() {
      this.$buefy.dialog.confirm({
        title: "Deleting country",
        message:
          "Are you sure you want to <b>delete</b> this country? This action cannot be undone.",
        confirmText: "Delete country",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          this.$store
            .dispatch("adminPrices/deleteCountry", this.country)
            .then(() => {
              this.$router.replace({ name: "admin-prices" });
            }),
      });
    },
  },
  mounted() {
    this.$store.dispatch(
      "adminPrices/getCountry",
      this.$router.currentRoute.params.country
    );
  },
};
</script>
