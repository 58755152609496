export const setShippingLabels = function (state, payload) {
  state.shipping_labels = payload;
};

export const setNewShippingLabel = function (state, payload) {
  state.shipping_labels.push(payload);
};

export const removeShippingLabel = function (state, payload) {
  const array = state.shipping_labels;
  const id = payload.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1);
};

export const updateShippingLabel = function (state, payload) {
  const array = state.shipping_labels;
  const id = payload.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1, payload);
};
