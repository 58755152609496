import Vue from "vue";

const customMounted = Vue.mixin({
  methods: {
    customMounted(payload) {
      return this.$store.dispatch("auth/getTokenBeforeMount", payload);
    },
  },
});

export default customMounted;
