<style scoped>
.customInput {
  margin-bottom: 10px;
}

.modal {
  width: 100%;
  display: block !important;
}

.modal-card {
  width: 100%;
  overflow-y: initial !important
  
}

.modal-card-body {
  width: 100%;
  height: 80vh;
}
</style>

<template>
  <div>
    <div class="modal is-active">
      <div class="modal-background" @click="closeModal()"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Update {{ model }}</p>

          <button
            class="delete"
            aria-label="close"
            @click="closeModal()"
          ></button>
        </header>
        <form @submit.prevent="submit()">
          <section class="modal-card-body">
            <formPictureUpload
              class="customInput"
              :preview="true"
              :model="formData"
              modelKey="foto"
              :title="model"
              size="is-small"
              height="400"
            />
          </section>

          <footer class="modal-card-foot">
            <form-button
              type="is-primary"
              title="Update"
              size="is-small"
              loadingKey="edit-category-picture"
            />

            <button class="button is-rounded is-small" @click="closeModal()">
              Annuleren
            </button>
          </footer>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    editPicture: {
      type: String,
      required: true,
    },
    category: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      model: "foto",
      formData: new FormData(),
    };
  },
  computed: {},
  methods: {
    closeModal() {
      if (this.updateForm) {
        this.$store.dispatch(
          "adminCategories/cancelUpdateCategory",
          this.updateForm
        );
      }
      this.$emit("closeModal");
    },
    submit() {
      this.update();
    },
    update() {
      this.formData.append("category", this.category.id);
      this.formData.append("picture_key", this.editPicture);
      this.$store
        .dispatch("adminCategories/updateCategoryPicture", this.formData)
        .then(() => {
          this.closeModal();
        });
    },
  },
  mounted() {
    if (this.editPicture === "main_picture") {
      this.model = "Main picture";
    } else {
      this.model = "Hover picture";
    }
    this.$store.dispatch("clearValidationError", this.modelKey);
  },
};
</script>
