export const toggleLoginModal = function (state, payload) {
  state.loginModal = payload;
};

export const toggleRegisterModal = function (state, payload) {
  state.registerModal = payload;
};

export const toggleResetPasswordModal = function (state, payload) {
  state.resetPasswordModal = payload;
};

export const setUser = function (state, payload) {
  state.user = payload;
};

export const setOrders = function (state, payload) {
  state.orders = payload;
};
