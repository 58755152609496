import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import state from "./state";
import * as mutations from "./mutations";
import * as getters from "./getters";
import * as actions from "./actions";

import cart from "@/store/cart";
import auth from "@/store/auth";
import shop from "@/store/shop";
import adminCategories from "@/store/admin/adminCategories";
import adminInvoices from "@/store/admin/adminInvoices";
import adminCommands from "@/store/admin/adminCommands";
import adminProducts from "@/store/admin/adminProducts";
import adminShipping from "@/store/admin/adminShipping";
import adminPrices from "@/store/admin/adminPrices";
import adminOrders from "@/store/admin/adminOrders";
import adminUsers from "@/store/admin/adminUsers";
import adminRevenue from "@/store/admin/adminRevenue";
import websocket from "@/store/websocket";

export default new Vuex.Store({
  modules: {
    cart,
    auth,
    shop,
    adminCategories,
    adminInvoices,
    adminProducts,
    adminShipping,
    websocket,
    adminPrices,
    adminCommands,
    adminOrders,
    adminRevenue,
    adminUsers,
  },
  state,
  mutations,
  getters,
  actions,
});
