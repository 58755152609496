var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container box"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4"},[_c('div',{},[(!_vm.previewImage)?_c('pagePictureShower',{attrs:{"variable_key":_vm.modelKey,"settings":_vm.settings}}):_vm._e()],1)]),(_vm.settings && _vm.settings.variables)?_c('div',{staticClass:"column has-text-centered"},[(
          _vm.settings.variables.find((a) => a.key === _vm.modelKey) &&
          _vm.settings.variables.find((a) => a.key === _vm.modelKey).value != ''
        )?_c('span',_vm._l((_vm.settings.variables.find((a) => a.key === _vm.modelKey)
            .value.length),function(key){return _c('span',{key:key},[_c('div',{staticClass:"button is-fullwidth is-primary is-outlined",class:{
              'is-loading': _vm.ajaxloading['destroy_picture' + key + _vm.modelKey],
            },on:{"click":function($event){return _vm.deletePictureApi(key)}}},[_vm._v(" Delete Picture "+_vm._s(key)+" ")]),_c('br')])}),0):_vm._e()]):_vm._e()]),_c('b-field',{attrs:{"label":_vm.computedTitle}},[(_vm.previewImage)?_c('div',[_c('div',{staticClass:"box has-text-centered"},[_c('button',{staticClass:"delete is-small is-pulled-right",attrs:{"type":"button"},on:{"click":function($event){return _vm.deletePicture()}}}),(_vm.previewImage && _vm.cropper)?_c('cropper',{staticClass:"cropper",style:('height:' + _vm.height + 'px'),attrs:{"src":_vm.previewImage.url,"resizeImage":{ wheel: true },"stencil-props":{
            scalable: false,
            aspectRatio: _vm.aspectRatio,
          }},on:{"change":_vm.change}}):_vm._e()],1)]):_vm._e(),_c('b-field',[_c('b-upload',{attrs:{"expanded":""},on:{"input":function($event){return _vm.fileChanged()}},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}},[_c('a',{class:['button is-outlined  is-primary is-fullwidth ' + _vm.size]},[_c('b-icon',{attrs:{"icon":"upload"}}),_c('span',[_vm._v(_vm._s(_vm.computedPictureName || "Click to upload"))])],1)])],1),(_vm.validation['foto'])?_c('p',{staticClass:"help is-danger"},[_vm._v(" "+_vm._s(_vm.validation["foto"][0])+" ")]):_vm._e()],1),(_vm.upload.get('foto'))?_c('div',{staticClass:"button is-primary is-fullwidth",class:{ 'is-loading': _vm.ajaxloading.save_variables },on:{"click":function($event){return _vm.savePicture()}}},[_vm._v(" Upload ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }