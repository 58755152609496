<style scoped>
.column {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.columns {
  margin-bottom: 0px;
  margin-top: 0px;
}

.customInput {
  margin-bottom: 20px;
}
</style>

<template>
  <div class="box">
    <div class="columns">
      <div class="column is-6">
        <formInput
          class="customInput"
          :model="form"
          modelKey="name"
          label="on-border"
          size="is-small"
        />
      </div>
      <div class="column is-6">
        <formInput
          class="customInput"
          :model="form"
          modelKey="surname"
          label="on-border"
          size="is-small"
        />
      </div>
    </div>
    <formInput
      class="customInput"
      :model="form"
      modelKey="email"
      label="on-border"
      size="is-small"
    />

    <formInput
      class="customInput"
      :model="form"
      modelKey="email_confirmation"
      title="Confirm email"
      label="on-border"
      size="is-small"
    />

    <button
      @click="saveUserData()"
      class="button is-rounded is-primary is-small"
      :class="{ 'is-loading': ajaxloading.updateuserdata }"
    >
      Save Changes
    </button>
    <button class="button is-rounded is-small" @click="$router.go(-1)">
      Cancel
    </button>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      business: false,
    };
  },
  computed: {},
  methods: {
    saveUserData() {
      return this.$store.dispatch("auth/updateUserData", this.form).then(() => {
        this.$buefy.toast.open({
          message: "User data updated.",
          type: "is-success",
        });
      });
    },
  },
  mounted() {},
};
</script>
