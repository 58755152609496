import axios from "axios";
import router from "@/router";
import store from "@/store";

export const axiosrequest = axios.interceptors.request.use((config) => {
  store.dispatch("clearValidationErrors");
  return config;
});

export const axiosresponse = axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // if (error.message === "Geannuleerd") {
    //   return Promise.reject(error);
    // }
    // if (error.message === "Your email address is not verified") {
    //   return Promise.reject(error);
    // }
    if (error.response) {
      if (error.response.status === 401) {
        //iemand anders is ingelogd met dezelfde account / of is niet ingelogd
        // store.dispatch("auth/clearLoginData");
        store.dispatch("auth/clearUser");
        router.replace({ name: "webshop-categories" });
        store.dispatch("createFlashBanner", {
          message: "You are not logged in, please log in to continue.",
          timeout: 5,
        });
        return Promise.reject(error);
      }

      if (error.response.status === 422) {
        //validatie error van geposte form data

        store.dispatch("setValidationErrors", error.response.data.errors);
        return Promise.reject(error);
      }
      if (error.response.status === 419) {
        //validatie error van geposte form data

        store.dispatch("auth/getLoggedInUser").then(() => {
          return Promise.reject(error);
        });
      }
      if (error.response.status === 403) {
        //iemand anders is ingelogd met dezelfde account / of is niet ingelogd
        store.dispatch("createFlashBanner", {
          message: "This action is forbidden",
          timeout: 3,
          type: "is-danger",
        });
        return Promise.reject(error);
      }
      if (error.response.status === 404) {
        router.replace({ name: "404" });
        return Promise.reject(error);
      }

      if (error.response.status === 480) {
        //iemand anders is ingelogd met dezelfde account / of is niet ingelogd
        // // store.dispatch("auth/clearLoginData");
        // var message =
        //   "Unfortunally the store is closed, please come back later.";
        // // var settings = store.getters["shop/settings"];

        // // const closedMessage = settings.variables.find(
        // //   (a) => a.key === "website_closed_message"
        // // );

        // // if (closedMessage) {
        // //   message = closedMessage.value;
        // // }

        // store.dispatch("createFlashBanner", {
        //   message: message,
        //   type: "is-danger",
        //   html: true,
        //   timeout: 30,
        // });

        store.dispatch("shop/setClosedModal", true);
        return Promise.reject(error);
      }

      if (error.response.status === 490) {
        //iemand anders is ingelogd met dezelfde account / of is niet ingelogd
        // // store.dispatch("auth/clearLoginData");
        // var message =
        //   "Unfortunally the store is closed, please come back later.";
        // // var settings = store.getters["shop/settings"];

        // // const closedMessage = settings.variables.find(
        // //   (a) => a.key === "website_closed_message"
        // // );

        // // if (closedMessage) {
        // //   message = closedMessage.value;
        // // }

        store.dispatch("createFlashBanner", {
          message:
            "Some products were unavailable. These products will be automatically removed from your cart.",
          type: "is-danger",
          html: true,
          timeout: 5,
        });

        store.dispatch("shop/getCategories").then((response) => {
          store.dispatch("cart/syncCartToProducts", response.data);
        });

        return Promise.reject(error);
      }

      if (error.response.status === 496) {
        //iemand anders is ingelogd met dezelfde account / of is niet ingelogd
        // // store.dispatch("auth/clearLoginData");
        // var message =
        //   "Unfortunally the store is closed, please come back later.";
        // // var settings = store.getters["shop/settings"];

        // // const closedMessage = settings.variables.find(
        // //   (a) => a.key === "website_closed_message"
        // // );

        // // if (closedMessage) {
        // //   message = closedMessage.value;
        // // }

        store.dispatch("createFlashBanner", {
          message:
            "Your order expired, we could not complete your order. If you still wish to order, please try again.",
          type: "is-danger",
          html: true,
          timeout: 5,
        });

        store.dispatch("shop/getCategories").then((response) => {
          store.dispatch("cart/syncCartToProducts", response.data);
        });

        return Promise.reject(error);
      }

      if (error.response.status === 497) {
        store.dispatch("createFlashBanner", {
          message: "Cant find payment id for order",
          type: "is-danger",
          html: true,
          timeout: 10,
        });
        return Promise.reject(error);
      }

      if (error.response.status === 495) {
        //iemand anders is ingelogd met dezelfde account / of is niet ingelogd
        // // store.dispatch("auth/clearLoginData");
        // var message =
        //   "Unfortunally the store is closed, please come back later.";
        // // var settings = store.getters["shop/settings"];

        // // const closedMessage = settings.variables.find(
        // //   (a) => a.key === "website_closed_message"
        // // );

        // // if (closedMessage) {
        // //   message = closedMessage.value;
        // // }

        store.dispatch("createFlashBanner", {
          message:
            "Something went wrong and this error has been logged. Please contact us for more information.",
          type: "is-danger",
          html: true,
          timeout: 10,
        });

        store.dispatch("shop/getCategories").then((response) => {
          store.dispatch("cart/syncCartToProducts", response.data);
        });

        return Promise.reject(error);
      }

      if (error.response.status === 503) {
        //iemand anders is ingelogd met dezelfde account / of is niet ingelogd
        store.dispatch("createFlashBanner", {
          message: "The website is being updated, please come back later",
          timeout: 10,
          type: "is-danger",
        });
        return Promise.reject(error);
      }
      //   //apparaat niet gevalideerd
      //   if (error.response.status === 444) {
      //     router.replace({ name: "onbekendApparaat" });
      //     return Promise.reject(error);
      //   }
      //   //apparaat validatie cookie niet bevestigd via email
      //   if (error.response.status === 445) {
      //     router.replace({ name: "apparaatEmail" });
      //     return Promise.reject(error);
      //   }
      //   //apparaat validatielink is niet geldig
      //   if (error.response.status === 446) {
      //     //wordt afgehandeld in component
      //     return Promise.reject(error);
      //   }
      //   //apparaat cookie is verlopen
      //   if (error.response.status === 447) {
      //     router.replace({ name: "apparaatEmail" });
      //     return Promise.reject(error);
      //   }

      //   //apparaat validatielink is verlopen
      //   if (error.response.status === 448) {
      //     //wordt afgehandeld in component
      //     return Promise.reject(error);
      //   }

      //   //csfr token niet meer geldig, refreshing pagina
      //   if (error.response.status === 419) {
      //     store.dispatch("auth/clearLoginData");
      //     router.replace({ name: "login" });
      //     location.reload();
      //     return Promise.reject(error);
      //   }
      return Promise.reject(error);
    }
  }
);
