<template>
  <div>
    <span class="title is-5">Users</span>
    <span class="block table-icons is-pulled-right">
      <span v-if="selected">
        <b-tooltip label="Gebruiker bekijken" position="is-top">
          <span class="pointer table-icon" @click="showUser(selected)">
            <b-icon icon="search"></b-icon>
          </span>
        </b-tooltip>
      </span>

      <span v-if="selected">
        <b-tooltip
          label="Gebruiker admin maken"
          v-if="selected.role !== 'admin'"
          position="is-top"
        >
          <span class="pointer table-icon" @click="makeUserAdmin()">
            <b-icon icon="user"></b-icon>
          </span>
        </b-tooltip>
      </span>

      <span v-if="selected">
        <b-tooltip
          label="Gebruiker user maken"
          v-if="selected.role !== 'user'"
          position="is-top"
        >
          <span class="pointer table-icon" @click="removeAdminFromUser()">
            <b-icon icon="user-slash"></b-icon>
          </span>
        </b-tooltip>
      </span>

      <span v-if="selected">
        <b-tooltip
          label="Gebruiker activeren"
          v-if="selected.status !== 'actief'"
          position="is-top"
        >
          <span class="pointer table-icon" @click="unlockUser()">
            <b-icon icon="lock-open"></b-icon>
          </span>
        </b-tooltip>
      </span>
      <span v-if="selected">
        <b-tooltip
          label="Gebruiker deactiveren"
          v-if="selected.status === 'actief'"
          position="is-top"
        >
          <span class="pointer table-icon" @click="lockUser()">
            <b-icon icon="lock"></b-icon>
          </span>
        </b-tooltip>
      </span>
      <span v-if="selected">
        <b-tooltip label="Gebruiker verwijderen" position="is-top">
          <span class="pointer table-icon" @click="deleteUser()">
            <b-icon icon="trash"></b-icon>
          </span>
        </b-tooltip>
      </span>
    </span>

    <br />
    <br />

    <b-dropdown :mobile-modal="false" expanded>
      <template #trigger>
        <b-field label="">
          <b-input
            expanded
            v-model="zoekwaarde"
            @input="searchUsers()"
            placeholder="Zoeken..."
          ></b-input>
        </b-field>
      </template>

      <span v-if="dropdownAddresses.length || dropdownUsers.length">
        <b-dropdown-item custom :focusable="false">
          <strong>Users</strong>
        </b-dropdown-item>

        <span v-if="dropdownUsers.length && zoekwaarde.length">
          <b-dropdown-item
            :value="user.id"
            v-for="user in dropdownUsers"
            :key="user.id"
          >
            <div class="box" @click="showUser(user)">
              <span
                >{{ user.id }} - {{ user.name }} {{ user.surname }} -
                {{ user.email }}</span
              >
            </div>
          </b-dropdown-item>
        </span>

        <b-dropdown-item custom :focusable="false">
          <strong>Addresses</strong>
        </b-dropdown-item>

        <span v-if="dropdownAddresses.length && zoekwaarde.length">
          <b-dropdown-item
            :value="address.id"
            v-for="address in dropdownAddresses"
            :key="address.id"
          >
            <div class="box" @click="showUser(address.user)">
              <span v-if="address.user && address.user.id"
                ><strong
                  >{{ address.user.id }} - {{ address.user.name }}
                  {{ address.user.surname }} - {{ address.user.email }}</strong
                ></span
              >
              <span v-else>
                <strong>No user found with this address</strong>
              </span>
              <br />
              <span
                >{{ address.street }} - {{ address.house_number }} |
                {{ address.postal_code }} {{ address.city }}
                {{ address.country }} | {{ address.name }}
                {{ address.surname }}
                <span v-if="address.business_name">|</span>
                {{ address.business_name }}
              </span>
              <span v-if="address.different_ship_address">
                <br />
                {{ address.send_street }} - {{ address.send_house_number }} |
                {{ address.send_postal_code }} {{ address.send_city }}
                {{ address.send_country }} | {{ address.send_name }}
                {{ address.send_surname }}
                <span v-if="address.send_business_name">|</span>
                {{ address.send_business_name }}
              </span>
            </div>
          </b-dropdown-item>
        </span>
      </span>
    </b-dropdown>

    <br />
    <br />

    <b-table
      :data="users"
      :bordered="true"
      :striped="true"
      :narrowed="true"
      :selected.sync="selected"
      @dblclick="showUser(selected)"
    >
      <b-table-column field="id" label="ID" width="40" numeric v-slot="props">
        {{ props.row.id }}
      </b-table-column>

      <b-table-column label="Name" sortable v-slot="props">
        {{ props.row.name }} {{ props.row.surname }}
      </b-table-column>

      <b-table-column label="Email" sortable v-slot="props">
        <a :href="'mailto:' + props.row.email">{{ props.row.email }}</a>
      </b-table-column>

      <b-table-column label="Orders" sortable v-slot="props">
        {{ props.row.orders.length }}
      </b-table-column>

      <b-table-column label="Role" sortable v-slot="props">
        {{ props.row.role }}
      </b-table-column>

      <b-table-column label="Status" sortable v-slot="props">
        {{ props.row.status }}
      </b-table-column>

      <b-table-column label="Created on" sortable v-slot="props">
        {{ formatDatabaseDate(props.row.created_at) }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      selected: null,
      selectedOptions: [],
      zoekwaarde: null,
      dropdownUsers: [],
      dropdownAddresses: [],
    };
  },
  computed: {
    users() {
      const users = this.$store.getters["adminUsers/users"];
      users.sort((a, b) => {
        return a.id - b.id;
      });
      return users;
    },
  },
  methods: {
    showUser(user) {
      // this.$store.dispatch("adminUsers/setUser", user).then(() => {
      this.$router.push({ name: "admin-user", params: { user: user.id } });
      // });
    },
    makeUserAdmin() {
      this.$buefy.dialog.confirm({
        title: "Make user admin",
        message: "Are you sure you want to make this user an admin?",
        confirmText: "Promote to admin",
        type: "is-warning",
        hasIcon: true,
        onConfirm: () =>
          this.$store.dispatch("adminUsers/makeUserAdmin", this.selected),
      });
    },
    searchUsers() {
      if (this.zoekwaarde.length === 0) {
        this.dropdownUsers = [];
        this.dropdownAddresses = [];
        return;
      }
      this.$store
        .dispatch("adminUsers/searchUsers", this.zoekwaarde)
        .then((response) => {
          if (this.zoekwaarde.length > 0) {
            this.dropdownUsers = response.data.users;
            this.dropdownAddresses = response.data.addresses;
          }
        });
    },
    removeAdminFromUser() {
      this.$store.dispatch("adminUsers/setUserRoleToUser", this.selected);
    },
    lockUser() {
      this.$store.dispatch("adminUsers/lockUser", this.selected);
    },
    unlockUser() {
      this.$store.dispatch("adminUsers/unlockUser", this.selected);
    },
    deleteUser() {
      this.$buefy.dialog.confirm({
        title: "Deleting user",
        message: "Are you sure you want to <b>delete</b> this user?.",
        confirmText: "Delete user",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          this.$store.dispatch("adminUsers/deleteUser", this.selected),
      });
    },
  },
  mounted() {
    this.$store.dispatch("adminUsers/getUsers");
  },
};
</script>
