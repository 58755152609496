<style scoped>
.custommodal {
  position: fixed;
  height: 100%;
  width: 100%;
}

.customInput {
  margin-bottom: 10px;
}

.card {
  z-index: 9999999999;
  overflow: auto;
}
</style>

<template>
  <div>
    <div class="modal is-active is-fullscreen">
      <div class="modal-background" @click="closeModal()"></div>
      <div class="card">
        <header class="modal-card-head">
          <p class="modal-card-title" v-if="updateForm">
            {{ hoofdletter(model) }} aanpassen
          </p>
          <p class="modal-card-title" v-else>
            {{ hoofdletter(model) }} aanmaken
          </p>
          <button
            class="delete"
            aria-label="close"
            @click="closeModal()"
          ></button>
        </header>
        <form @submit.prevent="submit()">
          <section class="modal-card-body">
            <formInput
              class="customInput"
              modelKey="naam"
              :model="form"
              placeholder="Naam van categorie"
            />

            <formPictureUpload
              class="customInput"
              :preview="true"
              :model="formData"
              :cropper="false"
              modelKey="main_picture"
              title="Primaire foto"
              size="is-small"
              height="150"
            />

            <formPictureUpload
              class="customInput"
              :preview="true"
              :cropper="false"
              :model="formData"
              modelKey="alt_picture"
              title="Hover foto"
              size="is-small"
              height="150"
            />

            <formTags
              class="customInput"
              :model="form"
              modelKey="tags"
              size="is-small"
            />

            <formCheckbox
              :model="form"
              modelKey="visible"
              title="Category visible"
              :labelHidden="true"
            />
          </section>

          <footer class="modal-card-foot">
            <form-button
              type="is-primary"
              :title="updateForm ? `Updaten` : `Aanmaken`"
              size="is-small"
              loadingKey="edit_category"
            />

            <button class="button is-rounded is-small" @click="closeModal()">
              Annuleren
            </button>
          </footer>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    updateForm: {
      type: Object,
    },
  },
  data() {
    return {
      model: "categorie",
      form: {
        visible: true,
      },
      formData: new FormData(),
    };
  },
  computed: {},
  methods: {
    closeModal() {
      if (this.updateForm) {
        this.$store.dispatch(
          "adminCategories/cancelUpdateCategory",
          this.updateForm
        );
      }
      this.$emit("closeModal");
    },
    submit() {
      if (this.updateForm) {
        this.update();
      } else {
        this.create();
      }
    },
    create() {
      for (const [key, value] of Object.entries(this.form)) {
        this.formData.append(key, value);
      }
      this.$store
        .dispatch("adminCategories/createCategory", this.formData)
        .then(() => {
          this.closeModal();
        });
    },
  },
  mounted() {
    if (this.updateForm) {
      this.form = this.updateForm;
    }
    this.$store.dispatch("clearValidationError", this.modelKey);
  },
};
</script>
