import router from "@/router";
import axios from "axios";

export const createProduct = function ({ dispatch, commit }, payload) {
  // let i = 0;
  // payload.images.forEach(element => {
  //   i++;
  // });

  // payload.form.images = i;

  const data = {};
  data.url = "admin-product-create";
  data.method = "post";
  data.loading = "create_product";
  // data.data = payload.form;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setNewProduct", response.data);
      commit("setProduct", response.data);
      dispatch("getProducts");
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const uploadPictures = function ({ dispatch, commit }, payload) {
  const formData = new FormData();

  let i = 0;
  payload.images.forEach((element) => {
    i++;
    formData.append(i, element);
  });

  formData.append("images", i);

  const data = {};
  data.url = "admin-product-create-pictures/" + payload.product.id;
  data.method = "post";
  data.loading = "create_product";
  data.data = formData;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      dispatch("adminCategories/getCategories", "", { root: true });
      dispatch("getProducts");
      commit("setNewProduct", response.data);
      router.push({
        name: "admin-product",
        params: { product: response.data.slug },
      });
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const deleteProductPicture = function ({ dispatch, commit }, payload) {
  const request = {};
  request.uuid = payload;
  const data = {};
  data.url = "admin-product-delete-picture";
  data.method = "post";
  data.loading = "delete_product_product";
  data.data = request;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      dispatch("adminCategories/getCategories", "", { root: true });
      dispatch("getProducts");
      commit("setProduct", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const updateProduct = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-product-update/" + payload.id;
  data.method = "post";
  data.loading = "create_product";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("updateProduct", response.data);
      dispatch("adminCategories/getCategories", "", { root: true });
      dispatch("getProducts");
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const changePosition = function ({ dispatch, commit }, payload) {
  const array = payload.pictures;
  var newIndex;

  if (payload.index === 0 && payload.direction === "up") {
    return;
  }

  if (
    payload.index === payload.pictures.length - 1 &&
    payload.direction === "down"
  ) {
    return;
  }

  if (payload.direction === "up") {
    newIndex = payload.index - 1;
  }

  if (payload.direction === "down") {
    newIndex = payload.index + 1;
  }

  const Oldposition = array[payload.index].position;
  const NewPosition = array[newIndex].position;

  const Other = array.find((a) => a.position === NewPosition);
  const Clicked = array.find((a) => a.position === Oldposition);

  Other.position = Oldposition;
  Clicked.position = NewPosition;

  const request = {
    other: Other,
    clicked: Clicked,
  };

  const data = {};
  data.url = "admin-product-change-picture-position/";
  data.method = "post";
  data.loading = "change_position";
  data.data = request;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      // commit("updateProduct", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const uploadExtraPicture = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-product-upload-extra-picture/" + payload.get("product");
  data.method = "post";
  data.loading = "upload_picture";
  data.data = payload;

  dispatch("loadingOn", data.loading, { root: true });
  return axios({
    url: process.env.VUE_APP_API_URL + "/web/" + data.url,
    method: data.method,
    data: data.data,
    withCredentials: true,
    onUploadProgress: (progressEvent) =>
      commit("setUploadPercentage", progressEvent),
  })
    .then(function (response) {
      commit("setUploadPercentage", 0);
      commit("setProduct", response.data);
      dispatch("loadingOff", data.loading, { root: true });
      dispatch("adminCategories/getCategories", "", { root: true });
      dispatch("getProducts");

      return Promise.resolve(response);
    })
    .catch(function (error) {
      commit("setUploadPercentage", 0);
      dispatch("loadingOff", data.loading, { root: true });
      return Promise.reject(error);
    });
};

export const uploadResizedPicture = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-product-upload-resized-picture/" + payload.get("product");
  data.method = "post";
  data.loading = "upload_picture";
  data.data = payload;

  dispatch("loadingOn", data.loading, { root: true });
  return axios({
    url: process.env.VUE_APP_API_URL + "/web/" + data.url,
    method: data.method,
    data: data.data,
    withCredentials: true,
    onUploadProgress: (progressEvent) =>
      commit("setUploadPercentage", progressEvent),
  })
    .then(function (response) {
      commit("setUploadPercentage", 0);
      commit("setProduct", response.data);
      dispatch("loadingOff", data.loading, { root: true });
      dispatch("adminCategories/getCategories", "", { root: true });
      dispatch("getProducts");
      return Promise.resolve(response);
    })
    .catch(function (error) {
      commit("setUploadPercentage", 0);
      dispatch("loadingOff", data.loading, { root: true });
      return Promise.reject(error);
    });
};

export const cancelUpdateProduct = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-product-cancel-update/" + payload.id;
  data.method = "post";
  data.loading = "cancel_update_product";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("cancelUpdateProduct", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getProducts = function ({ dispatch, commit }, payload) {
  const data = {};

  data.url = "admin-get-products";
  data.method = "get";
  data.loading = "products_index";
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setProducts", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getProduct = function ({ dispatch, commit }, payload) {
  const data = {};
  commit("setProduct", {});
  data.url = "admin-get-product/" + payload;
  data.method = "get";
  data.loading = "get-product";
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setProduct", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const deleteProduct = function ({ dispatch, commit }, payload) {
  const data = {};
  commit("setProduct", {});
  data.url = "admin-product-delete/" + payload.id;
  data.method = "POST";
  data.loading = "delete-product";
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("deleteProduct", payload);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const setProduct = function ({ dispatch, commit }, payload) {
  commit("setProduct", payload);
};
