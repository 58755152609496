<style scoped>
.formInput {
  margin-bottom: 12px;
}
</style>

<template>
  <div v-if="showLoginModal">
    <div class="modal is-active">
      <div class="modal-background" @click.prevent="closeLoginModal()"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Welcome back!</p>
          <button
            class="delete"
            aria-label="close"
            @click.prevent="closeLoginModal()"
          ></button>
        </header>
        <form action="" @submit.prevent="login()">
          <section class="modal-card-body">
            <div class="modal-card" style="width: auto">
              <formInput
                class="formInput"
                :model="form"
                modelKey="email"
                label="inside"
                size="is-small"
              />

              <formInput
                class="formInput"
                :model="form"
                modelKey="password"
                type="password"
                label="inside"
                size="is-small"
              />

              <formCheckbox
                :model="form"
                modelKey="rememberme"
                size="is-small"
                :labelHidden="true"
                title="Stay
              logged in on this computer"
              />

              <p class="help" @click="openPasswordResetModal()">
                <a>I forgot my password</a>
              </p>
            </div>
          </section>
          <footer class="modal-card-foot">
            <form-button
              title="Login"
              type="is-primary"
              size="is-small"
              loadingKey="login"
            />
            <button
              class="button is-rounded is-small"
              @click.prevent="closeLoginModal()"
            >
              Cancel
            </button>
          </footer>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      form: {
        rememberme: false,
      },
    };
  },
  computed: {
    showLoginModal() {
      return this.$store.getters["auth/loginModal"];
    },
  },
  methods: {
    openPasswordResetModal() {
      this.closeLoginModal();
      return this.$store.dispatch("auth/toggleResetPasswordModal", true);
    },
    closeLoginModal() {
      this.$store.dispatch("clearValidationErrors");
      return this.$store.dispatch("auth/toggleLoginModal", false);
    },
    login() {
      return this.$store.dispatch("auth/login", this.form).then(() => {
        this.form = {
          rememberme: false,
        };
        this.closeLoginModal();
      });
    },
  },
  mounted() {},
};
</script>
