import store from "@/store";
import router from "@/router";

export default async function (to, from, next) {
  store.dispatch("clearValidationErrors");

  if (to.meta.admin) {
    const user = await getUser();

    if (user && user.id && user.role === "admin") {
      next();
    } else {
      router.push({ name: "webshop-categories" });
      forbidden();
    }
  } else if (to.meta.user) {
    const user = await getUser();
    if (user.id) {
      next();
    } else {
      router.push({ name: "webshop-categories" });
      notloggedin();
    }
  } else {
    next();
  }
}

function getUser() {
  if (store.getters["auth/user"].id) {
    return store.getters["auth/user"];
  } else {
    return new Promise((resolve) => {
      var user = {};

      store.dispatch("auth/getLoggedInUser").then((response) => {
        if (store.getters["auth/user"]) {
          return resolve(store.getters["auth/user"]);
        }

        store.dispatch("auth/logInFromRememberMeCookie").then((response) => {
          return resolve(store.getters["auth/user"]);
        });
      });
    });
  }
}

function forbidden() {
  store.dispatch("createFlashBanner", {
    message: "This action is forbidden",
    timeout: 3,
    type: "is-danger",
  });
}

function notloggedin() {
  store.dispatch("createFlashBanner", {
    message: "You are not logged in, please log in to continue.",
    timeout: 5,
  });
}
