<style scoped>
.customtitle {
  font-weight: bold;
}
</style>

<template>
  <div>
    <navBar />
    <div class="container is-fluid">
      <span
        v-if="settings && settings.variables"
        class="content"
        v-html="
          settings.variables.find((a) => a.key === 'text_contact_form').value
        "
      ></span>
      <div class="columns">
        <div class="column is-8">
          <div class="box">
            <formInput
              class="customInput"
              modelKey="name"
              :model="form"
              placeholder="Name"
              label="on-border"
            />

            <br />

            <formInput
              class="customInput"
              modelKey="email"
              :model="form"
              placeholder="Email"
              label="on-border"
            />

            <br />

            <formInput
              class="customInput"
              modelKey="subject"
              :model="form"
              placeholder="Subject"
              label="on-border"
            />

            <br />

            <!-- <b-field label="">
            <b-input maxlength="1000" rows="7" type="textarea"></b-input>
          </b-field> -->

            <b-field
              label="Question"
              label-position="on-border"
              :type="{ 'is-danger': validation.question }"
              :message="{
                [validation.question]: validation.question,
              }"
            >
              <b-input
                v-model="form.question"
                type="textarea"
                maxlength="10000"
                placeholder="What is your question?"
                @input="clearValidationError('question')"
              ></b-input>
            </b-field>

            <br />

            <div class="has-text-centered">
              <button
                @click="submitContactForm()"
                :class="[
                  'button is-rounded is-primary',
                  { 'is-loading': ajaxloading.submit_contact_form },
                ]"
              >
                Verzenden
              </button>
            </div>
          </div>
        </div>
        <div class="column" v-if="settings && settings.variables">
          <!-- adresblok -->
          <div class="customtitle">
            {{ settings.variables.find((a) => a.key === "company_name").value }}
          </div>
          <div>
            {{
              settings.variables.find((a) => a.key === "company_street").value
            }}
            {{
              settings.variables.find((a) => a.key === "company_house_number")
                .value
            }}
            <br />
            {{
              settings.variables.find((a) => a.key === "company_postal_code")
                .value
            }}
            {{ settings.variables.find((a) => a.key === "company_city").value
            }}<br />
            E:
            <a
              :href="
                'mailto:' +
                settings.variables.find(
                  (a) => a.key === 'company_contact_email'
                ).value
              "
              >{{
                settings.variables.find(
                  (a) => a.key === "company_contact_email"
                ).value
              }}</a
            >
            <br />
            T:
            {{
              settings.variables.find((a) => a.key === "company_phonenumber")
                .value
            }}
            <br />
            <br />
            KvK:
            {{
              settings.variables.find((a) => a.key === "company_kvk_number")
                .value
            }}
          </div>
          <!-- &nbsp;&nbsp;&nbsp;&nbsp;Optie 1: Aanmeldingen zorg<br />
        &nbsp;&nbsp;&nbsp;&nbsp;Optie 2: Dagprogramma<br />
        &nbsp;&nbsp;&nbsp;&nbsp;Optie 3: Beschermd wonen<br />
        &nbsp;&nbsp;&nbsp;&nbsp;Optie 4: Overig<br /> -->

          <!-- contactinfo -->
          <div class="customtitle"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/views/Navbar.vue";
export default {
  components: {
    navBar: NavBar,
  },
  data() {
    return {
      form: {},
    };
  },
  computed: {
    settings() {
      return this.$store.getters["shop/settings"];
    },
  },
  methods: {
    submitContactForm() {
      this.$store
        .dispatch("submitContactForm", this.form)
        .then(() => this.$router.push({ name: "contact-form-send" }));
    },
    clearValidationError(key) {
      this.$store.dispatch("clearValidationError", key);
    },
  },
  mounted() {},
};
</script>
