<template>
  <div>
    <webshopNavbar />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <closedShopModal />
    <oldOrderModal
      v-if="
        oldOrder &&
        oldOrder.id &&
        $router.currentRoute.name !== 'order-redirect'
      "
      :order="oldOrder"
    />
  </div>
</template>

<script>
import WebshopNavbar from "@/views/Navbar.vue";
import closedShopModal from "./ShopClosedModal.vue";
import oldOrderModal from "@/views/shoppingcart/oldOrderModal.vue";

export default {
  components: {
    webshopNavbar: WebshopNavbar,
    closedShopModal: closedShopModal,
    oldOrderModal,
  },
  props: [],
  data() {
    return {};
  },
  computed: {
    oldOrder() {
      return this.$store.getters["cart/oldOrder"];
    },
  },
  methods: {},
  mounted() {
    this.$store.dispatch("shop/getCategories").then(() => {
      this.$store.dispatch("websocket/joinShopChannel");
      this.$store.dispatch("cart/getCartFromLocalStorage").then(() => {
        this.$store.dispatch("cart/getUnpaidOrder");
      });
    });
  },
};
</script>
