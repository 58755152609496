import Vue from "vue";

export default Vue.mixin({
  methods: {
    calcVatMixin(cart, payment_costs, shipping_costs, country, settings) {
      const vat_country = this.findVatCountryMixin(country, settings);
      if (vat_country && vat_country.id) {
        var tax_high_percentage = 0;
        var tax_low_percentage = 0;
        if (vat_country.vat_high !== "geen") {
          tax_high_percentage = vat_country.vat_high;
        }

        if (vat_country.vat_low !== "geen") {
          tax_low_percentage = vat_country.vat_low;
        }

        var tax_high = 0;
        var tax_low = 0;
        for (let product of cart) {
          let price = 0;
          if (product.product.type === "unique") {
            price = product.product.price;
          }

          if (product.product.type === "multiple") {
            price = product.product.price * product.amount;
          }

          if (product.product.type === "options") {
            price =
              (product.product.price + product.option.price) * product.amount;
          }

          if (product.product.btwtype === "low") {
            tax_low =
              tax_low +
              (price * tax_low_percentage) / (100 + tax_low_percentage);
          }

          if (product.product.btwtype === "high") {
            tax_high =
              tax_high +
              (price * tax_high_percentage) / (100 + tax_high_percentage);
          }
        }

        if (tax_high > 0) {
          tax_high =
            tax_high +
            (shipping_costs * tax_high_percentage) /
              (100 + tax_high_percentage);
          tax_high =
            tax_high +
            (payment_costs * tax_high_percentage) / (100 + tax_high_percentage);
        } else if (tax_low > 0) {
          tax_low =
            tax_low +
            (shipping_costs * tax_low_percentage) / (100 + tax_low_percentage);
          tax_low =
            tax_low +
            (payment_costs * tax_low_percentage) / (100 + tax_low_percentage);
        }

        return {
          tax_low: Math.round(tax_low),
          tax_high: Math.round(tax_high),
        };
      }
    },
    findVatCountryMixin(country, settings) {
      if (!country || !settings || !settings.variables) {
        return {};
      }
      const kor = settings.variables.find((a) => a.key === "kor").value;

      if (country.short_name === "NL" && kor === "1") {
        country.vat_high = "geen";
        country.vat_low = "geen";
        return country;
      }

      if (country.short_name === "NL" && kor === "0") {
        return country;
      }

      const moss = settings.variables.find((a) => a.key === "moss").value;

      if (country.moss_country && kor === "1" && moss === "1") {
        const netherlands = this.settings.countries.find(
          (a) => a.short_name === "NL"
        );
        netherlands.vat_high = "geen";
        netherlands.vat_low = "geen";
        return netherlands;
      }

      if (country.moss_country && kor === "0" && moss === "1") {
        const netherlands = this.settings.countries.find(
          (a) => a.short_name === "NL"
        );
        return netherlands;
      }

      return country;
    },
  },
});
