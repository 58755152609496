import formatCurrency from "@/globals/functions/formatCurrency";

export const btw = (state) => state.btw;
export const uploadPercentage = (state) => state.uploadPercentage;
export const product = (state) => {
  // formatCurrency(state.product);
  if (state.product.pictures) {
    state.product.pictures.sort((a, b) => {
      return a.position - b.position;
    });
  }

  return state.product;
};
export const products = (state) => {
  for (let product of state.products) {
    // formatCurrency(product);
    // setProductStatus(product);
    product.pictures.sort((a, b) => {
      return a.position - b.position;
    });
  }
  return state.products;
};

function setProductStatus(product) {
  if (product.status === "sold") {
    return;
  }

  if (product.type === "unique") {
    return;
  }

  if (product.type === "multiple") {
    if (product.amount == 0) {
      product.status = "sold";
      return;
    }
  }

  if (product.type === "options") {
    if (product.status === "available") {
      let i = 0;

      for (let option of product.options) {
        i = i + option.amount;
      }

      if (i === 0) {
        product.status = "sold";
      }
    }
  }
}
