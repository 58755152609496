<style scoped>
.greyImage {
  filter: grayscale(100%);
}

.hiddenImage {
  filter: grayscale(100%);
}
</style>

<template>
  <div>
    <div class="columns is-mobile">
      <div class="column">
        <h1 class="title is-5">Producten</h1>
        <b-field>
          <b-switch v-model="showAll">Niet sorteren op Categorie</b-switch>
        </b-field>
      </div>
      <div class="column">
        <button
          class="button is-pulled-right is-rounded is-small"
          :class="{ 'is-loading': ajaxloading.create_product }"
          @click="createProduct()"
        >
          Toevoegen
        </button>
      </div>
    </div>
    <div class="">
      <b-tabs
        position="is-centered"
        class="block"
        v-model="activeTab"
        @input="syncActiveTabToQuery()"
      >
        <b-tab-item
          v-for="(category, index) of categories"
          :label="category.naam"
          :key="category.id"
          :value="String(index)"
        >
          <div
            class="columns"
            v-for="outerLoop in Math.ceil(
              filteredProducts.length / maxItemsPerRow
            )"
            :key="outerLoop"
          >
            <div
              class="column is-2"
              v-for="item in calculatedMax(outerLoop)"
              :key="item"
            >
              <div
                class="card pointer"
                @click="
                  productClicked(
                    filteredProducts[
                      item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                    ].slug
                  )
                "
              >
                <div
                  class="card-image"
                  @mouseover="
                    mouseoverImage(
                      filteredProducts[
                        item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                      ]
                    )
                  "
                  @mouseleave="mouseleaveImage"
                >
                  <figure class="image is-1by1">
                    <div
                      v-if="
                        filteredProducts[
                          item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                        ].pictures[0]
                      "
                    >
                      <img
                        :class="{
                          greyImage:
                            filteredProducts[
                              item +
                                outerLoop * maxItemsPerRow -
                                maxItemsPerRow -
                                1
                            ].status === 'sold',
                          hiddenImage:
                            filteredProducts[
                              item +
                                outerLoop * maxItemsPerRow -
                                maxItemsPerRow -
                                1
                            ].visible === false,
                        }"
                        v-if="
                          hoveredProduct ===
                          filteredProducts[
                            item +
                              outerLoop * maxItemsPerRow -
                              maxItemsPerRow -
                              1
                          ].id
                        "
                        :src="
                          calcPictureLength(
                            filteredProducts[
                              item +
                                outerLoop * maxItemsPerRow -
                                maxItemsPerRow -
                                1
                            ]
                          )
                            ? env.VUE_APP_API_URL +
                              '/web/picture/' +
                              filteredProducts[
                                item +
                                  outerLoop * maxItemsPerRow -
                                  maxItemsPerRow -
                                  1
                              ].pictures[1].uuid
                            : env.VUE_APP_API_URL +
                              '/web/picture/' +
                              filteredProducts[
                                item +
                                  outerLoop * maxItemsPerRow -
                                  maxItemsPerRow -
                                  1
                              ].pictures[0].uuid
                        "
                        alt="Product Image"
                      />
                      <img
                        :class="{
                          greyImage:
                            filteredProducts[
                              item +
                                outerLoop * maxItemsPerRow -
                                maxItemsPerRow -
                                1
                            ].status === 'sold',
                          hiddenImage:
                            filteredProducts[
                              item +
                                outerLoop * maxItemsPerRow -
                                maxItemsPerRow -
                                1
                            ].visible === false,
                        }"
                        v-else
                        :src="
                          env.VUE_APP_API_URL +
                          '/web/picture/' +
                          filteredProducts[
                            item +
                              outerLoop * maxItemsPerRow -
                              maxItemsPerRow -
                              1
                          ].pictures[0].uuid
                        "
                        alt="Product Image"
                      />
                    </div>
                  </figure>
                </div>
                <div class="card-content">
                  <div class="media">
                    <div class="media"></div>
                    <div class="media-content">
                      <div class="content has-text-centered">
                        {{
                          hoofdletter(
                            filteredProducts[
                              item +
                                outerLoop * maxItemsPerRow -
                                maxItemsPerRow -
                                1
                            ].name
                          )
                        }}
                        <br />

                        <p
                          style="color: red"
                          v-if="
                            filteredProducts[
                              item +
                                outerLoop * maxItemsPerRow -
                                maxItemsPerRow -
                                1
                            ].status === 'sold'
                          "
                        >
                          Sold
                        </p>
                        <p
                          v-else-if="
                            filteredProducts[
                              item +
                                outerLoop * maxItemsPerRow -
                                maxItemsPerRow -
                                1
                            ].status === 'preview'
                          "
                        >
                          Preview
                        </p>

                        <p v-else>
                          {{
                            euroFromCents(
                              filteredProducts[
                                item +
                                  outerLoop * maxItemsPerRow -
                                  maxItemsPerRow -
                                  1
                              ].price
                            )
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab-item>

        <b-tab-item label="Concept" value="concept">
          <div
            class="columns"
            v-for="outerLoop in Math.ceil(
              conceptProducts.length / maxItemsPerRow
            )"
            :key="outerLoop"
          >
            <div
              class="column is-2"
              v-for="item in calculatedConceptMax(outerLoop)"
              :key="item"
            >
              <div
                class="card pointer"
                @click="
                  productClicked(
                    conceptProducts[
                      item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                    ].slug
                  )
                "
              >
                <div
                  class="card-image"
                  @mouseover="
                    mouseoverImage(
                      conceptProducts[
                        item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                      ]
                    )
                  "
                  @mouseleave="mouseleaveImage"
                >
                  <figure class="image is-1by1">
                    <div
                      v-if="
                        conceptProducts[
                          item + outerLoop * maxItemsPerRow - maxItemsPerRow - 1
                        ].pictures[0]
                      "
                    >
                      <img
                        :class="{
                          greyImage:
                            conceptProducts[
                              item +
                                outerLoop * maxItemsPerRow -
                                maxItemsPerRow -
                                1
                            ].status === 'sold',
                          hiddenImage:
                            conceptProducts[
                              item +
                                outerLoop * maxItemsPerRow -
                                maxItemsPerRow -
                                1
                            ].visible === false,
                        }"
                        v-if="
                          hoveredProduct ===
                          conceptProducts[
                            item +
                              outerLoop * maxItemsPerRow -
                              maxItemsPerRow -
                              1
                          ].id
                        "
                        :src="
                          calcPictureLength(
                            conceptProducts[
                              item +
                                outerLoop * maxItemsPerRow -
                                maxItemsPerRow -
                                1
                            ]
                          )
                            ? env.VUE_APP_API_URL +
                              '/web/picture/' +
                              conceptProducts[
                                item +
                                  outerLoop * maxItemsPerRow -
                                  maxItemsPerRow -
                                  1
                              ].pictures[1].uuid
                            : env.VUE_APP_API_URL +
                              '/web/picture/' +
                              conceptProducts[
                                item +
                                  outerLoop * maxItemsPerRow -
                                  maxItemsPerRow -
                                  1
                              ].pictures[0].uuid
                        "
                        alt="Product Image"
                      />
                      <img
                        :class="{
                          greyImage:
                            conceptProducts[
                              item +
                                outerLoop * maxItemsPerRow -
                                maxItemsPerRow -
                                1
                            ].status === 'sold',
                          hiddenImage:
                            conceptProducts[
                              item +
                                outerLoop * maxItemsPerRow -
                                maxItemsPerRow -
                                1
                            ].visible === false,
                        }"
                        v-else
                        :src="
                          env.VUE_APP_API_URL +
                          '/web/picture/' +
                          conceptProducts[
                            item +
                              outerLoop * maxItemsPerRow -
                              maxItemsPerRow -
                              1
                          ].pictures[0].uuid
                        "
                        alt="Product Image"
                      />
                    </div>
                  </figure>
                </div>
                <div class="card-content">
                  <div class="media">
                    <div class="media"></div>
                    <div class="media-content">
                      <div class="content has-text-centered">
                        {{
                          hoofdletter(
                            conceptProducts[
                              item +
                                outerLoop * maxItemsPerRow -
                                maxItemsPerRow -
                                1
                            ].name
                          )
                        }}

                        <br />

                        <p
                          style="color: red"
                          v-if="
                            conceptProducts[
                              item +
                                outerLoop * maxItemsPerRow -
                                maxItemsPerRow -
                                1
                            ].status === 'sold'
                          "
                        >
                          Sold
                        </p>
                        <p
                          v-else-if="
                            conceptProducts[
                              item +
                                outerLoop * maxItemsPerRow -
                                maxItemsPerRow -
                                1
                            ].status === 'preview'
                          "
                        >
                          Preview
                        </p>
                        <p v-else>
                          {{
                            euroFromCents(
                              conceptProducts[
                                item +
                                  outerLoop * maxItemsPerRow -
                                  maxItemsPerRow -
                                  1
                              ].price
                            )
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
    </div>
    <!-- <crudModal v-on:closeModal="showCrudModal = false" v-if="showCrudModal" /> -->
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      activeTab: "0",
      showCrudModal: false,
      hoveredProduct: null,
      maxItemsPerRow: 6,
      env: process.env,
      showAll: false,
      // filteredCategories: []
    };
  },
  computed: {
    categories() {
      return this.$store.getters["adminCategories/categories"];
    },
    products() {
      return this.$store.getters["adminProducts/products"];
    },
    conceptProducts() {
      return this.products.filter((a) => a.status === "concept");
    },
    filteredProducts() {
      const products = this.products;
      const tab = this.activeTab;
      var category;
      if (this.showAll) {
        const data = this.products.filter((a) => a.status !== "concept");

        data.sort((a) => {
          if (a.status === "preview") {
            return -1;
          } else {
            return 1;
          }
        });

        data.sort((a) => {
          if (a.status === "sold") {
            return 1;
          } else {
            return -1;
          }
        });

        return data;
      }

      if (tab && tab !== "concept") {
        category = this.categories[tab];
      } else {
        category = this.categories[0];
      }

      if (category && this.products.length && tab !== "concept") {
        const data = products.filter(
          (a) => a.category_id === category.id && a.status !== "concept"
        );

        data.sort((a) => {
          if (a.status === "preview") {
            return -1;
          } else {
            return 1;
          }
        });

        data.sort((a) => {
          if (a.status === "sold") {
            return 1;
          } else {
            return -1;
          }
        });

        return data;
      } else {
        return products;
      }
    },
  },
  methods: {
    createProduct() {
      this.$store.dispatch("adminProducts/createProduct").then((response) => {
        this.$router.push({
          name: "admin-product-edit",
          params: { product: response.data.id },
        });
      });
    },
    calculatedMax(loop) {
      const max = this.filteredProducts.length;
      const rows = this.maxItemsPerRow * loop;
      if (max < rows) {
        return max - this.maxItemsPerRow * (loop - 1);
      } else {
        return this.maxItemsPerRow;
      }
    },
    calculatedConceptMax(loop) {
      const max = this.conceptProducts.length;
      const rows = this.maxItemsPerRow * loop;
      if (max < rows) {
        return max - this.maxItemsPerRow * (loop - 1);
      } else {
        return this.maxItemsPerRow;
      }
    },
    mouseoverImage(product) {
      this.hoveredProduct = product.id;
    },
    mouseleaveImage() {
      this.hoveredProduct = false;
    },
    productClicked(product) {
      this.$router.push({
        name: "admin-product",
        params: { product: product },
      });
    },
    calcPictureLength(product) {
      if (product.pictures.length < 2) {
        return false;
      } else {
        return true;
      }
    },
    filterCategories() {
      // this.filteredCategories = this.products;
    },
    syncActiveTabToQuery() {
      if (this.$router.currentRoute.query.activeTab !== this.activeTab) {
        this.$router.replace({ query: { activeTab: this.activeTab } });
      }
    },
    getActiveTabFromQuery() {
      if (this.$router.currentRoute.query.activeTab) {
        this.activeTab = this.$router.currentRoute.query.activeTab;
      }
    },
  },
  watch: {
    // activeTab: {
    //   deep: true,
    //   handler() {
    //     this.syncActiveTabToQuery();
    //   },
    // },
  },
  mounted() {
    this.getActiveTabFromQuery();
  },
};
</script>
