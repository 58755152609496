import validation from "./validation.js";
import loading from "./loading.js";
import customMounted from "./customMounted.js";
import hoofdletter from "./hoofdletter.js";
import euro from "./euro.js";
import findAddress from "./findAddress.js";
import formatDatabaseDate from "./formatDatabaseDate.js";
import orderTotals from "./orderTotals.js";
// import displayDecimalDash from "./displayDecimalDash.js";

export default {
  validation: validation,
  loading: loading,
  customMounted: customMounted,
  euro: euro,
  hoofdletter: hoofdletter,
  findAddress: findAddress,
  formatDatabaseDate,
  orderTotals,
  // displayDecimalDash: displayDecimalDash
};
