<style>
.is-active {
  background-color: white !important;
}
</style>

<template>
  <div>
    <aside class="menu">
      <ul class="menu-list">
        <li>
          <router-link
            @click="closeMobile()"
            tag="a"
            exact
            :to="{ name: 'account' }"
            >Account</router-link
          >
        </li>
        <li>
          <router-link
            @click="closeMobile()"
            tag="a"
            exact
            :to="{ name: 'account-change-password' }"
            >Change password</router-link
          >
        </li>
        <li>
          <router-link
            @click="closeMobile()"
            tag="a"
            :to="{ name: 'account-orders' }"
            >Orders</router-link
          >
        </li>
        <li>
          <router-link
            @click="closeMobile()"
            tag="a"
            :to="{ name: 'account-delete-user' }"
            >Delete account</router-link
          >
        </li>
      </ul>
    </aside>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>
