export const setOrders = function (state, payload) {
  state.orders = payload;
};

export const setUpdateOrder = function (state, payload) {
  const array = state.orders;
  const id = payload.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1, payload);
};

export const deleteOrder = function (state, payload) {
  const array = state.orders;
  const id = payload.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1);
};

export const deleteOrderProduct = function (state, payload) {
  const array = payload.order.order_products;
  const id = payload.product.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1);
};

export const setUpdatedLabel = function (state, payload) {
  const order = state.orders.find((a) => a.id === payload.order_id);
  const array = order.shipping_labels;
  const id = payload.id;
  const index = array.findIndex((a) => a.id === id);
  array.splice(index, 1, payload);
};
