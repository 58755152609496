<style scoped>
.column {
  padding-top: 0px;
  padding-bottom: 0px;
}

.pointer {
  margin-left: 5px;
}
</style>

<template>
  <div>
    <div class="">
      <span class="title is-5">Categorie: {{ category.naam }}</span> &nbsp;
    </div>

    <br />

    <div class="columns is-mobile">
      <div class="column is-3">Naam:</div>
      <div class="column">
        {{ category.naam }}
      </div>
      <div class="column">
        <b-icon
          class="pointer is-pulled-right"
          icon="trash"
          size="is-small"
          @click.native="deleteCategory()"
        />
        <b-icon
          class="pointer is-pulled-right"
          icon="edit"
          size="is-small"
          @click.native="showEditModal"
        />
      </div>
    </div>

    <div class="columns is-mobile">
      <div class="column is-3">Visible:</div>
      <div class="column">
        {{ category.visible }}
      </div>
    </div>

    <hr />

    <div class="columns is-mobile" v-for="tag of category.tags" :key="tag.id">
      <div class="column is-3">Tag:</div>
      <div class="column">
        {{ tag.naam }}
      </div>
      <div class="column">
        <b-icon
          class="pointer is-pulled-right"
          icon="trash"
          size="is-small"
          @click.native="deleteTag(tag)"
        />
        <b-icon
          class="pointer is-pulled-right"
          icon="edit"
          size="is-small"
          @click.native="toggleTagModal(true, tag)"
        />
        <b-icon
          class="pointer is-pulled-right"
          icon="plus"
          size="is-small"
          @click.native="toggleTagModal(true)"
        />
      </div>
    </div>

    <hr />

    <div class="columns">
      <div class="column is-3">Main picture:</div>
      <div class="column">
        <b-image
          ratio="1by1"
          class="pointer"
          @click.native="togglePictureModal(true, 'main_picture')"
          v-if="category.main_picture"
          :src="env.VUE_APP_API_URL + '/web/picture/' + category.main_picture"
          :alt="category.naam"
        />
        <br />
      </div>
    </div>

    <div class="columns">
      <div class="column is-3">Hover picture:</div>
      <div class="column">
        <b-image
          ratio="1by1"
          class="pointer"
          @click.native="togglePictureModal(true, 'alt_picture')"
          v-if="category.alt_picture"
          :src="env.VUE_APP_API_URL + '/web/picture/' + category.alt_picture"
          :alt="category.naam"
        />
        <br />
      </div>
    </div>
    <adminCategoriesEditModal
      v-on:closeModal="closeEditModal()"
      v-if="editModal"
      :updateForm="category"
    />
    <adminCategoryEditPictureModal
      v-if="editPictureModal"
      v-on:closeModal="togglePictureModal(false)"
      :editPicture="editPicture"
      :category="category"
    />

    <adminCategoryTagModal
      v-if="tagModal"
      v-on:closeModal="toggleTagModal(false)"
      :updateForm="tag"
      :category="category"
    />
  </div>
</template>

<script>
import adminCategoriesEditModal from "./AdminCategoriesEditModal.vue";
import adminCategoryEditPictureModal from "./AdminCategoryEditPictureModal.vue";
import adminCategoryTagModal from "./AdminCategoryTagModal.vue";

export default {
  components: {
    adminCategoriesEditModal,
    adminCategoryEditPictureModal,
    adminCategoryTagModal,
  },
  props: [],
  data() {
    return {
      env: process.env,
      editModal: false,
      editPictureModal: false,
      editPicture: null,
      tagModal: false,
      tag: null,
    };
  },
  computed: {
    categories() {
      return this.$store.getters["adminCategories/categories"];
    },
    category() {
      if (this.categories.length) {
        const cat = this.categories.find(
          (a) => a.slug == this.$route.params.category
        );
        if (cat) {
          return cat;
        } else {
          return {};
        }
      } else {
        return {};
      }
    },
  },
  methods: {
    showEditModal() {
      this.editModal = true;
    },
    closeEditModal() {
      this.editModal = false;
    },
    togglePictureModal(bool, picture) {
      this.editPictureModal = bool;
      this.editPicture = picture;
    },
    toggleTagModal(bool, tag) {
      this.tagModal = bool;
      this.tag = tag;
    },
    deleteTag(tag) {
      const data = {
        tag: tag,
        category: this.category,
      };

      this.$buefy.dialog.confirm({
        title: "Deleting tag: " + tag.naam,
        message: "Are you sure you want to delete this tag?",
        confirmText: "Delete Tag",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          this.$store.dispatch("adminCategories/deleteTag", data),
      });
    },
    deleteCategory() {
      this.$buefy.dialog.confirm({
        title: "Deleting category: " + this.category.naam,
        message: "Are you sure you want to delete this category?",
        confirmText: "Delete Category",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          this.$store
            .dispatch("adminCategories/deleteCategory", this.category)
            .then(() => {
              this.$router.replace({ name: "admin-categories" });
            }),
      });
    },
  },
  mounted() {},
};
</script>
