<style scoped>
.column {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.columns {
  margin-bottom: 0px;
  margin-top: 0px;
}

.customInput {
  margin-bottom: 20px;
}
</style>

<template>
  <div>
    <div class="title" v-if="$router.currentRoute.name === updateRoute">
      Update Country: {{ country.name }}
    </div>
    <div v-else class="title">Add shipping label</div>

    <formSelect
      v-if="settings && settings.countries.length"
      type="password"
      class="customInput"
      modelKey="country"
      :selectOptions="
        settings.countries.map(function (count) {
          return count.display_name;
        })
      "
      label="on-border"
      size="is-small"
      :model="form"
    />

    <b-field>
      <b-checkbox class="customCheckbox" v-model="form.business" size="is-small"
        >I am ordering for a business</b-checkbox
      >
    </b-field>

    <formInput
      v-if="form.business"
      class="customInput"
      :model="form"
      modelKey="business_name"
      label="on-border"
      title="Business name"
      size="is-small"
    />

    <formInput
      class="customInput"
      :model="form"
      modelKey="email"
      label="on-border"
      title="Email"
      size="is-small"
    />

    <div class="columns">
      <div class="column is-5">
        <formInput
          class="customInput"
          :model="form"
          modelKey="name"
          label="on-border"
          size="is-small"
        />
      </div>
      <div class="column is-7">
        <formInput
          class="customInput"
          :model="form"
          modelKey="surname"
          label="on-border"
          size="is-small"
        />
      </div>
    </div>

    <span v-if="form.country && !findObject.foundAddress">
      <formAutocomplete
        class="customInput"
        :model="form"
        label="on-border"
        optionKey="description"
        modelKey="autocomplete"
        notFoundText="No address found"
        :options="addressOptions"
        :openOnFocus="false"
        title="Address"
        v-on:input="findAddressId()"
        v-on:selected="findAddressDetails"
      />

      <formCheckbox
        size="is-small"
        class="customCheckbox"
        :model="findObject"
        :labelHidden="true"
        modelKey="foundAddress"
        title="Enter address manually"
      />
      <br />
      <br />
      <br />
      <br />
      <br />
    </span>
    <span v-if="findObject.foundAddress">
      <div class="columns">
        <div class="column is-6">
          <formInput
            class="customInput"
            :model="form"
            modelKey="reference_on_label"
            label="on-border"
            size="is-small"
            title="Reference on label (optional)"
            placeholder="Example: Order 66"
          />
        </div>
        <div class="column is-6">
          <formInput
            class="customInput"
            :model="form"
            modelKey="internal_reference"
            title="Internal reference"
            label="on-border"
            size="is-small"
            placeholder="Example: Order 66"
          />
        </div>
      </div>
      <formInput
        class="customInput"
        :model="form"
        modelKey="street"
        label="on-border"
        size="is-small"
      />
      <div class="columns">
        <div class="column is-7">
          <formInput
            class="customInput"
            :model="form"
            modelKey="postal_code"
            label="on-border"
            title="Postal code"
            size="is-small"
          />
        </div>
        <div class="column is-5">
          <formInput
            class="customInput"
            :model="form"
            modelKey="house_number"
            label="on-border"
            title="House number"
            size="is-small"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <formInput
            class="customInput"
            :model="form"
            modelKey="city"
            label="on-border"
            size="is-small"
          />
        </div>
        <div class="column">
          <formInput
            class="customInput"
            :model="form"
            modelKey="state"
            label="on-border"
            size="is-small"
          />
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <formInput
            class="customInput"
            :model="form"
            modelKey="weight"
            title="Weight in grams"
            label="on-border"
            size="is-small"
          />
        </div>
        <div class="column">{{ form.weight / 1000 }} kilo</div>
      </div>

      <formSelect
        :model="form"
        modelKey="package_type"
        label="on-border"
        :selectOptions="settings.admin.package_types"
      />

      <br />
    </span>

    <span v-if="findObject.foundAddress">
      <button
        v-if="$router.currentRoute.name === updateRoute"
        class="button is-primary is-rounded"
        :class="{ 'is-loading': ajaxloading[loadingKey] }"
        @click="update()"
      >
        Update
      </button>
      <button
        v-else
        class="button is-primary is-rounded"
        :class="{ 'is-loading': ajaxloading[loadingKey] }"
        @click="submit()"
      >
        Create
      </button>
      &nbsp;
    </span>
    <button class="button is-rounded" @click.prevent="cancelUpdate()">
      Cancel
    </button>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  components: {},
  props: {},
  data() {
    return {
      env: process.env,
      addressOptions: [],
      updateRoute: "admin-shipping-update-label",
      loadingKey: "create_shipping_label",
      form: {
        weight: 0,
        package_type: "regular",
      },
      findObject: {
        foundAddress: false,
      },
    };
  },
  computed: {
    // country() {
    //   return this.$store.getters["adminPrices/country"];
    // },
    settings() {
      return this.$store.getters["shop/settings"];
    },
  },
  methods: {
    submit() {
      this.form.country_short_name = this.settings.countries.find(
        (a) => a.display_name === this.form.country
      ).short_name;
      this.$store
        .dispatch("adminShipping/createShippingLabel", this.form)
        .then((response) => {
          this.$router.go(-1);
          const extension = this.settings.variables.find(
            (a) => a.key === "shipping_label_extension"
          ).value;

          if (extension === ".pdf") {
            window.open(
              this.env.VUE_APP_API_URL +
                "/web/admin-show-pdf-shipping-label/" +
                response.data.id
            );
          }

          if (extension === ".zpl") {
            window.open(
              this.env.VUE_APP_API_URL +
                "/web/admin-show-zpl-shipping-label/" +
                response.data.id
            );
          }
        });
    },

    cancelUpdate() {
      this.$router.go(-1);
    },

    async findAddressId() {
      var country = {};

      if (this.form.country) {
        country = this.settings.countries.find(
          (a) => a.name === this.form.country
        );
      }

      const places = await this.findGooglePlaceIdMixin(
        this.form.autocomplete,
        country
      );

      this.addressOptions = places.predictions;
    },
    async findAddressDetails(address) {
      if (address) {
        const addressDetails = await this.findAddressDetailsMixin(address);

        if (addressDetails.house_number) {
          this.form.house_number = addressDetails.house_number;
        }

        if (addressDetails.street) {
          this.form.street = addressDetails.street;
        }

        if (addressDetails.postal_code) {
          this.form.postal_code = addressDetails.postal_code;
        }

        if (addressDetails.city) {
          this.form.city = addressDetails.city;
        }

        if (addressDetails.state) {
          this.form.state = addressDetails.state;
        }

        this.findObject.foundAddress = true;
      }
    },
  },
  mounted() {
    // if (this.$router.currentRoute.name === this.updateRoute) {
    //   if (this.country && this.country.id) {
    //     this.form = this.country;
    //     this.foundCountry = true;
    //   } else {
    //     this.$store
    //       .dispatch(
    //         "adminPrices/getCountry",
    //         this.$router.currentRoute.params.country
    //       )
    //       .then(() => {
    //         this.form = this.country;
    //       });
    //   }
    // }
  },
};
</script>
