import Vue from "vue";

export const loadingOn = function (state, payload) {
  Vue.set(state.loading, payload, true);
};

export const loadingOff = function (state, payload) {
  Vue.delete(state.loading, payload);
};

export const setValidationErrors = function (state, payload) {
  state.valerrors = payload;
};

export const clearValidationError = function (state, payload) {
  if (state.valerrors[payload]) {
    state.valerrors[payload] = null;
  }
};

export const setFlashBanner = function (state, payload) {
  state.flashBanner = payload;
};
