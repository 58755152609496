import Vue from "vue";

import Echo from "laravel-echo";
window.Pusher = require("pusher-js");

import App from "./App.vue";
import router from "./router";
import store from "./store";
import buefy from "buefy";
// import "buefy/dist/buefy.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAlignCenter,
  faAlignLeft,
  faAlignRight,
  faAngleLeft,
  faAngleRight,
  faArrowDown,
  faArrowLeft,
  faArrowUp,
  faBold,
  faCalendar,
  faCartArrowDown,
  faCartPlus,
  faCode,
  faEdit,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFilePdf,
  faHeading,
  faHome,
  faInfoCircle,
  faItalic,
  faLink,
  faListOl,
  faListUl,
  faLock,
  faLockOpen,
  faMinus,
  faPaperPlane,
  faPlus,
  faQuoteLeft,
  faRedo,
  faSearch,
  faShoppingCart,
  faStrikethrough,
  faSyncAlt,
  faTag,
  faTimes,
  faTimesCircle,
  faTrash,
  faUnderline,
  faUndo,
  faUnlink,
  faUpload,
  faUser,
  faUserSlash,
} from "@fortawesome/free-solid-svg-icons";

import {
  faCcPaypal,
  faIdeal,
  faPaypal,
} from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faCartPlus,
  faShoppingCart,
  faAngleLeft,
  faAngleRight,
  faTimes,
  faTrash,
  faIdeal,
  faPaypal,
  faCcPaypal,
  faEye,
  faUser,
  faEyeSlash,
  faArrowUp,
  faArrowDown,
  faUpload,
  faTag,
  faEdit,
  faPlus,
  faBold,
  faItalic,
  faStrikethrough,
  faUnderline,
  faCode,
  faHeading,
  faListUl,
  faListOl,
  faQuoteLeft,
  faUndo,
  faRedo,
  faMinus,
  faAlignLeft,
  faAlignRight,
  faAlignCenter,
  faCartArrowDown,
  faHome,
  faExclamationTriangle,
  faExclamationCircle,
  faSyncAlt,
  faUserSlash,
  faUser,
  faLock,
  faLockOpen,
  faSearch,
  faTimesCircle,
  faEnvelope,
  faInfoCircle,
  faFilePdf,
  faCalendar,
  faPaperPlane,
  faLink,
  faUnlink,
  faArrowLeft
);

import customInput from "@/components/form/input.vue";
import pagePictureShower from "@/components/pages/PagePictureShower.vue";
import formAutocomplete from "@/components/form/formAutocomplete.vue";
import formtextArea from "@/components/form/formTextarea.vue";
import numberInput from "@/components/form/numberInput.vue";
import formSelect from "@/components/form/formSelect.vue";
import pictureUpload from "@/components/form/pictureUpload.vue";
import pictureUploadSingle from "@/components/form/pictureUploadSingle.vue";
import formTags from "@/components/form/tags.vue";
import formTagsAutocomplete from "@/components/form/tagsAutocomplete.vue";
import formButton from "@/components/form/formButton.vue";
import showModel from "@/components/form/showModel.vue";
import formCheckbox from "@/components/form/formCheckbox.vue";
import formDatePicker from "@/components/form/formDatePicker.vue";
import formEditor from "@/components/form/editor/Editor.vue";
import customIcon from "@/components/icons/icons.vue";

import settingsCard from "@/components/settings/settingsCard.vue";

Vue.component("settingsCard", settingsCard);
Vue.component("pagePictureShower", pagePictureShower);
Vue.component("formInput", customInput);
Vue.component("formAutocomplete", formAutocomplete);
Vue.component("formTextarea", formtextArea);
Vue.component("numberInput", numberInput);
Vue.component("customIcon", customIcon);
Vue.component("showModel", showModel);
Vue.component("formSelect", formSelect);
Vue.component("formPictureUpload", pictureUpload);
Vue.component("formSinglePictureUpload", pictureUploadSingle);
Vue.component("formTags", formTags);
Vue.component("formTagsAutocomplete", formTagsAutocomplete);
Vue.component("formButton", formButton);
Vue.component("formCheckbox", formCheckbox);
Vue.component("formDatePicker", formDatePicker);
Vue.component("formEditor", formEditor);
Vue.component("icon", FontAwesomeIcon);

import { axiosrequest } from "@/globals/interceptors.js";
import { axiosresponse } from "@/globals/interceptors.js";

import vuemixins from "@/globals/mixins/mixins.js";
Vue.mixin(vuemixins);

Vue.config.productionTip = false;
Vue.use(buefy, {
  defaultIconComponent: "icon",
  defaultIconPack: "fas",
});

const url = new URL(process.env.VUE_APP_API_URL);

window.echo = new Echo({
  broadcaster: "pusher",
  key: process.env.VUE_APP_WEBSOCKET_KEY,
  wsHost: url.hostname,
  wsPort: process.env.VUE_APP_WEBSOCKET_PORT,
  wssPort: process.env.VUE_APP_WEBSOCKET_SSL_PORT,
  disableStats: true,
  encrypted: false,
  forceTLS: url.protocol === "https:",
  enabledTransports: ["ws", "wss"],
});

import routerMiddleware from "@/globals/middleware/beforeEach";
router.beforeEach(routerMiddleware);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
