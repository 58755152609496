<style scoped>
.last-hr {
  border: none;
  height: 2px;
  margin-bottom: 4px;
  margin-top: 4px;
  /* Set the hr color */
  color: #333; /* old IE */
  background-color: #333; /* Modern Browsers */
}

.seperator-hr {
  border: none;
  height: 1px;
  margin-bottom: 4px;
  margin-top: 4px;
  /* Set the hr color */
  color: #a9a9a9; /* old IE */
  background-color: #a9a9a9; /* Modern Browsers */
}

.first-hr {
  border: none;
  height: 2px;
  margin-bottom: 4px;
  margin-top: 4px;
  /* Set the hr color */
  color: #333; /* old IE */
  background-color: #333; /* Modern Browsers */
}
</style>

<template>
  <div class="column no-wrap">
    <div class="align-right">
      {{ hoofdletter(columnKey) }}
      <hr class="first-hr" />
      <!-- categories -->
    </div>
    <div class="align-right">
      <span v-for="(value, key) in revenue.categories" :key="key">
        {{ euroFromCentsFormatZeroToDash(value[columnKey]) }}
      </span>
      <hr class="seperator-hr" />
      {{ euroFromCentsFormatZeroToDash(revenue.subtotal[columnKey]) }}
      <hr class="seperator-hr" />
      <!-- shipping and payment -->
      <span v-for="(value, key) in revenue.misc" :key="key">
        {{ euroFromCentsFormatZeroToDash(value[columnKey]) }} <br />
      </span>
      <!-- total -->
      <hr class="last-hr" />
      <strong>{{
        euroFromCentsFormatZeroToDash(
          revenue.subtotal[columnKey] + revenue.misc_total[columnKey]
        )
      }}</strong>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    revenue: {
      required: true,
    },
    columnKey: {
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>
