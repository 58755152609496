<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Send mail</p>
        <button
          class="delete"
          aria-label="close"
          @click="closeModal()"
        ></button>
      </header>
      <section class="modal-card-body">
        <div class="content" style="color: black">
          <span v-if="!preview">
            <strong>Usable variables:</strong> <br />
            <div class="columns">
              <div class="column">
                <span v-if="order">
                  Firstname<br />
                  Surname <br />
                  Order number <br />
                </span>
                <span v-if="invoice">
                  Customer name <br />
                  Customer surname <br />
                  Invoice number <br />
                  Invoice total <br />
                </span>
                Contact email link (shows: your email) <br />
                Contact form link (shows: contact form)<br />
              </div>
              <div class="column">
                <span v-if="order">
                  @@@@name@@@@ <br />
                  @@@@surname@@@@ <br />
                  @@@@order_number@@@@ <br />
                </span>
                <span v-if="invoice">
                  @@@@customer_name@@@@ <br />
                  @@@@customer_surname@@@@ <br />
                  @@@@invoice_number@@@@ <br />
                  @@@@invoice_total@@@@ <br />
                </span>
                @@@@contact_email@@@@ <br />
                @@@@contact_form@@@@
              </div>
            </div>
            <formInput
              class="customInput"
              :model="form"
              modelKey="subject"
              title="Subject"
            />

            <br />

            <formEditor
              :value="standardmail"
              title="Email"
              :model="form"
              modelKey="email"
            />
            <br />
            <span v-if="attachment">Attachment: {{ attachment }}</span>
          </span>
          <span v-else>
            <strong>Subject: </strong>
            <span v-html="previewsubject"></span>
            <hr />
            <span v-html="previewemail"></span>
          </span>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          :class="{ 'is-loading': ajaxloading[loadingKey] }"
          class="button is-success"
          @click="sendEmail()"
        >
          Send email
        </button>
        <button
          v-if="!preview"
          class="button is-info"
          @click="previewVariables()"
        >
          Preview filled variables
        </button>
        <button v-else class="button is-info" @click="preview = false">
          Close preview
        </button>
        <button @click="closeModal()" class="button">Cancel</button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    order: {
      type: Object,
      required: false,
    },
    invoice: {
      type: Object,
      required: false,
    },
    vuexStoreAction: {
      type: String,
      required: true,
    },
    settingsDefaultEmailKey: {
      type: String,
      required: false,
    },
    settingsDefaultEmailSubjectKey: {
      type: String,
      required: false,
    },
    attachment: {
      type: String,
      required: false,
    },
    loadingKey: {
      type: String,
      default: "sending_email",
    },
  },
  data() {
    return {
      form: {
        email: "",
        subject: "",
      },
      env: process.env,
      preview: false,
      previewemail: "",
      previewsubject: "",
      standardmail: null,
    };
  },
  computed: {
    settings() {
      return this.$store.getters["shop/settings"];
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    sendEmail() {
      const data = {
        email: this.form.email,
        subject: this.form.subject,
      };

      if (this.order) {
        data.order = this.order;
      }

      if (this.invoice) {
        data.invoice = this.invoice;
      }
      this.$store.dispatch(this.vuexStoreAction, data).then(() => {
        this.$emit("close");
        this.$buefy.toast.open("Sending mail");
      });
    },
    setStandardMail() {
      if (this.settingsDefaultEmailSubjectKey) {
        var subject = this.settings.variables.find(
          (a) => a.key === this.settingsDefaultEmailSubjectKey
        ).value;
      }

      if (this.settingsDefaultEmailKey) {
        var mail = this.settings.variables.find(
          (a) => a.key === this.settingsDefaultEmailKey
        ).value;
      }

      if (subject) {
        // subject = this.replaceVariables(subject);
        this.form.subject = subject;
      }

      if (mail) {
        // mail = this.replaceVariables(mail);
        this.standardmail = mail;
        this.form.email = mail;
      } else {
        this.standardmail = "";
        this.form.email = "";
      }
    },
    previewVariables() {
      var source;
      if (this.order) {
        source = this.order;
      }
      if (this.invoice) {
        source = this.invoice;
      }
      this.previewemail = this.replaceVariables(this.form.email, source);
      this.previewsubject = this.replaceVariables(this.form.subject, source);
      this.preview = true;
    },
    replaceVariables(text, source) {
      if (this.order) {
        text = this.getName(text, source);
        text = this.getSurname(text, source);
        text = this.getOrdernumber(text, source);
      }

      if (this.invoice) {
        text = this.getCustomerName(text, source);
        text = this.getCustomerSurname(text, source);
        text = this.getInvoiceNumber(text, source);
        text = this.getInvoiceTotal(text, source);
      }
      text = this.getContactForm(text);
      text = this.getContactEmail(text);

      return text;
    },
    getSurname(mail, source) {
      return String(mail).replace("@@@@surname@@@@", source.surname);
    },
    getName(mail, source) {
      return String(mail).replace("@@@@name@@@@", source.name);
    },
    getCustomerName(mail, source) {
      return String(mail).replace(
        "@@@@customer_name@@@@",
        source.customer_name
      );
    },
    getCustomerSurname(mail, source) {
      return String(mail).replace(
        "@@@@customer_surname@@@@",
        source.customer_surname
      );
    },
    getOrdernumber(mail, source) {
      return String(mail).replace("@@@@order_number@@@@", source.number);
    },
    getInvoiceNumber(mail, source) {
      return String(mail).replace("@@@@invoice_number@@@@", source.number);
    },
    getInvoiceTotal(mail, source) {
      return String(mail).replace(
        "@@@@invoice_total@@@@",
        this.euroFromCents(source.total_price)
      );
    },
    getContactForm(mail) {
      return String(mail).replace(
        "@@@@contact_email@@@@",
        this.settings.variables.find((a) => a.key === "company_contact_email")
          .value
      );
    },
    getContactEmail(mail) {
      return String(mail).replace(
        "@@@@contact_form@@@@",
        "<a href=" + this.env.VUE_APP_API_URL + "/contact>contact form</a>"
      );
    },
  },
  watch: {
    settings: {
      deep: true,
      handler() {
        this.setStandardMail();
      },
    },
  },
  mounted() {
    if (this.settings) {
      this.setStandardMail();
    }
  },
};
</script>
