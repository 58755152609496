export const getRevenue = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-revenue-get-revenue";
  data.method = "post";
  data.loading = "getting_revenue";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setRevenue", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
