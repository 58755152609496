<template>
  <div>
    <b-table
      :data="order.order_products"
      :bordered="true"
      :striped="true"
      :narrowed="true"
      :hoverable="true"
      class="pointer"
      @click="editOrderProduct"
    >
      <b-table-column label="Name" v-slot="props">
        {{ props.row.id }} - {{ props.row.name }}
      </b-table-column>

      <b-table-column label="Amount" v-slot="props">
        {{ props.row.amount }}
      </b-table-column>

            <b-table-column label="BTW type" v-slot="props">
        {{ props.row.btwtype }}
      </b-table-column>



      <b-table-column label="Price" v-slot="props">
        {{ euroFromCents(props.row.price * props.row.amount) }}
      </b-table-column>
    </b-table>

    <modal
      v-if="selectedProduct"
      v-on:close="closeModal"
      :product="selectedProduct"
      :order="order"
    />
  </div>
</template>

<script>
import modal from "./AdminEditOrderProductModal.vue";

export default {
  components: {
    modal,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedProduct: null,
    };
  },
  computed: {},
  methods: {
    editOrderProduct(product) {
      this.selectedProduct = product;
    },
    closeModal(cancel) {
      if (cancel) {
        this.$store.dispatch("adminOrders/getOrders");
      }
      this.selectedProduct = null;
    },
  },
  mounted() {},
};
</script>
