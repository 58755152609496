export const getUsers = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-get-users";
  data.method = "get";
  data.loading = "getusers";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setUsers", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const makeUserAdmin = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-set-role-to-admin/" + payload.id;
  data.method = "post";
  data.loading = "changinguserrole";
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("updateUser", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const setUserRoleToUser = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-set-role-to-user/" + payload.id;
  data.method = "post";
  data.loading = "changinguserrole";
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("updateUser", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const lockUser = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-lock-user/" + payload.id;
  data.method = "post";
  data.loading = "changinguserrole";
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("updateUser", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const unlockUser = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-unlock-user/" + payload.id;
  data.method = "post";
  data.loading = "changinguserrole";
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("updateUser", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const deleteUser = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-delete-user/" + payload.id;
  data.method = "post";
  data.loading = "changinguserrole";
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("deleteUser", payload);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const searchUsers = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-search-user";
  data.method = "post";
  data.loading = "changinguserrole";
  data.data = {
    zoekwaarde: payload,
  };
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const setUser = function ({ dispatch, commit }, payload) {
  commit("setUser", payload);
};

export const getUser = function ({ dispatch, commit }, payload) {
  const data = {};
  data.url = "admin-get-user/" + payload;
  data.method = "get";
  data.loading = "getusers";
  data.data = payload;
  return dispatch("axiosWeb", data, { root: true })
    .then((response) => {
      commit("setUser", response.data);
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
