<style scoped>
.content {
  z-index: 999999;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.box {
}

.loadingBackground {
  background: #e8e8e8 !important;
}
</style>

<template>
  <div class="content">
    <div class="container box">
      <b-loading
        v-model="isLoading"
        class="loadingBackground"
        :can-cancel="false"
        v-if="!somethingwrong"
      >
        <b-icon
          pack="fas"
          icon="sync-alt"
          size="is-large"
          custom-class="fa-spin"
        >
        </b-icon>
      </b-loading>
      <span v-if="order && order.id">
        <div class="title is-3" v-if="order.payment_status === 'paid'">
          Order payment confirmed
        </div>
        <div class="title is-3" v-else-if="order.payment_status === 'canceled'">
          Order canceled
        </div>
        <div class="title is-3" v-else>Something went wrong</div>
        <div v-if="order.payment_status === 'paid'">
          Your payment for order {{ order.order_year }}-{{
            order.order_number
          }}
          has been received. <br />
          We will send you an email soon with the details of your order. <br />
          <br />
          If you have not received an email within 2 hours, please check your
          junk folder or contact
          <a href="mailto:info@knuskeramiek.nl">info@knuskeramiek.nl</a>.<br />
          <br />
          Thank you for supporting me!
          <br />
          <br />
          <button
            class="button is-fullwidth is-primary"
            @click="returnToShop()"
          >
            Return to shop
          </button>
        </div>
        <div
          v-else-if="
            order.payment_status === 'canceled' ||
            order.payment_status === 'failed' ||
            order.payment_status === 'expired'
          "
        >
          Your order {{ order.order_year }}-{{ order.order_number }} has been
          canceled.<br />
          We will send you an email soon with a confirmation for canceling this
          order. <br />
          <br />
          If you have not received an email within 10 minutes, check your spam
          folder or contact
          <a href="mailto:info@knuskeramiek.nl">info@knuskeramiek.nl</a>.<br />
          <br />
          Hope to see your back some day!
          <br />
          <br />
          <button
            class="button is-fullwidth is-primary"
            @click="returnToShop()"
          >
            Return to shop
          </button>
        </div>

        <div v-else>
          We were unable to get the status of your order. <br />
          A orderconfirmed mail should have been send to your email. <br />
          <br />
          If you have not received an email within 10 minutes, please check your
          junk folder or contact
          <a href="mailto:info@knuskeramiek.nl">info@knuskeramiek.nl</a>.<br />
          <br />
          Thank you for supporting me!
          <br />
          <br />
          <button
            class="button is-fullwidth is-primary"
            @click="returnToShop()"
          >
            Return to shop
          </button>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      isLoading: true,
      order: {},
      somethingwrong: false,
      timeout: null,
    };
  },
  computed: {},
  methods: {
    returnToShop() {
      this.$router.replace({ name: "webshop-categories" });
    },
    joinOrderChannel(order) {
      window.echo
        .channel("orderChannel_" + this.order.id)
        .listen(".updateOrderStatus", (message) => {
          this.order.payment_status = message.payment_status;
          this.order.shipping_status = message.shipping_status;
          this.handleStatusChange(this.order);
        });
    },
    startLastCallTimeout() {
      this.timeout = setTimeout(
        function () {
          this.somethingwrong = true;
        }.bind(this),
        20000
      );
    },
    handleStatusChange(order) {
      if (!order) {
        return;
      }

      if (order.payment_status === "paid") {
        clearTimeout(this.timeout);
        this.isLoading = false;
      }

      if (order.payment_status === "canceled") {
        clearTimeout(this.timeout);
        this.isLoading = false;
      }

      if (order.payment_status === "failed") {
        clearTimeout(this.timeout);
        this.isLoading = false;
      }

      if (order.payment_status === "expired") {
        clearTimeout(this.timeout);
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.$store
      .dispatch("shop/getOrder", this.$router.currentRoute.params.order)
      .then((response) => {
        this.order = response.data;
        if (
          response.data &&
          response.data.payment_status === "waiting for payment"
        ) {
          this.joinOrderChannel(response.data);
        } else {
          this.handleStatusChange(response.data);
        }
      });
    this.startLastCallTimeout();
  },
};
</script>
